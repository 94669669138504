<script lang="ts">
	import type { Page as PageType } from '$models/Page/Page';
	import { afterNavigate, beforeNavigate } from '$app/navigation';
	import Hamburger from '$components/UI/Hamburger.svelte';
	import CartIcon from '$components/UI/Icons/CartIcon.svelte';
	import UserIcon from '$components/UI/Icons/UserIcon.svelte';
	import HeaderMenu from '$components/UI/Menus/HeaderMenu.svelte';
	import ToggleMenu from '$components/UI/Menus/ToggleMenu.svelte';
	import { createMobileMenuState, setMobileMenuState } from '$lib/state/menu.svelte';
	import { getCartStore } from '$lib/stores';

	let { page }: { page: PageType } = $props();

	const cartStore = getCartStore();

	// Prepare the menu interactivity
	let scrollY: number = $state(0);
	let scrollTreshold = page.layout.ribbon.length ? 40 : 0;

	beforeNavigate(() => {
		mobileMenuState.setActive(false);
	});

	// Create and set mobile menu state for use by child components.
	const mobileMenuState = createMobileMenuState();
	setMobileMenuState(mobileMenuState);
</script>

<header
	class="header"
	class:has-promotion-bar={page.layout.promotionBar.show}
	class:is-fixed={page.layout.isNavigationFixed ? scrollY > scrollTreshold : false}
>
	{#if page.layout.promotionBar.show}
		<div
			class="header-promotion-bar"
			style:background-color={page.layout.promotionBar.backgroundColor}
			style:color={page.layout.promotionBar.textColor}
		>
			<div class="header-promotion-bar-container container">
				{@html page.layout.promotionBar.text}
			</div>
		</div>
	{/if}
	<div class="header-container container">
		<figure class="header-logo">
			<a href="/">
				{@html page.layout.logo}
			</a>
		</figure>

		{#if page.layout.showNavigation}
			<HeaderMenu navigation={page.layout.navigation} />
		{/if}
		<div class="header-actions">
			{#await $cartStore then cart}
				{#if cart !== null}
					<div id="cart-icon" class="header-action">
						<a href={page.layout.pages.cartPage}>
							<span class="header-action-dot"></span>
							<CartIcon />
							<span class="header-action-text">{page.layout.translations.buttonLabels.cart}</span>
						</a>
					</div>
				{/if}
			{/await}
			<div class="header-action">
				<a href={page.layout.pages.accountPage}>
					<UserIcon />
				</a>
			</div>
			<div class="header-action header-action--hamburger">
				<button class:is-active={mobileMenuState.isActive()} onclick={mobileMenuState.toggle}>
					<Hamburger active={mobileMenuState.isActive()} />
				</button>
			</div>
			<ToggleMenu navigation={page.layout.navigation} active={mobileMenuState.isActive()} />
		</div>
	</div>
</header>
<svelte:window bind:scrollY />

<style lang="postcss" global>
	.ribbon + .header {
		top: 40px;
	}

	.header {
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0;
		right: 0;
		color: var(--header-text-color);
		background-color: var(--header-bg-color);
		transition:
			color var(--transition) ease,
			background-color var(--transition) ease;

		@media (--xl) {
			background-color: transparent;
		}

		&.is-fixed {
			position: fixed;
			top: 0 !important;
			color: var(--header-text-color-fixed);
			background-color: var(--header-bg-color-fixed);
		}

		&-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: var(--spacing-5);
			padding-bottom: var(--spacing-5);
			transition: padding var(--transition);

			@media (--xl) {
				padding-top: var(--spacing-8);
				padding-bottom: var(--spacing-8);
			}

			.is-fixed & {
				padding-top: var(--spacing-3);
				padding-bottom: var(--spacing-3);
				height: 80px;
			}
		}

		&-logo {
			width: 100%;
			max-width: 124px;
			margin-right: var(--spacing-6);
			transition: max-width var(--transition);

			a {
				display: block;
			}

			@media (--xl) {
				max-width: 180px;
				margin-right: var(--spacing-6);
			}

			.is-fixed & {
				max-width: 124px !important;
			}
		}

		&-actions {
			display: flex;
			align-items: center;
			margin-left: auto;
			position: relative;

			@media (--xl) {
				margin-left: auto;
			}
		}

		&-action {
			position: relative;
			margin-left: var(--spacing-3);

			a,
			button {
				position: relative;
				width: 40px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: var(--action-color);
				border: 2px solid var(--action-color);
				border-radius: var(--action-border-radius);
				box-sizing: border-box;

				&.is-active {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
					border-color: var(--action-active-color);
					background-color: var(--action-active-color);
				}
			}

			&-dot {
				display: block;
				position: absolute;
				top: -5px;
				right: -5px;
				z-index: 1;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background-color: var(--action-dot-color);
			}

			&-text {
				position: absolute;
				top: calc(100% + 4px);
				left: 50%;
				transform: translateX(-50%);
				font-size: 9px;
				text-transform: uppercase;
				font-weight: var(--font-weight-5);
				transition: opacity var(--transition);

				.is-fixed & {
					opacity: 0;
				}
			}

			&:first-child {
				margin-left: 0;
			}

			&--hamburger {
				@media (--xl) {
					display: none;
				}
			}
		}

		&-promotion-bar {
			display: block;
			position: relative;
			z-index: 1;
			color: var(--color-white);
			font-size: var(--font-size-0);
			line-height: var(--font-lineheight-0);
			text-align: center;
			background-color: var(--color-black);
			padding-top: var(--spacing-3);
			padding-bottom: var(--spacing-3);

			@media (--sm) {
				font-size: var(--font-size-1);
			}

			strong {
				font-weight: var(--font-weight-5);
			}

			a {
				text-decoration: underline;
				font-weight: var(--font-weight-5);
				color: inherit;
				transition: opacity var(--transition);

				&:hover {
					opacity: 0.75;
				}
			}
		}
	}
</style>
