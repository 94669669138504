<script lang="ts">
	import type { OrderConfigurator } from '$models/OrderConfigurator/OrderConfigurator';
	import { getSiteContext } from '$lib/context/site';
	import { formatCurrency } from '$lib/utils';

	// Component properties
	export let translations: any;
	export let configurator: OrderConfigurator;
	export let configuratorStore: any;
	export let step: Step;
	export let index: number;
	export let entityEventHandlers: any;

	const site = getSiteContext();

	let stepEntityAmount: number = 0;
	$: {
		stepEntityAmount = 0;
		configuratorStore.getEntities(step.entityType).forEach((product) => {
			stepEntityAmount += configuratorStore.getSelectedEntityAmount(step.entityType, product.id);
		});
	}
</script>

<div class="step">
	<div class="step-inner">
		<div class="step-main">
			<div class="step-header">
				<h3 class="step-title">
					{step.listTitle}
				</h3>
				<div class="step-subtitle">
					{step.listSubTitle}
				</div>
			</div>
			<div class="step-products">
				{#each configuratorStore.getEntities(step.entityType) as product}
					<div class="step-product" data-product-external-id={product.id}>
						<div class="step-product-title">
							{product.name}
						</div>
						<div class="step-product-input">
							<button on:click={(e) => entityEventHandlers.onDecreaseButtonClick(e, product)}>
								-
							</button>
							<input
								on:change={(e) => entityEventHandlers.onInputChange(e, product)}
								type="text"
								value={configuratorStore.getSelectedEntityAmount(step.entityType, product.id)}
							/>
							<button on:click={(e) => entityEventHandlers.onIncreaseButtonClick(e, product)}>
								+
							</button>
						</div>
					</div>
				{/each}
			</div>
			<div class="step-totals">
				<div class="step-totals-inner">
					{#if configuratorStore.entityErrorText[step.entityType] !== null}
						<div class="step-totals-error">
							{configuratorStore.entityErrorText[step.entityType]}
						</div>
					{/if}
					<div class="step-totals-count">
						{configuratorStore.getEntityTotalText(step.entityType)}
					</div>
				</div>
			</div>
			<div class="step-footer">
				{#if step.entityType === 'non_subscribable'}
					{#if stepEntityAmount > 0}
						{#await configuratorStore.prices then prices}
							<div class="step-price">
								<div class="step-price-count">
									{formatCurrency(prices.totalNonSubscribableProductsPrice, site.locale.code)}
								</div>
							</div>
						{/await}
					{/if}
					<div class="step-button">
						<button
							class="button button--secondary"
							on:click={configuratorStore.goToNextStepIfValid[step.entityType]}
						>
							{step.buttonText}
						</button>
					</div>
				{:else}
					{#if stepEntityAmount > 0 && configuratorStore.entityErrorText[step.entityType] === null}
						{#await configuratorStore.prices then prices}
							{#if prices.productsLowestRecurringPrice !== null}
								{#if prices.discountAmount > 0}
									{@const discountedPrice =
										prices.productsLowestRecurringPrice - prices.discountAmount}

									<div class="step-discount-price">
										{#if site.showUnitPrices}
											<div class="step-discount-price-per-unit">
												{translations.fromPriceLabel}
												{formatCurrency(discountedPrice / stepEntityAmount, site.locale.code)}
												{translations.perUnitText.replaceAll(
													'%product%',
													configurator.productCategory?.translations.unitNameSingle ?? '%product%'
												)}
											</div>
										{:else}
											<div class="step-discount-price-value">
												{translations.fromPriceLabel}
												{formatCurrency(discountedPrice, site.locale.code)}
											</div>
										{/if}
									</div>
								{:else}
									<div class="step-price">
										{#if !site.showUnitPrices}
											<div class="step-price-label">{translations.fromPriceLabel}</div>
											<div class="step-price-count">
												{formatCurrency(prices.productsLowestRecurringPrice, site.locale.code)}
											</div>
										{/if}
										{#if site.showUnitPrices}
											<div class="step-price-label">
												{translations.fromPriceLabel}
												{formatCurrency(
													prices.productsLowestRecurringPrice / stepEntityAmount,
													site.locale.code
												)}
												{translations.perUnitText.replaceAll(
													'%product%',
													configurator.productCategory?.translations.unitNameSingle ?? '%product%'
												)}
											</div>
										{/if}
									</div>
								{/if}
							{/if}
						{/await}
					{/if}
					<div class="step-button">
						{#await configuratorStore.prices}
							<button
								class="button button--secondary disabled"
								on:click={configuratorStore.goToNextStepIfValid[step.entityType]}
							>
								{step.buttonText}
							</button>
						{:then prices}
							<button
								class="button button--secondary"
								class:disabled={prices.productsLowestRecurringPrice === null}
								on:click={configuratorStore.goToNextStepIfValid[step.entityType]}
							>
								{step.buttonText}
							</button>
						{/await}
					</div>
				{/if}
			</div>
		</div>
		{#if step.listImage !== null}
			<figure class="step-image">
				<img src={step.listImage.url} alt={step.listImage.alt} />
			</figure>
		{/if}
	</div>
</div>
