<script lang="ts">
	import type { CardsBlock } from '$models/Block/CardsBlock';
	import type { EmblaCarouselType } from 'embla-carousel';
	import Button from '$components/UI/Buttons/Button.svelte';
	import Slider from '$components/UI/Slider/Slider.svelte';
	import { sanitizeHtml } from '$lib/utils';

	// Component properties
	export let block: CardsBlock;

	let cardSlider: EmblaCarouselType | undefined;
	let scrollSnapList: any[];
	let selectedScrollSnap = 0;

	function initSlider(e) {
		cardSlider = e.detail as EmblaCarouselType;
		scrollSnapList = cardSlider.scrollSnapList();
		// Keep track of snap when it changes
		cardSlider.on('select', (instance) => {
			selectedScrollSnap = instance.selectedScrollSnap();
		});
		// Update snap on resize
		cardSlider.on('resize', (instance) => {
			scrollSnapList = instance.scrollSnapList();
			selectedScrollSnap = instance.selectedScrollSnap();
		});
	}
</script>

{#if block.introText}
	<div class="block-intro">
		{@html block.introText}
	</div>
{/if}

<div class="cards cards--count-{block.cards.length}">
	<Slider
		options={{ loop: false, align: 'start', inViewThreshold: 1 }}
		let:Slide
		on:init={initSlider}
	>
		{#each block.cards as card, index}
			<Slide>
				<div class="card">
					{#if card.image}
						<figure class="card-image">
							<img src={card.image.url} alt={card.image.alt} />
						</figure>
					{/if}
					<div class="card-content">
						{#if card.title}
							<h3 class="card-title">{@html card.title}</h3>
						{/if}

						{#if card.text}
							<div class="card-text rich-text">
								{@html sanitizeHtml(card.text)}
							</div>
						{/if}

						{#if card.button}
							<div class="card-button">
								<Button type="anchor" href={card.button.url}>
									{card.button.title}
								</Button>
							</div>
						{/if}
					</div>
				</div>
			</Slide>
		{/each}
	</Slider>
	{#if cardSlider && scrollSnapList && scrollSnapList.length > 1}
		<div class="slider-pagination">
			{#each scrollSnapList as snapPosition, index}
				<button
					class="slider-bullet"
					class:is-active={index === selectedScrollSnap}
					on:click={() => cardSlider?.scrollTo(index)}
				/>
			{/each}
		</div>
	{/if}
</div>

<style lang="postcss" global>
	.cards {
		&:not(:last-child) {
			margin-bottom: var(--spacing-10);

			@media (--lg) {
				margin-bottom: var(--spacing-12);
			}
		}

		.slider {
			@media (--sm) {
				--slide-size: calc(100% / 2);
			}

			@media (--md) {
				--slide-size: calc(100% / 3);
			}

			@media (--lg) {
				--slide-size: 25%;
			}
		}

		/* modifiers */

		&--count-2 {
			.slider {
				--slide-size: calc(100% / 2);
				@media (--md) {
					--slide-size: calc(100% / 3);
				}

				@media (--lg) {
					--slide-size: 25%;
				}
			}
		}

		&--count-2,
		&--count-3 {
			.slider {
				&-container {
					@media (--sm) {
						justify-content: center;
					}
				}
			}
		}

		&--count-4 {
			.slider {
				@media (--sm) {
					--slide-size: 50%;
				}

				@media (--lg) {
					--slide-size: 25%;
				}
			}
		}

		&--count-5 {
			.slider {
				@media (--md) {
					--slide-size: calc(100% / 4);
				}
				@media (--lg) {
					--slide-size: calc(100% / 5);
				}
			}
		}

		&--count-6,
		&--count-7,
		&--count-8,
		&--count-9,
		&--count-10 {
			.slider {
				@media (--md) {
					--slide-size: calc(100% / 4);
				}
				@media (--lg) {
					--slide-size: calc(100% / 5);
				}
				@media (--xl) {
					--slide-size: calc(100% / 6);
				}
			}
		}
	}

	.card {
		& {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
		&-image {
			max-width: 248px;
			margin: 0 auto var(--spacing-6);
			img {
				display: block;
			}
		}

		&-content {
			flex: 1;
			display: flex;
			flex-direction: column;
			text-align: center;
		}

		&-title {
			text-transform: var(--cards-title-text-tranform);
			font-family: var(--cards-title-font-family);
			font-size: var(--cards-title-font-size);
			font-weight: var(--cards-title-font-weight);
			line-height: var(--font-lineheight-1);
			margin-bottom: var(--spacing-2);
		}

		&-text {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			flex: 1;
			font-size: var(--cards-text-font-size);
			line-height: var(--font-lineheight-2);

			@media (--sm) {
				max-width: 85%;
				margin: 0 auto;
			}

			&:not(:last-child) {
				margin-bottom: var(--spacing-4);
			}
		}

		&-button {
			margin-top: auto;
		}

		/* boxed */

		&--boxed {
			background-color: white;
			padding: var(--spacing-4);
		}
	}
</style>
