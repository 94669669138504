<script lang="ts">
	// Type imports
	import type { Order } from '$models/Order/Order';
	import type { UserStoreData } from '$models/Store/UserStore';
	import type { LayoutData } from '$models/WordPress/LayoutData';
	// Utility imports
	import { onMount } from 'svelte';

	export let order: Order;
	export let user: UserStoreData;

	let tries = 0;

	function init() {
		// Do not initialize if the trustcard was already rendered
		if (document.getElementById('trustcard-container')) {
			return;
		}

		// Make sure trustcard methods are available, or wait
		if (
			typeof window.trustbadge !== 'undefined' &&
			typeof window.trustbadge.remove !== 'undefined'
		) {
			window.trustbadge.remove();
			window.trustbadge.reInitialize();
		} else if (tries < 20) {
			tries++;
			setTimeout(init, 500);
		}
	}

	onMount(init);
</script>

<div id="trustedShopsCheckout" style="display: none;">
	<span id="tsCheckoutOrderNr">{order.number}</span>
	<span id="tsCheckoutBuyerEmail">{user?.email}</span>
	<span id="tsCheckoutOrderAmount">{order.total}</span>
	<span id="tsCheckoutOrderCurrency">EUR</span>
	<span id="tsCheckoutOrderPaymentType">Mollie</span>
	<span id="tsCheckoutOrderEstDeliveryDate">{order.deliveryDate}</span>
</div>
