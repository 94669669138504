<script lang="ts">
	import type { ShippingStatus } from '$models/Status/ShippingStatus';
	import { page } from '$app/stores';
	import CheckIcon from '$components/UI/Icons/CheckIcon.svelte';
	import ClockIcon from '$components/UI/Icons/ClockIcon.svelte';

	export let status: ShippingStatus;

	$: translations = $page.data.page.layout.translations;
</script>

<div class="shipping-status">
	{#if status === 'delivered'}
		<figure class="shipping-status-icon">
			<CheckIcon />
		</figure>
	{:else}
		<figure class="shipping-status-icon">
			<ClockIcon />
		</figure>
	{/if}
	{#if translations.shippingStatus && translations.shippingStatus[status]}
		{translations.shippingStatus[status]}
	{/if}
</div>

<style>
	.shipping-status {
		display: flex;
		align-items: center;

		&-icon {
			max-width: 13px;
			color: var(--color-grey-3);
			margin-right: var(--spacing-2);
		}
	}
</style>
