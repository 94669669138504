<script lang="ts">
	import type { ContentPage } from '$models/Page/ContentPage';
	import { sanitizeHtml } from '$lib/utils';

	// Component properties
	export let page: ContentPage;
</script>

<div class="block">
	<div class="block-container container">
		<div class="block-main">
			<div class="block-header block-header--align-left">
				<h1 class="block-title">
					{page.title}
				</h1>
			</div>
			<div class="rich-text">
				{@html sanitizeHtml(page.content)}
			</div>
		</div>
	</div>
</div>
