<script lang="ts">
	import type { ContentBlock } from '$models/Block/ContentBlock';
	import { goto } from '$app/navigation';
	import { page as pageStore } from '$app/stores';
	import Button from '$components/UI/Buttons/Button.svelte';
	import { getCartStore } from '$lib/stores';
	import { sanitizeHtml } from '$lib/utils';

	// Component properties
	export let block: ContentBlock;

	const cartStore = getCartStore();

	// TODO: MAKE THE ADD TO CART BUNDLE BUTTON CONFIGURABLE IN WORDPRESS
	let onButtonClick = null;
	$: {
		if (block.button.url === '#add-to-cart-bundle') {
			onButtonClick = (event) => {
				event.preventDefault();
				cartStore.setCart({
					id: null,
					frequency: 8,
					products: [
						{
							id: '99968d2c-6371-4a7d-a336-c382995d5a38',
							quantity: 1
						},
						{
							id: '99968d2c-60ae-4be4-8ac7-e691cfc06d2d',
							quantity: 1
						},
						{
							id: '99968d2c-5c3e-40c2-b364-a758acf55fda',
							quantity: 1
						}
					],
					nonSubscribableProducts: [
						{
							id: '99968d2c-6741-4686-aa30-bdaaa752ca0e',
							quantity: 3
						}
					],
					couponCode: 'kortingscode',
					deliveryDate: null,
					deliveryCompanyName: null,
					billingAddressId: null,
					shippingAddressId: null,
					deliveryNote: null
				});
				goto($pageStore.data.page.layout.pages.cartPage);
			};
		}
	}
</script>

<div class="content">
	<div class="content-container" class:is-inverted={block.imageAlignment === 'inverted'}>
		{#if block.text}
			<div class="content-text">
				<div class="rich-text">
					{@html block.text}
					{#if block.button}
						<div class="content-button content-button--{block.buttonAlignment}">
							{#if onButtonClick !== null}
								<Button type="anchor" href={block.button.url} on:click={onButtonClick}
									>{block.button.title}</Button
								>
							{:else}
								<Button type="anchor" href={block.button.url}>{block.button.title}</Button>
							{/if}
						</div>
					{/if}
				</div>
			</div>
		{/if}
		{#if block.image}
			<figure class="content-visual">
				<img src={block.image.url} alt={block.image.alt} />
			</figure>
		{/if}
	</div>
</div>

<style lang="postcss">
	.content {
		max-width: var(--content-block-max-width);
		margin: 0 auto;

		&-container {
			@media (--sm) {
				display: flex;
				align-items: flex-start;
			}
		}

		&-text {
			flex: 1;
			align-self: center;
			margin-bottom: var(--spacing-6);

			@media (--sm) {
				margin-bottom: 0;

				.is-inverted & {
					order: 2;
				}
			}
		}

		&-button {
			&--center {
				display: flex;
				justify-content: center;
				margin: 0 auto;
			}
		}

		&-visual {
			@media (--sm) {
				width: var(--content-block-image-width);
				margin-left: var(--spacing-12);

				.is-inverted & {
					order: 1;
					margin-left: 0;
					margin-right: var(--spacing-12);
				}
			}

			img {
				display: block;
				max-width: 100%;
				height: auto;
			}
		}
	}
</style>
