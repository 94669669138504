import type { AccountAddressDetailPage as AccountAddressDetailPageType } from '$models/Page/AccountAddressDetailPage';
import type { AccountAddressesPage as AccountAddressesPageType } from '$models/Page/AccountAddressesPage';
import type { AccountCustomerInfoPage as AccountCustomerInfoPageType } from '$models/Page/AccountCustomerInfoPage';
import type { AccountOrderDetailPage as AccountOrderDetailPageType } from '$models/Page/AccountOrderDetailPage';
import type { AccountOrdersPage as AccountOrdersPageType } from '$models/Page/AccountOrdersPage';
import type { AccountPage as AccountPageType } from '$models/Page/AccountPage';
import type { AccountSubscriptionDetailPage as AccountSubscriptionDetailPageType } from '$models/Page/AccountSubscriptionDetailPage';
import type { AccountSubscriptionsPage as AccountSubscriptionsPageType } from '$models/Page/AccountSubscriptionsPage';
import type { BlogArticlePage as BlogArticlePageType } from '$models/Page/BlogArticlePage';
import type { BlogOverviewPage as BlogOverviewPageType } from '$models/Page/BlogOverviewPage';
import type { CartPage as CartPageType } from '$models/Page/CartPage';
import type { CheckoutPage as CheckoutPageType } from '$models/Page/CheckoutPage';
import type { ContentPage as ContentPageType } from '$models/Page/ContentPage';
import type { LandingPage as LandingPageType } from '$models/Page/LandingPage';
import type { OrderStatusPage as OrderStatusPageType } from '$models/Page/OrderStatusPage';
import type { Page, PageType } from '$models/Page/Page';
import AccountLayout from '$components/Account/AccountLayout.svelte';
import AccountAddressDetailPage from '$components/Page/AccountAddressDetailPage.svelte';
import AccountAddressesPage from '$components/Page/AccountAddressesPage.svelte';
import AccountCustomerInfoPage from '$components/Page/AccountCustomerInfoPage.svelte';
import AccountOrderDetailPage from '$components/Page/AccountOrderDetailPage.svelte';
import AccountOrdersPage from '$components/Page/AccountOrdersPage.svelte';
import AccountPage from '$components/Page/AccountPage.svelte';
import AccountSubscriptionDetailPage from '$components/Page/AccountSubscriptionDetailPage.svelte';
import AccountSubscriptionsPage from '$components/Page/AccountSubscriptionsPage.svelte';
import BlogArticlePage from '$components/Page/BlogArticlePage.svelte';
import BlogOverviewPage from '$components/Page/BlogOverviewPage.svelte';
import CartPage from '$components/Page/CartPage.svelte';
import CheckoutPage from '$components/Page/CheckoutPage.svelte';
import ContentPage from '$components/Page/ContentPage.svelte';
import LandingPage from '$components/Page/LandingPage.svelte';
import OrderStatusPage from '$components/Page/OrderStatusPage.svelte';

// Page component data. Keep here to keep page map configuration in one file.
interface PageComponentData {
	component:
		| typeof BlogArticlePage
		| typeof BlogOverviewPage
		| typeof CartPage
		| typeof CheckoutPage
		| typeof ContentPage
		| typeof LandingPage
		| typeof AccountPage
		| typeof AccountCustomerInfoPage
		| typeof AccountOrdersPage
		| typeof AccountOrderDetailPage
		| typeof AccountSubscriptionsPage
		| typeof AccountSubscriptionDetailPage
		| typeof OrderStatusPage;
	page: PageType;
}

export function getPageComponentData(page: Page): PageComponentData {
	let pageConfig: PageComponentData | null = null;
	switch (page.type) {
		case 'blogArticle':
			pageConfig = {
				layout: null,
				component: BlogArticlePage,
				page: page as BlogArticlePageType
			};
			break;
		case 'blogOverview':
			pageConfig = {
				layout: null,
				component: BlogOverviewPage,
				page: page as BlogOverviewPageType
			};
			break;
		case 'cart':
			pageConfig = {
				layout: null,
				component: CartPage,
				page: page as CartPageType
			};
			break;
		case 'checkout':
			pageConfig = {
				layout: null,
				component: CheckoutPage,
				page: page as CheckoutPageType
			};
			break;
		case 'content':
			pageConfig = {
				layout: null,
				component: ContentPage,
				page: page as ContentPageType
			};
			break;
		case 'landing':
			pageConfig = {
				layout: null,
				component: LandingPage,
				page: page as LandingPageType
			};
			break;
		case 'account':
			pageConfig = {
				layout: AccountLayout,
				component: AccountPage,
				page: page as AccountPageType
			};
			break;
		case 'account-customer-info':
			pageConfig = {
				layout: AccountLayout,
				component: AccountCustomerInfoPage,
				page: page as AccountCustomerInfoPageType
			};
			break;
		case 'account-orders':
			pageConfig = {
				layout: AccountLayout,
				component: AccountOrdersPage,
				page: page as AccountOrdersPageType
			};
			break;
		case 'account-order-detail':
			pageConfig = {
				layout: AccountLayout,
				component: AccountOrderDetailPage,
				page: page as AccountOrderDetailPageType
			};
			break;
		case 'account-subscriptions':
			pageConfig = {
				layout: AccountLayout,
				component: AccountSubscriptionsPage,
				page: page as AccountSubscriptionsPageType
			};
			break;
		case 'account-subscription-detail':
			pageConfig = {
				layout: AccountLayout,
				component: AccountSubscriptionDetailPage,
				page: page as AccountSubscriptionDetailPageType
			};
			break;
		case 'account-addresses':
			pageConfig = {
				layout: AccountLayout,
				component: AccountAddressesPage,
				page: page as AccountAddressesPageType
			};
			break;
		case 'account-address-detail':
			pageConfig = {
				layout: AccountLayout,
				component: AccountAddressDetailPage,
				page: page as AccountAddressDetailPageType
			};
			break;
		case 'order-status':
			pageConfig = {
				layout: null,
				component: OrderStatusPage,
				page: page as OrderStatusPageType
			};
			break;
	}

	return pageConfig;
}
