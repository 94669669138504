<script lang="ts">
	import type { Form } from '$models/Form/Form';
	import ForgotPasswordForm from '$components/Form/AuthForm/ForgotPasswordForm.svelte';
	import LoginForm from '$components/Form/AuthForm/LoginForm.svelte';
	import RegisterForm from '$components/Form/AuthForm/RegisterForm.svelte';
	import ResetPasswordForm from '$components/Form/AuthForm/ResetPasswordForm.svelte';
	import { writable } from 'svelte/store';

	// Component properties
	export let form: Form;
	export let extraForm: any;

	let state = writable({
		resetPasswordToken: null,
		initialEmail: null,
		setInitialEmail: (initialEmail) => {
			$state.initialEmail = initialEmail;
		},
		currentForm: form.initialForm,
		setCurrentForm: (currentForm) => {
			$state.currentForm = currentForm;
		}
	});

	const urlParams = new URLSearchParams(window.location.search);
	if (urlParams.has('token')) {
		$state.resetPasswordToken = urlParams.get('token');
		$state.currentForm = 'resetPassword';
	}

	function showForgotPasswordForm() {
		$state.setCurrentForm('forgotPassword');
	}
	function showRegisterForm() {
		$state.setCurrentForm('register');
	}
	function showLoginForm() {
		$state.setCurrentForm('login');
	}
	function showLoginFormWithInitialEmail(email) {
		$state.setInitialEmail(email);
		$state.setCurrentForm('login');
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
	}
</script>

<div
	class="box"
	class:box--width-small={form.register === null}
	class:box--width-medium={form.register !== null}
>
	{#if $state.currentForm === 'login'}
		<LoginForm
			form={form.login}
			initialEmail={$state.initialEmail}
			{showForgotPasswordForm}
			showRegisterForm={form.register !== null ? showRegisterForm : null}
			{extraForm}
		/>
	{:else if $state.currentForm === 'forgotPassword'}
		<ForgotPasswordForm form={form.forgotPassword} {showLoginForm} {extraForm} />
	{:else if $state.currentForm === 'register'}
		<RegisterForm
			form={form.register}
			{showLoginForm}
			{showLoginFormWithInitialEmail}
			{extraForm}
		/>
	{:else if $state.currentForm === 'resetPassword'}
		<ResetPasswordForm
			form={form.resetPassword}
			token={$state.resetPasswordToken}
			{showLoginForm}
			{extraForm}
		/>
	{/if}
</div>
