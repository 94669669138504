<script lang="ts">
	import type { Order } from '$models/Order/Order';
	import type { OrderConfigurator } from '$models/OrderConfigurator/OrderConfigurator';
	import type { Subscription } from '$models/Subscription/Subscription';
	import { page as pageStore } from '$app/stores';
	import Step from '$components/OrderConfigurator/Step/Step.svelte';
	import {
		formatNumericString,
		formatQuantitySelectorString,
		subscriptionToOrder,
		throttle
	} from '$lib/utils';
	import { getOrderPrices } from '$lib/webparking';
	import { onMount } from 'svelte';
	import { writable } from 'svelte/store';

	// Component properties
	export let translations: any;
	export let couponCodeRecurring: string | null;
	export let couponCodeOnetime: string | null;
	export let configurator: OrderConfigurator;
	export let defaultItems: any;
	export let defaultFrequency: any;
	export let defaultQuantity: any;
	export let defaultOrder: Order;
	export let defaultSubscription: Subscription | undefined;
	export let onSubmit: (order: Order) => void;

	const orderPricesDelay = 150;

	let quantityMultiplier = 1;

	let resolveCouponCodePromise = null;
	const couponCodePromise = new Promise((resolve, reject) => {
		resolveCouponCodePromise = resolve;
	});

	// Map the products from the CMS with products from the backend.
	// This is needed here because we need the quantityMultiplier values.
	// @TODO: Does this need to happen here? See /src/routes/layout.server.ts
	if (configurator.products.length) {
		configurator.products = configurator.products.map((product) => {
			const externalProduct = $pageStore.data.products.regular.find((p) => p.id === product.id);
			return {
				...product,
				quantityMultiplier: externalProduct ? externalProduct.quantityMultiplier : 1
			};
		});
		if (configurator.products[0] && configurator.products[0].quantityMultiplier > 1) {
			quantityMultiplier = configurator.products[0].quantityMultiplier;
		}
	}

	if (defaultSubscription !== undefined) {
		defaultOrder = subscriptionToOrder(defaultSubscription);
	}

	// If no defaults are passed, use the defaults from CMS data.
	if (defaultItems === null || defaultItems == undefined) {
		const _c = { ...configurator.defaultItems.product };
		Object.keys(_c).forEach((key) => {
			_c[key] = configurator.defaultItems.product[key] / quantityMultiplier;
		});

		// Remove all default items with a quantity of 0
		Object.keys(_c).forEach((key) => {
			if (_c[key] === 0) {
				delete _c[key];
			}
		});

		defaultItems = {
			non_subscribable: configurator.defaultItems.non_subscribable,
			product: _c
		};
	}
	if (defaultQuantity === null || defaultQuantity == undefined) {
		defaultQuantity = configurator.defaultQuantity;
	}
	if (defaultFrequency === null || defaultFrequency == undefined) {
		defaultFrequency = configurator.defaultFrequency;
	}

	// If a defaultOrder is passed in, use it as the base for default data.
	if (defaultOrder !== null && defaultOrder !== undefined) {
		defaultFrequency = defaultOrder.frequency;
		defaultQuantity = defaultOrder.products.reduce((accumulator, currentValue) => {
			return accumulator + currentValue.quantity;
		}, 0);
		defaultItems = {
			product: defaultOrder.products.reduce((accumulator, currentValue) => {
				accumulator[currentValue.id] = currentValue.quantity;
				return accumulator;
			}, {}),
			non_subscribable: defaultOrder.nonSubscribableProducts.reduce((accumulator, currentValue) => {
				accumulator[currentValue.id] = currentValue.quantity;
				return accumulator;
			}, {})
		};
	}

	function getOrder(frequency, items) {
		return {
			id: null,
			frequency: frequency,
			products: Object.entries(items.product)
				.filter(([id, quantity]) => quantity > 0)
				.map(([id, quantity]) => ({
					id,
					quantity
				})),
			nonSubscribableProducts: Object.entries(items.non_subscribable)
				.filter(([id, quantity]) => quantity > 0)
				.map(([id, quantity]) => ({ id, quantity })),
			couponCode: null,
			deliveryDate: null,
			deliveryCompanyName: null,
			billingAddressId: null,
			shippingAddressId: null,
			deliveryNote: null,
			poNumber: null
		};
	}

	function getOrderProducts(items) {
		return {
			products: Object.entries(items.product)
				.filter(([id, quantity]) => quantity > 0)
				.map(([id, quantity]) => ({
					id,
					quantity
				})),
			nonSubscribableProducts: Object.entries(items.non_subscribable)
				.filter(([id, quantity]) => quantity > 0)
				.map(([id, quantity]) => ({ id, quantity }))
		};
	}

	// Prepare a writable store for managing the configurator state
	let configuratorStore = writable({
		couponCodeRecurring: null,
		couponCodeOnetime: couponCodeOnetime,
		order: null,
		targetPrices: new Promise(() => {}),
		prices: new Promise(() => {}),
		items: defaultItems,
		steps: configurator.steps.map((step, index) => {
			const stepState = {
				isHidden: true,
				type: step.type
			};
			if (index === 0) {
				stepState.isHidden = false;
			}
			return stepState;
		}),
		hideStep: (index) => {
			$configuratorStore.steps[index].isHidden = true;
		},
		targetQuantity: defaultQuantity,
		targetQuantities: configurator.quantities,
		targetFrequency: defaultFrequency,
		targetFrequencies: configurator.frequencies,
		isRecurringOrder: true,
		entityErrorText: {
			non_subscribable: null,
			product: null
		},
		quantityMultiplier: quantityMultiplier,
		setTarget: async (frequency, quantity) => {
			$configuratorStore.targetQuantity = quantity;
			$configuratorStore.targetFrequency = frequency;
			$configuratorStore.targetQuantities = [$configuratorStore.targetQuantity];
			$configuratorStore.targetFrequencies = [$configuratorStore.targetFrequency];
			$configuratorStore.order = {
				...$configuratorStore.order,
				...getOrderProducts($configuratorStore.items),
				frequency: $configuratorStore.targetFrequency
			};

			const targetOrder = { ...$configuratorStore.order };
			const recurringProductAmount = $configuratorStore.order.products.reduce(
				(accumulator, currentValue) => accumulator + currentValue.quantity,
				0
			);
			if ($configuratorStore.targetQuantity !== recurringProductAmount) {
				targetOrder.products = [
					{
						id: configurator.products[0].id,
						quantity: $configuratorStore.targetQuantity
					}
				];
			}
			throttle($pageStore.data.page.slug, 'getOrderPrices', orderPricesDelay, () => {
				getOrderPrices(
					targetOrder,
					$configuratorStore.targetQuantities,
					$configuratorStore.couponCodeRecurring,
					$configuratorStore.couponCodeOnetime
				).then((orderPrices) => {
					if (orderPrices.productsLowestRecurringPrice !== null) {
						$configuratorStore.targetPrices = Promise.resolve(orderPrices);
					}
				});
			});
		},
		getEntityTotalText: (entityType) => {
			const singularProductName = configurator?.productCategory?.translations?.unitNameSingle;
			const pluralProductName = configurator?.productCategory?.translations?.unitNamePlural;
			return formatNumericString(
				translations.productAmountTextSingle.replaceAll(
					'%product%',
					singularProductName ?? '%product%'
				),
				translations.productAmountTextMultiple.replaceAll(
					'%products%',
					pluralProductName ?? '%products%'
				),
				$configuratorStore.getTotalAmount(entityType),
				'%quantity%'
			);
		},
		setIsRecurringOrder: (isRecurringOrder) => {
			$configuratorStore.isRecurringOrder = isRecurringOrder;
		},
		setEntityAmount: (entityType, productExternalId, amount) => {
			$configuratorStore.items[entityType][productExternalId] =
				amount / $configuratorStore.quantityMultiplier;
			$configuratorStore.order = {
				...$configuratorStore.order,
				...getOrderProducts($configuratorStore.items),
				frequency: $configuratorStore.targetFrequency
			};
			throttle($pageStore.data.page.slug, 'getOrderPrices', orderPricesDelay, () => {
				getOrderPrices(
					$configuratorStore.order,
					$configuratorStore.targetQuantities,
					$configuratorStore.couponCodeRecurring,
					$configuratorStore.couponCodeOnetime
				).then((orderPrices) => {
					if (orderPrices.productsLowestRecurringPrice !== null) {
						$configuratorStore.prices = Promise.resolve(orderPrices);
					}
				});
			});
		},
		setTargetFrequency: (event) => {
			$configuratorStore.targetFrequency = parseInt(event.target.value);
			$configuratorStore.order = {
				...$configuratorStore.order,
				...getOrderProducts($configuratorStore.items),
				frequency: $configuratorStore.targetFrequency
			};
			throttle($pageStore.data.page.slug, 'getOrderPrices', orderPricesDelay, () => {
				getOrderPrices(
					$configuratorStore.order,
					$configuratorStore.targetQuantities,
					$configuratorStore.couponCodeRecurring,
					$configuratorStore.couponCodeOnetime
				).then((orderPrices) => {
					if (orderPrices.productsLowestRecurringPrice !== null) {
						$configuratorStore.prices = Promise.resolve(orderPrices);
					}
				});
			});
		},
		goToNextStep: (event, options) => {
			const stepIndex = parseInt(event.target.closest('[data-step-index]').dataset.stepIndex);
			let nextStepIndex = stepIndex + 1;
			if (nextStepIndex < configurator.steps.length) {
				if (
					options !== undefined &&
					options.skipFrequencySelector &&
					$configuratorStore.steps[nextStepIndex].type === 'frequencySelector'
				) {
					nextStepIndex += 1;
				}
			}
			if (nextStepIndex < configurator.steps.length) {
				$configuratorStore.steps[nextStepIndex].isHidden = false;
				setTimeout(() => {
					const nextStep = document.getElementById(configurator.steps[nextStepIndex].htmlId);
					if (nextStep) {
						window.scrollTo({
							top: nextStep.getBoundingClientRect().top + window.scrollY,
							left: 0,
							behavior: 'smooth'
						});
					}
				}, 0);
			} else {
				$configuratorStore.prices.then((prices) => {
					// If a valid recurring coupon is used, set it on the order
					if ($configuratorStore.isRecurringOrder && $configuratorStore.couponCodeRecurring) {
						$configuratorStore.order.couponCode = $configuratorStore.couponCodeRecurring;
					}

					// If making a recurring order, make sure frequency is 0
					if (!$configuratorStore.isRecurringOrder) {
						$configuratorStore.order.frequency = 0;
						// If a valid onetime coupon is used, set it on the order
						if ($configuratorStore.couponCodeOnetime) {
							$configuratorStore.order.couponCode = $configuratorStore.couponCodeOnetime;
						}
					}

					onSubmit($configuratorStore.order);
				});
			}
		},
		goToNextStepIfValid: {
			non_subscribable: (event) => {
				$configuratorStore.goToNextStep(event);
			},
			product: (event) => {
				$configuratorStore.prices.then((prices) => {
					if (prices.productsLowestRecurringPrice !== null) {
						let productAmount = 0;
						configurator.products.forEach((product) => {
							productAmount += $configuratorStore.getSelectedEntityAmount('product', product.id);
						});
						productAmount = productAmount / quantityMultiplier;
						if (configurator.quantities.includes(productAmount)) {
							const options = {};
							if (productAmount === 0) {
								options.skipFrequencySelector = true;
							}
							$configuratorStore.goToNextStep(event, options);
						}
					}
				});
			}
		},
		getEntities: (entityType) => {
			if (entityType === 'product') {
				return configurator.products;
			}
			return configurator.nonSubscribableProducts;
		},
		getSelectedEntityAmount: (entityType, productExternalId) => {
			if (productExternalId in $configuratorStore.items[entityType]) {
				return (
					$configuratorStore.items[entityType][productExternalId] *
					$configuratorStore.quantityMultiplier
				);
			}
			return 0;
		},
		getTotalAmount: (entityType) => {
			const total = Object.values($configuratorStore.items[entityType]).reduce(
				(accumulator, currentValue) => {
					return accumulator + currentValue;
				},
				0
			);

			return total * $configuratorStore.quantityMultiplier;
		},
		setEntityErrorText: (entityType) => {
			if (
				entityType !== 'non_subscribable' &&
				!$configuratorStore.targetQuantities.includes(
					$configuratorStore.getTotalAmount(entityType) / $configuratorStore.quantityMultiplier
				)
			) {
				$configuratorStore.entityErrorText[entityType] = formatQuantitySelectorString(
					translations.quantitySelector,
					$configuratorStore.targetQuantities.map((q) => q * $configuratorStore.quantityMultiplier),
					$configuratorStore.getTotalAmount(entityType),
					'%quantity%',
					'%quantity_add%',
					'%quantity_remove%'
				)
					.replaceAll(
						'%product%',
						configurator.productCategory?.translations?.unitNameSingle ?? '%product%'
					)
					.replaceAll(
						'%products%',
						configurator.productCategory?.translations?.unitNamePlural ?? '%products%'
					);
			} else {
				$configuratorStore.entityErrorText[entityType] = null;
			}
		}
	});

	onMount(() => {
		let loadedCouponCode = null;

		// Use the coupon code from the url if present
		const couponCodeFromUrl = $pageStore.url.searchParams.get('coupon');
		if (couponCodeFromUrl !== null && couponCodeFromUrl.length > 0) {
			loadedCouponCode = couponCodeFromUrl;

			// Otherwise, use the coupon code from the default order if present
		} else if (defaultOrder !== null && defaultOrder !== undefined) {
			loadedCouponCode = defaultOrder.couponCode;

			// Otherwise, use the coupon code from the block
		} else {
			loadedCouponCode = couponCodeRecurring;
		}

		$configuratorStore.couponCodeRecurring = loadedCouponCode;
		$configuratorStore.order = defaultOrder ?? getOrder(defaultFrequency, defaultItems);

		resolveCouponCodePromise(loadedCouponCode);

		$configuratorStore.prices = getOrderPrices(
			$configuratorStore.order,
			$configuratorStore.targetQuantities,
			$configuratorStore.couponCodeRecurring,
			$configuratorStore.couponCodeOnetime
		);
		$configuratorStore.targetPrices = $configuratorStore.prices;
	});

	const hasStructuredData = (): boolean => {
		return configurator.structuredData.show;
	};

	const hasDefaultsForStructuredData = (): boolean => {
		if (configurator.defaultQuantity === 0) {
			return false;
		}
		if (
			$configuratorStore.getTotalAmount('product') !==
			configurator.defaultQuantity * $configuratorStore.quantityMultiplier
		) {
			return false;
		}
		return true;
	};

	const getStructuredData = (priceData: any): null | any => {
		if (!hasStructuredData() || !hasDefaultsForStructuredData()) {
			return null;
		}

		const isRecurring = configurator.defaultFrequency > 0;
		const listPrice = isRecurring
			? priceData.productsLowestRecurringPrice
			: priceData.productsOneTimePrice;
		const discountAmount = isRecurring ? priceData.discountAmount : priceData.discountAmountOnetime;
		const priceSpecification = [];

		priceSpecification.push({
			'@type': 'UnitPriceSpecification',
			priceType: 'https://schema.org/ListPrice',
			price: listPrice,
			priceCurrency: 'EUR'
		});

		// Add discounted price if there's a discount
		if (discountAmount > 0) {
			priceSpecification.push({
				'@type': 'UnitPriceSpecification',
				price: listPrice - discountAmount, // @TODO: round this
				priceCurrency: 'EUR'
			});
		}

		return {
			'@context': 'https://schema.org/',
			'@type': 'Product',
			name: configurator.structuredData.name,
			description: configurator.structuredData.description,
			image: configurator.structuredData.images.flatMap((image) => [image.url]),
			offers: {
				'@type': 'Offer',
				availability: 'https://schema.org/InStock',
				priceSpecification
			},
			shippingDetails: {
				'@type': 'OfferShippingDetails',
				shippingRate: {
					'@type': 'MonetaryAmount',
					value: '0',
					currency: 'EUR'
				}
			}
		};
	};
</script>

<svelte:head>
	{#await $configuratorStore.prices then priceData}
		{#if getStructuredData(priceData) !== null}
			{@html `<script type="application/ld+json">${JSON.stringify(
				getStructuredData(priceData)
			)}</script>`}
		{/if}
	{/await}
</svelte:head>

{#await couponCodePromise then couponCode}
	{#each configurator.steps as step, index}
		<Step {translations} {configurator} configuratorStore={$configuratorStore} {step} {index} />
	{/each}
{/await}
