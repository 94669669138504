<script lang="ts">
	import type { CountryOption } from '$models/WordPress/FormData';
	import { page as pageStore } from '$app/stores';
	import FormElement from '$components/Form/FormElement/FormElement.svelte';
	import { throttle } from '$lib/utils';

	// Component properties
	export let id: string;
	export let subLabel: string;
	export let name: string;
	export let formState: any;
	export let onChange: any;
	export let onFocus: any | undefined;
	export let onBlur: any | undefined;
	export let options: any;
	export let address: any;
	export let addAddressLabel: any;

	if (id === undefined) {
		id = null;
	}
	if (subLabel === undefined) {
		subLabel = null;
	}
	if (options === undefined) {
		options = [];
	}

	let elements = {
		id: null,
		country: null,
		postalCode: null,
		houseNumber: null,
		houseNumberSuffix: null,
		street: null,
		city: null
	};

	// TODO: Move me to my own file
	interface AddressSuggestion {
		street: string;
		houseNumber: number;
		houseNumberAddition: number | null;
		postcode: string;
		city: string;
		houseNumberAdditions: string[];
	}

	interface AutocompleteSuggestion {
		value: string;
		label: string;
		description: string;
		precision: string;
		context: string;
		type: 'Locality' | 'Street' | 'PostalCode' | 'PartialAddress' | 'Address';
	}

	let autocompleteSessionID = crypto.randomUUID();
	let autocompleteContext = '';
	let autocompleteLanguage = '';
	let autocompleteSuggestions: AutocompleteSuggestion[] = [];
	let addressSuggestionError: string | null = null;
	let addressSuggestions: AddressSuggestion[] = [];

	const defaultCountry = address?.countryOptions.length ? address.countryOptions[0].code : 'nl';
	let currentCountry = defaultCountry;
	function setCurrentCountry() {
		// Regenerate the autocompleteSessionID when switching countries
		autocompleteSessionID = crypto.randomUUID();
		currentCountry = elements.country.getValue();
	}

	// When the country changes, we need to change to a new context
	$: autocompleteContext = getAutocompleteContext(currentCountry);
	$: autocompleteLanguage = getAutocompleteLanguage(currentCountry);

	export function getValue() {
		if (elements.id === null || elements.id.getValue() === '%add_address%') {
			return {
				id: null,
				country: elements.country.getValue(),
				postalCode: elements.postalCode.getValue(),
				houseNumber: elements.houseNumber.getValue().toString(),
				houseNumberSuffix:
					elements.houseNumberSuffix.getValue().length > 0
						? elements.houseNumberSuffix.getValue()
						: null,
				street: elements.street.getValue(),
				city: elements.city.getValue()
			};
		}
		for (const option of options) {
			if (option.id === elements.id.getValue()) {
				return option;
			}
		}
	}

	export function setValue(address) {
		if (elements.id !== null) {
			elements.id.setValue(address);
		} else {
			elements.country.setValue(address.country.toLowerCase());
			elements.postalCode.setValue(address.postalCode);
			elements.houseNumber.setValue(address.houseNumber);
			elements.houseNumberSuffix.setValue(address.houseNumberSuffix);
			elements.street.setValue(address.street);
			elements.city.setValue(address.city);

			if (address.country) {
				setCurrentCountry();
			}
		}
	}

	export function validate() {
		let isValid = true;
		if (elements.id === null || elements.id.getValue() === '%add_address%') {
			Object.entries(elements)
				.filter(([key, element]) => {
					return key !== 'id' && element !== null;
				})
				.forEach(([key, element]) => {
					if (!element.validate()) {
						isValid = false;
					}
				});
		}
		return isValid;
	}

	export function isValid() {
		let isValid = true;
		if (elements.id === null || elements.id.getValue() === '%add_address%') {
			Object.entries(elements)
				.filter(([key, element]) => {
					return key !== 'id' && element !== null;
				})
				.forEach(([key, element]) => {
					if (!element.isValid()) {
						isValid = false;
					}
				});
		}
		return isValid;
	}

	export function scrollIntoView() {
		const [key, firstInvalidElement] = Object.entries(elements).find(
			([key, element]) => element && !element.isValid()
		);
		if (firstInvalidElement) {
			firstInvalidElement.scrollIntoView();
		}
	}

	const addressOptions = [];
	for (const option of options) {
		addressOptions.push([
			option.id,
			`${option.street} ${option.houseNumber}${option.houseNumberSuffix.length ? '-' : ''}${option.houseNumberSuffix}, ${option.city}`
		]);
	}
	let currentAddressOption = null;
	if (addressOptions.length > 0) {
		addressOptions.push(['%add_address%', addAddressLabel]);
		currentAddressOption = addressOptions[0][0];
	}
	function setCurrentAddressOption(event) {
		currentAddressOption = event.target.value;
	}

	function getAutocompleteContext(country: string) {
		let context = '';

		switch (country) {
			case 'be':
				context = 'bel';
				break;
			case 'nl':
			default:
				context = 'nld';
				break;
		}

		return context;
	}

	function getAutocompleteLanguage(country: string) {
		let language = '';

		switch (country) {
			case 'be':
			case 'nl':
			default:
				language = 'nl-NL';
				break;
		}

		return language;
	}

	async function onAddressChange(event) {
		throttle($pageStore.data.page.slug, 'getAddressSuggestion', 150, () => {
			getAddressSuggestion();
		});
	}

	function validatePostalCode() {
		return addressSuggestionError;
	}

	async function getAddressSuggestion() {
		const street = elements?.street?.getValue();
		const postcode = elements?.postalCode?.getValue();
		const houseNumber = elements?.houseNumber?.getValue();
		const houseNumberSuffix = elements?.houseNumberSuffix?.getValue();
		const city = elements?.city?.getValue();

		addressSuggestionError = null;
		addressSuggestions = [];

		if (currentCountry === 'nl') {
			// NL postcode lookup
			if (!postcode || !houseNumber) {
				return;
			}

			let requestUrl = `/api/postcode/nl/v1/addresses/postcode/${encodeURIComponent(
				postcode.replace(' ', '')
			)}/${encodeURIComponent(houseNumber)}/`;

			if (houseNumberSuffix && houseNumberSuffix.length > 0) {
				requestUrl += `${houseNumberSuffix}/`;
			}

			// Catch general (network) errors
			try {
				const res = await fetch(requestUrl);
				const data = await res.json();

				// Checking for errors from Postcode API
				if (data.exceptionId) {
					addressSuggestionError =
						$pageStore.data.page.layout.translations.errors.addressSuggestionNotFoundError;
					elements.postalCode.validate();
					return;
				}
				elements.postalCode.validate();
				const additions = data.houseNumberAdditions.filter((a) => a.length > 0);

				if (additions.length > 0) {
					addressSuggestions = [
						{
							street: data.street,
							houseNumber: data.houseNumber,
							houseNumberAddition: data.houseNumberAddition || null,
							houseNumberAdditions: additions,
							postcode: data.postcode,
							city: data.city
						}
					];
				} else {
					setAddressSuggestion(data);
				}
			} catch (e) {
				// Fail silently
			}
		} else {
			// International autocomplete
			// TODO: Improve and re-enable (with postcode.eu library?)
			return;

			/**
			 * Street and number are required to get higher likelihood of
			 * matches with precision level of 'Address'.
			 */
			if (!street || !houseNumber) {
				return;
			}

			let terms = [street, houseNumber];

			if (houseNumberSuffix) {
				terms = [...terms, houseNumberSuffix];
			}

			if (postcode) {
				terms = [...terms, postcode];
			}

			if (city) {
				terms = [...terms, city];
			}

			try {
				//  https://api.postcode.eu/international/v1/autocomplete/{context}/{term}/{language}
				const term = terms.join(' ');
				let requestUrl = `/api/postcode/international/v1/autocomplete/${autocompleteContext}/${encodeURIComponent(
					term
				)}/${autocompleteLanguage}/`;
				const res = await fetch(requestUrl, {
					headers: {
						'X-Autocomplete-Session': autocompleteSessionID
					}
				});
				const data = await res.json();

				autocompleteSuggestions = data.matches.filter(
					(match: AutocompleteSuggestion) => match.precision === 'Address'
				);
			} catch (error) {
				// Fail silently
			}
		}
	}

	function setAddressSuggestion(suggestion: AddressSuggestion, addition?: string) {
		elements?.street?.setValue(suggestion.street);
		elements?.city?.setValue(suggestion.city);
		// elements?.postalCode?.setValue(suggestion.postcode); // TODO: UPDATE ALL FIELDS EXCEPT THE ONE YOU'RE TYPING IN
		// elements?.houseNumber?.setValue(suggestion.houseNumber); // TODO: UPDATE ALL FIELDS EXCEPT THE ONE YOU'RE TYPING IN

		if (addition) {
			elements?.houseNumberSuffix?.setValue(addition);
		}

		// Clear the suggestions
		addressSuggestions = [];
	}

	async function setAutocompleteSuggestion(suggestion: AutocompleteSuggestion) {
		// Clear the suggestions
		autocompleteSuggestions = [];

		// Get address details, and fill the form
		try {
			//  https://api.postcode.eu/international/v1/address/{context}/{dispatchCountry}
			const requestUrl = `/api/postcode/international/v1/address/${suggestion.context}/`;
			const res = await fetch(requestUrl, {
				headers: {
					'X-Autocomplete-Session': autocompleteSessionID
				}
			});
			const data = await res.json();

			elements?.street?.setValue(data.address.street);
			elements?.houseNumber?.setValue(data.address.buildingNumber);
			elements?.houseNumberSuffix?.setValue(data.address.buildingNumberAddition);
			elements?.postalCode?.setValue(data.address.postcode);
			elements?.city?.setValue(data.address.locality);
		} catch (error) {
			// Fail silently
		}
	}

	function getCountryOptions(countryOptions: CountryOption[]) {
		if (!countryOptions || countryOptions.length === 0) {
			return [['nl', 'Nederland']];
		}
		return countryOptions.map((country: CountryOption) => [country.code, country.label]);
	}
</script>

{#if addressOptions.length > 0}
	<FormElement
		bind:this={elements.id}
		type="optionGroup"
		subType="select"
		size="large"
		name={name + '.id'}
		options={addressOptions}
		{formState}
		onChange={setCurrentAddressOption}
		autocomplete="address-line1"
	/>
{/if}
{#if addressOptions.length === 0 || currentAddressOption === '%add_address%'}
	{#if subLabel !== null}
		<div class="box-subtitle">
			{subLabel}
		</div>
	{/if}
	<FormElement
		bind:this={elements.country}
		type="optionGroup"
		subType="radio"
		size="large"
		name={name + '.country'}
		label={address.countryLabel}
		options={getCountryOptions(address.countryOptions)}
		{formState}
		onChange={setCurrentCountry}
		defaultValue={defaultCountry}
	/>
	<FormElement
		bind:this={elements.postalCode}
		type="input"
		subType="text"
		size="medium"
		options={{ errorSize: 'large' }}
		name={name + '.postalCode'}
		label={address.postalCodeLabel}
		requiredError={address.postalCodeRequiredError}
		autocomplete="postal-code"
		{formState}
		onChange={onAddressChange}
	>
		{#if addressSuggestionError}
			<div class="form-error form-error-large">{addressSuggestionError}</div>
		{/if}
	</FormElement>
	<FormElement
		bind:this={elements.houseNumber}
		type="input"
		subType="text"
		size="medium"
		name={name + '.houseNumber'}
		label={address.houseNumberLabel}
		requiredError={address.houseNumberRequiredError}
		autocomplete="address-line2"
		{formState}
		onChange={onAddressChange}
	/>
	<!-- @TODO: Expand suffix labels for all countries and map properly -->
	<FormElement
		bind:this={elements.houseNumberSuffix}
		type="input"
		subType="text"
		size="medium"
		name={name + '.houseNumberSuffix'}
		label={address.houseNumberSuffixLabel[currentCountry] || address.houseNumberSuffixLabel['nl']}
		autocomplete="address-line3"
		{formState}
		onChange={onAddressChange}
	/>

	{#if addressSuggestions.length > 0}
		<div class="form-row">
			<div class="address-suggestions">
				<div class="address-suggestions-container">
					<div class="address-suggestions-label">Welk huisnummer bedoel je?</div>
					{#each addressSuggestions as suggestion}
						<div class="address-suggestions-buttons">
							{#if suggestion.houseNumberAdditions.length}
								{#each suggestion.houseNumberAdditions as addition}
									<button
										type="button"
										class="address-suggestions-button"
										on:click={() => setAddressSuggestion(suggestion, addition)}
									>
										{suggestion.houseNumber}-{addition}
									</button>
								{/each}
							{/if}
						</div>
					{/each}
				</div>
			</div>
		</div>
	{/if}

	<FormElement
		type="input"
		subType="text"
		size="small"
		name={name + '.street'}
		label={address.streetLabel}
		requiredError={address.streetRequiredError}
		autocomplete="address-line1"
		{formState}
		bind:this={elements.street}
	/>
	<FormElement
		type="input"
		subType="text"
		size="small"
		name={name + '.city'}
		label={address.cityLabel}
		requiredError={address.cityRequiredError}
		autocomplete="address-line2"
		{formState}
		bind:this={elements.city}
	/>
	{#if autocompleteSuggestions.length > 0}
		<div class="autocomplete-suggestions">
			{#each autocompleteSuggestions as suggestion}
				<button
					type="button"
					class="autocomplete-suggestions-button"
					on:click={() => setAutocompleteSuggestion(suggestion)}
				>
					{suggestion.label}
					&laquo;
				</button>
			{/each}
		</div>
	{/if}
{/if}

<style lang="postcss">
	.address-suggestions {
		padding: var(--spacing-3) var(--spacing-4);
		font-size: var(--font-size-0);
		line-height: var(--font-lineheight-0);
		background-color: var(--color-primary-light);
		display: inline-block;

		&-container {
			display: flex;
			align-items: center;
		}

		&-label {
			margin-right: var(--spacing-2);
		}

		&-buttons {
			flex: 1;
		}

		&-button {
			display: inline-block;
			color: var(--color-grey-2);
			font-weight: var(--font-weight-5);
			text-decoration: underline;
			transition: color var(--transition);

			&:hover {
				color: var(--color-grey-1);
			}

			&:not(:last-child) {
				margin-right: var(--spacing-2);
			}
		}
	}
</style>
