<script lang="ts">
	import type { Form } from '$models/Form/Form';
	import FormElement from '$components/Form/FormElement/FormElement.svelte';
	import { getCartStore } from '$lib/stores';
	import { validateForm } from '$lib/utils';
	import { createPasswordReset } from '$lib/webparking';
	import { writable } from 'svelte/store';

	// Component properties
	export let form: Form;
	export let showLoginForm: any;
	export let extraForm: any;

	const cartStore = getCartStore();

	let formState = writable({
		isHot: false,
		elements: {
			email: null
		},
		errors: {
			email: null,
			submit: null
		},

		setIsHot: (isHot) => {
			$formState.isHot = isHot;
		},
		setError: (elementName, error) => {
			$formState.errors[elementName] = error;
		}
	});

	let isSubmitSuccess = false;

	export async function onSubmit() {
		let isExtraFormValid = true;
		let couponCodeValue = null;
		if (extraForm !== null && extraForm !== undefined) {
			if (extraForm.isEditing()) {
				[isExtraFormValid, couponCodeValue] = await extraForm.submit(null, false);
			}
		}
		if (!validateForm($formState) || !isExtraFormValid) {
			return;
		}

		try {
			const cart = await $cartStore;
			if (cart !== null && couponCodeValue !== null) {
				cart.couponCode = couponCodeValue;
				cartStore.setCart(cart);
			}

			createPasswordReset({
				email: $formState.elements.email.getValue(),
				url: window.location.origin + window.location.pathname
			});
			$formState.setError('submit', null);
			isSubmitSuccess = true;
		} catch (error) {
			$formState.setError('submit', 'Geen gebruiker bekend met dit e-mailadres.');
			isSubmitSuccess = false;
		}
	}
</script>

<h2 class="box-title">
	{form.title}
</h2>

<div class="box-text">
	{form.text}
</div>

<form class="form" on:submit|preventDefault={onSubmit}>
	{#if isSubmitSuccess}
		<div class="form-main">
			<div class="message message--success">{form.submitSuccessLabel}</div>
			<button class="button" on:click={showLoginForm}>
				{form.loginButtonLabel}
			</button>
		</div>
	{:else}
		<div class="form-main">
			{#if $formState.errors.submit}
				<div class="message message--error">{$formState.errors.submit}</div>
			{/if}
			<FormElement
				type="input"
				subType="email"
				size="large"
				name="email"
				label={form.emailLabel}
				requiredError={form.emailRequiredError}
				invalidError={form.emailInvalidError}
				formState={$formState}
				bind:this={$formState.elements.email}
			/>
		</div>
		<div class="form-submit">
			<button class="button" type="submit">{form.submitButtonLabel}</button>
		</div>
		<div class="form-row">
			<button class="form-toggle-link" on:click={showLoginForm}>{form.loginButtonLabel}</button>
		</div>
	{/if}
</form>
