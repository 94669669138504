<script lang="ts">
	import { page } from '$app/state';
	import { formatNumericString, validateQuantity } from '$lib/utils';

	interface Props {
		quantities: number[];
		quantity: number;
		multiplier?: number;
		unitNameSingle?: string;
		unitNamePlural?: string;
	}

	let {
		quantities,
		quantity,
		multiplier = 1,
		unitNameSingle = 'stuk',
		unitNamePlural = 'stuks'
	}: Props = $props();

	const translations = page.data.page.layout.translations.quantitySelector;

	let { isValid, nextValid, previousValid } = $derived(validateQuantity(quantities, quantity));
	let quantityToAdd = $derived(nextValid ? nextValid - quantity : 0);
	let quantityToRemove = $derived(previousValid ? quantity - previousValid : 0);
	let displayQuantityToAdd = $derived(quantityToAdd * multiplier);
	let displayQuantityToRemove = $derived(quantityToRemove * multiplier);
	let quantityMessage = $derived.by(() => {
		if (!previousValid) {
			return formatNumericString(
				translations.addSingle,
				translations.addMultiple,
				displayQuantityToAdd,
				'%quantity%'
			);
		}

		if (!nextValid) {
			return formatNumericString(
				translations.removeSingle,
				translations.removeMultiple,
				displayQuantityToRemove,
				'%quantity%'
			);
		}

		if (previousValid && nextValid) {
			if (displayQuantityToAdd === 1 && displayQuantityToRemove === 1) {
				return translations.addSingleOrRemoveSingle;
			} else if (displayQuantityToAdd > 1 && displayQuantityToRemove === 1) {
				return translations.addMultipleOrRemoveSingle.replaceAll(
					'%quantity%',
					displayQuantityToAdd
				);
			} else if (displayQuantityToAdd === 1 && displayQuantityToRemove > 1) {
				return translations.addSingleOrRemoveMultiple.replaceAll(
					'%quantity%',
					displayQuantityToRemove
				);
			} else {
				return translations.addMultipleOrRemoveMultiple
					.replaceAll('%quantity_add%', displayQuantityToAdd)
					.replaceAll('%quantity_remove%', displayQuantityToRemove);
			}
		}
	});
	let message = $derived(
		quantityMessage.replaceAll('%product%', unitNameSingle).replaceAll('%products%', unitNamePlural)
	);
</script>

{#if !isValid}
	{message}
{/if}
