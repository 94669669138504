<script lang="ts">
	import type { PlansBlock } from '$models/Block/PlansBlock';
	import type { Order } from '$models/Order/Order';
	import type { ProductCategory } from '$models/Product/ProductCategory';
	import { page } from '$app/stores';
	import Button from '$components/UI/Buttons/Button.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { getCartStore } from '$lib/stores';
	import { formatCurrency, formatNumericString, sanitizeHtml } from '$lib/utils';
	import { getOrderPrices } from '$lib/webparking';

	export let block: PlansBlock;

	const cartStore = getCartStore();
	const site = getSiteContext();

	function getPlanUrl(plan) {
		let url = plan.button.url;

		if (plan.coupon) {
			url += `?coupon=${plan.coupon}`;
		}

		return url;
	}

	async function getPlanPrice(
		productCategory: ProductCategory,
		frequency: number,
		quantity: number,
		coupon: string | null
	) {
		// We need product ID's to calculate order price, but none have been chosen yet.
		const dummyProduct = $page.data.products.regular.find(
			(p) => p.category.id === productCategory.id
		);

		// Return early if no product is found
		if (!dummyProduct) {
			return { productsRecurringPrice: 0, productsOneTimePrice: 0 };
		}

		const dummyOrder: Order = {
			frequency,
			products: [
				{
					id: dummyProduct.id,
					quantity
				}
			],
			nonSubscribableProducts: []
		};

		if (coupon) {
			dummyOrder.couponCode = coupon;
		}

		return await getOrderPrices(dummyOrder, [quantity]);
	}
</script>

<div class="plans">
	<div class="plans-container">
		<div class="plans-header">
			<h2 class="plans-title">
				{block.plansTitle}
			</h2>
			{#if block.plansText}
				<div class="plans-text rich-text">
					{@html block.plansText}
				</div>
			{/if}
		</div>

		<div class="plans-grid plans-grid--count-{block.plans.length}">
			{#each block.plans as plan}
				{@const planUrl = plan.coupon ? '' : plan.button.url}
				<div class="plans-item" class:plans-item--featured={plan.label}>
					{#if plan.label}
						<div class="plans-item-label">
							{@html plan.label}
						</div>
					{/if}
					<div class="plans-item-header">
						<div class="plans-item-title">{plan.name}</div>
						{#await getPlanPrice(plan.productCategory, plan.frequency, plan.quantity, plan.coupon) then prices}
							<div class="plans-item-price">
								{#if plan.frequency > 0}
									{formatCurrency(prices.productsRecurringPrice, site.locale.code)}
									<small>
										/
										{formatNumericString(
											$page.data.page.layout.translations.frequencySingle,
											$page.data.page.layout.translations.frequencyMultiple,
											plan.frequency,
											'%quantity%'
										)}
									</small>
								{:else}
									{formatCurrency(prices.productsOneTimePrice, site.locale.code)}
								{/if}
							</div>
						{/await}
					</div>
					{#if plan.description}
						<div class="plans-item-content rich-text">
							{@html plan.description}
						</div>
					{/if}
					<div class="plans-item-button">
						<a href={getPlanUrl(plan)} class="button">
							{plan.button.title}
						</a>
					</div>
				</div>
			{/each}
		</div>

		{#if block.outro && (block.outro.text || block.outro.button)}
			<div class="plans-outro">
				{#if block.outro.text}
					<div class="plans-outro-text rich-text">
						{@html sanitizeHtml(block.outro.text)}
					</div>
				{/if}
				{#if block.outro.button}
					<div class="plans-outro-button">
						<Button type="anchor" href={block.outro.button.url}>
							{block.outro.button.title}
						</Button>
					</div>
				{/if}
			</div>
		{/if}
	</div>
	{#if block.backgroundImage}
		<div class="plans-background" style="opacity: 0.75;">
			<img src={block.backgroundImage.url} alt="" />
		</div>
	{/if}
</div>

<style lang="postcss" global>
	.plans {
		& {
			position: relative;
			padding-top: 40px;
			padding-bottom: 40px;
		}

		@media (--md) {
			padding-top: 60px;
			padding-bottom: 60px;
		}

		@media (--lg) {
			padding-top: 80px;
			padding-bottom: 80px;
		}

		&-background {
			display: none;

			@media (--md) {
				display: block;
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-container {
			position: relative;
			z-index: 2;
			max-width: var(--container);
			padding-left: var(--spacing-5);
			padding-right: var(--spacing-5);
			margin: 0 auto;
		}

		&-header {
			margin-bottom: var(--spacing-18);
		}

		&-title {
			font-family: var(--plans-title-font-family);
			font-size: var(--plans-title-font-size);
			font-weight: var(--plans-title-font-weight);
			color: var(--plans-title-color);
			text-transform: var(--plans-title-text-transform);
			margin-bottom: var(--spacing-6);
		}

		&-grid {
			display: grid;
			gap: var(--spacing-8);

			&--count-2 {
				@media (--xl) {
					grid-template-columns: 1fr 1fr;
				}
			}

			&--count-3 {
				@media (--xl) {
					max-width: 80%;
					grid-template-columns: 1fr 1fr 1fr;
				}
			}

			&--count-4 {
				@media (--xl) {
					grid-template-columns: 1fr 1fr 1fr 1fr;
				}
			}
		}

		&-item {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: var(--spacing-5);
			color: var(--color-black);
			background-color: white;
			border-radius: var(--radius-2);

			@media (--md) {
				flex-direction: row;
			}

			@media (--xl) {
				flex-direction: column;
			}

			&-label {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				text-align: center;
				position: absolute;
				bottom: 10px;
				right: -8px;
				z-index: 2;
				width: 80px;
				height: 80px;
				text-transform: uppercase;
				font-size: 15px;
				line-height: var(--font-lineheight-0);
				border-radius: 50%;
				color: var(--plans-item-label-text-color);
				background-color: var(--plans-item-label-bg-color);
				transform: rotate(22deg);
				box-shadow: var(--plans-item-label-shadow);

				strong {
					font-size: 20px;
					font-weight: var(--font-weight-5);
				}

				@media (--md) {
					bottom: auto;
					top: -40px;
					right: -12px;
				}
			}

			&--featured {
				@media (--xl) {
					margin-top: -24px;
					margin-bottom: -24px;
					padding-top: 41px;
					padding-bottom: 41px;
				}
			}

			&-header {
				display: flex;
				margin-bottom: var(--spacing-3);

				@media (--md) {
					width: 175px;
					flex-direction: column;
				}

				@media (--xl) {
					width: auto;
					flex-direction: row;
				}
			}

			&-title {
				font-family: var(--plans-item-title-font-family);
				font-size: var(--plans-item-title-font-size);
				font-weight: var(--plans-item-title-font-weight);
				color: var(--plans-item-title-color);
				text-transform: var(--plans-item-title-text-transform);
			}

			&-price {
				text-transform: uppercase;
				margin-left: auto;

				@media (--md) {
					margin-left: 0;
				}

				@media (--xl) {
					margin-left: auto;
				}

				small {
					opacity: 0.5;
					font-size: 11px;
				}
			}

			&-content {
				font-size: var(--plans-item-text-font-size);
				margin-bottom: var(--spacing-5);

				@media (--md) {
					flex: 1;
					margin-bottom: 0;
					margin-left: auto;
					margin-right: var(--spacing-6);
				}

				@media (--xl) {
					margin-bottom: var(--spacing-5);
					margin-left: 0;
					margin-right: 0;
				}

				ul {
					margin-left: var(--spacing-4) !important;
				}
			}

			&-button {
				@media (--md) {
					align-self: flex-end;
				}

				@media (--xl) {
					align-self: stretch;
					justify-self: flex-end;
					margin-top: auto;
				}

				.button {
					display: block;
				}
			}
		}

		&-outro {
			margin-top: var(--spacing-16);
			&-text {
				a {
					text-decoration: underline;
					font-weight: var(--font-weight-4);
					transition: color var(--transition);

					&:hover {
						color: var(--color-primary);
					}
				}

				&:not(:last-child) {
					margin-bottom: var(--spacing-6);
				}
			}
		}
	}
</style>
