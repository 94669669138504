<script lang="ts">
	import Form from '$components/Form/Form.svelte';
	import { getToastStore } from '$lib/stores';

	// Component properties
	export let accountBlock;
	export let page;

	const toastStore = getToastStore();
	const form = {
		...page.forms.changePassword,
		title: accountBlock.title
	};

	function onSubmit() {
		toastStore.trigger({
			message: accountBlock.successText,
			type: 'success'
		});
	}
</script>

<Form {form} {page} {onSubmit} />
