<script lang="ts">
	import type { Form } from '$models/Form/Form';
	import { page as pageStore } from '$app/stores';
	import FormElement from '$components/Form/FormElement/FormElement.svelte';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { validateForm } from '$lib/utils';
	import { listUserAddresses, updateUserAddress } from '$lib/webparking';
	import { onMount } from 'svelte';
	import { writable } from 'svelte/store';

	// Component properties
	export let form: Form;
	export let onSubmit: any;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();

	let address = null;

	let formState = writable({
		isHot: false,
		elements: {
			address: null
		},
		errors: {
			address: null
		},
		setIsHot: (isHot) => {
			$formState.isHot = isHot;
		},
		setError: (elementName, error) => {
			$formState.errors[elementName] = error;
		}
	});

	function onSubmitInternal() {
		if (!validateForm($formState)) {
			return;
		}
		try {
			updateUserAddress({
				...$formState.elements.address.getValue(),
				id: address.id
			});
			onSubmit();
		} catch (error) {
			if (error.response.status === 401) {
				$cartStore.then((cart) => {
					cartStore.anonymizeCart(cart);
					checkoutStore.clearCheckout();
					userStore.setUser(null);
				});
			}
		}
	}

	onMount(async () => {
		await $userStore;
		try {
			const addresses = await listUserAddresses();
			let resourceId = $pageStore.data.page.resourceId;
			if (resourceId === undefined) {
				resourceId = $pageStore.data.page.detailPage.resourceId;
			}
			address = addresses.find((a) => a.id === resourceId);
			$formState.elements.address.setValue?.(address);
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});
</script>

<h2 class="box-title">{form.title}</h2>
<form class="form" on:submit|preventDefault={onSubmitInternal}>
	<div class="form-main">
		<FormElement
			type="address"
			size="large"
			name="address"
			formState={$formState}
			bind:this={$formState.elements.address}
			address={form.address}
		/>
		<div class="form-submit">
			<button class="button" type="submit">{form.submitButtonLabel}</button>
		</div>
	</div>
</form>
