<script lang="ts">
	// Component properties
	export let variant: 'primary' | 'secondary' | 'ghost' = 'primary';
	export let type: 'submit' | 'button' | 'reset' | 'anchor' = 'anchor';
	export let size: 'small' | 'medium' | 'large' = 'medium';
	export let href: string;
</script>

{#if type === 'anchor'}
	<a on:click {href} class="button button--{variant} button--size-{size}">
		<slot />
	</a>
{:else}
	<button on:click {type} class="button button--{variant} button--size-{size}">
		<slot />
	</button>
{/if}
