<script lang="ts">
	import type { ColumnsBlock } from '$models/Block/ColumnsBlock';
	import { sanitizeHtml } from '$lib/utils';

	export let block: ColumnsBlock;
</script>

<div class="columns columns--count-{block.columnsAmount}">
	{#each block.columns as column}
		<div class="column">
			{#if column.image}
				<figure class="column-image">
					<img
						src={column.image.url}
						width={column.image.width}
						height={column.image.height}
						alt={column.image.alt}
					/>
				</figure>
			{/if}
			<div class="column-content">
				{#if column.title}
					<h3 class="column-title">{column.title}</h3>
				{/if}

				{#if column.text}
					<div class="column-text rich-text">
						{@html sanitizeHtml(column.text)}
					</div>
				{/if}
			</div>
		</div>
	{/each}
</div>

<style lang="postcss">
	.columns {
		display: grid;
		gap: var(--spacing-4);
		row-gap: var(--spacing-8);

		&:not(:last-child) {
			margin-bottom: var(--spacing-10);
		}

		@media (--xl) {
			gap: var(--spacing-8);
			row-gap: var(--spacing-12);
		}

		&--count {
			&-2 {
				@media (--sm) {
					grid-template-columns: 1fr 1fr;
					max-width: var(--container-extra-small);
					margin: 0 auto;
				}
			}
			&-3 {
				@media (--sm) {
					grid-template-columns: 1fr 1fr 1fr;
				}
			}
			&-4 {
				@media (--sm) {
					grid-template-columns: 1fr 1fr;
				}

				@media (--lg) {
					grid-template-columns: 1fr 1fr 1fr 1fr;
				}
			}
		}
	}
	.column {
		&-image {
			margin-bottom: var(--spacing-6);

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		&-content {
			padding-right: var(--spacing-4);
		}
		&-title {
			font-size: var(--font-size-3);

			&:not(:last-child) {
				margin-bottom: var(--spacing-5);
			}

			.columns--count-2 & {
				@media (--sm) {
					font-size: var(--font-size-4);
				}
			}

			.columns--count-3 & {
				@media (--lg) {
					font-size: var(--font-size-4);
				}
			}
		}
	}
</style>
