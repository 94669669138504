<script lang="ts">
	import type { Form } from '$models/Form/Form';
	import FormElement from '$components/Form/FormElement/FormElement.svelte';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { validateForm } from '$lib/utils';
	import { updateCurrentUser } from '$lib/webparking';
	import { onMount } from 'svelte';
	import { writable } from 'svelte/store';

	// Component properties
	export let form: Form;
	export let onSubmit: any;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();

	let formState = writable({
		isHot: false,
		elements: {
			emailPreferences: null
		},
		errors: {
			emailPreferences: null
		},
		setIsHot: (isHot) => {
			$formState.isHot = isHot;
		},
		setError: (elementName, error) => {
			$formState.errors[elementName] = error;
		}
	});

	function onSubmitInternal() {
		if (!validateForm($formState)) {
			return;
		}

		$userStore.then((user) => {
			const emailPreferences = $formState.elements.emailPreferences.getValue();
			updateCurrentUser({
				subscribeToNewsletter: emailPreferences.includes('subscribeToNewsletter'),
				subscribeToMarketingEmails: emailPreferences.includes('subscribeToMarketingEmails'),
				wantsPreRenewalEmails: emailPreferences.includes('wantsPreRenewalEmails'),
				wantsPostRenewalEmails: emailPreferences.includes('wantsPostRenewalEmails')
			})
				.then(() => {
					onSubmit();
				})
				.catch((error) => {
					if (error.response.status === 401) {
						$cartStore.then((cart) => {
							cartStore.anonymizeCart(cart);
							checkoutStore.clearCheckout();
							userStore.setUser(null);
						});
					}
				});
		});
	}

	onMount(async () => {
		const user = await $userStore;
		const emailPreferences = [];
		if (user.subscribeToNewsletter) {
			emailPreferences.push('subscribeToNewsletter');
		}
		if (user.subscribeToMarketingEmails) {
			emailPreferences.push('subscribeToMarketingEmails');
		}
		if (user.wantsPreRenewalEmails) {
			emailPreferences.push('wantsPreRenewalEmails');
		}
		if (user.wantsPostRenewalEmails) {
			emailPreferences.push('wantsPostRenewalEmails');
		}
		$formState.elements.emailPreferences.setValue(emailPreferences);
	});
</script>

<div class="box">
	<h2 class="box-title">{form.title}</h2>
	<form class="form" on:submit|preventDefault={onSubmitInternal}>
		<div class="form-main">
			<FormElement
				type="optionGroup"
				subType="checkbox"
				size="large"
				name="emailPreferences"
				options={[
					['subscribeToNewsletter', form.subscribeToNewsletterLabel],
					['subscribeToMarketingEmails', form.subscribeToMarketingEmailsLabel],
					['wantsPostRenewalEmails', form.wantsPostRenewalEmailsLabel],
					['wantsPreRenewalEmails', form.wantsPreRenewalEmailsLabel]
				]}
				formState={$formState}
				bind:this={$formState.elements.emailPreferences}
			/>
			<div class="form-submit">
				<button class="button" type="submit">{form.submitButtonLabel}</button>
			</div>
		</div>
	</form>
</div>
