<script lang="ts">
	import type { Form } from '$models/Form/Form';
	import type { CocSuggestion } from '$models/Webparking/CocSuggestions';
	import { page as pageStore } from '$app/stores';
	import FormElement from '$components/Form/FormElement/FormElement.svelte';
	import Loader from '$components/UI/Loader.svelte';
	import { intersectionObserver } from '$lib/actions/intersection-observer.svelte';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { scrollFirstInvalidElementIntoView, throttle, validateForm } from '$lib/utils';
	import {
		createUserAddress,
		listCocSuggestions,
		listOrders,
		listUserAddressesByType,
		updateCurrentUser
	} from '$lib/webparking';
	import { onMount } from 'svelte';
	import { writable } from 'svelte/store';

	// Component properties
	export let form: Form;
	export let onSubmit: any;
	export let extraForm: any;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();

	let formState = writable({
		isHot: false,
		elements: {
			salutation: null,
			firstName: null,
			lastName: null,
			email: null,
			phoneNumber: null,
			customerType: null,
			companyName: null,
			cocNumber: null,
			vatNumber: null,
			poNumber: null,
			billingAddress: null,
			shippingAddressToggle: null,
			shippingAddress: null
		},
		errors: {
			salutation: null,
			firstName: null,
			lastName: null,
			email: null,
			phoneNumber: null,
			customerType: null,
			companyName: null,
			cocNumber: null,
			vatNumber: null,
			poNumber: null
		},
		serverErrors: {
			phoneNumber: null,
			vatNumber: null
		},
		setIsHot: (isHot) => {
			$formState.isHot = isHot;
		},
		setError: (elementName, error) => {
			$formState.errors[elementName] = error;
		},
		setServerError: (elementName, error) => {
			$formState.serverErrors[elementName] = error;
		}
	});

	let cocSuggestions: Promise<CocSuggestion[]> | undefined;

	let isShippingAddressVisible = false;
	function onShippingAddressToggleChange() {
		isShippingAddressVisible = $formState.elements.shippingAddressToggle.getValue().includes('on');
	}

	let customerType = 'private';
	function onCustomerTypeChange() {
		customerType = $formState.elements.customerType.getValue();
	}

	let ctaInViewport: boolean = false;

	function onCompanyNameChange(event) {
		const companyName = event.target.value;
		if (companyName.length >= 2) {
			// TODO: Pass in countryCode from somewhere else
			throttle($pageStore.data.page.slug, 'listCocSuggestions', 500, () => {
				cocSuggestions = listCocSuggestions({ companyName, countryCode: 'NL' });
			});
		} else {
			throttle($pageStore.data.page.slug, 'listCocSuggestions', 0, () => {
				cocSuggestions = undefined;
			});
		}
	}

	function selectCocSuggestion(suggestion: CocSuggestion) {
		$formState.elements.cocNumber.setValue(suggestion.cocNumber.toString());
		$formState.elements.companyName.setValue(suggestion.companyName);
		throttle($pageStore.data.page.slug, 'listCocSuggestions', 0, () => {
			cocSuggestions = undefined;
		});
	}

	async function onSubmitInternal() {
		let isExtraFormValid = true;
		let couponCodeValue = null;
		if (extraForm !== null && extraForm !== undefined) {
			if (extraForm.isEditing()) {
				[isExtraFormValid, couponCodeValue] = await extraForm.submit(null, false);
			}
		}
		if (!validateForm($formState)) {
			scrollFirstInvalidElementIntoView($formState);
			return;
		}
		if (!isExtraFormValid) {
			extraForm.scrollCouponCodeIntoView();
			return;
		}

		const user = await $userStore;
		const cart = await $cartStore;
		try {
			user.salutation =
				$formState.elements.salutation.getValue().length > 0
					? $formState.elements.salutation.getValue()
					: null;
			user.firstName = $formState.elements.firstName.getValue();
			user.lastName = $formState.elements.lastName.getValue();
			user.email = $formState.elements.email.getValue();
			user.phoneNumber = $formState.elements.phoneNumber.getValue();
			if ($formState.elements.customerType.getValue() === 'private') {
				user.companyName = null;
				user.cocNumber = null;
				user.vatNumber = null;
			} else {
				user.companyName = $formState.elements.companyName.getValue();
				user.cocNumber = $formState.elements.cocNumber.getValue();
				user.vatNumber = $formState.elements.vatNumber.getValue();
			}
			if (form.showPoNumber && $formState.elements.poNumber !== null) {
				cart.poNumber = $formState.elements.poNumber.getValue();
			}

			const userResponse = await updateCurrentUser(user);
			if ('errors' in userResponse) {
				if ('phone_number' in userResponse.errors) {
					$formState.setServerError('phoneNumber', userResponse.errors.phone_number);
				}
				if ('vat_number' in userResponse.errors) {
					$formState.setServerError('vatNumber', userResponse.errors.vat_number);
				}
				validateForm($formState);
				return;
			}
			$formState.setServerError('phoneNumber', null);
			$formState.setServerError('vatNumber', null);

			let addressResponses = [];
			if (form.showAddresses) {
				const updateAdressPromises = [];
				const billingAddress = $formState.elements.billingAddress.getValue();
				if (billingAddress.id === null || billingAddress.id == '%add_address%') {
					updateAdressPromises.push(
						createUserAddress({
							...billingAddress,
							type: 'billing'
						})
					);
				} else {
					updateAdressPromises.push(Promise.resolve(billingAddress));
				}
				if ($formState.elements.shippingAddress === null) {
					updateAdressPromises.push(
						createUserAddress({
							...billingAddress,
							type: 'shipping'
						})
					);
				} else {
					const shippingAddress = $formState.elements.shippingAddress.getValue();
					if (shippingAddress.id === null || shippingAddress.id == '%add_address%') {
						updateAdressPromises.push(
							createUserAddress({
								...shippingAddress,
								type: 'shipping'
							})
						);
					} else {
						updateAdressPromises.push(Promise.resolve(shippingAddress));
					}
				}
				addressResponses = await Promise.all(updateAdressPromises);
			}
			onSubmit($formState, [userResponse, ...addressResponses]);
			userStore.setUser(userResponse);

			if (cart !== null && couponCodeValue !== null) {
				cart.couponCode = couponCodeValue;
				cartStore.setCart(cart);
			}
		} catch (error) {
			if (error.response.status === 401) {
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	}

	let billingAddressesPromise = new Promise(() => {});
	let shippingAddressesPromise = new Promise(() => {});
	let poNumberPromise = new Promise(() => {});
	onMount(async () => {
		const user = await $userStore;
		const cart = await $cartStore;

		// $formState elements are possibly undefined
		if (!$formState.elements.salutation) {
			return;
		}

		try {
			$formState.elements.salutation.setValue?.(user.salutation !== null ? user.salutation : '');
			$formState.elements.firstName.setValue?.(user.firstName);
			$formState.elements.lastName.setValue?.(user.lastName);
			$formState.elements.email.setValue?.(user.email);
			$formState.elements.phoneNumber.setValue?.(user.phoneNumber);
			$formState.elements.customerType.setValue?.(user.customerType);
			customerType = user.customerType;

			if (form.showPoNumber) {
				const orders = await listOrders();
				const latestOrderWithPoNumber = orders.reduce((accumulator, currentValue) => {
					if (currentValue.poNumber === null) {
						return accumulator;
					}
					if (accumulator === null) {
						return currentValue;
					}
					if (currentValue.index > accumulator.index) {
						return currentValue;
					}
					return accumulator;
				}, null);
				if (latestOrderWithPoNumber !== null) {
					poNumberPromise = Promise.resolve(latestOrderWithPoNumber.poNumber);
				} else {
					poNumberPromise = Promise.resolve('');
				}
			}
			setTimeout(() => {
				if (user.customerType === 'commercial') {
					$formState.elements.companyName?.setValue?.(user.companyName);
					$formState.elements.cocNumber?.setValue?.(user.cocNumber);
					$formState.elements.vatNumber?.setValue?.(user.vatNumber);
				}
			}, 0);

			if (form.showAddresses) {
				const [billingAddresses, shippingAddresses] = await listUserAddressesByType();
				billingAddressesPromise = Promise.resolve(billingAddresses);
				shippingAddressesPromise = Promise.resolve(shippingAddresses);
				setTimeout(() => {
					if (billingAddresses.length > 0) {
						$formState.elements.billingAddress?.setValue(
							cart.billingAddressId || billingAddresses[0].id
						);
					}
					if (shippingAddresses.length > 0) {
						$formState.elements.shippingAddress?.setValue(
							cart.shippingAddressId || shippingAddresses[0].id
						);
					}
				}, 0);
			}
		} catch (error) {
			if (error.response.status === 401) {
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});
</script>

<div class="box">
	<h2 class="box-title">{form.title}</h2>
	<form class="form" on:submit|preventDefault={onSubmitInternal}>
		<div class="form-main">
			<FormElement
				type="optionGroup"
				subType="radio"
				size="large"
				name="salutation"
				label={form.salutationLabel}
				options={[
					['mr', form.salutationMale],
					['mrs', form.salutationFemale],
					['', form.salutationOther]
				]}
				formState={$formState}
				bind:this={$formState.elements.salutation}
				defaultValue="mr"
			/>
			<FormElement
				type="input"
				subType="text"
				size="small"
				name="firstName"
				label={form.firstNameLabel}
				requiredError={form.firstNameRequiredError}
				formState={$formState}
				bind:this={$formState.elements.firstName}
			/>
			<FormElement
				type="input"
				subType="text"
				size="small"
				name="lastName"
				label={form.lastNameLabel}
				requiredError={form.lastNameRequiredError}
				formState={$formState}
				bind:this={$formState.elements.lastName}
			/>
			<FormElement
				type="input"
				subType="email"
				size="small"
				name="email"
				label={form.emailLabel}
				requiredError={form.emailRequiredError}
				invalidError={form.emailInvalidError}
				formState={$formState}
				bind:this={$formState.elements.email}
			/>
			<FormElement
				type="input"
				subType="tel"
				size="small"
				name="phoneNumber"
				label={form.phoneLabel}
				invalidError={form.phoneInvalidError}
				formState={$formState}
				bind:this={$formState.elements.phoneNumber}
			/>
			<FormElement
				type="optionGroup"
				subType="radio"
				size="large"
				name="customerType"
				label={form.customerTypeLabel}
				options={[
					['private', form.customerTypePrivateLabel],
					['commercial', form.customerTypeCommercialLabel]
				]}
				formState={$formState}
				bind:this={$formState.elements.customerType}
				onChange={onCustomerTypeChange}
			/>
			{#if customerType === 'commercial'}
				<FormElement
					type="input"
					subType="text"
					size="large"
					name="companyName"
					label={form.companyNameLabel}
					formState={$formState}
					bind:this={$formState.elements.companyName}
					onChange={onCompanyNameChange}
				>
					{#await cocSuggestions}
						<Loader />
					{:then suggestions}
						{#if suggestions?.length}
							<div class="coc-suggestions">
								{#each suggestions as suggestion}
									<button
										type="button"
										class="coc-suggestion"
										on:click={() => selectCocSuggestion(suggestion)}
									>
										<strong>{suggestion.companyName}</strong>
										{suggestion.cocNumber}
									</button>
								{/each}
							</div>
						{/if}
					{/await}
				</FormElement>
				<FormElement
					type="input"
					subType="text"
					size="small"
					name="cocNumber"
					label={form.cocNumberLabel}
					formState={$formState}
					bind:this={$formState.elements.cocNumber}
				/>
				<FormElement
					type="input"
					subType="text"
					size="small"
					name="vatNumber"
					invalidError="BTW nummer is ongeldig"
					label={form.vatNumberLabel}
					formState={$formState}
					bind:this={$formState.elements.vatNumber}
				/>
				{#if form.showPoNumber}
					{#await poNumberPromise then poNumber}
						<FormElement
							type="input"
							subType="text"
							size="large"
							name="poNumber"
							label={form.poNumberLabel}
							formState={$formState}
							initialValue={poNumber}
							bind:this={$formState.elements.poNumber}
						/>
					{/await}
				{/if}
			{/if}
			{#if form.showAddresses}
				{#await Promise.all([billingAddressesPromise, shippingAddressesPromise])}
					<Loader />
				{/await}

				{#await billingAddressesPromise then billingAddresses}
					<FormElement
						type="address"
						size="large"
						name="billingAddress"
						label={billingAddresses.length > 0 ? form.billingAddress.title : undefined}
						subLabel={form.billingAddress.subTitle}
						formState={$formState}
						bind:this={$formState.elements.billingAddress}
						options={billingAddresses}
						address={form.billingAddress}
						addAddressLabel={form.addAddressLabel}
					/>
					{#if billingAddresses.length === 0}
						<FormElement
							type="optionGroup"
							subType="checkbox"
							size="large"
							name="shippingAddressToggle"
							options={[['on', form.shippingAddressToggleLabel]]}
							{formState}
							bind:this={$formState.elements.shippingAddressToggle}
							onChange={onShippingAddressToggleChange}
						/>
					{/if}
				{/await}
				{#await shippingAddressesPromise then shippingAddresses}
					{#if isShippingAddressVisible || shippingAddresses.length > 0}
						<FormElement
							type="address"
							size="large"
							name="shippingAddress"
							label={shippingAddresses.length > 0 ? form.shippingAddress.title : undefined}
							subLabel={form.shippingAddress.subTitle}
							formState={$formState}
							bind:this={$formState.elements.shippingAddress}
							options={shippingAddresses}
							address={form.shippingAddress}
							addAddressLabel={form.addAddressLabel}
						/>
					{/if}
				{/await}
			{/if}
			<div
				class="form-submit"
				use:intersectionObserver={{
					callback: (isIntersecting: boolean) => (ctaInViewport = isIntersecting)
				}}
			>
				<button class="button button--secondary" type="submit" class:button--fixed={!ctaInViewport}>
					{form.submitButtonLabel}
				</button>
			</div>
		</div>
	</form>
</div>

<style lang="postcss">
	.coc-suggestions {
		position: absolute;
		z-index: 10;
		top: calc(100% - 1px);
		left: 0;
		right: 0;
		border: 1px solid var(--border-color);
		background-color: white;
	}
	.coc-suggestion {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		text-align: left;
		padding: 12px 14px;
		color: var(--color-grey-1);
		font-size: 15px;
		line-height: 1;
		transition: background-color 0.25s ease;

		&:not(:last-child) {
			border-bottom: 1px solid var(--border-color);
		}

		strong {
			display: inline-block;
			color: var(--color-grey-1);
			margin-right: var(--spacing-1);
		}

		&:after {
			content: '';
			display: inline-block;
			margin-left: auto;
			border-right: 2px solid var(--color-primary);
			border-bottom: 2px solid var(--color-primary);
			width: 8px;
			height: 8px;
			transform: rotate(-45deg);
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.03);
		}
	}
</style>
