<script lang="ts">
	import type { AccountSubscriptionsPage } from '$models/Page/AccountSubscriptionsPage';
	import { browser } from '$app/environment';
	import AccountSubscriptionDetailPage from '$components/Page/AccountSubscriptionDetailPage.svelte';
	import Summary from '$components/Summary/Summary.svelte';
	import Loader from '$components/UI/Loader.svelte';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { listSubscriptions } from '$lib/webparking';
	import { onMount } from 'svelte';

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();

	// Component properties
	export let page: AccountSubscriptionsPage;

	let subscriptionsPromise = new Promise(() => {});
	let resourceIdPromise = new Promise(() => {});
	onMount(async () => {
		await $userStore;
		try {
			subscriptionsPromise = listSubscriptions({ orderBy: 'status' });
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});

	$: {
		if (browser) {
			const url = new URL(window.location);
			page.detailPage.resourceId = url.searchParams.get('resourceId');
			resourceIdPromise = Promise.resolve(page.detailPage.resourceId);
		}
	}
</script>

{#await resourceIdPromise then resourceId}
	{#if resourceId === null}
		<div class="account-box">
			<div class="box">
				<h1 class="box-title">
					{page.title}
				</h1>
			</div>
		</div>
		{#await subscriptionsPromise}
			<Loader />
		{:then subscriptions}
			{#if subscriptions.length}
				{#each subscriptions as subscription}
					<div class="account-box">
						<div class="box">
							<h3 class="box-title">
								{subscription.number}
							</h3>
							<Summary {subscription} />

							{#if subscription.status.id === 'cancelled' || subscription.status.id === 'on_hold'}
								<a
									href="{page.layout.pages
										.accountSubscriptionsPage}/?resourceId={subscription.id}&action=activate"
									class="button button--highlight"
								>
									{page.layout.translations.buttonLabels.subscriptionActivateButtonLabel}
								</a>
							{:else}
								<a
									href="{page.layout.pages.accountSubscriptionsPage}/?resourceId={subscription.id}"
									class="button"
								>
									{page.layout.translations.buttonLabels.subscriptionViewButtonLabel}
								</a>
							{/if}
						</div>
					</div>
				{/each}
			{:else}
				{page.layout.translations.errors.noSubscriptionsFound}
			{/if}
		{:catch error}
			<div class="error">{page.layout.translations.errors.failedToLoadSubscriptions}</div>
		{/await}
	{:else}
		<AccountSubscriptionDetailPage page={page.detailPage} />
	{/if}
{/await}
