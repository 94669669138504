<script lang="ts">
	import type { Subscription } from '$models/Subscription/Subscription';
	import { getCartStore, getCheckoutStore, getToastStore, getUserStore } from '$lib/stores';
	import { changeSubscriptionPaymentMethod } from '$lib/webparking/subscriptions';

	export let subscription: Subscription;
	export let returnUrl: string | URL;
	export let messages: {
		success: string;
		error: string;
	};
	export let translations: {
		submitLabel: string;
	};

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const toasts = getToastStore();
	const userStore = getUserStore();

	let isLoading = false;

	async function handleChangePaymentMethod() {
		try {
			isLoading = true;

			const { checkoutUrl } = await changeSubscriptionPaymentMethod(subscription, returnUrl);

			window.location.href = checkoutUrl;
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
			toasts.trigger({
				type: 'error',
				message: messages.error
			});
		} finally {
			isLoading = false;
		}
	}
</script>

<footer class="modal-buttons modal-buttons--align-left">
	<button type="button" class="modal-button button" on:click={handleChangePaymentMethod}>
		{translations.submitLabel}
	</button>
</footer>
