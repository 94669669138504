<script lang="ts">
	// Component properties
	export let active: boolean = false;
</script>

<div class="hamburger" class:is-active={active}>
	<span />
</div>

<style lang="postcss">
	.hamburger {
		display: block;
		position: absolute;
		top: -2px;
		left: -2px;
		right: -2px;
		bottom: -2px;

		span {
			display: block;
			position: absolute;
			top: 19px;
			left: 10px;
			right: 10px;
			height: 2px;
			border-radius: 1px;
			background-color: var(--hamburger-bar-color);

			&:before,
			&:after {
				content: '';
				position: absolute;
				display: block;
				left: 0;
				right: 0;
				width: 100%;
				height: 2px;
				border-radius: 1px;
				background-color: var(--hamburger-bar-color);
			}

			&:before {
				top: -6px;
			}

			&:after {
				bottom: -6px;
			}
		}

		/* active state */
		&.is-active {
			span {
				background-color: transparent !important;

				&:before {
					top: 0;
					transform: rotate(45deg);
					background-color: var(--hamburger-bar-color-active);
				}

				&:after {
					bottom: 0;
					transform: rotate(-45deg);
					background-color: var(--hamburger-bar-color-active);
				}
			}
		}
	}
</style>
