<script lang="ts">
	import { page } from '$app/state';
	import CopyIcon from '$components/UI/Icons/CopyIcon.svelte';
	import EnvelopeIcon from '$components/UI/Icons/EnvelopeIcon.svelte';
	import WhatsappIcon from '$components/UI/Icons/WhatsappIcon.svelte';
	import { getAffiliateLink } from '$lib/affiliate';
	import { getUserStore } from '$lib/stores';

	export let block: {
		title: string;
		text: string;
		linkTitle: string;
		url: string;
		whatsAppText: string;
		emailSubject: string;
		emailBody: string;
	};

	const user = getUserStore();

	let urlElement: HTMLInputElement | undefined;

	function handleCopyUrl() {
		if (!urlElement) {
			return;
		}

		urlElement.select();
		document.execCommand('copy');
	}
</script>

{#await $user then user}
	<div class="account-box">
		<div class="box">
			<h2 class="box-title">
				{block.title}
			</h2>
			<div class="box-text rich-text">
				{@html block.text}
			</div>
			<div class="mgm-block">
				<h2 class="mgm-block-title">
					{block.linkTitle}
				</h2>
				<div class="mgm-block-input">
					{#if user !== null}
						<input
							type="url"
							readonly
							value={getAffiliateLink(block.url, user.id)}
							bind:this={urlElement}
						/>
					{/if}
				</div>
				<div class="mgm-block-buttons">
					<!-- WhatsApp share -->
					<a
						href="https://api.whatsapp.com/send?text={block.whatsAppText.replace(
							'%link%',
							getAffiliateLink(block.url, user.id)
						)}"
						class="mgm-block-button"
						target="_blank"
					>
						<WhatsappIcon />
					</a>
					<!-- E-mail share -->
					<a
						href="mailto:?subject={encodeURIComponent(block.emailSubject)}&body={encodeURIComponent(
							block.emailBody.replace('%link%', getAffiliateLink(block.url, user.id))
						)}"
						class="mgm-block-button"
						target="_blank"
					>
						<EnvelopeIcon />
					</a>
					<!-- Copy URL -->
					<button type="button" class="mgm-block-button" on:click={handleCopyUrl}>
						<CopyIcon />
					</button>
				</div>
			</div>
		</div>
	</div>
{/await}

<style lang="postcss">
	.mgm-block {
		&-title {
			font-size: var(--font-size-1);
			font-weight: var(--box-title-font-weight);
			margin-bottom: var(--spacing-2);
		}

		&-input {
			margin-bottom: var(--spacing-4);
		}

		&-buttons {
			display: flex;
		}

		&-button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			color: var(--color-black);
			background-color: var(--color-primary);

			&:not(:last-child) {
				margin-right: var(--spacing-2);
			}
		}
	}
</style>
