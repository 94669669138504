<script lang="ts">
	import type { ProductCategory } from '$models/Product/ProductCategory';
	import { page as pageStore } from '$app/stores';
	import CheckoutCouponCode from '$components/Checkout/CheckoutCouponCode.svelte';
	import CheckoutFreeNonSubscribableProducts from '$components/Checkout/CheckoutFreeNonSubscribableProducts.svelte';
	import CheckoutNonSubscribableProducts from '$components/Checkout/CheckoutNonSubscribableProducts.svelte';
	import OrderItems from '$components/Order/OrderItems.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { getCartStore, getCheckoutStore } from '$lib/stores';
	import {
		calculateTotalProductsQuantity,
		formatCurrency,
		formatQuantityAndFrequencyString
	} from '$lib/utils';
	import CartButtons from './CartButtons.svelte';

	// Component properties
	export let page: any;
	export let orderPricesPromise: any;
	export let showCartConfigurator: any;

	const cartStore = getCartStore();
	const site = getSiteContext();

	let recurringProductAmount = 0;
	let checkoutCouponCodeElement = null;
	let productCategory: ProductCategory | null;

	export function isEditing() {
		if (checkoutCouponCodeElement !== null) {
			return checkoutCouponCodeElement.isEditing();
		}
		return false;
	}

	export function scrollCouponCodeIntoView() {
		checkoutCouponCodeElement.scrollIntoView();
	}

	export async function submit(event, updateCart) {
		if (checkoutCouponCodeElement !== null) {
			return checkoutCouponCodeElement.submit(event, updateCart);
		}
		return true;
	}

	$: {
		$cartStore.then((cart) => {
			productCategory = $pageStore.data.products.regular.find(
				(product) => product.id === cart.products[0].id
			)?.category;
			recurringProductAmount = cart.products.reduce(
				(accumulator, currentValue) => accumulator + currentValue.quantity,
				0
			);
		});
	}
</script>

{#await $cartStore then cart}
	<div class="box">
		<div class="box-header">
			<h2 class="box-title">
				{page.sidebar.title}
			</h2>
			<CartButtons {page} {showCartConfigurator} showEditButton={recurringProductAmount > 0} />
		</div>
		<div class="summary">
			{#if recurringProductAmount > 0}
				<div class="summary-row">
					<div class="summary-row-label">
						{page.forms.cart.amountLabel}
					</div>
					<div class="summary-row-value">
						{formatQuantityAndFrequencyString(
							page.forms.cart.amountValueLabels,
							calculateTotalProductsQuantity(cart.products, $pageStore.data.products.regular),
							cart.frequency
						)
							.replaceAll('%product%', productCategory?.translations?.unitNameSingle ?? '%product%')
							.replaceAll(
								'%products%',
								productCategory?.translations?.unitNamePlural ?? '%products%'
							)}
					</div>
				</div>
				<div class="summary-row">
					<div class="summary-row-label">
						{page.forms.cart.productsLabel}
					</div>
					<div class="summary-row-value">
						<div class="summary-row-container">
							<OrderItems items={cart.products} />
							{#if cart.nonSubscribableProducts.length > 0}
								<div class="summary-row-price summary-row-price--align-top">
									{#await orderPricesPromise then orderPrices}
										{#if orderPrices.productsRecurringPrice !== null}
											<span
												>{formatCurrency(
													orderPrices.productsRecurringPrice,
													site.locale.code
												)}</span
											>
										{/if}
									{/await}
								</div>
							{/if}
						</div>
					</div>
				</div>
			{/if}
			<CheckoutNonSubscribableProducts {orderPricesPromise} />
			<CheckoutCouponCode bind:this={checkoutCouponCodeElement} {page} {orderPricesPromise} />
			<CheckoutFreeNonSubscribableProducts {page} {orderPricesPromise} />
			{#await orderPricesPromise then orderPrices}
				{#if orderPrices.discountAmount > 0}
					<div class="summary-row">
						<div class="summary-row-label">
							{page.forms.cart.priceLabel}
						</div>
						<div class="summary-row-value">
							{#if orderPrices.productsRecurringPrice !== null}
								{formatCurrency(
									orderPrices.productsRecurringPrice +
										orderPrices.totalNonSubscribableProductsPrice,
									site.locale.code
								)}
							{/if}
						</div>
					</div>
					<div class="summary-row">
						<div class="summary-row-label">
							{page.forms.cart.discountAmountLabel}
						</div>
						<div class="summary-row-value">
							{#if orderPrices.discountAmount !== null}
								- {formatCurrency(orderPrices.discountAmount, site.locale.code)}
							{/if}
						</div>
					</div>
				{/if}
			{/await}
			<div class="summary-row">
				<div class="summary-row-label">
					{page.sidebar.shippingLabel}
				</div>
				<div class="summary-row-value">
					{page.sidebar.shippingValue}
				</div>
			</div>
			<div class="summary-row">
				<div class="summary-row-label">
					{page.sidebar.totalLabel}
				</div>
				<div class="summary-row-value">
					{#await orderPricesPromise then orderPrices}
						{#if orderPrices.productsRecurringPrice !== null && orderPrices.discountAmount !== null}
							{formatCurrency(
								orderPrices.productsRecurringPrice +
									orderPrices.totalNonSubscribableProductsPrice -
									orderPrices.discountAmount,
								site.locale.code
							)}
						{/if}
					{/await}
				</div>
			</div>
		</div>
	</div>
{/await}
