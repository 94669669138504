<script lang="ts">
	import type { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
	import Autoplay from 'embla-carousel-autoplay';
	import emblaCarouselSvelte from 'embla-carousel-svelte';
	import { createEventDispatcher } from 'svelte';
	import Slide from './Slide.svelte';

	export let options: EmblaOptionsType = {};
	export let instance: EmblaCarouselType | undefined = undefined;
	export let autoplay: Record<any, any> | undefined = undefined;

	const plugins: any[] = [];
	const dispatch = createEventDispatcher();

	if (autoplay !== undefined) {
		plugins.push(Autoplay(autoplay));
	}

	function init(e: CustomEvent<EmblaCarouselType>) {
		instance = e.detail;
		dispatch('init', instance);
	}
</script>

<div class="slider">
	<div class="slider-viewport" use:emblaCarouselSvelte={{ options, plugins }} on:emblaInit={init}>
		<div class="slider-container">
			<slot {Slide} />
		</div>
	</div>
</div>
