import { webparking } from '$lib/webparking';

function passwordResetToWebparkingPasswordResetData(passwordReset) {
	return {
		email: passwordReset.email,
		url: passwordReset.url,
		token: passwordReset.token,
		password: passwordReset.password
	};
}

export async function createPasswordReset(passwordReset) {
	return webparking.post('send-password-reset-email', {
		json: passwordResetToWebparkingPasswordResetData(passwordReset)
	});
}

export async function updatePasswordReset(passwordReset) {
	return webparking.post('reset-password', {
		json: passwordResetToWebparkingPasswordResetData(passwordReset)
	});
}
