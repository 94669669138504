<script lang="ts">
	import type { EmblaOptionsType } from 'embla-carousel';
	import Slider from '$components/UI/Slider/Slider.svelte';
	import { sanitizeHtml } from '$lib/utils';

	export let ribbon: string[] = [];

	let ribbonSliderOptions: EmblaOptionsType = {
		align: 'start',
		loop: true
	};
</script>

<div class="ribbon ribbon--count-{ribbon.length}">
	<div class="ribbon-container">
		<Slider options={ribbonSliderOptions} autoplay={{ playOnInit: true, delay: 5000 }} let:Slide>
			{#each ribbon as ribbonItem}
				<Slide>
					<div class="ribbon-item">
						{@html sanitizeHtml(ribbonItem)}
					</div>
				</Slide>
			{/each}
		</Slider>
	</div>
</div>

<style lang="postcss" global>
	.ribbon {
		& {
			background-color: var(--ribbon-bg-color);
		}

		&-container {
			display: flex;
			align-items: center;
			height: var(--spacing-10);
		}

		.slider {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}

		.slider-item {
			flex: 0 0 100%;

			@media (--sm) {
				flex: 0 0 50%;
			}

			@media (--lg) {
				flex: 0 0 33.3333333333%;
			}

			@media (--xl) {
				flex: 0 0 25%;
			}
		}

		&-item {
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: var(--ribbon-font-size);
			font-family: var(--ribbon-font-family);
			font-weight: var(--ribbon-font-weight);
			text-align: center;
			text-transform: uppercase;
			line-height: var(--ribbon-text-lineheight);
			color: var(--ribbon-text-color);
		}

		&--count-3 {
			.slider-item {
				@media (--lg) {
					flex: 0 0 33.3333333333%;
				}
			}
		}
	}
</style>
