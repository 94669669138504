<script lang="ts">
	import type { Button } from '$models/Button/Button';

	export let block: {
		title: string | null;
		text: string;
		button: Button | null;
	};
</script>

<div class="account-box">
	<div class="box">
		{#if block.title}
			<h2 class="box-title">{block.title}</h2>
		{/if}
		<div class="box-text rich-text">
			{@html block.text}

			{#if block.button !== null}
				<a href={block.button.url} class="button"> {block.button.title} </a>
			{/if}
		</div>
	</div>
</div>
