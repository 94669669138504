<script lang="ts">
	export let block: CallToActionBlock;
</script>

<div class="cta">
	{#if block.ctaTitle}
		<h2 class="cta-title">
			{block.ctaTitle}
		</h2>
	{/if}
	{#if block.ctaText}
		<div class="cta-text">
			{@html block.ctaText}
		</div>
	{/if}
	{#if block.button && block.button.title}
		<div class="cta-button">
			<a href={block.button.url} class="button">
				{block.button.title}
			</a>
		</div>
	{/if}
</div>

<style lang="postcss">
	.cta {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		max-width: var(--container-small);
		margin: 0 auto;

		&-title {
			margin-bottom: var(--spacing-4);
		}

		&-text {
			&:not(:last-child) {
				margin-bottom: var(--spacing-6);
			}
		}
	}
</style>
