<script lang="ts">
	import type { BlogArticlePage } from '$models/Page/BlogArticlePage';
	import { getSiteContext } from '$lib/context/site';
	import { formatDate, sanitizeHtml } from '$lib/utils';

	// Component properties
	export let page: BlogArticlePage;

	const site = getSiteContext();
</script>

<div class="block">
	<div class="block-container container">
		<article class="blog-article">
			<header class="blog-article-header">
				<h1 class="blog-article-title">
					{@html page.title}
				</h1>
				<div class="blog-article-subtitle">
					{page.subTitle.replace('%date%', formatDate(page.date, site.locale.code))}
				</div>
			</header>
			<div class="blog-article-content rich-text">
				{@html sanitizeHtml(page.content)}
			</div>
			{#if page.previousButton || page.nextButton}
				<div class="blog-article-navigation">
					{#if page.previousButton}
						<a href={page.previousButton.url} class="blog-article-prev">
							{page.previousButton.title}
						</a>
					{/if}
					{#if page.nextButton}
						<a href={page.nextButton.url} class="blog-article-next">
							{page.nextButton.title}
						</a>
					{/if}
				</div>
			{/if}
		</article>
	</div>
</div>

<style lang="postcss">
	.blog-article {
		& {
			max-width: 948px;
			margin: 0 auto;
		}
		&-title {
			font-family: var(--blog-item-title-font-family);
			font-weight: var(--blog-item-title-font-weight);
			text-transform: var(--blog-item-title-text-tranform);
			margin-bottom: var(--spacing-2);
			&:not(:last-child) {
				margin-bottom: var(--spacing-3);
			}
		}
		&-subtitle {
			font-family: var(--blog-item-subtitle-font-family);
			font-size: var(--blog-item-subtitle-font-size);
			text-transform: uppercase;
			color: var(--blog-item-subtitle-color);
			font-weight: var(--blog-item-subtitle-font-weight);
			margin-bottom: var(--spacing-5);
		}
		&-header {
			margin-bottom: var(--spacing-6);
		}
		&-content {
			&:not(:last-child) {
				border-bottom: 1px solid var(--border-color);
				padding-bottom: var(--spacing-8);
				margin-bottom: var(--spacing-6);
			}
		}
		&-navigation {
			display: flex;
			justify-content: space-between;
		}
		&-prev,
		&-next {
			position: relative;
			display: inline-block;
			color: var(--color-primary);
			line-height: 1;
			font-size: var(--font-size-1);
			transition: color var(--transition);

			&:hover {
				color: color-mix(in srgb, var(--color-primary) 90%, black);
			}

			&:before,
			&:after {
				position: absolute;
				top: 0;
				display: inline-block;
				top: calc(50% - 1px);
				transform: translateY(-50%);
				border-bottom: 1.5px solid var(--color-primary);
				width: 7px;
				height: 7px;
			}
		}
		&-prev {
			padding-left: 14px;
			margin-right: var(--spacing-5);
			&:before {
				content: '';
				left: 0;
				border-left: 1.5px solid var(--color-primary);
				transform: rotate(45deg) translateY(-50%);
			}
		}
		&-next {
			padding-right: 14px;
			margin-left: auto;
			text-align: right;

			&:after {
				content: '';
				right: 0;
				border-right: 1.5px solid var(--color-primary);
				transform: rotate(-45deg) translateY(-50%);
			}
		}
	}
</style>
