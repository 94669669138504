<script lang="ts">
	import Pencil from '$components/UI/Icons/Pencil.svelte';
	import TrashIcon from '$components/UI/Icons/TrashIcon.svelte';
	import { getCartStore } from '$lib/stores';
	import { deleteOrder } from '$lib/webparking/orders/orders';

	export let page;
	export let showCartConfigurator;
	export let showEditButton: boolean = false;

	const cartStore = getCartStore();

	async function clearCart() {
		try {
			const cart = await $cartStore;
			if (cart && cart.order) {
				if (cart.order.id) {
					await deleteOrder(cart.order.id);
				}
				cartStore.clearCart();
			} else {
				cartStore.clearCart();
			}
		} catch (error) {
			// fail silently
		}
	}
</script>

<div class="box-actions">
	{#if showEditButton}
		<button class="box-edit" on:click={showCartConfigurator}>
			<Pencil />
			{page.forms.cart.editButtonLabel}
		</button>
	{/if}

	<button class="box-edit" on:click={clearCart}>
		<TrashIcon />
		{page.forms.cart.deleteButtonLabel}
	</button>
</div>
