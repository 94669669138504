<script lang="ts">
	import type { Form } from '$models/Form/Form';
	import FormElement from '$components/Form/FormElement/FormElement.svelte';
	import { getCartStore, getCheckoutStore, getToastStore, getUserStore } from '$lib/stores';
	import { validateForm } from '$lib/utils';
	import { createUser } from '$lib/webparking';
	import { writable } from 'svelte/store';

	// Component properties
	export let form: Form;
	export let showLoginForm: any;
	export let showLoginFormWithInitialEmail: any;
	export let extraForm: any;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const toastStore = getToastStore();
	const userStore = getUserStore();

	let formState = writable({
		isHot: false,
		elements: {
			firstName: null,
			lastName: null,
			email: null,
			password: null,
			newsletter: null
		},
		errors: {
			firstName: null,
			lastName: null,
			email: null,
			password: null,
			submit: null
		},
		setIsHot: (isHot) => {
			$formState.isHot = isHot;
		},
		setError: (elementName, error) => {
			$formState.errors[elementName] = error;
		}
	});

	async function onSubmit() {
		let isExtraFormValid = true;
		let couponCodeValue = null;
		if (extraForm !== null && extraForm !== undefined) {
			if (extraForm.isEditing()) {
				[isExtraFormValid, couponCodeValue] = await extraForm.submit(null, false);
			}
		}
		if (!validateForm($formState) || !isExtraFormValid) {
			return;
		}

		const firstName = $formState.elements.firstName.getValue();
		const lastName = $formState.elements.lastName.getValue();
		const email = $formState.elements.email.getValue();
		const password = $formState.elements.password.getValue();
		const subscribeToNewsletter = $formState.elements.newsletter.getValue().includes('on');

		try {
			const cart = await $cartStore;
			if (cart !== null && couponCodeValue !== null) {
				cart.couponCode = couponCodeValue;
				cartStore.setCart(cart);
			}

			checkoutStore.clearCheckout();
			const user = await createUser({
				firstName,
				lastName,
				email,
				password: password.length > 0 ? password : undefined,
				salutation: 'mr',
				subscribeToNewsletter: subscribeToNewsletter
			});
			userStore.setUser(user);

			// Keep track of registration time for tracking purposes
			try {
				localStorage.setItem('registered_at', new Date().toString());
			} catch (error) {
				// Fail silently
			}
		} catch (error) {
			if (error.name === 'HTTPError') {
				const errorJson = await error.response.json();
				if (errorJson.errors && errorJson.errors.email) {
					toastStore.trigger({
						message: form.accountAlreadyExistsNotice,
						type: 'success'
					});
					showLoginFormWithInitialEmail(email);
				}
			}
		}
	}
</script>

<h2 class="box-title">
	{form.title}
</h2>

<form class="form" on:submit|preventDefault={onSubmit}>
	<div class="form-main">
		<FormElement
			type="input"
			subType="text"
			size="small"
			name="firstName"
			label={form.firstNameLabel}
			requiredError={form.firstNameRequiredError}
			formState={$formState}
			bind:this={$formState.elements.firstName}
		/>
		<FormElement
			type="input"
			subType="text"
			size="small"
			name="lastName"
			label={form.lastNameLabel}
			requiredError={form.lastNameRequiredError}
			formState={$formState}
			bind:this={$formState.elements.lastName}
		/>
		<FormElement
			type="input"
			subType="email"
			size="large"
			name="email"
			label={form.emailLabel}
			requiredError={form.emailRequiredError}
			invalidError={form.emailInvalidError}
			formState={$formState}
			bind:this={$formState.elements.email}
		/>
		<FormElement
			type="input"
			subType="password"
			size="large"
			name="password"
			label={form.passwordLabel}
			placeholder={form.passwordPlaceholder}
			minLength={8}
			minLengthError={form.passwordMinLengthError}
			formState={$formState}
			bind:this={$formState.elements.password}
		/>
		<FormElement
			type="optionGroup"
			subType="checkbox"
			size="large"
			name="newsletter"
			options={[['on', form.newsletterLabel]]}
			formState={$formState}
			defaultValue="on"
			bind:this={$formState.elements.newsletter}
		/>
	</div>
	<div class="form-sidebar">
		<div class="form-toggle">
			<div class="form-toggle-label">
				{form.loginLabel}
			</div>
			<span class="form-toggle-link" on:click={showLoginForm}>{form.loginButtonLabel}</span>
		</div>
	</div>
	<div class="form-submit">
		<button class="button" type="submit">{form.submitButtonLabel}</button>
	</div>
</form>
