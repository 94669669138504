import type { NonSubscribableProduct } from '$models/NonSubscribableProduct/NonSubscribableProduct';
import type { OrderConfigurator } from '$models/OrderConfigurator/OrderConfigurator';
import type { OrderConfiguratorV2 } from '$models/OrderConfigurator/OrderConfiguratorV2';
import type { Product } from '$models/Product/Product';

export function isNotUndefined<T>(value: T | undefined): value is T {
	return value !== undefined;
}

export function isOrderConfiguratorV2(
	value: OrderConfigurator | OrderConfiguratorV2
): value is OrderConfiguratorV2 {
	return 'type' in value;
}

export function isRegularProduct(product: Product | NonSubscribableProduct): product is Product {
	return 'category' in product;
}

export function isNonSubscribableProduct(
	product: Product | NonSubscribableProduct
): product is NonSubscribableProduct {
	return 'price' in product;
}
