<script lang="ts">
	import type { Order } from '$models/Order/Order';
	import type {
		OrderConfigurationOrder,
		OrderConfiguratorV2
	} from '$models/OrderConfigurator/OrderConfiguratorV2';
	import { page } from '$app/state';
	import { createConfiguratorState, setConfiguratorState } from '$lib/state/configurator.svelte';
	import { onMount } from 'svelte';
	import OrderConfiguratorCompact from './OrderConfiguratorCompact.svelte';
	import OrderConfiguratorDefault from './OrderConfiguratorDefault.svelte';
	import OrderConfiguratorDualStep from './OrderConfiguratorDualStep.svelte';

	interface Props {
		configurator: OrderConfiguratorV2;
		onSubmit: (order: OrderConfigurationOrder) => void;
		defaultOrder?: {
			frequency: number;
			productCategory?: any;
			products: { id: string; quantity: number }[];
			nonSubscribableProducts: any[]; // @TODO Implement and fix type
			couponCode: string | null;
		};
		recurringCouponCode?: string | null;
		onetimeCouponCode?: string | null;
	}

	// @TODO Some external onSubmit functions still expect a complete Order as argument. This does not break anything, but it needs to be revised
	let { configurator, onSubmit, defaultOrder, recurringCouponCode, onetimeCouponCode }: Props =
		$props();

	// If a defaultOrder is supplied, override the configuration defaults
	// @TODO First designed to be backwards compatible, implement in a better way
	if (defaultOrder) {
		configurator.configuration.categories = configurator.configuration.categories.map(
			(categoryItem) => {
				// Check if the products of this category includes a product from the defaultOrder
				const includesProduct = categoryItem.products.find(
					({ product }) => product.id === defaultOrder.products[0].id
				);

				// If no default category is set, set it if a product is found
				if (!defaultOrder.productCategory && includesProduct) {
					defaultOrder.productCategory = categoryItem.productCategory;
				}

				// Map all the products and set the quantities according to defaultOrder
				if (includesProduct) {
					categoryItem.products = categoryItem.products.map((productItem) => {
						const defaultProduct = defaultOrder.products.find(
							(defaultProduct) => defaultProduct.id === productItem.product.id
						);
						return {
							...productItem,
							quantity: defaultProduct ? defaultProduct.quantity : 0
						};
						return productItem;
					});
				}

				return categoryItem;
			}
		);

		if (defaultOrder.productCategory) {
			configurator.configuration.defaultCategory = defaultOrder.productCategory;
		}

		if (defaultOrder.frequency) {
			configurator.configuration.defaultFrequency = defaultOrder.frequency;
		}

		// If a coupon is passed on the default order, use it instead.
		// This should precede coupons because they usually come from cart.
		// Since we have no way of knowing which type it is, set both to this value.
		if (defaultOrder && defaultOrder.couponCode && defaultOrder.couponCode.length) {
			recurringCouponCode = defaultOrder.couponCode;
			onetimeCouponCode = defaultOrder.couponCode;
		}

		// @TODO also populate nonSubscribableProducts
	}

	onMount(() => {
		// Check for a coupon code from URL, and override the recurring coupon.
		if (page.url.searchParams.has('coupon')) {
			const couponFromUrl = page.url.searchParams.get('coupon');
			if (couponFromUrl !== null && couponFromUrl.length > 0) {
				recurringCouponCode = couponFromUrl;
			}
		}

		if (recurringCouponCode) {
			configuratorState.setRecurringCouponCode(recurringCouponCode);
		}

		if (onetimeCouponCode) {
			configuratorState.setOnetimeCouponCode(onetimeCouponCode);
		}
	});

	const configuratorState = createConfiguratorState(configurator.configuration);

	setConfiguratorState(configuratorState);
</script>

{#if configurator.type === 'default'}
	<OrderConfiguratorDefault {configurator} {onSubmit} />
{:else if configurator.type === 'compact'}
	<OrderConfiguratorCompact {configurator} {onSubmit} />
{:else if configurator.type === 'dual_step'}
	<OrderConfiguratorDualStep {configurator} {onSubmit} />
{/if}

<style lang="postcss" global>
	.step-section {
		& {
			max-width: 1000px;
			margin: 0 auto;
		}

		&--small {
			max-width: var(--container-extra-small);
		}

		&--wide {
			max-width: 100%;
		}

		&:not(.hidden) {
			margin-bottom: var(--spacing-18);
		}

		&:last-child {
			margin-bottom: 0;
		}

		&-header {
			text-align: center;

			&:not(:empty) {
				margin-bottom: var(--spacing-8);
			}
		}

		&-title {
			font-family: var(--configurator-section-title-font-family);
			font-weight: var(--configurator-section-title-font-weight);
			font-size: var(--configurator-section-title-font-size);
			text-transform: var(--configurator-section-title-text-transform);
			line-height: var(--font-lineheight-1);
			text-transform: uppercase;
			color: var(--configurator-section-title-color);
			letter-spacing: 0.5px;
			margin-bottom: var(--spacing-1);
		}

		&-subtitle {
			font-family: var(--configurator-section-subtitle-font-family);
			font-weight: var(--configurator-section-subtitle-font-weight);
			font-size: var(--configurator-section-subtitle-font-size);
			text-transform: var(--configurator-section-subtitle-text-transform);
		}

		&-box {
			position: relative;
			text-align: center;
			padding: var(--spacing-8);
			color: var(--base-color);
			background-color: var(--color-white);

			&:before {
				content: '';
				display: block;
				position: absolute;
				z-index: 1;
				top: 10px;
				left: 10px;
				right: -10px;
				bottom: -10px;
				border: 2px solid var(--color-primary);
			}

			&-inner {
				position: relative;
				z-index: 2;
			}
		}

		&-text {
			margin-bottom: var(--spacing-4);
		}

		&-choices {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 auto;
			max-width: 540px;
			padding-top: var(--spacing-2);

			@media (--sm) {
				flex-direction: row;
			}
		}

		&-choice {
			&-image {
				width: 100%;
				opacity: 0.5;
				transition: opacity var(--transition);
				margin: 0 12px;

				@media (--sm) {
					width: calc(50% - 24px);
				}

				&:not(:first-child) {
					margin-top: var(--spacing-8);

					@media (--sm) {
						margin-top: 0;
					}
				}

				.no-selection &,
				&.is-active {
					opacity: 1;
				}

				&:hover {
					opacity: 1;
				}

				&-holder {
					border-radius: 8px;
					border: 4px solid transparent;
					overflow: hidden;
					transition: border-color var(--transition);
					margin-bottom: var(--spacing-5);

					.is-active & {
						border-color: var(--color-primary);
					}
				}

				&-src {
					width: 100%;
					height: auto;
					aspect-ratio: 9 / 6;
					object-fit: cover;
				}
			}

			&-button {
				margin: 0 4px;
				flex: 1;
				padding-left: var(--spacing-3);
				padding-right: var(--spacing-3);

				@media (--md) {
					flex: 0 1 auto;
					margin: 0 5px;
					padding-left: var(--button-spacing-y);
					padding-right: var(--button-spacing-y);
				}

				&.is-active {
					background-color: var(--button-background-hover);
				}
			}
		}
	}

	.step {
		& {
			color: var(--base-color);
			background-color: white;
		}

		&-inner {
			@media (--md) {
				display: flex;
				align-items: stretch;
			}
		}

		&-main {
			padding: var(--spacing-6);
			flex: 1;

			@media (--md) {
				padding: var(--spacing-8);
			}
		}

		&-header {
			margin-bottom: var(--spacing-6);
		}

		&-title {
			font-size: var(--configurator-box-title-font-size);
			font-family: var(--configurator-box-title-font-family);
			font-weight: var(--configurator-box-title-font-weight);
			text-transform: var(--configurator-box-title-text-transform);
			line-height: var(--font-lineheight-0);
			margin-bottom: var(--spacing-2);
		}

		&-subtitle {
			font-size: var(--font-size-0);
			line-height: var(--font-lineheight-0);
			color: var(--color-grey-3);
		}

		&-products {
			margin-bottom: var(--spacing-6);
		}

		&-product {
			display: flex;
			align-items: center;

			&:not(:last-child) {
				margin-bottom: var(--spacing-3);
			}

			&-title {
				flex: 1;
				font-size: var(--configurator-product-list-title-font-size);
				font-family: var(--configurator-product-list-title-font-family);
				font-weight: var(--configurator-product-list-title-font-weight);
				text-transform: var(--configurator-product-list-title-text-transform);
				line-height: var(--font-lineheight-0);
				color: var(--configurator-product-list-title-color);
				padding-right: var(--spacing-4);
			}

			&-input {
				display: flex;
				align-items: stretch;

				input {
					max-width: 50px;
					padding: 8px;
					font-size: 14px;
					border-left: 0;
					border-right: 0;
					text-align: center;
				}

				button {
					display: block;
					padding: 4px 16px;
					color: white;
					background-color: var(--color-primary);
				}
			}
		}

		&-totals {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			margin-bottom: var(--spacing-2);

			&:before {
				content: '';
				display: block;
				width: 60px;
				height: 2px;
				background-color: var(--color-primary);
				margin-bottom: var(--spacing-2);
			}

			&-inner {
				position: relative;
				display: flex;
				align-items: center;
				min-height: 54px;
			}

			&-count {
				white-space: nowrap;
				font-size: var(--font-size-2);
				line-height: var(--font-lineheight-0);
			}

			&-error {
				position: absolute;
				top: 50%;
				right: calc(100% + 12px);
				transform: translateY(-50%);
				font-size: 13px;
				text-align: right;
				line-height: var(--font-lineheight-1);
				padding: 10px 12px 10px 35px;
				color: white;
				white-space: nowrap;
				background-color: var(--color-red);
				background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNCI+PHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJtOCAwIDggMTRIMEw4IDBaTTcgNC45NzZjMC0uNTU3LjQ0NC0xLjAxIDEtMS4wMS41NTIgMCAxIC40NDggMSAxLjAxdjMuOTMxYzAgLjU1OC0uNDQ0IDEuMDEtMSAxLjAxLS41NTIgMC0xLS40NDgtMS0xLjAxVjQuOTc2Wk03IDExLjlhLjk5My45OTMgMCAwIDEgMS0uOTkyYy41NTIgMCAxIC40NCAxIC45OTJhLjk5My45OTMgMCAwIDEtMSAuOTkyYy0uNTUyIDAtMS0uNDQtMS0uOTkyWiIvPjwvc3ZnPg==');
				background-size: 16px 14px;
				background-position: 11px calc(50% - 1px);
				background-repeat: no-repeat;
				border-radius: var(--radius-2);

				&:after {
					content: '';
					position: absolute;
					top: calc(50% - 8px);
					right: -0.375rem;
					width: 0;
					height: 0;
					border-top: 8px solid transparent;
					border-bottom: 8px solid transparent;
					border-left: 8px solid var(--color-red);
				}
			}
			&-success {
				position: absolute;
				top: 50%;
				right: calc(100% + 12px);
				transform: translateY(-50%);
				font-size: 13px;
				text-align: right;
				line-height: var(--font-lineheight-1);
				padding: 10px 12px;
				color: white;
				white-space: nowrap;
				background-color: var(--color-green);
				border-radius: var(--radius-2);

				&:after {
					content: '';
					position: absolute;
					top: calc(50% - 8px);
					right: -0.375rem;
					width: 0;
					height: 0;
					border-top: 8px solid transparent;
					border-bottom: 8px solid transparent;
					border-left: 8px solid var(--color-green);
				}
			}
		}

		&-footer {
			display: flex;
			align-items: center;
		}

		&-discount {
			&-price {
				display: flex;
				flex-wrap: wrap;
				line-height: var(--font-lineheight-2);

				&-value {
					width: 100%;
					font-size: var(--font-size-3);
				}
				&-per-unit {
					display: block;
					width: 100%;
					font-size: var(--font-size-0);
					color: var(--color-grey-3);
					margin-top: var(--spacing-1);
				}
			}
		}

		&-price {
			line-height: var(--font-lineheight-0);
			&-label {
				color: var(--color-grey-3);
				font-size: var(--font-size-0);
				line-height: var(--font-lineheight-0);
				margin-bottom: 4px;
			}
			&-count {
				font-size: var(--font-size-4);
				line-height: var(--font-lineheight-0);
			}
		}

		&-button {
			margin-left: auto;

			button {
				&.disabled {
					opacity: 0.4;
					cursor: not-allowed;
				}
			}
		}

		&-image {
			display: none;

			@media (--md) {
				display: block;
				width: 45%;
				position: relative;
			}

			img {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.step-extras {
		color: var(--color-black);
		padding: var(--spacing-5);
		background-color: var(--color-white);

		@media (--xs) {
			padding: var(--spacing-8);
		}

		&-slider {
			.slider {
				--slide-size: 90%;

				@media (--sm) {
					--slide-size: calc(100% / 3);
				}

				@media (--lg) {
					--slide-size: calc(100% / 4);
				}
			}

			&--count-1,
			&--count-2,
			&--count-3 {
				.slider {
					&-container {
						@media (--sm) {
							justify-content: center;
						}
					}
				}
			}
		}

		&-footer {
			display: flex;
			flex-direction: column;
			align-items: center;

			@media (--xs) {
				align-items: flex-end;
			}
		}

		&-subtotal {
			margin-top: var(--spacing-6);
			margin-bottom: var(--spacing-4);
			font-size: var(--font-size-3);

			@media (--sm) {
				font-size: var(--font-size-4);
			}
		}

		&-submit {
			display: flex;
			justify-content: center;
			width: 100%;

			@media (--xs) {
				width: auto;
			}

			.button {
				width: 100%;

				@media (--xs) {
					width: auto;
				}
			}
		}
	}

	.step-extra {
		& {
			text-align: center;
		}
		&-image {
			margin-bottom: var(--spacing-5);

			img {
				display: block;
				width: 100%;
				height: auto;
				aspect-ratio: 1/1;
				object-fit: cover;
			}
		}

		&-name {
			display: block;
			font-size: var(--font-size-0);
			line-height: var(--font-lineheight-0);
			margin-bottom: var(--spacing-1);

			@media (--md) {
				font-size: var(--font-size-2);
				line-height: var(--base-lineheight);
			}
		}

		&-price {
			display: block;
			margin-bottom: var(--spacing-3);
		}

		&-quantity {
			display: flex;
			align-items: stretch;
			margin: 0 auto;
			max-width: 136px;

			input {
				flex: 1;
				padding: 8px;
				font-size: 14px;
				border-left: 0;
				border-right: 0;
				text-align: center;
			}

			button {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 40px;
				height: 40px;
				color: white;
				background-color: var(--color-primary);
			}
		}
	}
</style>
