<script lang="ts">
	import type { BlogOverviewPage } from '$models/Page/LandingPage'; // @TODO: TS error?
	import Button from '$components/UI/Buttons/Button.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { formatDate, sanitizeHtml } from '$lib/utils';

	// Component properties
	export let page: BlogOverviewPage;

	const site = getSiteContext();
</script>

<div class="block">
	<div class="block-container container">
		<div class="blog">
			{#each page.articles as article}
				<div class="blog-item">
					<div class="blog-container">
						{#if article.featuredImage}
							<figure class="blog-image">
								<img src={article.featuredImage.url} alt={article.featuredImage.alt} />
							</figure>
						{/if}
						<div class="blog-content">
							<h2 class="blog-title">
								{@html article.title}
							</h2>
							<div class="blog-subtitle">
								{article.subTitle.replace('%date%', formatDate(article.date, site.locale.code))}
							</div>
							<div class="blog-intro">
								{@html sanitizeHtml(article.intro)}
							</div>
							<Button type="anchor" href={article.button.url}>
								{article.button.title}
							</Button>
						</div>
					</div>
				</div>
			{/each}
		</div>
	</div>
</div>

<style lang="postcss">
	.blog {
		max-width: 948px;
		margin: 0 auto;

		&-item {
			&:not(:last-child) {
				border-bottom: 1px solid var(--border-color);
				padding-bottom: var(--spacing-8);
				margin-bottom: var(--spacing-8);

				@media (--sm) {
					padding-bottom: var(--spacing-12);
					margin-bottom: var(--spacing-12);
				}
			}
		}

		&-container {
			@media (--sm) {
				display: flex;
				align-items: center;
			}
		}

		&-image {
			max-width: 364px;
			margin-bottom: var(--spacing-6);

			img {
				width: 100%;
				height: auto;
				aspect-ratio: 4 / 3;
				object-fit: cover;
				object-position: center center;
			}

			@media (--sm) {
				width: 37.91666667%; /* 364/960 */
				align-self: flex-start;
				margin-bottom: 0;
				margin-right: var(--spacing-10);
			}
		}

		&-content {
			flex: 1;
		}

		&-title {
			font-family: var(--blog-item-title-font-family);
			font-weight: var(--blog-item-title-font-weight);
			text-transform: var(--blog-item-title-text-tranform);
			margin-bottom: var(--spacing-2);
		}

		&-subtitle {
			font-family: var(--blog-item-subtitle-font-family);
			font-size: var(--blog-item-subtitle-font-size);
			text-transform: uppercase;
			color: var(--blog-item-subtitle-color);
			font-weight: var(--blog-item-subtitle-font-weight);
			margin-bottom: var(--spacing-5);
		}

		&-intro {
			margin-bottom: var(--spacing-6);
		}
	}
</style>
