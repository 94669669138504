<script lang="ts">
	import type { OrderItem } from '$models/Order/Order';
	import { page } from '$app/stores';

	export let items: OrderItem[];
</script>

{#if items.length > 0}
	<ul>
		{#each items as item}
			{@const product = $page.data.products.regular.find((product) => product.id === item.id)}
			{@const nonSubscribableProduct = $page.data.products.nonSubscribable.find(
				(product) => product.id === item.id
			)}

			{#if product !== undefined}
				<li>
					<span>{item.quantity * (product.quantityMultiplier || 1)}x</span>
					{@html product.name}
				</li>
			{/if}

			{#if nonSubscribableProduct !== undefined}
				<li>
					<span>{item.quantity}x</span>
					{nonSubscribableProduct.name}
				</li>
			{/if}
		{/each}
	</ul>
{/if}
