import type { Order, OrderItem } from '$models/Order/Order';
import type {
	Order as WebparkingOrder,
	OrderItem as WebparkingOrderItem
} from '$models/Webparking/Orders';
import type { WebparkingApiResponse } from '$models/Webparking/WebparkingApiResponse';
import { webparking } from '$lib/webparking';

function webparkingOrderItemDataToOrderItem(data: WebparkingOrderItem): OrderItem {
	if (typeof data.id === 'string') {
		return {
			id: data.id,
			quantity: data.quantity
		};
	}

	if (typeof data.product_id === 'string') {
		return {
			id: data.product_id,
			quantity: data.quantity
		};
	}

	throw new Error(
		'Could not transform WebparkingOrderItem. Either "id" or "product_id" is required.'
	);
}

function orderToWebparkingOrderData(order: Order): WebparkingOrder {
	return {
		id: order.id !== null ? order.id : undefined,
		shipping_address_id: order.shippingAddressId,
		billing_address_id: order.billingAddressId,
		order_frequency: order.frequency,
		remarks: order.deliveryNote,
		order_items: order.products.map((product) => ({
			product_id: product.id,
			quantity: product.quantity
		})),
		non_subscribable_order_items:
			order.nonSubscribableProducts.length > 0
				? order.nonSubscribableProducts.map((nonSubscribableProduct) => ({
						non_subscribable_product_id: nonSubscribableProduct.id,
						quantity: nonSubscribableProduct.quantity
					}))
				: undefined,
		po_number: order.poNumber
	};
}

function webparkingOrderDataToOrder(data: WebparkingOrder): Order {
	const numberParts = data.number.split('-');
	return {
		id: data.id,
		number: data.number,
		index: parseInt(numberParts[numberParts.length - 1]),
		billingAddressId: data.billing_address_id,
		shippingAddressId: data.shipping_address_id,
		frequency: data.order_frequency,
		remarks: data.remarks,
		poNumber: data.po_number ?? null,
		products: data.order_items.map(webparkingOrderItemDataToOrderItem),
		nonSubscribableProducts:
			data.non_subscribable_products?.map(webparkingOrderItemDataToOrderItem) ?? [],
		total: parseFloat(data.total_amount),
		paymentStatus: data.payment_status,
		shippingStatus: data.shipping_status,
		deliveryCompanyName: data.shipment_carrier.translation,
		deliveryDate: new Date(data.delivery_date),
		completedAt: data.completed_at ? new Date(data.completed_at) : null,
		couponCode: null // TODO: Where to populate from?
	};
}

export async function getOrder(orderId: string): Promise<Order> {
	const json: WebparkingApiResponse<WebparkingOrder> = await webparking
		.get(`orders/${orderId}`)
		.json();
	return webparkingOrderDataToOrder(json.data);
}

export async function createOrder(order: Order): Promise<Order> {
	const json: WebparkingApiResponse<WebparkingOrder> = await webparking
		.post('orders', {
			json: orderToWebparkingOrderData(order)
		})
		.json();
	return webparkingOrderDataToOrder(json.data);
}

export async function updateOrder(order: Order): Promise<Order> {
	const json: WebparkingApiResponse<WebparkingOrder> = await webparking
		.patch(`orders/${order.id}`, {
			json: orderToWebparkingOrderData(order)
		})
		.json();
	return webparkingOrderDataToOrder(json.data);
}

type ListOrdersRequestParams = {
	subscriptionId?: string;
	emergencyOrderForSubscriptionId?: string;
	uncompletedOnly?: boolean;
};
export async function listOrders(params?: ListOrdersRequestParams): Promise<Order[]> {
	const searchParams = new URLSearchParams();

	if (params?.subscriptionId) {
		searchParams.append('subscription_id', params.subscriptionId);
	}

	if (params?.emergencyOrderForSubscriptionId) {
		searchParams.append(
			'emergency_order_for_subscription_id',
			params.emergencyOrderForSubscriptionId
		);
	}

	if (params?.uncompletedOnly) {
		searchParams.append('uncompleted_only', params.uncompletedOnly.toString());
	}

	const json: WebparkingApiResponse<WebparkingOrder[]> = await webparking
		.get('orders', { searchParams })
		.json();

	return json.data.map(webparkingOrderDataToOrder);
}

export async function deleteOrder(orderId: string): Promise<void> {
	await webparking.delete(`orders/${orderId}`);
}
