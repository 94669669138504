import type { PaymentMethod, PaymentMethodTranslationLabels } from '$models/Payment/PaymentMethod';
import type { PaymentMethod as WebparkingPaymentMethod } from '$models/Webparking/PaymentMethod';
import type { WebparkingApiResponse } from '$models/Webparking/WebparkingApiResponse';
import { webparking } from '$lib/webparking';

function webparkingPaymentMethodDataToPaymentMethod(
	data: WebparkingPaymentMethod,
	translatedLabels: PaymentMethodTranslationLabels
): PaymentMethod {
	const paymentMethod = {
		id: data.id,
		name: transformPaymentMethodName(data, translatedLabels),
		logo: {
			url: data.logo.svg
		},
		issuers:
			data.issuers !== null
				? data.issuers.map((issuer) => ({
						id: issuer.id,
						name: issuer.name
					}))
				: []
	};
	return paymentMethod;
}

export async function listPaymentMethods(translatedLabels: PaymentMethodTranslationLabels) {
	const json: WebparkingApiResponse<WebparkingPaymentMethod[]> = await webparking
		.get('payment-methods')
		.json();
	return json.data.map((data) =>
		webparkingPaymentMethodDataToPaymentMethod(data, translatedLabels)
	);
}

export function transformPaymentMethodName(
	paymentMethod: WebparkingPaymentMethod,
	translatedLabels: PaymentMethodTranslationLabels
): string {
	// Try to find the translated label for the payment method
	const translatedPaymentMethod = translatedLabels.find(
		(label) => label.original_label === paymentMethod.id
	);

	return translatedPaymentMethod?.translated_label ?? paymentMethod.name;
}
