<script lang="ts">
	import { formatNumericString } from '$lib/utils';

	export let portions: number;
	export let frequency: number;
	export let days: number;
	export let translations: {
		totalSingle: string;
		totalPlural: string;
		perPeriodSingle: string;
		perPeriodPlural: string;
	};

	let averagePortions = 0;

	function calculateAveragePortions(portions: number, frequency: number, days: number) {
		return Math.round(portions / frequency); // Portions per week
		return Math.round(portions / (days * frequency)); // Portions per day
	}

	$: averagePortions = calculateAveragePortions(portions, frequency, days);
</script>

<div class="aio-step-suggestions">
	<div class="aio-step-suggestion">
		<svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" fill="none">
			<path
				fill="currentColor"
				fill-rule="evenodd"
				d="M.614.996A1.063 1.063 0 0 1 2.097.942l4.9 4.469c.214.195.336.469.336.756 0 .286-.122.56-.336.755l-4.9 4.47a1.063 1.063 0 0 1-1.483-.055A1.018 1.018 0 0 1 .669 9.88l4.07-3.713L.67 2.454A1.018 1.018 0 0 1 .614.996Z"
				clip-rule="evenodd"
			/>
		</svg>
		<!-- TODO: Restore <strong> tags as below -->
		<!-- <span><strong>{portions} porties</strong> granola</span> -->
		<span
			>{formatNumericString(
				translations.totalSingle,
				translations.totalPlural,
				portions,
				'%quantity%'
			)}</span
		>
	</div>
	<div class="aio-step-suggestion">
		<svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" fill="none">
			<path
				fill="currentColor"
				fill-rule="evenodd"
				d="M.614.996A1.063 1.063 0 0 1 2.097.942l4.9 4.469c.214.195.336.469.336.756 0 .286-.122.56-.336.755l-4.9 4.47a1.063 1.063 0 0 1-1.483-.055A1.018 1.018 0 0 1 .669 9.88l4.07-3.713L.67 2.454A1.018 1.018 0 0 1 .614.996Z"
				clip-rule="evenodd"
			/>
		</svg>
		<!-- TODO: Restore <strong> tags as below -->
		<!-- <span>Geschikt voor <strong>{averagePortions} porties</strong> per week</span> -->
		<span
			>{formatNumericString(
				translations.perPeriodSingle,
				translations.perPeriodPlural,
				averagePortions,
				'%quantity%'
			)}</span
		>
	</div>
</div>

<style lang="postcss">
	.aio-step {
		&-suggestions {
			position: relative;
			font-size: var(--font-size-0);
			line-height: var(--font-lineheight-1);
			padding: 14px 16px;
			margin-top: var(--spacing-5);
			background-color: var(--color-primary-light);

			@media (--sm) {
				margin-left: 31%;
			}

			&:before {
				content: '';
				position: absolute;
				z-index: 2;
				display: block;
				top: -8px;
				left: 12px;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 8px solid var(--color-primary-light);

				@media (--sm) {
					left: 12px;
				}
			}

			&:not(:last-child) {
				margin-bottom: var(--spacing-6);
			}
		}

		&-suggestion {
			display: flex;
			align-items: flex-start;

			svg {
				display: block;
				margin-top: 2px;
				margin-right: 6px;
				color: var(--color-primary);
			}

			span {
				flex: 1;
			}

			&:not(:last-child) {
				margin-bottom: var(--spacing-1);
			}
		}
	}
</style>
