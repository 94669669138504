<script lang="ts">
	import AccountNavigation from '$components/Account/AccountNavigation.svelte';
	import Form from '$components/Form/Form.svelte';
	import { getUserStore } from '$lib/stores';

	export let pageComponent: any;
	export let page: any;

	const userStore = getUserStore();
</script>

<div class="block block--flex variant-tint-1">
	<div class="block__container container">
		{#await $userStore then user}
			{#if user !== null}
				<AccountNavigation links={page.accountLinks} />
				<svelte:component this={pageComponent} {page} />
			{:else}
				<Form form={page.forms.auth} />
			{/if}
		{/await}
	</div>
</div>
