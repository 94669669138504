<script lang="ts">
	import type { AccountCustomerInfoPage } from '$models/Page/AccountCustomerInfoPage';
	import AccountBlock from '$components/Account/AccountBlock/AccountBlock.svelte';
	import AccountColumn from '$components/Account/AccountColumn.svelte';
	import AccountColumns from '$components/Account/AccountColumns.svelte';

	// Component properties
	export let page: AccountCustomerInfoPage;
</script>

<AccountColumns>
	{#each page.columns as column}
		<AccountColumn>
			{#each column.blocks as block}
				<AccountBlock accountBlock={block} {page} />
			{/each}
		</AccountColumn>
	{/each}
</AccountColumns>
