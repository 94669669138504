import type { ApplicableDiscount } from '$models/ApplicableDiscount/ApplicableDiscount';
import type { Order } from '$models/Order/Order';
import type { OrderPrices } from '$models/Order/OrderPrices';
import type { NonSubscribableProduct as WebparkingNonSubscribableProduct } from '$models/Webparking/NonSubscribableProducts';
import { webparking } from '$lib/webparking';

/**
 * Returns the order prices for a given order.
 *
 * @async
 * @function
 * @param {Order} order - The order to get the order prices for.
 * @param {Array<number>} allowedQuantities - The quantities that are allowed
 * @param {string} couponCodeRecurring - Coupon code to apply to recurring orders
 * @param {string} couponCodeOnetime - Coupon code to apply to onetime orders
 * @returns {Promise<OrderPrice>}
 */
export async function getOrderPrices(
	order: Order,
	allowedQuantities?: Array<number> | undefined,
	couponCodeRecurring?: string | undefined | null,
	couponCodeOnetime?: string | undefined | null
): Promise<OrderPrices> {
	// Order property was used in the past, preserve backwards compatibility
	if (!couponCodeRecurring && order.couponCode) {
		couponCodeRecurring = order.couponCode;
	}
	try {
		// @TODO Allowed quantities should probably not be checked at this level
		let isAllowedQuantity = true;
		if (allowedQuantities !== undefined) {
			const quantity = order.products.reduce((accumulator, currentValue) => {
				accumulator += currentValue.quantity;
				return accumulator;
			}, 0);
			isAllowedQuantity = allowedQuantities.includes(quantity);
		}
		if (
			!isAllowedQuantity ||
			(order.products.length === 0 && order.nonSubscribableProducts.length === 0)
		) {
			return {
				productsLowestRecurringPrice: null,
				productsRecurringPrice: null,
				productsOneTimePrice: null,
				nonSubscribableProductPrices: {},
				totalNonSubscribableProductsPrice: 0,
				discountAmount: 0,
				discountAmountOnetime: 0,
				freeNonSubscribableProducts: []
			};
		}

		// Get all the applicable Webparking data concurrently
		const [
			productsLowestRecurringPrice,
			productsRecurringPrice,
			productsOneTimePrice,
			webparkingNonSubscribableProducts
		] = await Promise.all([
			getOrderPricesProductsPrice(order, 1),
			getOrderPricesProductsPrice(order, order.frequency),
			getOrderPricesProductsPrice(order, 0),
			getOrderPricesNonSubscribableProducts(order)
		]);

		// Construct the initial order price object
		const orderPrices: OrderPrices = {
			productsLowestRecurringPrice: productsLowestRecurringPrice,
			productsRecurringPrice: productsRecurringPrice,
			productsOneTimePrice: productsOneTimePrice,
			nonSubscribableProductPrices: getOrderPricesNonSubscribableProductPrices(
				order,
				webparkingNonSubscribableProducts
			),
			totalNonSubscribableProductsPrice: 0,
			discountAmount: 0,
			discountAmountOnetime: 0,
			freeNonSubscribableProducts: []
		};
		orderPrices.totalNonSubscribableProductsPrice = getTotalNonSubscribableProductsPrice(
			orderPrices.nonSubscribableProductPrices
		);

		// Optionally add the coupon code price data to the order prices object
		if (couponCodeRecurring) {
			const discountDataRecurring = await getOrderPricesDiscountData(
				{
					...order,
					couponCode: couponCodeRecurring
				},
				orderPrices
			);
			orderPrices.discountAmount = discountDataRecurring.amount;
			orderPrices.freeNonSubscribableProducts = discountDataRecurring.freeNonSubscribableProducts;
		}
		if (couponCodeOnetime) {
			const discountDataOnetime = await getOrderPricesDiscountData(
				{
					...order,
					frequency: 0,
					couponCode: couponCodeOnetime
				},
				orderPrices
			);
			orderPrices.discountAmountOnetime = discountDataOnetime.amount;
		}

		// Return the order prices object
		return orderPrices;

		// If any error occurs, return an OrderPrices object that indicates the order is invalid
	} catch (error) {
		// If getting the regular products prices failed, try getting just the non subscribable prices if applicable
		let nonSubscribableProductPrices = {};
		if (order && order.nonSubscribableProducts.length > 0) {
			try {
				const webparkingNonSubscribableProducts =
					await getOrderPricesNonSubscribableProducts(order);
				nonSubscribableProductPrices = getOrderPricesNonSubscribableProductPrices(
					order,
					webparkingNonSubscribableProducts
				);
			} catch (error) {}
		}

		return {
			productsLowestRecurringPrice: null,
			productsRecurringPrice: null,
			productsOneTimePrice: null,
			nonSubscribableProductPrices: nonSubscribableProductPrices,
			totalNonSubscribableProductsPrice: getTotalNonSubscribableProductsPrice(
				nonSubscribableProductPrices
			),
			discountAmount: 0,
			discountAmountOnetime: 0,
			freeNonSubscribableProducts: []
		};
	}
}

function getTotalNonSubscribableProductsPrice(nonSubscribableProductPrices) {
	return Object.values(nonSubscribableProductPrices).reduce((accumulator, currentValue) => {
		accumulator += currentValue;
		return accumulator;
	}, 0);
}

/**
 * Returns the order products price for a given order and frequency.
 *
 * @async
 * @function
 * @param {Order} order - The order to get the order price for.
 * @param {number} frequency - The frequency to get the order price for.
 * @returns {Promise<number>}
 */
export async function getOrderPricesProductsPrice(
	order: Order,
	frequency: number
): Promise<number> {
	const orderPriceRequestData = {
		frequency
	};
	if (order.products.length > 0) {
		orderPriceRequestData.order_items = order.products;
	}
	return webparking.post('order-price', { json: orderPriceRequestData }).json();
}

/**
 * Returns the Webparking non subscribable products for a given order.
 *
 * @async
 * @function
 * @param {Order} order - The order to get the Webparking non subscribable products for.
 * @returns {Promise<WebparkingNonSubscribableProduct[]>}
 */
async function getOrderPricesNonSubscribableProducts(
	order: Order
): Promise<WebparkingNonSubscribableProduct[]> {
	if (order.nonSubscribableProducts.length === 0) {
		return Promise.resolve([]);
	}
	const json = await webparking.get(`non-subscribable-products`).json();
	return json.data;
}

/**
 * Returns the non subscribable products price for a given order and Webparking non subscribable products.
 *
 * @function
 * @param {Order} order - The order to get the non subscribable products price for.
 * @param {WebparkingNonSubscribableProduct[]} webparkingNonSubscribableProducts - The Webparking non subscribable products to get the non subscribable products price for.
 * @returns {Promise<number>}
 */
function getOrderPricesNonSubscribableProductPrices(
	order: Order,
	webparkingNonSubscribableProducts: WebparkingNonSubscribableProduct[]
): number {
	const webparkingNonSubscribableProductPricesMapping = webparkingNonSubscribableProducts.reduce(
		(accumulator, currentValue) => {
			accumulator[currentValue.id] = parseFloat(currentValue.price);
			return accumulator;
		},
		{}
	);
	return order.nonSubscribableProducts.reduce((accumulator, currentValue) => {
		if (!(currentValue.id in accumulator)) {
			accumulator[currentValue.id] =
				currentValue.quantity * webparkingNonSubscribableProductPricesMapping[currentValue.id];
		}
		return accumulator;
	}, {});
}

/**
 * Returns the products discount price for a given order and order prices.
 *
 * @async
 * @function
 * @param {Order} order - The order to get the products discount price for.
 * @param {OrderPrices} orderPrices - The order prices to get the products discount price for.
 * @returns {Promise<ApplicableDiscount>}
 */
export async function getOrderPricesDiscountData(
	order: Order,
	orderPrices: OrderPrices
): Promise<ApplicableDiscount> {
	// Prepare the applicable discount request data
	const productTotalQuantity = Object.values(order.products).reduce((accumulator, currentValue) => {
		return accumulator + currentValue.quantity;
	}, 0);
	const nonSubscribableTotalQuantity = Object.values(order.nonSubscribableProducts).reduce(
		(accumulator, currentValue) => {
			return accumulator + currentValue.quantity;
		},
		0
	);
	const productsPrice =
		order.frequency === 0 ? orderPrices.productsOneTimePrice : orderPrices.productsRecurringPrice;
	let applicableDiscounData: PostApplicableDiscountRequestData = {
		number_of_products: productTotalQuantity + nonSubscribableTotalQuantity,
		for_subscription: order.frequency > 0,
		total_product_price: productsPrice + orderPrices.totalNonSubscribableProductsPrice
	};
	if (order.couponCode !== null) {
		applicableDiscounData.code = order.couponCode;
	}
	if (order.products.length > 0) {
		applicableDiscounData.product_ids = order.products.map((product) => product.id);
	}
	if (order.nonSubscribableProducts.length > 0) {
		applicableDiscounData.non_subscribable_product_ids = order.nonSubscribableProducts.map(
			(nonSubscribableProduct) => nonSubscribableProduct.id
		);
	}

	// TODO: Move to its own place. Should we do something with the codes?
	interface ApplicableDiscountResponseData {
		amount: number;
		no_applicable_discount_reason: {
			code:
				| 'unknown'
				| 'used-up'
				| 'expired'
				| 'too-many-products'
				| 'only-subscriptions'
				| 'only-first-order-of-subscription'
				| 'amount-too-low'
				| 'amount-too-high'
				| 'no-match-for-products'
				| 'no-match-for-non-subscribable-products';
			message: string;
		};
	}
	// Get the applicable discount response data from the  async Webparking API call
	const applicableDiscountResponseData: ApplicableDiscountResponseData = await webparking
		.post('applicable-discount', { json: applicableDiscounData })
		.json();

	// Return the applicable discount data
	const freeNonSubscribableProductsDiscountAmount =
		applicableDiscountResponseData.free_non_subscribable_products.reduce(
			(accumulator, currentValue) => {
				let currentNonSubscribableProductQuantityInOrder = 0;
				order.nonSubscribableProducts.forEach((nonSubscribableProduct) => {
					if (currentValue.id === nonSubscribableProduct.id) {
						currentNonSubscribableProductQuantityInOrder = nonSubscribableProduct.quantity;
						return false;
					}
				});
				accumulator +=
					Math.min(currentValue.quantity, currentNonSubscribableProductQuantityInOrder) *
					currentValue.price;
				return accumulator;
			},
			0
		);

	return {
		isApplicable:
			applicableDiscountResponseData.amount > 0 ||
			applicableDiscountResponseData.free_non_subscribable_products.length > 0,
		amount: applicableDiscountResponseData.amount + freeNonSubscribableProductsDiscountAmount,
		freeNonSubscribableProducts: applicableDiscountResponseData.free_non_subscribable_products.map(
			(nonSubscribableProduct) => ({
				id: nonSubscribableProduct.id,
				quantity: nonSubscribableProduct.quantity
			})
		),
		noApplicableDiscountReason: applicableDiscountResponseData.no_applicable_discount_reason
	};
}

/**
 * Automatically applies a coupon code to the next order.
 *
 * @function
 * @param {string} code
 */
export async function autoApplyDiscount(code: string) {
	await webparking.post('discount/auto-apply', {
		json: {
			code
		}
	});
}
