<script lang="ts">
	import type { PaymentStatus } from '$models/Status/PaymentStatus';
	import { page } from '$app/stores';
	import CheckIcon from '$components/UI/Icons/CheckIcon.svelte';
	import ClockIcon from '$components/UI/Icons/ClockIcon.svelte';
	import CloseIcon from '$components/UI/Icons/CloseIcon.svelte';

	export let status: PaymentStatus;

	$: translations = $page.data.page.layout.translations;
</script>

<div class="payment-status">
	{#if status === 'paid'}
		<figure class="payment-status-icon">
			<CheckIcon />
		</figure>
	{:else if status === 'not_started'}
		<figure class="payment-status-icon">
			<ClockIcon />
		</figure>
	{:else if status === 'cancelled'}
		<figure class="payment-status-icon">
			<CloseIcon />
		</figure>
	{:else if status === 'failed'}
		<figure class="payment-status-icon">
			<CloseIcon />
		</figure>
	{:else if status === 'in_progress'}
		<figure class="payment-status-icon">
			<ClockIcon />
		</figure>
	{:else if status === 'complicated'}
		<figure class="payment-status-icon">
			<ClockIcon />
		</figure>
	{:else if status === 'directdebit_started'}
		<figure class="payment-status-icon">
			<ClockIcon />
		</figure>
	{:else if status === 'waived'}
		<figure class="payment-status-icon">
			<CloseIcon />
		</figure>
	{/if}
	{#if translations.paymentStatus[status]}
		{translations.paymentStatus[status]}
	{/if}
</div>

<style>
	.payment-status {
		display: flex;
		align-items: center;

		&-icon {
			max-width: 13px;
			color: var(--color-grey-3);
			margin-right: var(--spacing-2);
		}
	}
</style>
