import { getContext, setContext } from 'svelte';

const key = Symbol('menuState');

export function createMobileMenuState(initialValue: boolean = false) {
	let menuIsActive = $state(initialValue);

	return {
		isActive() {
			return menuIsActive;
		},
		toggle() {
			menuIsActive = !menuIsActive;
			return menuIsActive;
		},
		setActive(value: boolean) {
			menuIsActive = value;
		}
	};
}

export function setMobileMenuState(menuState: ReturnType<typeof createMobileMenuState>) {
	setContext(key, menuState);
}

export function getMobileMenuState(): ReturnType<typeof createMobileMenuState> {
	return getContext(key);
}
