<script lang="ts">
	import { isValidEmail, isValidPhone, scrollElementIntoView } from '$lib/utils';
	import { tick } from 'svelte';

	// Component properties
	export let subType: string;
	export let name: string;
	export let placeholder: string | undefined;
	export let requiredError: string | undefined;
	export let minLength: number | undefined;
	export let minLengthError: string | undefined;
	export let invalidError: string | undefined;
	export let formState: any;
	export let onChange: any;
	export let onFocus: any | undefined;
	export let onBlur: any | undefined;
	export let validator: any;
	export let options: any | undefined;
	export let autocomplete: string | undefined;
	let inputElement;
	let value = '';

	async function onInput(event) {
		if (formState.isHot || formState.errors[inputElement.name] !== null) {
			validate(inputElement);
		}
		await tick();
		if (onChange !== undefined) {
			onChange(event);
		}
	}

	function onFocusInternal(event) {
		if (onFocus !== undefined) {
			onFocus(event);
		}
	}

	function onBlurInternal(event) {
		validate(inputElement);
		if (onBlur !== undefined) {
			onBlur(event);
		}
	}

	export function getValue() {
		return value;
	}

	export function setValue(newValue) {
		value = newValue;
	}

	export function validate() {
		if (requiredError !== undefined && inputElement.value.length === 0) {
			formState.setError(name, requiredError);
			return false;
		}
		if (
			minLength !== undefined &&
			inputElement.value.length > 0 &&
			inputElement.value.length < minLength
		) {
			formState.setError(name, minLengthError);
			return false;
		}
		if (subType === 'email' && !isValidEmail(inputElement.value)) {
			formState.setError(name, invalidError);
			return false;
		}
		if (subType === 'tel' && !isValidPhone(inputElement.value)) {
			formState.setError(name, invalidError);
			return false;
		}
		if (validator !== undefined) {
			const customError = validator();
			formState.setError(name, customError);
			return customError === null;
		}
		formState.setError(name, null);
		return true;
	}

	export function isValid() {
		return formState.errors[name] === null;
	}

	export function scrollIntoView() {
		scrollElementIntoView(inputElement.closest('.form-row'), 12);
	}
</script>

{#if subType === 'text'}
	<input
		type="text"
		{name}
		{placeholder}
		bind:this={inputElement}
		bind:value
		on:input={onInput}
		on:focus={onFocusInternal}
		on:blur={onBlurInternal}
		on:change={onChange}
		{autocomplete}
		novalidate
	/>
{:else if subType === 'email'}
	<input
		type="email"
		{name}
		{placeholder}
		bind:this={inputElement}
		bind:value
		on:input={onInput}
		on:focus={onFocusInternal}
		on:blur={onBlurInternal}
		{autocomplete}
		novalidate
	/>
{:else if subType === 'password'}
	<input
		type="password"
		{name}
		{placeholder}
		bind:this={inputElement}
		bind:value
		on:input={onInput}
		on:focus={onFocusInternal}
		on:blur={onBlurInternal}
		{autocomplete}
		novalidate
	/>
{:else if subType === 'tel'}
	<input
		type="tel"
		{name}
		{placeholder}
		bind:this={inputElement}
		bind:value
		on:input={onInput}
		on:focus={onFocusInternal}
		on:blur={onBlurInternal}
		{autocomplete}
		novalidate
	/>
{/if}
{#if formState.errors[name]}
	<div class="form-error" class:form-error-large={options?.errorSize === 'large'}>
		{formState.errors[name]}
	</div>
{/if}
{#if formState.errors[name] === null && 'serverErrors' in formState && name in formState.serverErrors && formState.serverErrors[name] !== null}
	<div class="form-error" class:form-error-large={options?.errorSize === 'large'}>
		{invalidError}
	</div>
{/if}
