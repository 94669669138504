<script lang="ts">
	import type { Order, OrderItem } from '$models/Order/Order';
	import type { OrderConfigurator as OrderConfiguratorType } from '$models/OrderConfigurator/OrderConfigurator';
	import type { Subscription } from '$models/Subscription/Subscription';
	import { page } from '$app/stores';
	import OrderConfigurator from '$components/OrderConfigurator/OrderConfigurator.svelte';
	import OrderConfiguratorV2 from '$components/OrderConfigurator/OrderConfiguratorV2.svelte';
	import OrderSummary from '$components/OrderSummary/OrderSummary.svelte';
	import { getCartStore, getCheckoutStore, getToastStore, getUserStore } from '$lib/stores';
	import { isOrderConfiguratorV2 } from '$lib/type-helpers';
	import { subscriptionToOrder } from '$lib/utils';
	import { createOrder, createOrderPayment } from '$lib/webparking';
	import { updateSubscription } from '$lib/webparking/subscriptions';
	import InfoIcon from '../Icons/InfoIcon.svelte';

	export let subscription: Subscription;
	export let order: Order = subscriptionToOrder(subscription);
	export let messages: {
		success: string;
		error: string;
	};
	export let translations: {
		infoText: string;
		infoNextButtonLabel: string;
		stepsTitle: string;
		productsText: string;
		applyToSubscriptionText: string;
		urgentOrderPaymentText: string;
		previousStepLabel: string;
		submitLabel: string;
	};
	export let onSuccess: (updatedSubscription: Subscription) => void | undefined;
	export let skipIntro: boolean = false;
	export let configurator: OrderConfiguratorType;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const toasts = getToastStore();
	const userStore = getUserStore();

	let newProducts: OrderItem[] = [];
	let newFrequency: number;
	let applyProductsToSubscription = false;
	let isLoading = false;
	let currentStep = skipIntro ? 1 : 0;

	async function handleProductsSubmit(newOrder: Order) {
		try {
			isLoading = true;

			// Do not store directly on subscription, ask in final step
			newFrequency = newOrder.frequency;
			newProducts = newOrder.products;
			order.frequency = newFrequency;
			order.products = newProducts;

			currentStep = 2;
		} catch (error) {
			toasts.trigger({
				type: 'error',
				message: messages.error
			});
		} finally {
			isLoading = false;
		}
	}

	async function handleSubmit() {
		isLoading = true;

		try {
			// Store new selection on subscription
			if (applyProductsToSubscription) {
				if (newFrequency && newFrequency !== subscription.frequency) {
					subscription.frequency = newFrequency;
				}
				subscription.items = newProducts;
				subscription = await updateSubscription(subscription);
			}

			// Frequency must be 0 for urgent orders
			order.frequency = 0;

			// Create order
			if (!order.id) {
				try {
					order = {
						...(await createOrder(order)),
						couponCode: order.couponCode
					};
				} catch (error) {
					if (error.response.status === 401) {
						const cart = await $cartStore;
						cartStore.anonymizeCart(cart);
						checkoutStore.clearCheckout();
						userStore.setUser(null);
					}
				}
			}

			// Return to same page for now
			const returnUrl = new URL(
				`${$page.url.origin}${$page.data.page.layout.pages.orderStatusPage}/?resourceId=${order.id}&urgent-order=true`
			);

			// Create payment
			const payment = await createOrderPayment({
				orderId: order.id,
				discountCode: order.couponCode,
				emergencyOrderForSubscriptionId: subscription.id,
				returnUrl
			});

			if (onSuccess !== undefined) {
				onSuccess(subscription);
			}

			window.location.href = payment.checkoutUrl;
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
			toasts.trigger({
				type: 'error',
				message: messages.error
			});
		} finally {
			isLoading = false;
		}
	}
</script>

{#if currentStep > 0}
	<h3 class="modal-title">
		{translations.stepsTitle}
		<span>({currentStep}/2)</span>
		<button class="modal-title-info" type="button" on:click={() => (currentStep = 0)}>
			<InfoIcon />
		</button>
	</h3>
{/if}

{#if currentStep === 0}
	<!-- Step 0 (optionally skipped) -->
	<div class="modal-body rich-text">
		{@html translations.infoText}
	</div>

	<footer class="modal-buttons modal-buttons--align-right">
		<button type="button" class="modal-button button" on:click={() => (currentStep = 1)}>
			{translations.infoNextButtonLabel}
		</button>
	</footer>
{:else if currentStep === 1}
	<!-- Step 1 -->
	<div class="modal-body rich-text">
		<p>{@html translations.productsText}</p>

		{#if configurator && isOrderConfiguratorV2(configurator)}
			<OrderConfiguratorV2 {configurator} defaultOrder={order} onSubmit={handleProductsSubmit} />
		{:else}
			<OrderConfigurator
				{configurator}
				translations={$page.data.page.layout.translations}
				defaultOrder={order}
				onSubmit={handleProductsSubmit}
			/>
		{/if}
	</div>
{:else if currentStep === 2}
	<!-- Step 2 -->
	<div class="modal-body rich-text" class:is-loading={isLoading}>
		<OrderSummary bind:order />
		<div class="form-checkbox">
			<label for="apply-to-subscription">
				<input
					type="checkbox"
					bind:checked={applyProductsToSubscription}
					id="apply-to-subscription"
				/>
				<span />
				{translations.applyToSubscriptionText}
			</label>
		</div>
	</div>

	<footer class="modal-buttons modal-buttons--align-left">
		<button type="button" class="modal-button button" on:click={handleSubmit} disabled={isLoading}>
			{translations.submitLabel}
		</button>
	</footer>

	{#if translations.urgentOrderPaymentText && subscription.paymentAccountReference}
		<div class="modal-description">
			{@html translations.urgentOrderPaymentText.replaceAll(
				'%payment_info%',
				subscription.paymentAccountReference
			)}
		</div>
	{/if}
{/if}
