import { webparking } from '$lib/webparking';

export async function createSession(session) {
	return webparking.post('login', {
		json: session
	});
}

export async function deleteCurrentSession() {
	return webparking.post('logout');
}
