<script lang="ts">
	import type { Navigation } from '$models/Navigation/Navigation';
	import { getMobileMenuState } from '$lib/state/menu.svelte';

	export let navigation: Navigation;

	const mobileMenuState = getMobileMenuState();
</script>

<ul>
	{#each navigation.items as item}
		{@const hasChildren = item.children && item.children.length}
		{#if !item.parent}
			<li class:has-children={hasChildren}>
				<a
					href={item.url}
					class={item.classes.join(' ')}
					target={item.target ?? '_self'}
					on:click={() => mobileMenuState.setActive(false)}
				>
					{item.title}
				</a>

				{#if hasChildren}
					<ul>
						{#each item.children as child}
							<li>
								<a
									href={child.url}
									class={child.classes.join(' ')}
									target={child.target ?? '_self'}
									on:click={() => mobileMenuState.setActive(false)}
								>
									{child.title}
								</a>
							</li>
						{/each}
					</ul>
				{/if}
			</li>
		{/if}
	{/each}
</ul>
