<script lang="ts">
	import type { GridBlock } from '$models/Block/GridBlock';
	import { sanitizeHtml } from '$lib/utils';

	// Component properties
	export let block: GridBlock;
</script>

<div class="grid">
	{#if block.introText}
		<div class="grid-intro">
			{@html sanitizeHtml(block.introText)}
		</div>
	{/if}
	<div class="grid-container grid-container--count-{block.columns}">
		{#each block.items as item}
			<div class="grid-item">
				{#if item.image}
					<figure class="grid-item-image">
						<img src={item.image.url} alt={item.image.alt} />
					</figure>
				{/if}
				<div class="grid-item-content">
					{#if item.title}
						<h3 class="grid-item-title">{@html item.title}</h3>
					{/if}
					{#if item.text}
						<div class="grid-item-text rich-text">
							{@html sanitizeHtml(item.text)}
						</div>
					{/if}
				</div>
			</div>
		{/each}
	</div>
</div>

<style lang="postcss">
	.grid {
		& {
			max-width: var(--container-small);
			margin: 0 auto;
		}
		&-intro {
			text-align: center;
			margin-bottom: var(--spacing-10);
		}

		&-container {
			display: grid;
			gap: var(--spacing-5);

			@media (--lg) {
				gap: var(--spacing-8);
			}

			&--count-2 {
				max-width: 800px;
				margin: 0 auto;
				@media (--sm) {
					grid-template-columns: repeat(2, 1fr);
				}
			}

			&--count-3 {
				@media (--sm) {
					grid-template-columns: repeat(3, 1fr);
				}
			}

			&--count-4 {
				@media (--sm) {
					grid-template-columns: repeat(2, 1fr);
				}

				@media (--lg) {
					grid-template-columns: repeat(4, 1fr);
				}
			}
		}

		&-item {
			display: flex;
			flex-direction: column;
			gap: var(--spacing-2);

			&-image {
				margin: 0 auto;

				&:not(:last-child) {
					margin-bottom: var(--spacing-4);
				}

				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}

			&-content {
				flex: 1;
				text-align: center;
			}

			&-title {
				font-size: var(--font-size-3);

				&:not(:last-child) {
					margin-bottom: var(--spacing-3);
				}
			}

			&-text {
				margin-bottom: var(--spacing-4);
			}
		}
	}
</style>
