<script lang="ts">
	import type { PriceScale } from '$models/Product/ProductCategory';
	import { page } from '$app/state';
	import { formatNumericString, validateQuantity } from '$lib/utils';

	interface Props {
		quantity: number;
		priceScales?: PriceScale[];
		unitNameSingle?: string;
		unitNamePlural?: string;
	}

	let {
		quantity,
		priceScales,
		unitNameSingle = 'stuk',
		unitNamePlural = 'stuks'
	}: Props = $props();

	const showPriceScaleMessage = priceScales && priceScales.length > 0;
	const translations = page.data.page.layout.translations.priceScale;
	const discountMessage = translations.discount;
	const matchMessage = translations.match.replaceAll(
		'%discount%',
		`<strong>${discountMessage}</strong>`
	);
	let ctaMessage = translations.cta.replaceAll('%discount%', `<strong>${discountMessage}</strong>`);
	let useUnitNameSingle = false;

	let derivedMessage = $derived.by(() => {
		// If no price scales available, don't show any message
		if (!priceScales || priceScales.length === 0) {
			return '';
		}

		// Sort price scales by product amount for easier comparison
		const sortedScales = [...priceScales].sort((a, b) => a.productAmount - b.productAmount);
		const minScale = sortedScales[0];
		const maxScale = sortedScales[sortedScales.length - 1];

		// Find the current and next applicable price scales
		const currentScale = sortedScales.find((scale) => quantity >= scale.productAmount);
		const nextScale = sortedScales.find((scale) => quantity < scale.productAmount);

		// If quantity is 0, show next available scale and discount message
		if (quantity === 0) {
			return matchMessage.replaceAll('%discount_amount%', minScale.discountAmount);
		}

		// If quantity is above max scale, show default message with max discount
		if (quantity >= maxScale.productAmount) {
			return matchMessage.replaceAll('%discount_amount%', maxScale.discountAmount);
		}

		// If quantity is between min and max scale
		if (currentScale && nextScale) {
			const remainingProductAmount = nextScale.productAmount - quantity;
			useUnitNameSingle = remainingProductAmount === 1;
			return `${matchMessage.replaceAll('%discount_amount%', currentScale.discountAmount)} <br /> ${ctaMessage.replaceAll('%product_amount%', remainingProductAmount).replaceAll('%discount_amount%', nextScale.discountAmount)}`;
		}

		// If quantity is below min scale
		if (quantity <= minScale.productAmount) {
			const remainingProductAmount = minScale.productAmount - quantity;
			useUnitNameSingle = remainingProductAmount === 1;
			return ctaMessage
				.replaceAll('%product_amount%', remainingProductAmount)
				.replaceAll('%discount_amount%', minScale.discountAmount);
		}

		return '';
	});

	let message = $derived(
		derivedMessage.replaceAll('%products%', useUnitNameSingle ? unitNameSingle : unitNamePlural)
	);
</script>

{#if showPriceScaleMessage}
	<div class="step-totals-success">
		{@html message}
	</div>
{/if}
