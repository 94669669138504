<script lang="ts">
	import type { Page } from '$models/Page/Page';

	// Component properties
	export let page: Page;
</script>

{#if page.layout.showFooterMobileCtaButton && page.layout.footerMobileCtaButton}
	<div class="mobile-cta">
		<a
			href={page.layout.footerMobileCtaButton.url}
			class="button button--full button--large button--no-arrow button--rounded button--{page.layout
				.footerMobileCtaButtonColor}"
		>
			{page.layout.footerMobileCtaButton.title}
		</a>
	</div>
{/if}

<style lang="postcss">
	.mobile-cta {
		& {
			position: fixed;
			z-index: 999;
			bottom: var(--spacing-4);
			left: var(--spacing-4);
			right: var(--spacing-4);
			display: flex;
			justify-content: center;
		}

		@media (--sm) {
			display: none;
		}
	}
</style>
