<script lang="ts">
	import type { Image } from '$models/Image/Image';
	import type { Layout } from '$models/Layout/Layout';

	export let layout: Layout;

	// Component properties
	const paymentLogos: Image[] = layout.paymentLogos;
	const trustLogos: Image[] = layout.trustLogos;
</script>

{#if (paymentLogos && paymentLogos.length > 0) || (trustLogos && trustLogos.length > 0)}
	<div class="logo-bar">
		<div class="logo-bar-container container">
			{#if paymentLogos}
				<div class="logo-bar-column">
					<div class="logo-bar-icons">
						{#each paymentLogos as logo}
							<div class="logo-bar-icon">
								<img src={logo.url} alt={logo.alt} width={logo.width} height={logo.height} />
							</div>
						{/each}
					</div>
				</div>
			{/if}
			{#if trustLogos}
				<div class="logo-bar-column">
					<div class="logo-bar-icons">
						{#each trustLogos as logo}
							<div class="logo-bar-icon">
								<img src={logo.url} alt={logo.alt} width={logo.width} height={logo.height} />
							</div>
						{/each}
					</div>
				</div>
			{/if}
		</div>
	</div>
{/if}

<style lang="postcss">
	.logo-bar {
		& {
			position: relative;
			z-index: 1;
			padding-top: var(--spacing-6);
			padding-bottom: 4px;

			@media (--sm) {
				padding-bottom: 0;
			}

			@media (--lg) {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}

		&-container {
			@media (--lg) {
				display: flex;
			}
		}

		&-column {
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			align-items: center;

			&:first-child {
				@media (--lg) {
					justify-content: flex-end;
					padding-right: 20px;
				}

				@media (--lg) {
					border-right: 2px solid var(--border-color);
				}
			}

			&:last-child {
				@media (--lg) {
					justify-content: flex-start;
					padding-left: 20px;
				}
			}
		}

		&-icons {
			display: flex;
			align-items: center;
		}

		&-icon {
			margin: 0 12px 12px 0;

			@media (--sm) {
				margin: 0 16px 16px 0;
			}

			@media (--lg) {
				margin: 10px;
			}

			img {
				display: block;
				width: auto;
				height: 14px;

				@media (--sm) {
					height: 16px;
				}
			}
		}
	}
</style>
