import type { Order } from '$models/Order/Order';
import { page as pageStore } from '$app/stores';
import { getOrderPricesProductsPrice } from '$lib/webparking';
import { get } from 'svelte/store';

function pushData(data) {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(data);

	// console.log('pushData', data);
}

function roundDecimals(number) {
	return Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100;
}

async function formatItems(order: Order, isUrgent = false) {
	const page = await get(pageStore);
	const products = page.data.products;
	const items = order.products || [];
	const extraItems = order.nonSubscribableProducts || [];
	const itemsQuantity = items.reduce((acc, item) => {
		return acc + item.quantity;
	}, 0);
	const paymentStatus = order.paymentStatus ?? 'not_started';
	let itemsPrice = 0;
	let itemPrice = 0;

	if (items.length && paymentStatus !== 'paid' && itemsQuantity > 0) {
		itemsPrice = await getOrderPricesProductsPrice(order, order.frequency);
	} else if (paymentStatus === 'paid') {
		/* If an order is paid, we have all the data we need on the order object.
		 * Discounts with a maximum use of 1 can not be calculated anymore anyway.
		 */
		itemsPrice = order.total;

		// Substract potential extra items price. We want to calculate regular item price.
		const extraItemsTotal = extraItems.reduce((acc, item) => acc + parseFloat(item.price), 0);

		if (extraItemsTotal > 0) {
			itemsPrice = itemsPrice - extraItemsTotal;
		}
	}

	if (itemsPrice && itemsQuantity) {
		itemPrice = roundDecimals(itemsPrice / itemsQuantity);
	}

	return [
		...items.map((orderItem) => {
			const item = products.regular.find((p) => p.id === orderItem.id);
			if (!item) {
				return;
			}
			return {
				item_id: item.sku,
				item_name: item.name,
				price: itemPrice.toFixed(2),
				quantity: orderItem.quantity,
				item_category: item?.category?.name || 'Onbekend',
				item_category2: item.category.id
			};
		}),
		...extraItems.map((orderItem) => {
			const item = products.nonSubscribable.find((p) => p.id === orderItem.id);
			if (!item) {
				return;
			}
			return {
				item_id: item.name, // Non-subs don't have skus,
				item_name: item.name,
				price: parseFloat(item.price),
				quantity: orderItem.quantity,
				item_category: 'Los product'
			};
		})
	];
}

export function getCustomerType() {
	let customerType = 'existing';
	try {
		let registeredAt = localStorage.getItem('registered_at');

		if (registeredAt) {
			registeredAt = new Date(registeredAt);
			const timePassedMs = new Date().getTime() - registeredAt.getTime();
			if (timePassedMs < 24 * 60 * 60 * 1000) {
				customerType = 'new';
			} else {
				localStorage.removeItem('registered_at');
			}
		}
	} catch (error) {
		// Fail silently
	}

	return customerType;
}

export function trackEvent(eventName) {
	pushData({
		event: eventName
	});

	// console.log('trackEvent', eventName)
}

export function trackVirtualPageview(url, title) {
	const data = {
		event: 'virtualPageview',
		pageUrl: url,
		pageTitle: title
	};

	// console.log('trackVirtualPageview', data);

	pushData(data);
}

export async function trackViewItem(order) {
	const data = {
		event: 'view_item',
		ecommerce: {
			items: await formatItems(order, false)
		}
	};

	pushData(data);
}

export async function trackAddToCart(cart) {
	const data = {
		event: 'add_to_cart',
		ecommerce: {
			items: await formatItems(cart, false)
		}
	};

	pushData(data);
}

export async function trackCheckoutStepOne(cart) {
	try {
		const data = {
			event: 'begin_checkout',
			ecommerce: {
				frequency: cart.frequency,
				items: await formatItems(cart, false)
			}
		};
		trackVirtualPageview(
			window.location.origin + '/afrekenen/e-mailadres',
			'Afrekenen - E-mailadres'
		);
		pushData(data);

		// console.log('data1', data);
	} catch (error) {
		// console.log('step1 error', error);
	}
}

export async function trackCheckoutStepTwo(cart) {
	try {
		const data = {
			event: 'add_shipping_info',
			ecommerce: {
				frequency: cart.frequency,
				customer_type: getCustomerType(),
				items: await formatItems(cart, false)
			}
		};

		trackVirtualPageview(window.location.origin + '/afrekenen/gegevens', 'Afrekenen - Gegevens');
		pushData(data);

		// console.log('data2', data);
	} catch (error) {
		// console.log('step2 error', error);
	}
}

export async function trackCheckoutStepThree(cart) {
	try {
		const data = {
			event: 'add_payment_info',
			ecommerce: {
				frequency: cart.frequency,
				customer_type: getCustomerType(),
				items: await formatItems(cart, false)
			}
		};

		trackVirtualPageview(window.location.origin + '/afrekenen/afronden', 'Afrekenen - Afronden');
		pushData(data);

		// console.log('data3', data);
	} catch (error) {
		// console.log('step3 error', error);
	}
}

export async function trackCheckoutStepFour(order: Order, isUrgentOrder, customer) {
	if (order.paymentStatus !== 'paid' && !isUrgentOrder) {
		return;
	}

	const eclv = order.frequency ? (order.total / order.frequency) * 26 : order.total;
	const orderType = order.frequency > 0 ? 'Parent' : 'One-time';

	const data = {
		event: 'purchase',
		email: customer.email,
		ecommerce: {
			frequency: order.frequency,
			transaction_id: order.id,
			value: parseFloat(order.total).toFixed(2),
			currency: 'EUR',
			eclv: (Math.round((parseFloat(eclv) + Number.EPSILON) * 100) / 100).toFixed(2),
			customer_type: getCustomerType(),
			order_type: isUrgentOrder ? 'Urgent' : orderType,
			coupon: JSON.parse(localStorage.getItem('coupon')) || null,
			items: await formatItems(order, isUrgentOrder)
		}
	};

	trackVirtualPageview(window.location.origin + '/afrekenen/bedankt', 'Afrekenen - Bedankt');
	pushData(data);

	// console.log('data4', data);
}
