<script lang="ts">
	import type { Order } from '$models/Order/Order';
	import type { OrderPrices } from '$models/Order/OrderPrices';
	import type { CartPage } from '$models/Page/CartPage';
	import type { ProductCategory } from '$models/Product/ProductCategory';
	import { goto } from '$app/navigation';
	import { page as pageStore } from '$app/stores';
	import CartButtons from '$components/Checkout/CartButtons.svelte';
	import CheckoutCouponCode from '$components/Checkout/CheckoutCouponCode.svelte';
	import CheckoutFreeNonSubscribableProducts from '$components/Checkout/CheckoutFreeNonSubscribableProducts.svelte';
	import CheckoutNonSubscribableProducts from '$components/Checkout/CheckoutNonSubscribableProducts.svelte';
	import OrderItems from '$components/Order/OrderItems.svelte';
	import OrderConfigurator from '$components/OrderConfigurator/OrderConfigurator.svelte';
	import OrderConfiguratorV2 from '$components/OrderConfigurator/OrderConfiguratorV2.svelte';
	import InfoBlock from '$components/UI/InfoBlock.svelte';
	import { intersectionObserver } from '$lib/actions/intersection-observer.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { getCartStore } from '$lib/stores';
	import { isOrderConfiguratorV2 } from '$lib/type-helpers';
	import { formatCurrency, formatQuantityAndFrequencyString, sanitizeHtml } from '$lib/utils';
	import { getOrderPrices } from '$lib/webparking';
	import { onMount } from 'svelte';
	import { writable } from 'svelte/store';

	// Component properties
	export let page: CartPage;

	const cartStore = getCartStore();
	const site = getSiteContext();

	let currentView = writable('overview');
	let ctaInViewport: boolean = false;

	let checkoutCouponCodeElement: {
		isEditing: () => boolean;
		scrollIntoView: () => void;
		submit: (event?: Event, updateCart?: boolean) => Promise<boolean>;
	} | null = null;

	let productCategory: ProductCategory | null;

	onMount(() => {
		maybeLoadCartFromURL();
	});

	async function maybeLoadCartFromURL() {
		const searchParams = new URLSearchParams(window.location.search);

		if (!searchParams.has('products')) {
			return;
		}

		const cart = await $cartStore;
		const frequency = searchParams.has('frequency') ? parseInt(searchParams.get('frequency')) : 0;
		let products = JSON.parse(searchParams.get('products'));
		let nonSubscribableProducts = [];

		// Make sure the products exist before overruling the cart.
		products = products.filter((p) => !!findProduct(p.id));

		// Add non subscribable products if present
		if (searchParams.has('non_subscribable_products')) {
			nonSubscribableProducts = JSON.parse(searchParams.get('non_subscribable_products'));
			nonSubscribableProducts = nonSubscribableProducts.filter(
				(p) => !!findNonSubscribableProduct(p.id)
			);
		}

		if (products.length) {
			cartStore.setCart({
				id: cart?.id ?? null,
				deliveryDate: null,
				deliveryCompanyName: null,
				billingAddressId: null,
				shippingAddressId: null,
				deliveryNote: null,
				poNumber: null,
				couponCode: null,
				products,
				nonSubscribableProducts,
				frequency
			});
		}
	}

	function showCartOverview(order: Order, cart?) {
		let newOrder = order;

		// Cart is passed in for V2 configurators
		if (cart) {
			// Make sure we keep values from cart, they could also come from external order
			// Only overwrite values returned from OrderConfiguratorV2, which should be
			// of the type OrderConfigurationOrder.
			// @TODO: Refactor to OrderConfigurationOrder after v2 is released
			newOrder = {
				...cart,
				...order
			};
		}

		cartStore.setCart(newOrder);
		$currentView = 'overview';
	}

	function showCartConfigurator() {
		$currentView = 'edit';
	}

	function onCheckoutButtonClick(event: Event) {
		if (checkoutCouponCodeElement?.isEditing()) {
			event.preventDefault();
			checkoutCouponCodeElement.submit().then((isCouponCodeValid) => {
				if (isCouponCodeValid) {
					goto(page.layout.pages.checkoutPage);
				} else {
					checkoutCouponCodeElement?.scrollIntoView();
				}
			});
		}
	}

	function getFreeNonSubscribableProductsDiscountAmount(orderPrices: OrderPrices) {
		orderPricesPromise;
		$pageStore.data.products.nonSubscribable.find(
			(nonSubscribableProduct) => nonSubscribableProduct.id === id
		);
		return 0;
	}

	function getOrderConfigurator(cart: Order) {
		const product = findProduct(cart.products[0].id);

		// Attempt to get V1 configurators
		// @TODO Remove after V2 is released
		return page.layout.configurators.cartEdit.find((c) => {
			if (isOrderConfiguratorV2(c)) {
				return c.configuration.categories.find(
					({ productCategory }) => productCategory.id === product.category.id
				);
			}

			// For V1 configurators
			return c.productCategory?.id === product.category.id;
		});
	}

	function findProduct(id) {
		return $pageStore.data.products.regular.find((p) => p.id === id);
	}

	function findNonSubscribableProduct(id) {
		return $pageStore.data.products.nonSubscribable.find((p) => p.id === id);
	}

	let orderPricesPromise = new Promise(() => {});
	let recurringProductAmount = 0;
	$: {
		$cartStore.then((cart: Order) => {
			if (cart !== null) {
				orderPricesPromise = getOrderPrices(cart);
			}

			if (cart && cart.products) {
				const _product = findProduct(cart.products[0].id);
				const quantityMultiplier = _product?.quantityMultiplier || 1;
				productCategory = _product.category;
				recurringProductAmount = cart.products.reduce(
					(accumulator: number, currentValue: { quantity: number }) =>
						accumulator + currentValue.quantity * quantityMultiplier,
					0
				);
			}
		});
	}
</script>

{#await $cartStore then cart}
	<div class="block block--flex variant-tint-1">
		<div class="block__container container">
			<div class="box box--width-medium box--padding-medium">
				{#if cart === null}
					<InfoBlock
						title={page.cartEmptyTitle}
						text={page.cartEmptyText}
						buttonUrl={page.cartEmptyButtonUrl}
						buttonLabel={page.cartEmptyButtonLabel}
					/>
				{:else if $currentView == 'overview'}
					<div class="box-header">
						<h2 class="box-title">
							{page.cartTitle}
						</h2>
						<CartButtons
							{page}
							{showCartConfigurator}
							showEditButton={recurringProductAmount > 0}
						/>
					</div>
					<div class="summary">
						{#if recurringProductAmount > 0}
							<div class="summary-row">
								<div class="summary-row-label">
									{page.forms.cart.amountLabel}
								</div>
								<div class="summary-row-value">
									{formatQuantityAndFrequencyString(
										page.forms.cart.amountValueLabels,
										recurringProductAmount,
										cart.frequency
									)
										.replaceAll(
											'%product%',
											productCategory?.translations?.unitNameSingle ?? '%product%'
										)
										.replaceAll(
											'%products%',
											productCategory?.translations?.unitNamePlural ?? '%products%'
										)}
								</div>
							</div>
							<div class="summary-row">
								<div class="summary-row-label">
									{page.forms.cart.productsLabel}
								</div>
								<div class="summary-row-value">
									<div class="summary-row-container">
										<OrderItems items={cart.products} />
										{#if cart.nonSubscribableProducts.length > 0}
											{#await orderPricesPromise then orderPrices}
												{#if orderPrices.productsRecurringPrice !== null}
													<div class="summary-row-price summary-row-price--align-top">
														{formatCurrency(orderPrices.productsRecurringPrice, site.locale.code)}
													</div>
												{/if}
											{/await}
										{/if}
									</div>
								</div>
							</div>
						{/if}
						<CheckoutNonSubscribableProducts {orderPricesPromise} />
						<CheckoutCouponCode bind:this={checkoutCouponCodeElement} {page} {orderPricesPromise} />
						<CheckoutFreeNonSubscribableProducts {page} {orderPricesPromise} />
						{#await orderPricesPromise then orderPrices}
							{#if orderPrices.discountAmount !== null && orderPrices.discountAmount > 0}
								<div class="summary-row">
									<div class="summary-row-label">
										{page.forms.cart.priceLabel}
									</div>
									<div class="summary-row-value">
										{#if orderPrices.productsRecurringPrice !== null}
											{formatCurrency(
												orderPrices.productsRecurringPrice +
													orderPrices.totalNonSubscribableProductsPrice,
												site.locale.code
											)}
										{/if}
									</div>
								</div>
								<div class="summary-row">
									<div class="summary-row-label">
										{page.forms.cart.discountAmountLabel}
									</div>
									<div class="summary-row-value">
										{#if orderPrices.discountAmount !== null}
											- {formatCurrency(orderPrices.discountAmount, site.locale.code)}
										{/if}
									</div>
								</div>
							{/if}
						{/await}
						<div class="summary-row">
							<div class="summary-row-label">{page.forms.cart.totalLabel}</div>
							<div class="summary-row-value">
								{#await orderPricesPromise then orderPrices}
									{#if orderPrices.productsRecurringPrice !== null && orderPrices.discountAmount !== null}
										{formatCurrency(
											orderPrices.productsRecurringPrice +
												orderPrices.totalNonSubscribableProductsPrice -
												orderPrices.discountAmount,
											site.locale.code
										)}
									{/if}
								{/await}
							</div>
						</div>
					</div>

					<div
						use:intersectionObserver={{
							callback: (isIntersecting: boolean) => (ctaInViewport = isIntersecting)
						}}
					>
						<div class="box-button">
							<a
								href={page.layout.pages.checkoutPage}
								class="button button--secondary"
								on:click={onCheckoutButtonClick}
								class:button--fixed={!ctaInViewport}
							>
								{page.cartButtonLabel}
							</a>
						</div>
					</div>

					{#if page.cartButtonUspsText}
						<div class="box-usps">
							{@html sanitizeHtml(page.cartButtonUspsText)}
						</div>
					{/if}
				{:else}
					{@const cartConfigurator = getOrderConfigurator(cart)}
					{#if cartConfigurator && isOrderConfiguratorV2(cartConfigurator)}
						<OrderConfiguratorV2
							configurator={cartConfigurator}
							onSubmit={(order) => showCartOverview(order, cart)}
							defaultOrder={cart}
						/>
					{:else if cartConfigurator}
						<OrderConfigurator
							translations={page.layout.translations}
							configurator={cartConfigurator}
							defaultOrder={cart}
							onSubmit={showCartOverview}
						/>
					{/if}
				{/if}
			</div>
		</div>
	</div>
{/await}

<style lang="postcss" global>
	.summary {
		&:not(:last-child) {
			margin-bottom: var(--spacing-5);
		}

		&-row {
			position: relative;
			padding: var(--spacing-3) 0;
			font-size: var(--summary-font-size-sm);
			line-height: var(--font-lineheight-2);

			@media (--sm) {
				display: flex;
				font-size: var(--summary-font-size);
			}

			&:not(:last-child) {
				border-bottom: 1px solid var(--border-color);
			}

			&-label,
			&-value {
				min-height: 20px;
			}

			&-label {
				margin-bottom: var(--spacing-1);

				@media (--sm) {
					width: 40%;
					margin-bottom: 0;
				}
			}

			&-value {
				flex: 1;
				font-weight: var(--summary-label-font-weight);

				ul {
					font-weight: var(--font-weight-4);
					margin-left: 0;

					li {
						list-style: none;
					}
				}
			}

			&-edit {
				color: var(--box-edit-text-color);
				text-decoration: underline;
				font-weight: var(--font-weight-5);
				align-self: flex-start;
				transition: color var(--transition);
				position: absolute;
				top: var(--spacing-3);
				right: 0;

				@media (--sm) {
					position: relative;
					top: auto;
					right: auto;
				}

				&:hover {
					color: color-mix(in srgb, var(--color-primary) 80%, black);
				}
			}

			&-container {
				display: flex;
				align-items: center;
			}

			&-quantity {
				width: var(--spacing-18);
			}

			&-remove {
				color: var(--color-primary);
				margin-left: var(--spacing-3);

				svg {
					display: block;
				}
			}

			&-price {
				margin-left: auto;

				&--align-top {
					align-self: flex-start;
				}
			}

			&-cancel {
				display: block;
				color: var(--color-grey-3);
				text-decoration: underline;
				font-weight: var(--font-weight-5);
				transition: color var(--transition);

				&:hover {
					color: var(--color-grey-1);
				}
			}
		}
	}
</style>
