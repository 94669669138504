<script lang="ts">
	import type { PanoramaBlock } from '$models/Block/PanoramaBlock';
	import Button from '$components/UI/Buttons/Button.svelte';
	import { sanitizeHtml } from '$lib/utils';

	// Component properties
	export let block: PanoramaBlock;
</script>

<div class="panorama">
	<div class="panorama-container container">
		<div class="panorama-content panorama-content--text-{block.textColor}">
			{#if block.tagline}
				<h1 class="panorama-title">
					{@html sanitizeHtml(block.tagline)}
				</h1>
			{/if}

			{#if block.text}
				<div class="panorama-text">
					{@html sanitizeHtml(block.text)}
				</div>
			{/if}
			{#if block.button}
				<div class="panorama-button">
					<Button type="anchor" href={block.button.url}>
						{block.button.title}
					</Button>
				</div>
			{/if}
		</div>
	</div>
	<figure class="panorama-image">
		{#if block.imageOverlay && block.imageOverlay > 0}
			<div class="panorama-overlay" style="opacity: {block.imageOverlay / 100}" />
		{/if}
		<picture>
			<source media="(min-width: 960px)" srcset={block.imageDesktop.url} />
			<source media="(min-width: 768px)" srcset={block.imageTablet.url} />
			<img src={block.imageMobile.url} alt={block.imageDesktop.alt} />
		</picture>
	</figure>
</div>

<style lang="postcss">
	.panorama {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 480px;
		overflow: hidden;

		@media (--sm) {
			height: 580px;
		}

		@media (--xl) {
			height: 640px;
		}

		&-container {
			position: relative;
			z-index: 2;
			width: 100%;
			display: flex;
			justify-content: var(--panorama-container-align);
			max-width: var(--container);
			padding-left: var(--spacing-5);
			padding-right: var(--spacing-5);
		}

		&-content {
			text-align: var(--panorama-text-align);
			color: var(--color-white);
			max-width: var(--panorama-container-width-sm);

			@media (--sm) {
				max-width: var(--panorama-container-width);
			}

			&--text-dark {
				color: var(--color-black);
			}
		}

		&-title {
			color: inherit;
			font-size: var(--panorama-title-size-sm);
			font-family: var(--panorama-title-family);
			text-transform: var(--panorama-title-transform);
			font-weight: var(--panorama-title-weight);
			line-height: 1.2;
			margin-bottom: var(--spacing-6);

			@media (--sm) {
				font-size: var(--panorama-title-size-md);
			}

			@media (--md) {
				font-size: var(--panorama-title-size-lg);
			}
		}

		&-text {
			margin-bottom: var(--spacing-6);

			@media (--sm) {
				font-size: var(--panorama-text-size);
			}
		}

		&-image {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			background-color: var(--color-black);
		}
	}
</style>
