<script lang="ts">
	import type { Subscription } from '$models/Subscription/Subscription';
	import { getSiteContext } from '$lib/context/site';
	import {
		getCartStore,
		getCheckoutStore,
		getModalStore,
		getToastStore,
		getUserStore
	} from '$lib/stores';
	import { formatDate } from '$lib/utils';
	import { listPotentialShipmentDates, updateSubscription } from '$lib/webparking/subscriptions';
	import { onMount } from 'svelte';
	import Flatpickr from '../Flatpickr.svelte';
	import Loader from '../Loader.svelte';

	export let subscription: Subscription;
	export let messages: {
		success: string;
		error: string;
		shipmentDatesError: string;
	};
	export let translations: {
		submitLabel: string;
	};
	export let onSuccess: (updatedSubscription: Subscription) => void | undefined;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const modals = getModalStore();
	const toasts = getToastStore();
	const userStore = getUserStore();
	const site = getSiteContext();

	let isLoading = false;
	let resumeDate: Date;

	async function handlePause() {
		try {
			isLoading = true;

			subscription.nextDeliveryDate = resumeDate;

			const updatedSubscription = await updateSubscription(subscription);

			toasts.trigger({
				type: 'success',
				message: messages.success.replaceAll(
					'%date%',
					formatDate(updatedSubscription.nextDeliveryDate, site.locale.code)
				)
			});

			if (typeof onSuccess === 'function') {
				onSuccess(updatedSubscription);
			}

			modals.close();
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
			toasts.trigger({
				type: 'error',
				message: messages.error
			});
		} finally {
			isLoading = false;
		}
	}

	let shipmentDatesPromise = new Promise(() => {});
	onMount(async () => {
		await $userStore;
		try {
			shipmentDatesPromise = listPotentialShipmentDates(subscription);
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});
</script>

<div class="modal-datepicker" class:is-loading={isLoading}>
	{#await shipmentDatesPromise}
		<Loader />
	{:then shipmentDates}
		<Flatpickr options={{ enable: shipmentDates }} bind:value={resumeDate} />
	{:catch error}
		{messages.shipmentDatesError}
	{/await}
</div>

<footer class="modal-buttons modal-buttons--align-left">
	<button type="button" class="modal-button button" disabled={!resumeDate} on:click={handlePause}>
		{translations.submitLabel}
	</button>
</footer>
