<script lang="ts">
	import CardsProductSelectorStep from '$components/OrderConfigurator/Step/CardsProductSelectorStep.svelte';
	import ListProductSelectorStep from '$components/OrderConfigurator/Step/ListProductSelectorStep.svelte';

	// Component properties
	export let translations: any;
	export let configurator: OrderConfigurator;
	export let configuratorStore: any;
	export let step: Step;
	export let index: number;
	export let entityEventHandlers: any;
</script>

{#if step.layout == 'cards'}
	<CardsProductSelectorStep
		{translations}
		{configurator}
		{configuratorStore}
		{step}
		{index}
		{entityEventHandlers}
	/>
{:else if step.layout == 'list'}
	<ListProductSelectorStep
		{translations}
		{configurator}
		{configuratorStore}
		{step}
		{index}
		{entityEventHandlers}
	/>
{/if}
