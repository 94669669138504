<script lang="ts">
	import { getSiteContext } from '$lib/context/site';
	import { getCartStore } from '$lib/stores';
	import { formatDate, sanitizeHtml } from '$lib/utils';
	import { onMount } from 'svelte';

	// Component properties
	export let page: any;

	const cartStore = getCartStore();
	const site = getSiteContext();

	function onDeliveryNoteInput(event) {
		cartStore?.then((cart) => {
			cartStore.setCart({
				...cart,
				deliveryNote: event.target.value
			});
		});
	}

	let deliveryNoteElement = null;
	onMount(async () => {
		const cart = await $cartStore;
		if (cart?.deliveryNote !== undefined && cart.deliveryNote !== null) {
			deliveryNoteElement.value = cart.deliveryNote;
		}
	});

	let deliveryDate = null;
	let deliveryCompanyName = null;
	$: {
		$cartStore?.then((cart) => {
			if (cart.deliveryDate !== null) {
				deliveryDate = formatDate(new Date(cart.deliveryDate), site.locale.code);
			}
			deliveryCompanyName = cart.deliveryCompanyName;
		});
	}
</script>

<div class="box">
	<h2 class="box-title">
		{page.delivery.title}
	</h2>
	<div class="box-text">
		{@html sanitizeHtml(
			page.delivery.text
				.replaceAll('%delivery_date%', deliveryDate)
				.replaceAll('%delivery_company%', deliveryCompanyName)
		)}
	</div>
	<div class="form">
		<div class="form-row">
			<label for="delivery" class="form-label">
				{page.delivery.noteLabel}
			</label>
			<textarea
				id="delivery"
				placeholder={page.delivery.notePlaceholder}
				on:input={onDeliveryNoteInput}
				bind:this={deliveryNoteElement}
			/>
		</div>
	</div>
</div>
