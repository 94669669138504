<script lang="ts">
	import AddressFormElement from '$components/Form/FormElement/AddressFormElement.svelte';
	import InputFormElement from '$components/Form/FormElement/InputFormElement.svelte';
	import OptionGroupFormElement from '$components/Form/FormElement/OptionGroupFormElement.svelte';
	import { onMount } from 'svelte';

	// Component properties
	export let type: string;
	export let subType: string | undefined;
	export let size: string | undefined;
	export let name: string;
	export let label: string | undefined;
	export let subLabel: string | undefined;
	export let placeholder: string | undefined;
	export let autocomplete: string | undefined;
	export let requiredError: string | undefined;
	export let minLength: number | undefined;
	export let minLengthError: string | undefined;
	export let invalidError: string | undefined;
	export let options: any | undefined;
	export let formState: any;
	export let onChange: any | undefined;
	export let onFocus: any | undefined;
	export let onBlur: any | undefined;
	export let address: any | undefined;
	export let addAddressLabel: string | undefined;
	export let defaultValue: string | undefined;
	export let initialValue: any;
	export let validator: any;

	let subElement;

	export function getValue() {
		return subElement.getValue();
	}

	export function setValue(newValue) {
		return subElement.setValue(newValue);
	}

	export function validate() {
		return subElement.validate();
	}

	export function isValid() {
		return subElement.isValid();
	}

	export function scrollIntoView() {
		return subElement.scrollIntoView();
	}

	let containerType;
	$: {
		if (type === 'address') {
			containerType = 'group';
		} else {
			containerType = 'row';
		}
	}

	onMount(() => {
		if (initialValue) {
			setValue(initialValue);
		}
	});
</script>

<div
	class={'form-' +
		containerType +
		(size !== undefined ? ' form-' + containerType + '--' + size : '')}
>
	{#if label !== undefined}
		<label for={name} class="form-label">
			{label}
			{#if requiredError !== undefined}
				<span>*</span>
			{/if}
		</label>
	{/if}
	{#if type == 'address'}
		<AddressFormElement
			bind:this={subElement}
			{subLabel}
			{name}
			{formState}
			{address}
			{options}
			{onChange}
			{onFocus}
			{onBlur}
			{addAddressLabel}
			{validator}
		/>
	{:else if type == 'input'}
		<InputFormElement
			bind:this={subElement}
			{subType}
			{name}
			{placeholder}
			{requiredError}
			{minLength}
			{minLengthError}
			{invalidError}
			{formState}
			{onChange}
			{onFocus}
			{onBlur}
			{validator}
			{options}
			{autocomplete}
		/>
	{:else if type == 'optionGroup'}
		<OptionGroupFormElement
			bind:this={subElement}
			{subType}
			{name}
			{options}
			{formState}
			{onChange}
			{onFocus}
			{onBlur}
			{defaultValue}
			{validator}
		/>
	{/if}
	<slot />
</div>
