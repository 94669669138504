<script lang="ts">
	import type { Order, OrderItem } from '$models/Order/Order';
	import type { OrderConfigurator as OrderConfiguratorType } from '$models/OrderConfigurator/OrderConfigurator';
	import type { Subscription } from '$models/Subscription/Subscription';
	import { page } from '$app/stores';
	import DeliveryInfo from '$components/Delivery/DeliveryInfo.svelte';
	import OrderConfigurator from '$components/OrderConfigurator/OrderConfigurator.svelte';
	import OrderConfiguratorV2 from '$components/OrderConfigurator/OrderConfiguratorV2.svelte';
	import OrderSummary from '$components/OrderSummary/OrderSummary.svelte';
	import {
		getCartStore,
		getCheckoutStore,
		getModalStore,
		getToastStore,
		getUserStore
	} from '$lib/stores';
	import { isOrderConfiguratorV2 } from '$lib/type-helpers';
	import { subscriptionToOrder } from '$lib/utils';
	import { createOrder, createOrderPayment } from '$lib/webparking';
	import { autoApplyDiscount } from '$lib/webparking/orders/prices';
	import { listPotentialShipmentDates, updateSubscription } from '$lib/webparking/subscriptions';
	import { onMount } from 'svelte';
	import Flatpickr from '../Flatpickr.svelte';
	import Loader from '../Loader.svelte';

	export let subscription: Subscription;
	export let order: Order = subscriptionToOrder(subscription);
	export let messages: {
		success: string;
		error: string;
		shipmentDatesError: string;
	};
	export let translations: {
		stepsTitle: string;
		infoText: string;
		resumeAsapText: string;
		applyToSubscriptionText: string;
		urgentOrderPaymentText: string;
		nextStepLabel: string;
		previousStepLabel: string;
		deliveryDateSubmitLabel: string;
		submitLabel: string;
	};
	export let configurator: OrderConfiguratorType;

	export let onSuccess: (updatedSubscription: Subscription) => void | undefined;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const modals = getModalStore();
	const toasts = getToastStore();
	const userStore = getUserStore();

	let newProducts: OrderItem[] = [];
	let newFrequency: number;
	let currentStep = 1;
	let applyProductsToSubscription = false;
	let resumeAsap = false;
	let isLoading = false;
	let newDate: Date;

	function handleProductsSubmit(newOrder: Order) {
		try {
			isLoading = true;

			// Do not store directly on subscription, ask in final step
			newFrequency = newOrder.frequency;
			newProducts = newOrder.products;
			order.frequency = newFrequency;
			order.products = newProducts;

			currentStep = 3;
		} catch (error) {
			toasts.trigger({
				type: 'error',
				message: messages.error
			});
		} finally {
			isLoading = false;
		}
	}

	async function handleSubmit() {
		isLoading = true;

		try {
			// Update subscription status
			subscription.isCancelled = false;
			subscription.isOnHold = false;

			// Store new selection on subscription when:
			// - User requests it
			// - Activating in the future, because there will be no order
			if (applyProductsToSubscription || !resumeAsap) {
				if (newFrequency && newFrequency !== subscription.frequency) {
					subscription.frequency = newFrequency;
				}
				subscription.items = newProducts;
			}

			if (resumeAsap) {
				// Update the subscription without modifying next delivery date
				subscription = await updateSubscription(subscription);

				// Frequency must be 0 for urgent orders
				order.frequency = 0;

				// Create and submit urgent order
				if (!order.id) {
					order = {
						...(await createOrder(order)),
						couponCode: order.couponCode
					};
				}

				// Return to checkout status page
				const returnUrl = new URL(
					`${$page.url.origin}${$page.data.page.layout.pages.orderStatusPage}/?resourceId=${order.id}&urgent-order=true`
				);

				// Create payment
				const payment = await createOrderPayment({
					orderId: order.id,
					discountCode: order.couponCode,
					emergencyOrderForSubscriptionId: subscription.id,
					returnUrl
				});

				if (onSuccess !== undefined) {
					onSuccess(subscription);
				}

				window.location.href = payment.checkoutUrl;
			} else {
				// If a coupon code is set, auto apply it because there is no order.
				if (order.couponCode !== null) {
					await autoApplyDiscount(order.couponCode);
				}

				// No need for urgent order, just date change is enough
				subscription = await updateSubscription({
					...subscription,
					nextDeliveryDate: newDate
				});

				toasts.trigger({
					type: 'success',
					message: messages.success
				});

				if (
					$page.url.searchParams.has('action') &&
					$page.url.searchParams.get('action') === 'activate'
				) {
					$page.url.searchParams.delete('action');
				}

				if (onSuccess !== undefined) {
					onSuccess(subscription);
				}

				modals.close();
			}
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
			toasts.trigger({
				type: 'error',
				message: messages.error
			});
		} finally {
			isLoading = false;
		}
	}

	let shipmentDatesPromise = new Promise(() => {});
	onMount(async () => {
		await $userStore;
		try {
			shipmentDatesPromise = listPotentialShipmentDates(subscription);
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});
</script>

<h3 class="modal-title">{translations.stepsTitle} <span>({currentStep}/3)</span></h3>

{#if currentStep === 1}
	<div class="modal-body">{translations.infoText}</div>

	<div class="modal-datepicker">
		{#await shipmentDatesPromise}
			<Loader />
		{:then shipmentDates}
			<Flatpickr options={{ enable: shipmentDates }} disabled={resumeAsap} bind:value={newDate} />
		{:catch error}
			{messages.shipmentDatesError}
		{/await}
	</div>

	<div class="form-row">
		<div class="form-checkbox">
			<label for="resumeAsap">
				<input type="checkbox" name="resumeAsap" id="resumeAsap" bind:checked={resumeAsap} />
				<span />
				{translations.resumeAsapText}
			</label>
		</div>
	</div>

	<div class="modal-info rich-text">
		<DeliveryInfo />
	</div>

	<footer class="modal-buttons">
		<button
			type="button"
			class="modal-button button"
			disabled={!resumeAsap && newDate === undefined}
			on:click={() => (currentStep = 2)}
		>
			{translations.deliveryDateSubmitLabel}
		</button>
	</footer>
{:else if currentStep === 2}
	<div class="modal-body">
		{#if configurator && isOrderConfiguratorV2(configurator)}
			<OrderConfiguratorV2 {configurator} defaultOrder={order} onSubmit={handleProductsSubmit} />
		{:else}
			<OrderConfigurator
				{configurator}
				translations={$page.data.page.layout.translations}
				defaultOrder={order}
				onSubmit={handleProductsSubmit}
			/>
		{/if}
	</div>
	<div class="modal-back">
		<button
			type="button"
			class="modal-button button button--back button--outline"
			on:click={() => (currentStep = 1)}
		>
			{translations.previousStepLabel}
		</button>
	</div>
{:else if currentStep === 3}
	<div class="modal-body">
		<OrderSummary bind:order />
		<!-- When not creating urgent orders, there is no order and products will have to be applied to subscription  -->
		{#if resumeAsap}
			<div class="apply-to-subscription">
				<label>
					<input type="checkbox" bind:checked={applyProductsToSubscription} />
					{translations.applyToSubscriptionText}
				</label>
			</div>
		{/if}
	</div>

	<footer class="modal-buttons modal-buttons--align-left">
		<button
			type="button"
			class="modal-button button button--back button--outline"
			on:click={() => (currentStep = 2)}
		>
			{translations.previousStepLabel}
		</button>
		<button type="button" class="modal-button button" on:click={handleSubmit}>
			{translations.submitLabel}
		</button>
	</footer>

	{#if translations.urgentOrderPaymentText && subscription.paymentAccountReference}
		<div class="modal-description">
			{@html translations.urgentOrderPaymentText.replaceAll(
				'%payment_info%',
				subscription.paymentAccountReference
			)}
		</div>
	{/if}
{/if}
