<script lang="ts">
	import { page as pageStore } from '$app/stores';
	import TrashIcon from '$components/UI/Icons/TrashIcon.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { getCartStore } from '$lib/stores';
	import { formatCurrency } from '$lib/utils';

	export let orderPricesPromise: any;

	const cartStore = getCartStore();
	const site = getSiteContext();

	function getNonSubscribableProduct(id) {
		return $pageStore.data.products.nonSubscribable.find(
			(nonSubscribableProduct) => nonSubscribableProduct.id === id
		);
	}

	function updateCartNonSubscribableProductQuantity(id, quantity) {
		$cartStore.then((cart) => {
			cart.nonSubscribableProducts.forEach((nonSubscribableProductItem) => {
				if (nonSubscribableProductItem.id === id) {
					nonSubscribableProductItem.quantity = quantity;
					return false;
				}
			});
			cartStore.setCart(cart);
		});
	}

	function onNonSubscribableInputInput(event) {
		if (/^\d+$/.test(event.target.value) && event.target.value !== '0') {
			const id = event.target.closest('[data-non-subscribable-product-id]').dataset
				.nonSubscribableProductId;
			const quantity = parseInt(event.target.value);
			updateCartNonSubscribableProductQuantity(id, quantity);
		}
	}

	function onNonSubscribableInputChange(event) {
		const id = event.target.closest('[data-non-subscribable-product-id]').dataset
			.nonSubscribableProductId;
		if (/^\d+$/.test(event.target.value) && event.target.value !== '0') {
			const quantity = parseInt(event.target.value);
			updateCartNonSubscribableProductQuantity(id, quantity);
		} else {
			$cartStore.then((cart) => {
				cart.nonSubscribableProducts.forEach((nonSubscribableProductItem) => {
					if (nonSubscribableProductItem.id === id) {
						event.target.value = nonSubscribableProductItem.quantity;
						return false;
					}
				});
			});
		}
	}

	function onRemoveNonSubscribableProductButtonClick(event) {
		const id = event.target.closest('[data-non-subscribable-product-id]').dataset
			.nonSubscribableProductId;
		$cartStore.then((cart) => {
			cart.nonSubscribableProducts = cart.nonSubscribableProducts.filter(
				(nonSubscribableProductItem) => nonSubscribableProductItem.id !== id
			);
			cartStore.setCart(cart);
		});
	}
</script>

{#await $cartStore then cart}
	{#if cart !== null && cart.nonSubscribableProducts?.length > 0}
		{#each cart.nonSubscribableProducts as nonSubscribableProductItem}
			<div class="summary-row" data-non-subscribable-product-id={nonSubscribableProductItem.id}>
				<div class="summary-row-label">
					{@html getNonSubscribableProduct(nonSubscribableProductItem.id).name}
				</div>
				<div class="summary-row-value">
					<div class="summary-row-container">
						<div class="summary-row-quantity">
							<input
								type="number"
								min="1"
								on:input={onNonSubscribableInputInput}
								on:change={onNonSubscribableInputChange}
								value={nonSubscribableProductItem.quantity}
							/>
						</div>
						<div class="summary-row-remove">
							<button type="button" on:click={onRemoveNonSubscribableProductButtonClick}>
								<TrashIcon />
							</button>
						</div>
						{#await orderPricesPromise then orderPrices}
							{#if nonSubscribableProductItem.id in orderPrices.nonSubscribableProductPrices}
								<div class="summary-row-price">
									{formatCurrency(
										orderPrices.nonSubscribableProductPrices[nonSubscribableProductItem.id],
										site.locale.code
									)}
								</div>
							{/if}
						{/await}
					</div>
				</div>
			</div>
		{/each}
	{/if}
{/await}
