<script lang="ts">
	import type { OrderConfiguratorCompact } from '$models/OrderConfigurator/OrderConfiguratorCompact';
	import type { OrderConfigurationOrder } from '$models/OrderConfigurator/OrderConfiguratorV2';
	import type { ProductCategory } from '$models/Product/ProductCategory';
	import { page } from '$app/state';
	import PriceScaleMessage from '$components/PriceScale/PriceScaleMessage.svelte';
	import QuantityValidationMessage from '$components/Quantity/QuantityValidationMessage.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { getConfiguratorState } from '$lib/state/configurator.svelte';
	import { formatCurrency, formatNumericString } from '$lib/utils';
	import { tick } from 'svelte';
	import OrderConfiguratorCategories from './Components/OrderConfiguratorCategories.svelte';
	import OrderConfiguratorStepHeader from './OrderConfiguratorStepHeader.svelte';

	interface Props {
		configurator: OrderConfiguratorCompact;
		onSubmit: (order: OrderConfigurationOrder) => void;
	}

	let { configurator, onSubmit }: Props = $props();

	const configuratorState = getConfiguratorState();
	const site = getSiteContext();
	const translations = page.data.page.layout.translations;

	// Assign some local variables for ergonomics and readability
	let quantityMultiplier = $derived(configuratorState.quantityMultiplier());
	let prices = $derived(configuratorState.prices());
	let selectedCategory = $derived(configuratorState.selectedCategory());
	let selectedQuantity = $derived(configuratorState.selectedQuantity());
	let selectedDisplayQuantity = $derived(selectedQuantity * quantityMultiplier);

	// Local state
	let selectedFrequencyType: 'recurring' | 'onetime' = $state(
		configuratorState.selectedFrequency() > 0 ? 'recurring' : 'onetime'
	);
	let userSelectedFrequency: number = $state(configuratorState.selectedFrequency() || 1);
	let requiresCategorySelection = $derived(configuratorState.categories().length > 1);
	let productSelectionElement: HTMLElement | undefined = $state();
	let currentStep: number = $state(0);
	let savingsPercentage: number = $derived(
		prices
			? calculateDiscountPercentage(
					prices.productsRecurringPrice,
					prices.productsOneTimePrice,
					prices.discountAmount
				)
			: 0
	);

	$effect(() => {
		if (selectedFrequencyType === 'onetime') {
			configuratorState.setFrequency(0);
		} else {
			configuratorState.setFrequency(userSelectedFrequency);
			configuratorState.setRecurringFrequency(userSelectedFrequency);
		}
	});

	// If a default category is selected, open up the product selection right away
	if (configurator.configuration.defaultCategory) {
		configuratorState.setCategory(configurator.configuration.defaultCategory);
		currentStep = 1;
	}

	// Local helper functions
	function calculateDiscountPercentage(
		recurringPrice: number | null,
		onetimePrice: number | null,
		discount: number
	) {
		if (!recurringPrice || !onetimePrice) {
			return 0;
		}
		if (discount > 0) {
			recurringPrice = recurringPrice - discount;
		}
		return Math.round(((onetimePrice - recurringPrice) / onetimePrice) * 100);
	}

	function handleFrequencyChange(e) {
		const newFrequency = parseInt(e.target.value);
		userSelectedFrequency = newFrequency;
		configuratorState.setRecurringFrequency(newFrequency);
	}

	// Local event handlers
	async function handleCategorySelect(category: ProductCategory) {
		configuratorState.setCategory(category);
		currentStep = 1;
		await tick();
		if (productSelectionElement) {
			productSelectionElement.scrollIntoView();
		}
	}

	function handleSubmit() {
		onSubmit(configuratorState.order());
	}
</script>

<!-- Category selection step -->
{#if configuratorState.categories().length > 1}
	<OrderConfiguratorCategories
		title={configurator.categorySelectionIntroTitle}
		subtitle={configurator.categorySelectionIntroSubtitle}
		text={configurator.categorySelectionText}
		onCategorySelect={handleCategorySelect}
	/>
{/if}

<div
	class="step-section step-section--v2 step-section--wide"
	class:hidden={currentStep < 1 && requiresCategorySelection}
	bind:this={productSelectionElement}
>
	<OrderConfiguratorStepHeader
		title={configurator.productSelectionIntroTitle}
		subtitle={configurator.productSelectionIntroSubtitle}
	/>

	<div class="order-compact">
		<div class="order-compact-container">
			<div class="order-compact-column">
				<div class="order-compact-main">
					{#if configurator.productSelectionImage}
						<figure class="order-compact-image">
							<img
								src={configurator.productSelectionImage.url}
								alt={configurator.productSelectionImage.alt}
							/>
						</figure>
					{/if}

					<div class="order-compact-flavors">
						<h3 class="order-compact-flavors-title">{configurator.productSelectionTitle}</h3>

						{#if configurator.productSelectionSubtitle}
							<p class="order-compact-flavors-subtitle">{configurator.productSelectionSubtitle}</p>
						{/if}

						<div class="order-compact-products">
							{#each configuratorState.products() as { product, quantity }, i}
								{@const displayQuantity = quantity * (product.quantityMultiplier ?? 1)}
								<div class="order-compact-product">
									<div class="order-compact-product-title">
										{product.name}
									</div>
									<div class="order-compact-product-input">
										<button onclick={() => configuratorState.decrementProductQuantity(product)}
											>-</button
										>
										<input
											type="text"
											value={displayQuantity}
											onchange={(e) =>
												configuratorState.setProductQuantity(
													product,
													parseInt(e.target.value) / product.quantityMultiplier
												)}
										/>
										<button onclick={() => configuratorState.incrementProductQuantity(product)}
											>+</button
										>
									</div>
								</div>
							{/each}
						</div>

						<div class="order-compact-totals">
							<div class="order-compact-totals-quantity">
								{#if !configuratorState.isValidQuantity()}
									<div class="order-compact-totals-quantity-error">
										<QuantityValidationMessage
											quantities={configuratorState.quantities()}
											quantity={configuratorState.selectedQuantity()}
											multiplier={configuratorState.quantityMultiplier()}
											{...selectedCategory?.translations}
										/>
									</div>
								{:else}
									<PriceScaleMessage
										quantity={selectedDisplayQuantity}
										priceScales={selectedCategory?.priceScales}
										{...selectedCategory?.translations}
									/>
								{/if}

								<div class="order-compact-totals-quantity-value">
									{selectedDisplayQuantity}
									{#if selectedDisplayQuantity === 1}
										{selectedCategory?.translations.unitNameSingle}
									{:else}
										{selectedCategory?.translations.unitNamePlural}
									{/if}
								</div>
							</div>
							{#if site.showUnitPrices}
								{#if configuratorState.isValid() && prices}
									{#if prices.productsLowestRecurringPrice !== null}
										{#if prices.discountAmount > 0}
											{@const discountedPrice =
												prices.productsLowestRecurringPrice - prices.discountAmount}
											<div class="order-compact-totals-note">
												{translations.fromPriceLabel}
												{formatCurrency(
													discountedPrice / selectedDisplayQuantity,
													site.locale.code
												)}
												{translations.perUnitText.replaceAll(
													'%product%',
													selectedCategory?.translations.unitNameSingle ?? '%product%'
												)}
											</div>
										{:else}
											<div class="order-compact-totals-note">
												{translations.fromPriceLabel}
												{formatCurrency(
													prices.productsLowestRecurringPrice / selectedDisplayQuantity,
													site.locale.code
												)}
												{translations.perUnitText.replaceAll(
													'%product%',
													selectedCategory?.translations.unitNameSingle ?? '%product%'
												)}
											</div>
										{/if}
									{/if}
								{/if}
							{/if}
						</div>
					</div>
				</div>
			</div>
			<div class="order-compact-column">
				<div class="order-compact-aside">
					<div class="order-compact-toggles">
						<label class="order-compact-toggle">
							<input
								type="radio"
								name="frequency_type"
								value="onetime"
								bind:group={selectedFrequencyType}
							/>
							<div class="order-compact-toggle-button">
								<div class="order-compact-toggle-button-label">
									{configurator.onetimeOrderTitle}
								</div>
								{#if prices && prices.productsOneTimePrice}
									<div class="order-compact-toggle-button-prices">
										{#if prices.discountAmountOnetime > 0}
											<div class="order-compact-toggle-button-regular-price">
												{formatCurrency(prices.productsOneTimePrice, site.locale.code)}
											</div>
										{/if}
										<div class="order-compact-toggle-button-price">
											{formatCurrency(
												prices.productsOneTimePrice - prices.discountAmountOnetime,
												site.locale.code
											)}
										</div>
									</div>
								{/if}
							</div>
							<div class="order-compact-toggle-pane">
								<div class="order-compact-toggle-text">
									{@html configurator.onetimeOrderText}
								</div>
							</div>
						</label>
						<label class="order-compact-toggle">
							<input
								type="radio"
								name="frequency_type"
								value="recurring"
								bind:group={selectedFrequencyType}
							/>
							<div class="order-compact-toggle-button">
								<div class="order-compact-toggle-button-label">
									{configurator.recurringOrderTitle}
								</div>

								{#if savingsPercentage > 0}
									<div class="order-compact-toggle-button-sale">-{savingsPercentage}%</div>
								{/if}

								{#if prices && prices.productsRecurringPrice}
									<div class="order-compact-toggle-button-prices">
										{#if prices.discountAmount > 0}
											<div class="order-compact-toggle-button-regular-price">
												{formatCurrency(prices.productsRecurringPrice, site.locale.code)}
											</div>
										{/if}
										<div class="order-compact-toggle-button-price">
											{formatCurrency(
												prices.productsRecurringPrice - prices.discountAmount,
												site.locale.code
											)}
										</div>
									</div>
								{/if}
							</div>
							<div class="order-compact-toggle-pane">
								{#if selectedFrequencyType === 'recurring'}
									<div class="order-compact-toggle-frequency">
										{#if configurator.recurringOrderSelectLabelSingle && configurator.recurringOrderSelectLabelPlural}
											<div class="order-compact-toggle-frequency-label">
												{formatNumericString(
													configurator.recurringOrderSelectLabelSingle,
													configurator.recurringOrderSelectLabelPlural,
													selectedDisplayQuantity,
													'%quantity%'
												)
													.replaceAll(
														'%product%',
														selectedCategory?.translations.unitNameSingle ?? ''
													)
													.replaceAll(
														'%products%',
														selectedCategory?.translations.unitNamePlural ?? ''
													)}
											</div>
										{/if}

										<div class="order-compact-toggle-frequency-select">
											<select class="frequency-step-select-input" onchange={handleFrequencyChange}>
												{#each configuratorState.frequencies() as frequency}
													{#if frequency > 0}
														<option
															value={frequency}
															selected={configuratorState.selectedRecurringFrequency() == frequency}
														>
															{formatNumericString(
																translations.frequencySingle,
																translations.frequencyMultiple,
																frequency,
																'%quantity%'
															)}
														</option>
													{/if}
												{/each}
											</select>
										</div>
									</div>
								{/if}
								<div class="order-compact-toggle-text">
									{@html configurator.recurringOrderText}
								</div>
							</div>
						</label>
					</div>

					<div class="order-compact-submit">
						<div class="order-compact-submit-totals">
							<div class="order-compact-submit-price">
								{#if selectedFrequencyType === 'onetime' && prices && prices.productsOneTimePrice}
									{formatCurrency(
										prices.productsOneTimePrice - prices.discountAmountOnetime,
										site.locale.code
									)}
								{/if}

								{#if selectedFrequencyType === 'recurring' && prices && prices.productsRecurringPrice}
									{formatCurrency(
										prices.productsRecurringPrice - prices.discountAmount,
										site.locale.code
									)}
								{/if}
							</div>
						</div>
						<div class="order-compact-submit-button">
							<button
								class="button button--secondary"
								disabled={!configuratorState.isValid()}
								onclick={handleSubmit}
							>
								{configurator.orderButtonText}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<style lang="postcss" global>
	.order-compact {
		&-container {
			@media (--xl) {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}
		}

		&-column {
			display: block;
			position: relative;
			color: var(--base-color);
			background-color: white;

			@media (--xl) {
				width: 62%;
			}

			/* &:before {
				content: '';
				display: block;
				position: absolute;
				z-index: 1;
				top: 10px;
				left: 10px;
				right: -10px;
				bottom: -10px;
				border: 2px solid var(--color-primary);
			} */

			&:last-child {
				margin-top: var(--spacing-8);

				@media (--xl) {
					margin-top: 0;
					width: 34%;
				}
			}
		}

		&-main {
			@media (--md) {
				display: flex;
			}
		}

		&-image {
			width: 100%;
			aspect-ratio: 9 / 6;

			@media (--md) {
				aspect-ratio: auto;
				width: 40%;
			}

			@media (--xl) {
				width: 48%;
			}

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&-flavors {
			position: relative;
			z-index: 2;
			flex: 1;
			padding: var(--spacing-6);

			@media (--md) {
				padding: var(--spacing-6);
			}

			&-title {
				font-size: var(--font-size-1);

				&:not(:last-child) {
					margin-bottom: var(--spacing-2);
				}

				@media (--xs) {
					font-size: var(--font-size-2);
				}
			}

			&-subtitle {
				color: var(--color-grey-2);
				text-transform: uppercase;

				&:not(:last-child) {
					margin-bottom: var(--spacing-5);
				}

				@media (--xs) {
					font-size: var(--font-size-0);
				}
			}
		}

		&-products {
			margin-bottom: var(--spacing-6);

			&:after {
				content: '';
				display: block;
				width: 64px;
				height: 2px;
				background: var(--color-primary);
				opacity: 0.5;
				margin-left: auto;
				margin-top: 24px;
			}
		}

		&-product {
			display: flex;
			align-items: center;

			&:not(:last-child) {
				margin-bottom: var(--spacing-3);
			}

			&-title {
				flex: 1;
				font-size: var(--configurator-product-list-title-font-size);
				font-family: var(--configurator-product-list-title-font-family);
				font-weight: var(--configurator-product-list-title-font-weight);
				text-transform: var(--configurator-product-list-title-text-transform);
				line-height: var(--font-lineheight-0);
				color: var(--configurator-product-list-title-color);
			}

			&-input {
				display: flex;
				align-items: stretch;

				input {
					max-width: 50px;
					padding: 8px;
					font-size: 14px;
					border-left: 0;
					border-right: 0;
					text-align: center;
				}

				button {
					display: block;
					padding: 4px 16px;
					color: white;
					background-color: var(--color-primary);
				}
			}
		}

		&-totals {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			&-quantity {
				position: relative;

				&-value {
					font-size: var(--font-size-3);
					line-height: var(--font-lineheight-1);
					margin-left: auto;
				}

				&-error {
					position: absolute;
					top: 50%;
					right: calc(100% + 12px);
					transform: translateY(-50%);
					font-size: 13px;
					text-align: right;
					line-height: var(--font-lineheight-1);
					padding: 10px 12px 10px 35px;
					color: white;
					white-space: nowrap;
					background-color: var(--color-red);
					background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNCI+PHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJtOCAwIDggMTRIMEw4IDBaTTcgNC45NzZjMC0uNTU3LjQ0NC0xLjAxIDEtMS4wMS41NTIgMCAxIC40NDggMSAxLjAxdjMuOTMxYzAgLjU1OC0uNDQ0IDEuMDEtMSAxLjAxLS41NTIgMC0xLS40NDgtMS0xLjAxVjQuOTc2Wk03IDExLjlhLjk5My45OTMgMCAwIDEgMS0uOTkyYy41NTIgMCAxIC40NCAxIC45OTJhLjk5My45OTMgMCAwIDEtMSAuOTkyYy0uNTUyIDAtMS0uNDQtMS0uOTkyWiIvPjwvc3ZnPg==');
					background-size: 16px 14px;
					background-position: 11px calc(50% - 1px);
					background-repeat: no-repeat;
					border-radius: var(--radius-2);

					&:after {
						content: '';
						position: absolute;
						top: calc(50% - 8px);
						right: -0.375rem;
						width: 0;
						height: 0;
						border-top: 8px solid transparent;
						border-bottom: 8px solid transparent;
						border-left: 8px solid var(--color-red);
					}
				}

				&-success {
					position: absolute;
					top: 50%;
					right: calc(100% + 12px);
					transform: translateY(-50%);
					font-size: 13px;
					text-align: right;
					line-height: var(--font-lineheight-1);
					padding: 10px 12px;
					color: white;
					white-space: nowrap;
					background-color: var(--color-green);
					border-radius: var(--radius-2);

					&:after {
						content: '';
						position: absolute;
						top: calc(50% - 8px);
						right: -0.375rem;
						width: 0;
						height: 0;
						border-top: 8px solid transparent;
						border-bottom: 8px solid transparent;
						border-left: 8px solid var(--color-green);
					}
				}
			}

			&-note {
				margin-top: var(--spacing-3);
				color: var(--color-grey-2);
				font-size: var(--font-size-0);
			}
		}

		&-aside {
			position: relative;
			z-index: 2;
			padding: var(--spacing-6);

			@media (--md) {
				padding: var(--spacing-6);
			}
		}

		&-toggles {
			display: flex;
			flex-direction: column;

			&--onetime {
				flex-direction: column-reverse;
			}
		}

		&-toggle {
			display: block;
			font-family: $family-primary;
			font-weight: $weight-regular;
			text-transform: none;
			letter-spacing: 0;
			margin-bottom: 0;
			border-bottom: 1px solid var(--border-color);

			> input[type='radio'] {
				display: none;
			}

			&-button {
				position: relative;
				display: flex;
				align-items: center;
				text-transform: uppercase;
				padding-top: 14px;
				padding-left: 28px;
				padding-bottom: 14px;
				min-height: 50px;

				&:before,
				&:after {
					content: '';
					position: absolute;
					z-index: 2;
					border-radius: 50%;
				}

				&:before {
					top: 15px;
					left: 0;
					width: 20px;
					height: 20px;
					border: 1px solid var(--color-primary);
				}

				&:after {
					top: 19px;
					left: 4px;
					width: 12px;
					height: 12px;
					background-color: var(--color-primary);
					opacity: 0;
					transition: opacity var(--transition);
				}

				&:hover {
					cursor: pointer;

					&:after {
						opacity: 0.25;
					}
				}

				&-label {
					display: flex;
					align-items: center;
					font-size: 14px;
					line-height: 1.2;
					font-family: var(--font-secondary);
					font-weight: normal;

					@media (--xs) {
						font-size: 16px;
					}
				}

				&-sale {
					display: inline-block;
					margin-right: 8px;
					margin-left: 8px;
					font-size: 11px;
					line-height: 1.2;
					padding: 4px 6px;
					border-radius: 4px;
					font-family: var(--font-secondary);
					font-weight: normal;
					color: white;
					background-color: var(--color-green);
				}

				&-prices {
					margin-left: auto;
					display: flex;
					align-items: center;
				}

				&-regular-price {
					font-size: 15px;
					color: var(--color-grey-3);
					text-decoration: line-through;
					margin-right: 8px;

					@media (--xs) {
						font-size: 13px;
					}
				}

				&-price {
					font-size: 16px;
					font-weight: 500;
					text-align: right;

					@media (--xs) {
						font-size: var(--font-size-2);
					}
				}
			}

			input:checked ~ .order-compact-toggle-button {
				&:after {
					opacity: 1;
				}
			}

			&-pane {
				display: none;
				padding-left: 24px;
				padding-bottom: 18px;
			}

			input:checked ~ .order-compact-toggle-pane {
				display: block;
			}

			&-text {
				font-size: var(--font-size-0);
				line-height: var(--font-lineheight-2);
				color: var(--color-grey-3);

				ul {
					margin-left: 0;

					li {
						padding-left: var(--spacing-6);
						list-style: none;
						background-size: 14px 14px;
						background-repeat: no-repeat;
						background-position: left 3px;
						background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNOSAxOEMxMS4zODY5IDE4IDEzLjY3NjEgMTcuMDUxOCAxNS4zNjQgMTUuMzY0QzE3LjA1MTggMTMuNjc2MSAxOCAxMS4zODY5IDE4IDlDMTggNi42MTMwNSAxNy4wNTE4IDQuMzIzODcgMTUuMzY0IDIuNjM2MDRDMTMuNjc2MSAwLjk0ODIxMiAxMS4zODY5IDAgOSAwQzYuNjEzMDUgMCA0LjMyMzg3IDAuOTQ4MjEyIDIuNjM2MDQgMi42MzYwNEMwLjk0ODIxMiA0LjMyMzg3IDAgNi42MTMwNSAwIDlDMCAxMS4zODY5IDAuOTQ4MjEyIDEzLjY3NjEgMi42MzYwNCAxNS4zNjRDNC4zMjM4NyAxNy4wNTE4IDYuNjEzMDUgMTggOSAxOFpNMTIuOTcyNyA3LjM0NzY2TDguNDcyNjYgMTEuODQ3N0M4LjE0MjE5IDEyLjE3ODEgNy42MDc4MSAxMi4xNzgxIDcuMjgwODYgMTEuODQ3N0w1LjAzMDg2IDkuNTk3NjZDNC43MDAzOSA5LjI2NzE5IDQuNzAwMzkgOC43MzI4MSA1LjAzMDg2IDguNDA1ODZDNS4zNjEzMyA4LjA3ODkxIDUuODk1NyA4LjA3NTM5IDYuMjIyNjYgOC40MDU4Nkw3Ljg3NSAxMC4wNTgyTDExLjc3NzMgNi4xNTIzNEMxMi4xMDc4IDUuODIxODggMTIuNjQyMiA1LjgyMTg4IDEyLjk2OTEgNi4xNTIzNEMxMy4yOTYxIDYuNDgyODEgMTMuMjk5NiA3LjAxNzE5IDEyLjk2OTEgNy4zNDQxNEwxMi45NzI3IDcuMzQ3NjZaIiBmaWxsPSIjRDRDMDlGIj48L3BhdGg+PC9zdmc+');

						&:not(:last-child) {
							margin-bottom: 8px;
						}
					}
				}
			}

			&-frequency {
				position: relative;
				display: flex;
				align-items: center;
				padding: 8px;
				background-color: var(--color-primary-light);
				margin-bottom: var(--spacing-5);

				&:before {
					content: '';
					position: absolute;
					top: -8px;
					left: 48px;
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-bottom: 8px solid var(--color-primary-light);
				}

				&-label {
					flex: 1;
					padding-left: 8px;
					font-size: 13px;
					line-height: 1.2;
					font-family: var(--font-secondary);
					font-weight: normal;
					text-transform: uppercase;

					@media (--xs) {
						font-size: 14px;
					}
				}

				&-select {
					width: 124px;

					@media (--xs) {
						width: 160px;
					}

					select {
						width: 100%;
						height: 38px;
						padding: 0 12px;
					}
				}
			}
		}
		&-submit {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: var(--spacing-4);
			min-height: 64px;

			&-totals {
				flex: 1;
			}

			&-price {
				font-size: var(--font-size-4);
			}
		}
	}
</style>
