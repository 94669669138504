<script lang="ts">
	import type { AccountOrderDetailPage } from '$models/Page/AccountOrderDetailPage';
	import OrderItems from '$components/Order/OrderItems.svelte';
	import DocumentIcon from '$components/UI/Icons/DocumentIcon.svelte';
	import Loader from '$components/UI/Loader.svelte';
	import PaymentStatus from '$components/UI/Status/PaymentStatus.svelte';
	import ShippingStatus from '$components/UI/Status/ShippingStatus.svelte';
	import { PUBLIC_WEBPARKING_URL } from '$env/static/public';
	import { getSiteContext } from '$lib/context/site';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { formatCurrency, formatDate } from '$lib/utils';
	import { listOrders } from '$lib/webparking/orders/orders';
	import { onMount } from 'svelte';

	// Component properties
	export let page: AccountOrderDetailPage;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();
	const site = getSiteContext();

	let orderPromise = new Promise(() => {});
	onMount(async () => {
		await $userStore;
		try {
			const orders = await listOrders();
			const order = orders.find((o) => o.id === page.resourceId);
			if (!order) {
				throw new Error(page.layout.translations.errors.orderNotFound);
			}
			orderPromise = Promise.resolve(order);
		} catch (error) {
			if (error.response && error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});
</script>

{#await orderPromise}
	<Loader />
{:then order}
	<div class="account-box">
		<div class="box">
			<h1 class="box-title">
				#{order.number}
			</h1>
			<div class="summary">
				<div class="summary-row">
					<div class="summary-row-label">{page.layout.translations.productsLabel}:</div>
					<div class="summary-row-value">
						<OrderItems items={order.products} />
					</div>
				</div>
				<div class="summary-row">
					<div class="summary-row-label">{page.layout.translations.totalPriceLabel}:</div>
					<div class="summary-row-value">
						{order.total ? formatCurrency(order.total, site.locale.code) : '-'}
					</div>
				</div>
				<div class="summary-row">
					<div class="summary-row-label">{page.layout.translations.paymentStatusLabel}:</div>
					<div class="summary-row-value">
						<PaymentStatus status={order.paymentStatus} />
					</div>
				</div>
				<div class="summary-row">
					<div class="summary-row-label">{page.layout.translations.shippingStatusLabel}:</div>
					<div class="summary-row-value">
						<ShippingStatus status={order.shippingStatus} />
					</div>
				</div>
				<div class="summary-row">
					<div class="summary-row-label">{page.layout.translations.deliveryDateLabel}:</div>
					<div class="summary-row-value">
						{formatDate(order.deliveryDate, site.locale.code)}
					</div>
				</div>
				{#if order.paymentStatus === 'paid'}
					<div class="summary-row">
						<div class="summary-row-label">{page.layout.translations.invoiceLabel}:</div>
						<div class="summary-row-value">
							<a href={`${PUBLIC_WEBPARKING_URL}v1/orders/${order.id}/invoice`}>
								<DocumentIcon />
							</a>
						</div>
					</div>
				{/if}
			</div>
		</div>
	</div>
{:catch error}
	{page.layout.translations.errors.failedToLoadOrder}
{/await}
