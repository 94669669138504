<script lang="ts">
	import type { Layout as LayoutType } from '$models/Layout/Layout';
	import FooterLogoBar from '$components/Footer/FooterLogoBar.svelte';

	// Component properties
	export let layout: LayoutType;

	let expandedMenus: number[] = [];

	// Prepare the dynamic copyright text
	const footerCopyrightText = layout.footerCopyrightText.replaceAll(
		'%year%',
		new Date().getFullYear()
	);
</script>

<footer class="footer">
	<div class="footer-main">
		<div class="footer-container container">
			<figure class="footer-logo">
				{@html layout.footerLogo}
			</figure>
			{#each layout.footerNavigations as navigation, i}
				<!-- @TODO: Refactor to component/snippet in Svelte 5 -->
				<div class="footer-widget">
					<div class="footer-title">{navigation.title}</div>
					<ul class="footer-menu" class:is-expanded={expandedMenus.includes(i)}>
						{#each navigation.items as item}
							<li class="footer-menu-item"><a href={item.url}>{item.title}</a></li>
						{/each}
						{#if navigation.items.length > 5}
							<button
								class="footer-menu-toggle"
								on:click={() => (expandedMenus = [...expandedMenus, i])}
							>
								{layout.footerLinksMoreText}
							</button>
						{/if}
					</ul>
				</div>
			{/each}
			<div class="footer-socials">
				{#if layout.footerSocialNavigation.title}
					<div class="footer-title">{layout.footerSocialNavigation.title}</div>
				{/if}
				<div class="footer-socials-items">
					{#each layout.footerSocialNavigation.items as item}
						<a
							class="footer-socials-item footer-socials-item--{item.id}"
							href={item.url}
							target="_blank"
							title={item.title}><span class="screenreader">{item.title}</span></a
						>
					{/each}
				</div>
			</div>
			{#if layout.footerBcorpLogo?.url}
				<div class="footer-bcorp">
					<img
						src={layout.footerBcorpLogo.url}
						width={layout.footerBcorpLogo.width}
						height={layout.footerBcorpLogo.height}
						alt={layout.footerBcorpLogo.alt}
					/>
				</div>
			{/if}
			<div class="footer-copyright">{footerCopyrightText}</div>
		</div>
	</div>
</footer>

<FooterLogoBar {layout} />

<style lang="postcss">
	.footer {
		& {
			display: block;
			position: relative;
			z-index: 1;
			padding-top: var(--spacing-12);
			padding-bottom: var(--spacing-12);
			color: var(--footer-text-color);
			background-color: var(--footer-background);
		}

		&-container {
			@media (--md) {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
			}
		}

		a {
			color: var(--footer-text-color);
			transition: color var(--transition) ease;

			&:hover {
				color: var(--footer-text-color-hover);
			}
		}

		&-title {
			color: var(--footer-title-color);
			font-size: var(--footer-title-font-size);
			font-family: var(--footer-title-font-family);
			font-weight: var(--footer-title-font-weight);
			text-transform: var(--footer-title-transform);
			margin-bottom: var(--spacing-4);
		}

		&-logo {
			width: 120px;
			margin-bottom: var(--spacing-8);

			@media (--md) {
				width: 100%;
				margin-bottom: var(--spacing-10);
			}

			@media (--lg) {
				width: auto;
				flex: 1;
				padding-right: var(--spacing-16);
			}
		}

		&-widget {
			margin-bottom: var(--spacing-8);

			@media (--md) {
				flex: 1;
				padding-right: 8%;
			}

			@media (--lg) {
				padding-right: var(--spacing-10);
			}
		}

		&-menu {
			&-item {
				&:not(last-child) {
					margin-bottom: var(--spacing-3);
				}

				a {
					display: block;
					font-size: var(--footer-text-font-size);
					font-weight: var(--footer-text-font-weight);
					line-height: var(--footer-text-lineheight);
					text-transform: var(--footer-text-transform);
					text-decoration: none;
				}

				&:nth-child(n + 5) {
					display: none;
				}

				.is-expanded & {
					display: block !important;
				}
			}

			&-toggle {
				color: var(--color-primary);
				text-decoration: underline;
				font-size: var(--font-size-1);

				.is-expanded & {
					display: none;
				}
			}
		}

		&-socials {
			margin-bottom: var(--spacing-8);

			@media (--md) {
				width: 100%;
			}

			@media (--lg) {
				width: auto;
				margin-bottom: 0;
			}

			&-items {
				display: flex;
				align-items: center;
			}

			&-item {
				width: var(--footer-social-icon-size);
				height: var(--footer-social-icon-size);
				display: block;
				background-position: center center;
				background-repeat: no-repeat;

				&:not(:last-child) {
					margin-right: var(--spacing-3);
				}

				&--twitter {
					background-image: var(--footer-social-icon-twitter);
				}

				&--facebook {
					background-image: var(--footer-social-icon-facebook);
				}

				&--instagram {
					background-image: var(--footer-social-icon-instagram);
				}

				&--tiktok {
					background-image: var(--footer-social-icon-tiktok);
				}

				&--pinterest {
					background-image: var(--footer-social-icon-pinterest);
				}

				&--linkedin {
					background-image: var(--footer-social-icon-linkedin);
				}
			}
		}

		&-copyright {
			width: 100%;
			font-size: var(--font-size-0);
			text-transform: uppercase;
			font-weight: var(--font-weight-6);
			color: var(--footer-text-color);

			@media (--lg) {
				text-align: right;
			}
		}

		&-bcorp {
			position: absolute;
			right: var(--spacing-5);
			bottom: 0;
			width: 36px;

			@media (--lg) {
				bottom: var(--spacing-8);
			}

			img {
				width: 100%;
				height: auto;
			}
		}
	}
</style>
