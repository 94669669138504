<script lang="ts">
	import type { AccountPage } from '$models/Page/AccountPage';
	import type { Subscription } from '$models/Subscription/Subscription';
	import { page as pageStore } from '$app/stores';
	import DeliveryInfo from '$components/Delivery/DeliveryInfo.svelte';
	import UrgentOrderModal from '$components/UI/Modals/UrgentOrderModal.svelte';
	import { trackEvent } from '$lib/datalayer/datalayer';
	import { getCartStore, getModalStore } from '$lib/stores';
	import { isOrderConfiguratorV2 } from '$lib/type-helpers';
	import { listSubscriptions } from '$lib/webparking';
	import { onMount } from 'svelte';

	export let page: AccountPage;

	export let block: {
		type: 'urgent-order';
		title: string;
		text: string;
		buttonLabel: string;
	};

	const modals = getModalStore();

	const cartStore = getCartStore();

	let subscription: Subscription;

	function getOrderConfigurator(type: string, subscription: Subscription) {
		const product = $pageStore.data.products.regular.find((p) => p.id === subscription.items[0].id);
		const configuratorType =
			$pageStore.data.page.layout.configurators[type] ??
			$pageStore.data.page.layout.configurators.cartEdit;
		return configuratorType.find((c) => {
			if (isOrderConfiguratorV2(c)) {
				return c.configuration.categories.find(
					({ productCategory }) => productCategory.id === product.category.id
				);
			}

			// For V1 configurators
			return c.productCategory?.id === product.category.id;
		});
	}

	function triggerUrgentOrderModal(subscription: Subscription) {
		const { title, body, messages, translations } = page.modals.urgentOrder;
		const configurator = getOrderConfigurator('subscriptionUrgentOrder', subscription);

		modals.trigger({
			title,
			body,
			type: 'component',
			component: {
				ref: UrgentOrderModal,
				props: {
					onSuccess: (subscription: Subscription) => {
						if (subscription.id === 'cancelled') {
							trackEvent('activate_subscription');
							trackEvent('emergency_order_cancelled_customers');
						} else {
							trackEvent('emergency_order_active_customers');
						}
					},
					subscription,
					messages,
					translations,
					configurator,
					skipIntro: true
				}
			}
		});
	}

	onMount(async () => {
		try {
			let subscriptions = await listSubscriptions();
			subscriptions = subscriptions.filter((subscription) => subscription.status.id === 'active');

			// Only continue if there is exactly 1 subscription
			if (subscriptions.length !== 1) {
				return;
			}

			subscription = subscriptions[0];
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});
</script>

{#if subscription}
	<div class="account-box">
		<div class="box">
			<h2 class="box-title">{page.urgentOrderBox.title}</h2>
			<div class="rich-text">
				{@html page.urgentOrderBox.text}
				<DeliveryInfo />
				<button
					type="button"
					class="button button--secondary"
					on:click={() => triggerUrgentOrderModal(subscription)}
				>
					{page.layout.translations.buttonLabels.placeUrgentOrder}
				</button>
			</div>
		</div>
	</div>
{/if}
