<script lang="ts">
	// Component properties
	export let min: number;
	export let max: number;
	export let step: number;
	export let value: number;
	export let onChange: any;
</script>

<input
	style="background: linear-gradient(to right, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.05) {(value /
		max) *
		100}%, white {(value / max) * 100}%, white 100%)"
	class="range-slider"
	type="range"
	{min}
	{max}
	{step}
	{value}
	on:input={onChange}
/>

<style lang="postcss">
	.range-slider[type='range'] {
		width: 100%;
		border-radius: 4px;
		height: 8px;
		-webkit-appearance: none;

		&::-webkit-slider-runnable-track {
			margin: 0;
			height: 8px;
			cursor: pointer;
			background: inherit;
			border-radius: 4px;
			border: 1px solid var(--border-color);
		}

		&::-webkit-slider-thumb {
			width: 24px;
			height: 24px;
			-webkit-transform: scale(1.2);
			transform: scale(1.2);
			margin-top: -9px;
			border: 0;
			border-radius: 50%;
			-webkit-box-shadow: inset 0 0 0 8px color-mix(in srgb, var(--color-primary) 90%, black);
			box-shadow: inset 0 0 0 8px color-mix(in srgb, var(--color-primary) 90%, black);
			background-color: var(--color-primary);
			cursor: pointer;
			-webkit-appearance: none;
		}

		&::-moz-range-track {
			width: 100%;
			margin: 0;
			height: 8px;
			cursor: pointer;
			background: inherit;
			border-radius: 4px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			-moz-appearance: none;
		}

		&::-moz-range-progress {
			border-radius: 4px;
			background-color: #f0f0f0;
			height: 8px;
		}

		&::-moz-range-thumb {
			width: 24px;
			height: 24px;
			transform: scale(1.2);
			margin-top: -0.625rem;
			border-radius: 50%;
			border: 0;
			box-shadow: inset 0 0 0 8px #46664d;
			background-color: #577e5f;
			cursor: pointer;
			-moz-appearance: none;
		}
		&::-ms-track {
			width: 100%;
			margin: 0;
			height: 8px;
			cursor: pointer;
			background: inherit;
			border-radius: 4px;
			border: 1px solid rgba(0, 0, 0, 0.1);
			appearance: none;
		}
	}
</style>
