<script lang="ts">
	import Loader from '$components/UI/Loader.svelte';
	import { getCartStore, getCheckoutStore, getToastStore, getUserStore } from '$lib/stores';
	import { deleteUserAddress, listUserAddresses } from '$lib/webparking';
	import { onMount } from 'svelte';

	// Component properties
	export let accountBlock;
	export let page;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const toastStore = getToastStore();
	const userStore = getUserStore();

	let userAdressesPromise = new Promise(() => {});
	async function onDeleteButtonClick(address: UserAddress) {
		try {
			await deleteUserAddress(address);
			toastStore.trigger({
				type: 'success',
				message: accountBlock.deleteSuccessText
			});
			userAdressesPromise = listUserAddresses();
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
			toastStore.trigger({
				type: 'error',
				message: accountBlock.deleteErrorText
			});
		}
	}

	onMount(async () => {
		await $userStore;
		try {
			userAdressesPromise = listUserAddresses();
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});
</script>

{#await userAdressesPromise}
	<Loader />
{:then addresses}
	<div class="box">
		<h2 class="box-title">
			{accountBlock.title}
		</h2>
		<div class="addresses">
			{#each addresses as address}
				<div class="address">
					<div class="address-header">
						<div class="address-label">
							{accountBlock.addressTypeLabels[address.type]}
						</div>
					</div>
					<address class="address-info">
						<div>
							{address.street}
							{address.houseNumber}{address.houseNumberSuffix.length
								? '-'
								: ''}{address.houseNumberSuffix}
						</div>
						<div>{address.postalCode} {address.city}</div>
						<div>
							{#if page.layout.countries[address.country.toLowerCase()]}
								{page.layout.countries[address.country.toLowerCase()]}
							{:else}
								{address.country}
							{/if}
						</div>
					</address>
					<a
						href="{page.layout.pages.accountAddressesPage}/?resourceId={address.id}"
						class="address-edit"
					>
						{accountBlock.editButtonLabel}
					</a>
				</div>
			{/each}
		</div>
	</div>
{/await}

<style>
	.address {
		padding: var(--spacing-5);
		border: 1px solid var(--border-color);
		border-radius: var(--radius-2);

		&:not(:last-child) {
			margin-bottom: var(--spacing-5);
		}

		&-header {
			display: flex;
			align-items: center;
			margin-bottom: var(--spacing-4);
		}
		&-label {
			font-size: 14px;
			line-height: var(--font-lineheight-0);
			padding: var(--spacing-2) var(--spacing-3);
			border-radius: var(--radius-1);
			color: var(--label-text-color);
			background-color: var(--label-bg-color);
		}
		&-info {
			font-size: var(--font-size-0);
			line-height: var(--font-lineheight-3);
			font-style: normal;
			margin-bottom: var(--spacing-2);
		}
		&-edit {
			color: var(--color-primary);
			font-size: var(--font-size-0);
			font-weight: var(--font-weight-5);

			&:hover {
				text-decoration: underline;
			}
		}
	}
</style>
