<script lang="ts">
	export let show = false;
</script>

{#if show}
	<div class="button-spinner">
		<div />
		<div />
		<div />
		<div />
	</div>
{/if}

<style>
	.button-spinner {
		position: absolute;
		display: inline-block;
		width: 18px;
		height: 18px;
		top: 50%;
		left: 50%;
		transform: translateY(-50%);
		left: calc(100% - 32px);
		color: var(--color-white);

		div {
			box-sizing: border-box;
			display: block;
			position: absolute;
			width: 18px;
			height: 18px;
			border: 2px solid currentColor;
			border-radius: 50%;
			animation: button-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-color: currentColor transparent transparent transparent;

			&:nth-child(1) {
				animation-delay: -0.45s;
			}
			&:nth-child(2) {
				animation-delay: -0.3s;
			}
			&:nth-child(3) {
				animation-delay: -0.15s;
			}
		}
	}

	@keyframes button-spinner {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
</style>
