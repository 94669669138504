<script lang="ts">
	import type { OrderConfigurator } from '$models/OrderConfigurator/OrderConfigurator';
	import type { FrequencySelectorStep } from '$models/OrderConfigurator/Step/FrequencySelectorStep';
	import { getSiteContext } from '$lib/context/site';
	import { formatCurrency, formatNumericString, sanitizeHtml } from '$lib/utils';

	// Component properties
	export let translations: any;
	export let configurator: OrderConfigurator;
	export let configuratorStore: any;
	export let step: FrequencySelectorStep;
	export let index: number;
	export let entityEventHandlers: any;

	const site = getSiteContext();

	// Prepare the reactive recurring order label
	let recurringOrderLabel = null;

	let recurringProductAmount = 0;
	$: {
		recurringOrderLabel = formatNumericString(
			step.recurringOrderLabelSingle,
			step.recurringOrderLabelMultiple,
			configuratorStore.getTotalAmount('product'),
			'%quantity%'
		);
		const newRecurringProductAmount = configuratorStore.order.products.reduce(
			(accumulator, currentValue) => accumulator + currentValue.quantity,
			0
		);
		if (configurator.quantities.includes(newRecurringProductAmount)) {
			recurringProductAmount = newRecurringProductAmount;
		}
	}

	// Prepare the button event handlers
	function onRecurringOrderButtonClick(event) {
		configuratorStore.setIsRecurringOrder(true);
		configuratorStore.goToNextStep(event);
	}
	function onOneTimeOrderButtonClick() {
		configuratorStore.setIsRecurringOrder(false);
		configuratorStore.goToNextStep(event);
	}
</script>

<div class="frequency-step">
	<div class="frequency-step-inner">
		<div
			class="frequency-step-box"
			class:frequency-step-box--centered={!configurator.isOneTimeOrderAllowed}
		>
			<h3 class="frequency-step-title">
				{step.recurringOrderTitle}
			</h3>
			<div class="frequency-step-select">
				<span class="frequency-step-select-label">
					{recurringOrderLabel}
				</span>
				<select
					class="frequency-step-select-input"
					on:change={configuratorStore.setTargetFrequency}
				>
					{#each configurator.frequencies as frequency}
						{#if frequency > 0}
							<option value={frequency} selected={configuratorStore.targetFrequency == frequency}>
								{formatNumericString(
									translations.frequencySingle,
									translations.frequencyMultiple,
									frequency,
									'%quantity%'
								)}
							</option>
						{/if}
					{/each}
				</select>
			</div>
			<div class="frequency-step-text">
				{@html sanitizeHtml(step.recurringOrderText)}
			</div>
			{#await configuratorStore.prices then prices}
				{#if prices.productsRecurringPrice !== null && prices.discountAmount !== null}
					<div class="frequency-step-footer">
						{#if configuratorStore.entityErrorText['product'] === null}
							<div class="frequency-step-price">
								{#if prices.discountAmount > 0}
									{@const discountedPrice =
										prices.productsRecurringPrice +
										prices.totalNonSubscribableProductsPrice -
										prices.discountAmount}
									<div class="frequency-step-price-label">
										{translations.discountPriceLabel}
									</div>
									<div class="frequency-step-price-value">
										{formatCurrency(discountedPrice, site.locale.code)}
									</div>
									{#if formatCurrency(discountedPrice, site.locale.code) !== formatCurrency(prices.productsRecurringPrice, site.locale.code)}
										<div class="frequency-step-price-after">
											{translations.discountRegularPriceLabel.replaceAll(
												'%price%',
												formatCurrency(prices.productsRecurringPrice, site.locale.code)
											)}
										</div>
									{/if}
								{:else if prices.totalNonSubscribableProductsPrice > 0}
									<div class="frequency-step-price-value">
										{formatCurrency(
											prices.productsRecurringPrice + prices.totalNonSubscribableProductsPrice,
											site.locale.code
										)}
									</div>
									<div class="frequency-step-price-after">
										{translations.includedOnetimePriceLabel}
										{formatCurrency(prices.totalNonSubscribableProductsPrice, site.locale.code)}
									</div>
								{:else}
									<div class="frequency-step-price-value">
										{formatCurrency(prices.productsRecurringPrice, site.locale.code)}
									</div>
								{/if}
								{#if site.showUnitPrices}
									<div class="frequency-step-price-per-unit">
										{formatCurrency(
											(prices.productsRecurringPrice - prices.discountAmount) /
												configuratorStore.getTotalAmount('product'),
											site.locale.code
										)}
										{translations.perUnitText.replaceAll(
											'%product%',
											configurator.productCategory?.translations.unitNameSingle ?? '%product%'
										)}

										{#if site.showWeightPrices}
											<div class="frequency-step-tooltip">
												<!-- @TODO: This should be refactored into a proper feature. Hardcoded for cups for now. -->
												<div class="frequency-step-tooltip-label">
													{formatCurrency(
														(prices.productsRecurringPrice - prices.discountAmount) /
															configuratorStore.getTotalAmount('product') /
															0.0053,
														site.locale.code
													)} / 1kg
												</div>
												<div class="frequency-step-tooltip-trigger">i</div>
											</div>
										{/if}
									</div>
								{/if}
							</div>
						{/if}

						<div class="frequency-step-button">
							<button
								class="button button--secondary"
								on:click={onRecurringOrderButtonClick}
								disabled={configuratorStore.entityErrorText['product'] !== null}
							>
								{step.recurringOrderButtonText}
							</button>
							{#if prices.discountAmount > 0 && configuratorStore.entityErrorText['product'] === null}
								<div class="frequency-step-savings">
									{translations.discountPromotionLabel.replaceAll(
										'%percentage%',
										formatCurrency(
											prices.productsOneTimePrice -
												prices.discountAmountOnetime -
												(prices.productsRecurringPrice - prices.discountAmount),
											site.locale.code
										)
									)}
								</div>
							{/if}
						</div>
					</div>
				{/if}
			{/await}
		</div>
		{#if configurator.isOneTimeOrderAllowed}
			<div class="frequency-step-spacer">
				<span>{translations.configurator.andLabel}</span>
			</div>
			<div class="frequency-step-box">
				<h3 class="frequency-step-title">
					{step.oneTimeOrderTitle}
				</h3>
				<div class="frequency-step-text">
					{@html sanitizeHtml(step.oneTimeOrderText)}
				</div>
				{#await configuratorStore.prices then prices}
					{#if prices.productsOneTimePrice !== null}
						<div class="frequency-step-footer">
							{#if configuratorStore.entityErrorText['product'] === null}
								<div class="frequency-step-price">
									{#if prices.discountAmountOnetime > 0}
										{@const discountedPrice =
											prices.productsOneTimePrice +
											prices.totalNonSubscribableProductsPrice -
											prices.discountAmountOnetime}
										<div class="frequency-step-price-label">
											{translations.discountPriceLabel}
										</div>
										<div class="frequency-step-price-value">
											{formatCurrency(discountedPrice, site.locale.code)}
										</div>
										{#if formatCurrency(discountedPrice, site.locale.code) !== formatCurrency(prices.productsOneTimePrice + prices.totalNonSubscribableProductsPrice, site.locale.code)}
											<div class="frequency-step-price-old">
												{formatCurrency(
													prices.productsOneTimePrice + prices.totalNonSubscribableProductsPrice,
													site.locale.code
												)}
											</div>
										{/if}
									{:else}
										<div class="frequency-step-price-value">
											{formatCurrency(
												prices.productsOneTimePrice + prices.totalNonSubscribableProductsPrice,
												site.locale.code
											)}
										</div>
									{/if}
									{#if site.showUnitPrices}
										<div class="frequency-step-price-per-unit">
											{formatCurrency(
												(prices.productsOneTimePrice - prices.discountAmountOnetime) /
													configuratorStore.getTotalAmount('product'),
												site.locale.code
											)}
											{translations.perUnitText.replaceAll(
												'%product%',
												configurator.productCategory?.translations.unitNameSingle ?? '%product%'
											)}
											{#if site.showWeightPrices}
												<div class="frequency-step-tooltip">
													<!-- @TODO: This should be refactored into a proper feature. Hardcoded for cups for now. -->
													<div class="frequency-step-tooltip-label">
														{formatCurrency(
															(prices.productsOneTimePrice - prices.discountAmountOnetime) /
																configuratorStore.getTotalAmount('product') /
																0.0053,
															site.locale.code
														)} / 1kg
													</div>
													<div class="frequency-step-tooltip-trigger">i</div>
												</div>
											{/if}
										</div>
									{/if}
								</div>
							{/if}

							<div class="frequency-step-button">
								<button
									class="button button--outline"
									on:click={onOneTimeOrderButtonClick}
									disabled={configuratorStore.entityErrorText['product'] !== null}
								>
									{step.oneTimeOrderButtonText}
								</button>
							</div>
						</div>
					{/if}
				{/await}
			</div>
		{/if}
	</div>
</div>

<style lang="postcss" global>
	.frequency-step {
		&-inner {
			@media (--md) {
				display: flex;
				justify-content: space-between;
			}
		}

		&-box {
			display: flex;
			flex-direction: column;
			color: var(--base-color);
			background-color: white;
			border: var(--configurator-box-border);
			padding: var(--spacing-6);

			@media (--lg) {
				padding: var(--spacing-8);
			}

			&:first-child {
				@media (--md) {
					width: 58%;
				}
			}

			&:last-child {
				@media (--md) {
					width: 38%;
				}
			}

			&--centered {
				@media (--md) {
					width: 58% !important;
					margin: 0 auto;
				}
			}
		}

		&-spacer {
			display: flex;
			align-items: center;
			text-align: center;
			margin: var(--spacing-4) 0;

			@media (--md) {
				display: none;
			}

			&:after,
			&:before {
				content: '';
				flex: 1;
				display: block;
				height: 1px;
				background-color: var(--color-primary);
			}

			span {
				display: block;
				color: var(--color-primary);
				padding: 0 var(--spacing-2);
			}
		}

		&-title {
			font-size: var(--configurator-box-title-font-size);
			font-family: var(--configurator-box-title-font-family);
			font-weight: var(--configurator-box-title-font-weight);
			text-transform: var(--configurator-box-title-text-transform);
			line-height: var(--font-lineheight-0);
			margin-bottom: var(--spacing-4);
		}

		&-select {
			display: flex;
			align-items: center;
			margin-bottom: var(--spacing-6);

			&-label {
				font-size: var(--font-size-2);
				color: var(--color-grey-2);
				margin-right: var(--spacing-4);
			}

			&-input {
				flex: 1;
				max-width: 154px;
				border-radius: var(--configurator-input-radius);
			}
		}

		&-text {
			font-size: var(--configurator-frequency-font-size);
			line-height: var(--font-lineheight-2);
			color: var(--color-grey-2);
			margin-bottom: var(--spacing-6);

			:global(li) {
				position: relative;
				padding-left: 26px;

				&:before {
					content: '';
					position: absolute;
					top: 3px;
					left: 0;
					display: flex;
					width: 16px;
					height: 16px;
					margin-right: var(--spacing-2);
					border-radius: 50%;
					border: var(--configurator-frequency-usp-border);
					background-color: var(--configurator-frequency-usp-background);
					background-image: var(--configurator-frequency-usp-icon);
					background-size: var(--configurator-frequency-usp-icon-size);
					background-position: center center;
					background-repeat: no-repeat;
				}

				&:not(:last-child) {
					margin-bottom: var(--spacing-2);
				}
			}
		}

		&-price {
			flex: 1;
			display: flex;
			flex-wrap: wrap;
			line-height: var(--font-lineheight-0);
			margin-bottom: var(--spacing-4);

			@media (--lg) {
				margin-bottom: 0;
			}

			&-label {
				width: 100%;
				color: var(--color-primary);
				font-size: var(--font-size-0);
				font-weight: var(--font-weight-4);
				margin-bottom: var(--spacing-1);
			}

			&-value {
				font-size: var(--font-size-4);
			}

			&-after,
			&-old {
				font-size: var(--font-size-0);
				color: var(--color-grey-3);
				align-self: flex-end;
				margin-left: var(--spacing-2);
				margin-bottom: 2px;
			}

			&-old {
				text-decoration: line-through;
			}

			&-per-unit {
				display: flex;
				align-items: center;
				width: 100%;
				font-size: var(--font-size-0);
				color: var(--color-grey-3);
				margin-top: var(--spacing-1);
			}
		}

		&-tooltip {
			display: block;
			position: relative;
			margin-left: 7px;

			&-trigger {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 16px;
				height: 16px;
				font-family: 'Georgia', 'Times New Roman', serif;
				font-weight: bold;
				font-size: 11px;
				line-height: 1;
				color: var(--color-white);
				background-color: var(--color-primary);
				border-radius: 50%;
			}

			&-label {
				display: block;
				opacity: 0;
				visibility: hidden;
				position: absolute;
				bottom: calc(100% + 6px);
				left: 50%;
				transform: translateX(-50%);
				white-space: nowrap;
				padding: 6px 8px;
				font-size: 12px;
				line-height: 1;
				color: var(--color-black);
				background-color: var(--color-primary-light);
				transition: all var(--transition);

				&:after {
					content: '';
					display: block;
					position: absolute;
					bottom: -6px;
					left: 50%;
					transform: translateX(-50%);
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					border-top: 6px solid var(--color-primary-light);
				}
			}

			&:hover {
				cursor: help;
				.frequency-step-tooltip-label {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		&-footer {
			margin-top: auto;
			width: 100%;

			@media (--lg) {
				display: flex;
				align-items: center;
			}
		}

		&-button {
			position: relative;
			display: flex;
			flex-direction: column;

			@media (--lg) {
				margin-left: auto;
			}

			.button {
				display: block;
				width: 100%;

				@media (--lg) {
					width: auto;
				}
			}
		}

		&-savings {
			display: flex;
			align-items: center;
			position: relative;
			padding: var(--spacing-1) var(--spacing-3);
			margin: var(--spacing-3) auto 0 auto;
			font-size: var(--font-size-0);
			color: var(--color-white);
			text-align: center;
			white-space: nowrap;
			line-height: var(--font-lineheight-1);
			background-color: var(--color-green);

			@media (--md) {
				position: absolute;
				z-index: 2;
				left: 0;
				bottom: calc(100% + 12px);
				left: 75%;
				transform: translateX(-75%);
			}

			@media (--lg) {
				left: 50%;
				transform: translateX(-50%);
			}

			&:before {
				content: '';
				display: block;
				margin-right: var(--spacing-1);
				width: 12px;
				height: 9px;
				background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTEuNzIzOCAwLjIzNTU0OEMxMi4wODIxIDAuNTU4NTMgMTIuMDkzMyAxLjA5MjY2IDExLjc0ODcgMS40Mjg1Nkw0LjI0ODc1IDguNzQxMDZDNC4wNzU1IDguOTA5OTkgMy44MzUxIDkuMDAzNzUgMy41ODUxNyA4Ljk5OTg5QzMuMzM1MjMgOC45OTYwMiAzLjA5ODI2IDguODk0ODggMi45MzEwNCA4LjcyMDY5TDAuMjMxMDQzIDUuOTA4MTlDLTAuMTAxNDcgNS41NjE4MiAtMC4wNzE1MTk1IDUuMDI4MzMgMC4yOTc5MzkgNC43MTY2QzAuNjY3Mzk4IDQuNDA0ODYgMS4yMzY0NiA0LjQzMjk0IDEuNTY4OTcgNC43NzkzMUwzLjYyMTc5IDYuOTE3NjdMMTAuNDUxMyAwLjI1ODk0MUMxMC43OTU4IC0wLjA3Njk2MDcgMTEuMzY1NSAtMC4wODc0MzM4IDExLjcyMzggMC4yMzU1NDhaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K');
				background-size: 12px 9px;
				background-position: center left;
				background-repeat: no-repeat;
			}

			&:after {
				content: '';
				position: absolute;
				top: -12px;
				left: 50%;
				border: 6px solid rgba(0, 0, 0, 0);
				border-bottom-color: var(--color-green);

				@media (--md) {
					top: auto;
					bottom: -12px;
					border-bottom-color: transparent;
					border-top-color: var(--color-green);
				}
			}
		}
	}
</style>
