<script lang="ts">
	import type { Block } from '$models/Block/Block';
	import type { CallToActionBlock as CallToActionBlockType } from '$models/Block/CallToActionBlock';
	import type { CardsBlock as CardsBlockType } from '$models/Block/CardsBlock';
	import type { ColumnsBlock as ColumnsBlockType } from '$models/Block/ColumnsBlock';
	import type { ContentBlock as ContentBlockType } from '$models/Block/ContentBlock';
	import type { FaqBlock as FaqBlockType } from '$models/Block/FaqBlock';
	import type { FeaturedBlock as FeaturedBlockType } from '$models/Block/FeaturedBlock';
	import type { FormBlock as FormBlockType } from '$models/Block/FormBlock';
	import type { GridBlock as GridBlockType } from '$models/Block/GridBlock';
	import type { ListColumnsBlock as ListColumnsBlockType } from '$models/Block/ListColumnsBlock';
	import type { LogoSliderBlock as LogoSliderBlockType } from '$models/Block/LogoSliderBlock';
	import type { OrderConfiguratorBlock as OrderConfiguratorBlockType } from '$models/Block/OrderConfiguratorBlock';
	import type { PanoramaBlock as PanoramaBlockType } from '$models/Block/PanoramaBlock';
	import type { PlansBlock as PlansBlockType } from '$models/Block/PlansBlock';
	import type { ReviewsBlock as ReviewsBlockType } from '$models/Block/ReviewsBlock';
	import type { TimelineBlock as TimelineBlockType } from '$models/Block/TimelineBlock';
	import type { Page } from '$models/Page/Page';
	import CallToActionBlock from '$components/Block/CallToActionBlock.svelte';
	import CardsBlock from '$components/Block/CardsBlock.svelte';
	import ColumnsBlock from '$components/Block/ColumnsBlock.svelte';
	import ContentBlock from '$components/Block/ContentBlock.svelte';
	import FaqBlock from '$components/Block/FaqBlock.svelte';
	import FeaturedBlock from '$components/Block/FeaturedBlock.svelte';
	import FormBlock from '$components/Block/FormBlock.svelte';
	import GridBlock from '$components/Block/GridBlock.svelte';
	import ListColumnsBlock from '$components/Block/ListColumnsBlock.svelte';
	import LogoSliderBlock from '$components/Block/LogoSliderBlock.svelte';
	import OrderConfiguratorBlock from '$components/Block/OrderConfiguratorBlock.svelte';
	import PanoramaBlock from '$components/Block/PanoramaBlock.svelte';
	import PlansBlock from '$components/Block/PlansBlock.svelte';
	import ReviewsBlock from '$components/Block/ReviewsBlock.svelte';
	import TimelineBlock from '$components/Block/TimelineBlock.svelte';
	import Button from '$components/UI/Buttons/Button.svelte';
	import { sanitizeHtml } from '$lib/utils';

	// Component properties
	export let page: Page;
	export let block: Block;

	// Explicitely downcast the block model and prepare the block css classes
	let cssClasses: string[];
	let panoramaBlock: PanoramaBlockType;
	let contentBlock: ContentBlockType;
	let cardsBlock: CardsBlockType;
	let timelineBlock: TimelineBlockType;
	let orderConfiguratorBlock: OrderConfiguratorBlockType;
	let reviewsBlock: ReviewsBlockType;
	let faqBlock: FaqBlockType;
	let formBlock: FormBlockType;
	let plansBlock: PlansBlockType;
	let featuredBlock: FeaturedBlockType;
	let logoSliderBlock: LogoSliderBlockType;
	let columnsBlock: ColumnsBlockType;
	let listColumnsBlock: ListColumnsBlockType;
	let callToActionBlock: CallToActionBlockType;
	let gridBlock: GridBlockType;
	$: {
		if (block.type === 'panorama') {
			panoramaBlock = block as PanoramaBlockType;
		} else if (block.type === 'content') {
			contentBlock = block as ContentBlockType;
		} else if (block.type === 'cards') {
			cardsBlock = block as CardsBlockType;
		} else if (block.type === 'timeline') {
			timelineBlock = block as TimelineBlockType;
		} else if (block.type === 'order_configurator') {
			orderConfiguratorBlock = block as OrderConfiguratorBlockType;
		} else if (block.type === 'reviews') {
			reviewsBlock = block as ReviewsBlockType;
		} else if (block.type === 'faq') {
			faqBlock = block as FaqBlockType;
		} else if (block.type === 'form') {
			formBlock = block as FormBlockType;
		} else if (block.type === 'plans') {
			plansBlock = block as PlansBlockType;
		} else if (block.type === 'featured') {
			featuredBlock = block as FeaturedBlockType;
		} else if (block.type === 'logo_slider') {
			logoSliderBlock = block as LogoSliderBlockType;
		} else if (block.type === 'columns') {
			columnsBlock = block as ColumnsBlockType;
		} else if (block.type === 'list_columns') {
			listColumnsBlock = block as ListColumnsBlockType;
		} else if (block.type === 'cta') {
			callToActionBlock = block as CallToActionBlockType;
		} else if (block.type === 'grid') {
			gridBlock = block as GridBlockType;
		}
		cssClasses = ['block', `block-${block.type.toLowerCase()}`];
		if (block.htmlClass) {
			cssClasses.push(block.htmlClass);
		}
		if (block.variant) {
			cssClasses.push(`variant-${block.variant}`);
		}
	}
</script>

<div class={cssClasses.join(' ')} style:background-color={block.variantBackgroundColor}>
	{#if block.htmlId}
		<div class="block-anchor" id={block.htmlId} />
	{/if}
	<div class="block-container container">
		{#if block.type != 'featured'}
			{#if block.title || block.subtitle}
				<div class="block-header block-header--align-{block.titleAlignment}">
					{#if block.subtitle}
						<div class="block-subtitle">{block.subtitle}</div>
					{/if}
					{#if block.title}
						<h2 class="block-title">{@html block.title}</h2>
					{/if}
				</div>
			{/if}
		{/if}

		{#if block.type == 'panorama'}
			<PanoramaBlock block={panoramaBlock} />
		{:else if block.type == 'content'}
			<ContentBlock block={contentBlock} />
		{:else if block.type == 'cards'}
			<CardsBlock block={cardsBlock} />
		{:else if block.type == 'timeline'}
			<TimelineBlock block={timelineBlock} />
		{:else if block.type == 'order_configurator'}
			<!-- 
			Make sure this block is reconstructed when page data changes.
			Without this, OrderConfigurators will not be reconstructed on client
			side navigation.
			@TODO: Why do we need the page variable here? Shouldn't it all be in block 
			-->
			{#key page}
				<OrderConfiguratorBlock {page} block={orderConfiguratorBlock} />
			{/key}
		{:else if block.type == 'reviews'}
			<ReviewsBlock block={reviewsBlock} />
		{:else if block.type === 'faq'}
			<FaqBlock block={faqBlock} />
		{:else if block.type === 'form'}
			<FormBlock block={formBlock} />
		{:else if block.type === 'plans'}
			<PlansBlock block={plansBlock} />
		{:else if block.type === 'featured'}
			<FeaturedBlock block={featuredBlock} />
		{:else if block.type === 'logo_slider'}
			<LogoSliderBlock block={logoSliderBlock} />
		{:else if block.type === 'columns'}
			<ColumnsBlock block={columnsBlock} />
		{:else if block.type === 'list_columns'}
			<ListColumnsBlock block={listColumnsBlock} />
		{:else if block.type === 'cta'}
			<CallToActionBlock block={callToActionBlock} />
		{:else if block.type == 'grid'}
			<GridBlock block={gridBlock} />
		{/if}

		{#if block.type != 'plans'}
			{#if block.outro && (block.outro.text || block.outro.button)}
				<div class="block-outro">
					{#if block.outro.text}
						<div class="block-outro-text">
							{@html sanitizeHtml(block.outro.text)}
						</div>
					{/if}

					{#if block.outro.button}
						<div class="block-outro-button">
							<Button type="anchor" href={block.outro.button.url}>
								{block.outro.button.title}
							</Button>
						</div>
					{/if}
				</div>
			{/if}
		{/if}
	</div>
</div>
