<script lang="ts">
	import type { Order } from '$models/Order/Order';
	import type { OrderConfigurator as OrderConfiguratorType } from '$models/OrderConfigurator/OrderConfigurator';
	import type { Subscription } from '$models/Subscription/Subscription';
	import { page } from '$app/stores';
	import OrderConfigurator from '$components/OrderConfigurator/OrderConfigurator.svelte';
	import OrderConfiguratorV2 from '$components/OrderConfigurator/OrderConfiguratorV2.svelte';
	import {
		getCartStore,
		getCheckoutStore,
		getModalStore,
		getToastStore,
		getUserStore
	} from '$lib/stores';
	import { isOrderConfiguratorV2 } from '$lib/type-helpers';
	import { updateSubscription } from '$lib/webparking/subscriptions';

	export let subscription: Subscription;
	export let messages: {
		success: string;
		error: string;
	};
	export let onSuccess: (updatedSubscription: Subscription) => void | undefined;
	export let configurator: OrderConfiguratorType;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const modals = getModalStore();
	const toasts = getToastStore();
	const userStore = getUserStore();

	let isLoading = false;

	async function handleSubmit(order: Order) {
		try {
			isLoading = true;

			subscription.frequency = order.frequency;
			subscription.items = order.products;

			const updatedSubscription = await updateSubscription({
				...subscription,
				nextDeliveryDate: null
			});

			toasts.trigger({
				type: 'success',
				message: messages.success
			});

			if (typeof onSuccess === 'function') {
				onSuccess(updatedSubscription);
			}

			modals.close();
		} catch (error) {
			if (error.response && error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
			toasts.trigger({
				type: 'error',
				message: messages.error
			});
		} finally {
			isLoading = false;
		}
	}
</script>

<div class="modal-body">
	{#if configurator && isOrderConfiguratorV2(configurator)}
		<OrderConfiguratorV2
			{configurator}
			defaultOrder={{
				frequency: subscription.frequency,
				products: subscription.items,
				nonSubscribableProducts: []
			}}
			onSubmit={handleSubmit}
		/>
	{:else}
		<OrderConfigurator
			{configurator}
			translations={$page.data.page.layout.translations}
			defaultSubscription={subscription}
			onSubmit={handleSubmit}
		/>
	{/if}
</div>
