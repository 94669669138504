<script lang="ts">
	import type { ReviewsBlock } from '$models/Block/ReviewsBlock';
	import type { EmblaCarouselType } from 'embla-carousel';
	import ReviewFacebookIcon from '$components/UI/Icons/ReviewFacebookIcon.svelte';
	import ReviewGoogleIcon from '$components/UI/Icons/ReviewGoogleIcon.svelte';
	import StarIcon from '$components/UI/Icons/StarIcon.svelte';
	import Slider from '$components/UI/Slider/Slider.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { formatDate, sanitizeHtml } from '$lib/utils';

	// Components properties
	export let block: ReviewsBlock;

	const site = getSiteContext();

	let reviewsSlider: EmblaCarouselType | undefined;
</script>

<div class="reviews">
	<div class="reviews-header">
		<div class="reviews-header-rating">
			{block.averageRating}
			<div class="reviews-header-stars">
				<StarIcon />
				<StarIcon />
				<StarIcon />
				<StarIcon />
				<StarIcon />
			</div>
		</div>
		<div class="reviews-header-total">
			{@html sanitizeHtml(block.amountOfReviewsText).replace(
				'%reviews_count%',
				block.amountOfReviews
			)}
		</div>
	</div>
</div>
<div class="reviews-slider">
	{#if block.reviews.length > 0}
		<Slider options={{ loop: true, align: 'start' }} let:Slide bind:instance={reviewsSlider}>
			{#each block.reviews as review}
				<Slide>
					<div class="reviews-tile">
						<div class="reviews-tile-content">
							<div class="reviews-tile-header">
								<div class="reviews-tile-stars">
									<StarIcon />
									<StarIcon />
									<StarIcon />
									<StarIcon />
									<StarIcon />
								</div>
								<div class="reviews-tile-platform">
									{#if review.platform == 'google'}
										<ReviewGoogleIcon />
									{/if}
									{#if review.platform == 'facebook'}
										<ReviewFacebookIcon />
									{/if}
								</div>
							</div>
							<div class="reviews-tile-text">
								{#if review.text.length > 148 && !review.showMore}
									{@html sanitizeHtml(review.text.slice(0, 148))}...
									<button
										type="button"
										class="reviews-tile-toggle"
										on:click={() => (review.showMore = true)}
									>
										Lees meer
									</button>
								{:else}
									{@html sanitizeHtml(review.text)}
								{/if}
							</div>
						</div>
						<div class="reviews-tile-footer">
							{#if review.reviewerPhoto}
								<figure class="reviews-tile-image">
									<img src={review.reviewerPhoto} alt={review.reviewerName} />
								</figure>
							{/if}
							<div class="reviews-tile-info">
								<div class="reviews-tile-author">
									{review.reviewerName}
								</div>
								<div class="reviews-tile-date">
									{formatDate(review.createdAt, site.locale.code)}
								</div>
							</div>
						</div>
					</div>
				</Slide>
			{/each}
		</Slider>
	{/if}
	{#if reviewsSlider}
		<button
			class="reviews-slider-arrow reviews-slider-arrow--prev"
			on:click={reviewsSlider.scrollPrev}
		/>
		<button
			class="reviews-slider-arrow reviews-slider-arrow--next"
			on:click={reviewsSlider.scrollNext}
		/>
	{/if}
</div>

<style lang="postcss" global>
	.reviews-header {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: var(--spacing-8);

		&-rating {
			display: flex;
			align-items: center;
			font-size: var(--reviews-header-rating-font-size);
			line-height: var(--font-lineheight-1);
			color: var(--reviews-primary-color);
		}

		&-stars {
			display: flex;
			margin-left: var(--spacing-2);
		}

		&-total {
			font-size: var(--reviews-header-total-font-size);
			color: var(--color-grey-3);
		}
	}

	.reviews-slider {
		& {
			position: relative;
		}

		&:not(:last-child) {
			margin-bottom: var(--spacing-10);
		}

		.slider {
			--slide-spacing: 18px;
			--slide-size: 248px;

			@media (--sm) {
				--slide-size: 50%;
			}

			@media (--lg) {
				--slide-spacing: 24px;
				--slide-size: 33.3333333333%;
			}
		}

		&-arrow {
			position: absolute;
			top: -80px;
			width: 36px;
			height: 36px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background-color: var(--reviews-arrow-color);

			@media (--xxl) {
				top: 64px;
			}

			&:before {
				content: '';
				border-right: 2px solid var(--color-white);
				border-bottom: 2px solid var(--color-white);
				width: 8px;
				height: 8px;
				transform: rotate(-45deg);
			}

			&--prev {
				left: 0;

				@media (--xxl) {
					left: -54px;
				}

				&:before {
					margin-left: 4px;
					transform: rotate(135deg);
				}
			}

			&--next {
				right: 0;

				@media (--xxl) {
					right: -54px;
				}

				&:before {
					margin-right: 4px;
				}
			}
		}
	}

	.reviews-tile {
		& {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
		&-header {
			display: flex;
			align-items: center;
			margin-bottom: var(--spacing-2);
		}
		&-stars {
			display: flex;
			align-items: center;
			color: var(--reviews-primary-color);
		}
		&-platform {
			margin-left: auto;
		}
		&-content {
			position: relative;
			flex: 1;
			border: 1px solid var(--border-color);
			background-color: white;
			padding: var(--spacing-4);
			border-radius: var(--radius-3);
			margin-bottom: var(--spacing-4);

			&:before,
			&:after {
				content: '';
				position: absolute;
				z-index: 1;
				bottom: -11px;
				left: var(--spacing-7);
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
				border-top: 10px solid var(--color-grey-4);
			}

			&:before {
				z-index: 3;
				bottom: -10px;
				border-top-color: white;
			}
		}
		&-text {
			font-size: var(--reviews-tile-font-size);
			line-height: var(--reviews-tile-font-lineheight);
			color: var(--color-grey-2);
		}
		&-toggle {
			color: var(--reviews-tile-toggle-color);
			font-weight: var(--font-weight-5);
			text-decoration: underline;
		}
		&-footer {
			display: flex;
			align-items: center;
			padding-left: var(--spacing-4);
		}
		&-image {
			width: var(--spacing-12);
			height: var(--spacing-12);
			position: relative;
			border-radius: var(--radius-100);
			overflow: hidden;
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		&-info {
			padding-left: var(--spacing-3);
			flex: 1;
			font-size: var(--font-size-2);
		}
		&-author {
			font-family: var(--reviews-tile-title-font-family);
			font-weight: var(--reviews-tile-title-font-weight);
			font-size: var(--reviews-tile-title-font-size);
		}

		&-date {
			font-size: var(--font-size-0);
			color: var(--color-grey-3);
		}
	}
</style>
