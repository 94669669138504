<script lang="ts">
	import type { LogoSliderBlock } from '$models/Block/LogoSliderBlock';
	import type { EmblaCarouselType } from 'embla-carousel';
	import Slider from '$components/UI/Slider/Slider.svelte';

	export let block: LogoSliderBlock;

	let logoSlider: EmblaCarouselType | undefined;
	let scrollSnapList: any[];
	let selectedScrollSnap = 0;

	function initSlider(e) {
		logoSlider = e.detail as EmblaCarouselType;
		scrollSnapList = logoSlider.scrollSnapList();
		// Keep track of snap when it changes
		logoSlider.on('select', (instance) => {
			selectedScrollSnap = instance.selectedScrollSnap();
		});
		// Update snap on resize
		logoSlider.on('resize', (instance) => {
			scrollSnapList = instance.scrollSnapList();
			selectedScrollSnap = instance.selectedScrollSnap();
		});
	}
</script>

<div class="logo-slider">
	<Slider
		autoplay={{}}
		options={{ loop: false, align: 'start', inViewThreshold: 1 }}
		let:Slide
		on:init={initSlider}
	>
		{#each block.logos as logo, index}
			<Slide>
				<div class="logo-slider-item">
					{#if logo.link}
						<a class="logo-slider-link" href={logo.link.url}>
							<figure class="logo-slider-image">
								<img src={logo.image.url} alt={logo.image.alt} />
							</figure>
						</a>
					{:else}
						<figure class="logo-slider-image">
							<img src={logo.image.url} alt={logo.image.alt} />
						</figure>
					{/if}
				</div>
			</Slide>
		{/each}
	</Slider>

	{#if logoSlider}
		<div class="slider-pagination">
			{#each scrollSnapList as snapPosition, index}
				<button
					class="slider-bullet"
					class:is-active={index === selectedScrollSnap}
					on:click={() => logoSlider?.scrollTo(index)}
				/>
			{/each}
		</div>
	{/if}
</div>

<style lang="postcss" global>
	.logo-slider {
		& {
			margin: 0 auto;

			@media (--sm) {
				max-width: 540px;
			}

			@media (--md) {
				max-width: 720px;
			}

			@media (--lg) {
				max-width: 920px;
			}

			@media (--xl) {
				max-width: 100%;
			}
		}

		.slider {
			--slide-size: 100%;

			@media (--sm) {
				--slide-size: 50%;
			}

			@media (--md) {
				--slide-size: 33.3333333333%;
			}

			@media (--lg) {
				--slide-size: 25%;
			}
		}

		&:not(:last-child) {
			margin-bottom: var(--spacing-10);
		}

		&-items {
			margin-bottom: var(--spacing-8);
		}

		&-link {
			display: block;
		}

		&-image {
			width: 100%;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				display: block;
				width: auto;
				height: auto;
				max-width: 75%;
				max-height: 75%;
			}
		}
	}
</style>
