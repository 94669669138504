<script lang="ts">
	import AddressesAccountBlock from '$components/Account/AccountBlock/AddressesAccountBlock.svelte';
	import AffiliateAccountBlock from '$components/Account/AccountBlock/AffiliateAccountBlock.svelte';
	import ChangePasswordAccountBlock from '$components/Account/AccountBlock/ChangePasswordAccountBlock.svelte';
	import CustomerInfoAccountBlock from '$components/Account/AccountBlock/CustomerInfoAccountBlock.svelte';
	import EmailPreferencesAccountBlock from '$components/Account/AccountBlock/EmailPreferencesAccountBlock.svelte';
	import OrderHistoryAccountBlock from '$components/Account/AccountBlock/OrderHistoryAccountBlock.svelte';
	import SubscriptionsAccountBlock from '$components/Account/AccountBlock/SubscriptionsAccountBlock.svelte';
	import TextAccountBlock from './TextAccountBlock.svelte';
	import UrgentOrderAccountBlock from './UrgentOrderAccountBlock.svelte';

	// Component properties
	export let accountBlock;
	export let page: any;
</script>

{#if accountBlock.type == 'addresses'}
	<AddressesAccountBlock {accountBlock} {page} />
{:else if accountBlock.type == 'change-password'}
	<ChangePasswordAccountBlock {accountBlock} {page} />
{:else if accountBlock.type == 'customer-info'}
	<CustomerInfoAccountBlock {accountBlock} {page} />
{:else if accountBlock.type == 'email-preferences'}
	<EmailPreferencesAccountBlock {accountBlock} {page} />
{:else if accountBlock.type == 'orders'}
	<OrderHistoryAccountBlock {accountBlock} />
{:else if accountBlock.type == 'member-get-member'}
	<AffiliateAccountBlock block={accountBlock} />
{:else if accountBlock.type == 'subscriptions'}
	<SubscriptionsAccountBlock {accountBlock} />
{:else if accountBlock.type === 'text'}
	<TextAccountBlock block={accountBlock} />
{:else if accountBlock.type === 'urgent-order'}
	<UrgentOrderAccountBlock block={accountBlock} {page} />
{/if}
