<script lang="ts">
	import type { TimelineBlock } from '$models/Block/TimelineBlock';
	import Button from '$components/UI/Buttons/Button.svelte';
	import { sanitizeHtml } from '$lib/utils';

	// Component properties
	export let block: TimelineBlock;
</script>

<div class="timeline">
	<div class="timeline-container">
		{#if block.image !== null}
			<figure class="timeline-image">
				<img
					src={block.image.url}
					width={block.image.width}
					height={block.image.height}
					alt={block.image.alt}
				/>
			</figure>
		{/if}

		<div class="timeline-main">
			<div class="timeline-items">
				{#each block.items as item}
					<div class="timeline-item">
						<div class="timeline-inner">
							<figure class="timeline-icon" />
							<div class="timeline-content">
								{#if item.title}
									<h3 class="timeline-title">{item.title}</h3>
								{/if}
								{#if item.text}
									<div class="timeline-text">
										{@html sanitizeHtml(item.text)}
									</div>
								{/if}
							</div>
						</div>
					</div>
				{/each}
			</div>

			{#if block.button !== null}
				<div class="timeline-button">
					<Button type="anchor" href={block.button.url}>
						{block.button.title}
					</Button>
				</div>
			{/if}
		</div>
	</div>
</div>

<style lang="postcss" global>
	.timeline {
		&:not(:last-child) {
			margin-bottom: var(--spacing-8);
		}

		&-container {
			display: flex;
			align-items: flex-start;
		}

		&-image {
			display: none;

			@media (--lg) {
				display: block;
				width: 30.86419753%;
				margin-right: var(--spacing-9);
			}

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}

		&-main {
			flex: 1;
		}

		&-items {
			position: relative;

			&:not(:last-child) {
				margin-bottom: var(--spacing-8);
			}

			@media (--md) {
				display: flex;
				flex-direction: column;
			}

			&:after,
			&:before {
				content: '';
				width: var(--timeline-line-size);
				height: 48px;
				display: block;
				margin: 0 auto;
				background-color: var(--timeline-line-color);
			}
		}

		&-item {
			text-align: center;

			@media (--md) {
				width: 50%;
				text-align: right;
			}

			&:not(:last-child) {
				&:after {
					content: '';
					width: var(--timeline-line-size);
					height: 48px;
					display: block;
					margin: 0 auto;
					background: var(--timeline-line-color);

					@media (--md) {
						margin-left: auto;
						margin-right: 0;
					}
				}
			}

			&:nth-child(2n) {
				@media (--md) {
					margin-left: auto;
					text-align: left;

					&:after {
						@media (--md) {
							margin-right: auto;
							margin-left: 0;
						}
					}
				}
			}
		}

		&-inner {
			padding-top: var(--spacing-6);
			padding-bottom: var(--spacing-6);

			@media (--md) {
				display: flex;
				align-items: center;
			}
		}

		&-icon {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 auto var(--spacing-4) auto;
			width: var(--spacing-14);
			height: var(--spacing-14);
			box-sizing: border-box;
			border: var(--timeline-icon-border-width) solid var(--timeline-icon-color);
			border-radius: var(--timeline-icon-border-radius);

			&:before {
				display: block;
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-image: var(--timeline-icon-src);
				background-position: var(--timeline-icon-position);
				background-repeat: no-repeat;
				background-size: var(--timeline-icon-size);
			}

			@media (--md) {
				margin: 0 -28px 0 0;
				order: 2;

				.timeline-item:nth-child(2n) & {
					order: 1;
					margin: 0 0 0 -28px;
				}
			}
		}

		&-content {
			@media (--md) {
				flex: 1;
				padding-right: var(--spacing-8);

				.timeline-item:nth-child(2n) & {
					order: 2;
					padding-right: 0;
					padding-left: var(--spacing-8);
				}
			}
		}

		&-title {
			font-size: var(--timeline-title-size);
			text-transform: var(--timeline-title-transform);
			font-family: var(--timeline-title-font-family);
			font-weight: var(--timeline-title-font-weight);
			margin-bottom: var(--spacing-1);

			@media (--md) {
				font-size: var(--timeline-title-size);
			}
		}

		&-text {
			font-size: var(--timeline-text-size);
			line-height: var(--font-lineheight-2);
		}

		&-button {
			display: flex;
			justify-content: center;
		}
	}
</style>
