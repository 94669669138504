<script lang="ts">
	import type { Navigation } from '$models/Navigation/Navigation';
	import Menu from './Menu.svelte';

	export let active: boolean = false;
	export let navigation: Navigation;
</script>

<nav class="toggle-menu" class:is-active={active}>
	<Menu {navigation} />
</nav>

<style lang="postcss" global>
	.toggle-menu {
		position: absolute;
		z-index: 2;
		top: 0;
		right: var(--spacing-10);
		padding: var(--spacing-5);
		background-color: var(--action-active-color);
		border-top-left-radius: var(--action-border-radius);
		border-bottom-left-radius: var(--action-border-radius);
		border-bottom-right-radius: var(--action-border-radius);
		opacity: 0;
		visibility: hidden;

		&.is-active {
			opacity: 1;
			visibility: visible;
		}

		@media (--xl) {
			display: none;
		}

		ul {
			li {
				white-space: nowrap;
				&:not(:first-child) {
					padding-top: var(--spacing-3);
				}

				&:not(:last-child) {
					padding-bottom: var(--spacing-3);
					border-bottom: 1px solid var(--toggle-menu-border-color);
				}

				a {
					display: block;
					color: var(--color-white);
					font-family: var(--toggle-menu-font-family);
					font-size: var(--toggle-menu-font-size);
					line-height: var(--toggle-menu-font-lineheight);
					text-transform: uppercase;
					font-weight: var(--toggle-menu-font-weight);
				}

				ul {
					padding-top: var(--spacing-3);
					padding-left: var(--spacing-3);
					li {
						&:not(:first-child),
						&:not(:last-child) {
							margin: 0;
							padding: 0;
							border: 0;
						}

						&:not(:last-child) {
							margin-bottom: var(--spacing-2);
						}

						a {
							font-size: 14px;
							text-transform: none;
							font-weight: var(--font-weight-4);
						}
					}
				}
			}
		}
	}
</style>
