<script lang="ts">
	import type { Page } from '$models/Page/Page';
	import { afterNavigate } from '$app/navigation';
	import Footer from '$components/Layout/Footer.svelte';
	import Header from '$components/Layout/Header.svelte';
	import HeaderSpacing from '$components/Layout/HeaderSpacing.svelte';
	import MainLayout from '$components/Layout/MainLayout.svelte';
	import Ribbon from '$components/Layout/Ribbon.svelte';
	import MobileCtaButton from '$components/UI/Buttons/MobileCtaButton.svelte';
	import { trackVirtualPageview } from '$lib/datalayer/datalayer';
	import { getPageComponentData } from '$lib/pages';

	let { page }: { page: Page } = $props();

	let pageComponentData = $derived(getPageComponentData(page));

	afterNavigate(() => {
		if (window && page.metadata.title) {
			trackVirtualPageview(window.location.href, page.metadata.title);
		}
	});
</script>

{#if page.layout.ribbon.length > 0}
	<Ribbon ribbon={page.layout.ribbon} />
{/if}

<MainLayout {page}>
	{#if pageComponentData}
		{#if pageComponentData.layout !== null}
			<pageComponentData.layout
				page={pageComponentData.page}
				pageComponent={pageComponentData.component}
			/>
		{:else}
			<pageComponentData.component page={pageComponentData.page} />
		{/if}
	{:else}
		<!-- TODO: What to do? -->
	{/if}
</MainLayout>

<svelte:head>
	{#if page.metadata.raw}
		{@html page.metadata.raw}
	{/if}
</svelte:head>
