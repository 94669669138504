<script lang="ts">
	import type { Order } from '$models/Order/Order';
	import type { OrderPrices } from '$models/Order/OrderPrices';
	import type { ProductCategory } from '$models/Product/ProductCategory';
	import { page } from '$app/stores';
	import OrderItems from '$components/Order/OrderItems.svelte';
	import ArrowRightIcon from '$components/UI/Icons/ArrowRightIcon.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { getToastStore } from '$lib/stores';
	import {
		calculateTotalProductsQuantity,
		formatCurrency,
		formatQuantityAndFrequencyString
	} from '$lib/utils';
	import { getOrderPrices, getOrderPricesDiscountData } from '$lib/webparking';
	import { onMount, tick } from 'svelte';

	export let order: Order;

	const toasts = getToastStore();
	const site = getSiteContext();

	let productCategory: ProductCategory | null = $page.data.products.regular.find(
		(product) => product.id === order.products[0].id
	)?.category;
	let orderPricesPromise: Promise<OrderPrices> = new Promise(() => {});
	let showCouponForm = false;
	let isSubmittingCoupon = false;
	let couponInput: HTMLElement;
	let couponInputValue = '';

	$: translations = $page.data.page.forms.cart;

	function setOrder(newOrder: Order) {
		order = newOrder;
		orderPricesPromise = getOrderPrices(order);
	}

	async function startCouponInput() {
		showCouponForm = true;
		// The moment this function is run, the coupon input is not
		// created yet and cannot be focused. Wait one Svelte "tick".
		await tick();
		couponInput.focus();
	}

	function cancelCouponInput() {
		couponInputValue = '';
		showCouponForm = false;
	}

	function removeCouponCode() {
		couponInputValue = '';
		setOrder({
			...order,
			couponCode: null
		});
	}

	async function submitCouponForm() {
		if (couponInputValue.length <= 0) {
			return;
		}

		isSubmittingCoupon = true;

		try {
			const orderPrices = await orderPricesPromise;
			const newOrder = {
				...order,
				couponCode: couponInputValue
			};
			const discountData = await getOrderPricesDiscountData(newOrder, orderPrices);

			if (!discountData.isApplicable) {
				toasts.trigger({
					type: 'error',
					message: discountData.noApplicableDiscountReason.message
				});
				return;
			}

			setOrder(newOrder);
			showCouponForm = false;
		} catch (error) {
			toasts.trigger({
				type: 'error',
				message: translations.errors.discountCodeInvalidError
			});
		} finally {
			isSubmittingCoupon = false;
		}
	}

	onMount(() => {
		orderPricesPromise = getOrderPrices(order);
	});
</script>

<div class="summary">
	<div class="summary-row">
		<div class="summary-row-label">
			{translations.amountLabel}
		</div>
		<div class="summary-row-value">
			{formatQuantityAndFrequencyString(
				translations.amountValueLabels,
				calculateTotalProductsQuantity(order.products, $page.data.products.regular),
				order.frequency
			)
				.replaceAll('%product%', productCategory?.translations?.unitNameSingle ?? '%product%')
				.replaceAll('%products%', productCategory?.translations?.unitNamePlural ?? '%products%')}
		</div>
	</div>
	{#if order.products.length > 0}
		<div class="summary-row">
			<div class="summary-row-label">
				{translations.productsLabel}
			</div>
			<div class="summary-row-value">
				<OrderItems items={order.products} />
			</div>
		</div>
	{/if}
	{#if order.nonSubscribableProducts.length > 0}
		<div class="summary-row">
			<div class="summary-row-label">
				{translations.productsLabel}
			</div>
			<div class="summary-row-value">
				<OrderItems items={order.nonSubscribableProducts} />
			</div>
		</div>
	{/if}
	{#await orderPricesPromise then orderPrices}
		{#if orderPrices.discountAmount > 0}
			<div class="summary-row">
				<div class="summary-row-label">
					{translations.priceLabel}
				</div>
				<div class="summary-row-value">
					{#if orderPrices.productsRecurringPrice !== null}
						{formatCurrency(
							orderPrices.productsRecurringPrice + orderPrices.totalNonSubscribableProductsPrice,
							site.locale.code
						)}
					{/if}
				</div>
			</div>
			<div class="summary-row">
				<div class="summary-row-label">
					{translations.discountAmountLabel}
				</div>
				<div class="summary-row-value">
					{#if orderPrices.discountAmount !== null}
						- {formatCurrency(orderPrices.discountAmount, site.locale.code)}
					{/if}
				</div>
			</div>
		{/if}
	{/await}
	<div class="summary-row">
		<div class="summary-row-label">
			{translations.totalLabel}
		</div>
		<div class="summary-row-value">
			{#await orderPricesPromise then orderPrices}
				{#if orderPrices.productsRecurringPrice !== null && orderPrices.discountAmount !== null}
					{formatCurrency(
						orderPrices.productsRecurringPrice +
							orderPrices.totalNonSubscribableProductsPrice -
							orderPrices.discountAmount,
						site.locale.code
					)}
				{/if}
			{/await}
		</div>
	</div>
	<div class="summary-row">
		<div class="summary-row-label">
			{translations.discountCodeLabel}
		</div>
		<div class="summary-row-value">
			<div class="coupon">
				{#if order.couponCode === null}
					{#if showCouponForm}
						<form
							class="coupon-form"
							class:is-loading={isSubmittingCoupon}
							on:submit|preventDefault={submitCouponForm}
						>
							<input
								type="text"
								class="coupon-field"
								bind:this={couponInput}
								bind:value={couponInputValue}
							/>
							<button
								type="submit"
								class="coupon-button"
								disabled={couponInputValue.length <= 0 || isSubmittingCoupon}
							>
								<ArrowRightIcon />
								<span class="hidden">
									{translations.discountCodeSaveLabel}
								</span>
							</button>
							<button type="button" class="coupon-cancel" on:click={cancelCouponInput}>
								{translations.discountCodeCancelLabel}
							</button>
						</form>
					{:else}
						<button type="button" class="coupon-toggle" on:click={startCouponInput}>
							{translations.discountCodeAddLabel}
						</button>
					{/if}
				{:else}
					<div class="coupon-container">
						<span class="coupon-label">
							{order.couponCode}
						</span>
						<button type="button" class="coupon-remove" on:click={removeCouponCode}>
							{translations.discountCodeRemoveLabel}
						</button>
					</div>
				{/if}
			</div>
		</div>
	</div>
</div>

<style lang="postcss" global>
	.summary {
		&:not(:last-child) {
			margin-bottom: var(--spacing-5);
		}

		&-row {
			position: relative;
			padding: var(--spacing-3) 0;
			font-size: var(--summary-font-size-sm);
			line-height: var(--font-lineheight-2);

			@media (--sm) {
				display: flex;
				font-size: var(--summary-font-size);
			}

			&:not(:last-child) {
				border-bottom: 1px solid var(--border-color);
			}

			&-label {
				margin-bottom: var(--spacing-1);

				@media (--sm) {
					width: 40%;
					margin-bottom: 0;
				}
			}

			&-value {
				flex: 1;
				font-weight: var(--summary-label-font-weight);

				ul {
					font-weight: var(--font-weight-4);
				}
			}

			&-edit {
				color: var(--color-primary);
				text-decoration: underline;
				font-weight: var(--font-weight-5);
				align-self: flex-start;
				transition: color var(--transition);
				position: absolute;
				top: var(--spacing-3);
				right: 0;

				@media (--sm) {
					position: relative;
					top: auto;
					right: auto;
				}

				&:hover {
					color: color-mix(in srgb, var(--color-primary) 80%, black);
				}
			}

			&-cancel {
				margin-top: var(--spacing-2);
				color: var(--color-grey-3);
				text-decoration: underline;
				font-weight: var(--font-weight-5);
				transition: color var(--transition);

				&:hover {
					color: var(--color-grey-1);
				}
			}
		}
	}
	.coupon {
		&-toggle {
			color: var(--color-primary);
			text-decoration: underline;
		}
		&-form {
			display: flex;
			flex-wrap: wrap;
			padding-top: var(--spacing-1);
			padding-bottom: var(--spacing-2);
		}
		&-field {
			flex: 1;
			margin-right: 10px;
		}
		&-button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			color: white;
			background-color: var(--color-primary);
		}
		&-cancel {
			display: block;
			margin-top: var(--spacing-2);
			width: 100%;
			font-size: var(--font-size-0);
			text-align: left;
			text-decoration: underline;
			color: var(--color-grey-3);
		}
		&-validation {
			margin-top: var(--spacing-3);
		}
		&-container {
			display: flex;
		}
		&-remove {
			font-size: var(--font-size-0);
			font-weight: var(--font-weight-4);
			color: var(--color-primary);
			text-decoration: underline;
			margin-left: auto;

			&:hover {
				color: var(--color-primary);
				cursor: pointer;
			}
		}
	}
</style>
