<script lang="ts">
	import type { Form } from '$models/Form/Form';
	import FormElement from '$components/Form/FormElement/FormElement.svelte';
	import { getUserStore } from '$lib/stores';
	import { validateForm } from '$lib/utils';
	import { createSession, updateCurrentUser } from '$lib/webparking';
	import { writable } from 'svelte/store';

	// Component properties
	export let form: Form;
	export let onSubmit: any;

	const userStore = getUserStore();

	let formState = writable({
		isHot: false,
		elements: {
			currentPassword: null,
			newPassword: null,
			repeatNewPassword: null
		},
		errors: {
			currentPassword: null,
			newPassword: null,
			repeatNewPassword: null
		},
		serverErrors: {
			currentPassword: null
		},
		setIsHot: (isHot) => {
			$formState.isHot = isHot;
		},
		setError: (elementName, error) => {
			$formState.errors[elementName] = error;
		},
		setServerError: (elementName, error) => {
			$formState.serverErrors[elementName] = error;
		},
		reset: () => {
			Object.keys($formState.elements).forEach((key) => {
				$formState.elements[key].setValue('');
				$formState.errors[key] = null;
				$formState.serverErrors[key] = null;
				if (document.activeElement) {
					document.activeElement.blur();
				}
			});
			$formState.isHot = false;
		}
	});

	function validateRepeatNewPassword(value) {
		if (
			$formState.elements.repeatNewPassword.getValue() !==
			$formState.elements.newPassword.getValue()
		) {
			return form.repeatNewPasswordInvalidError;
		}
		return null;
	}

	function onSubmitInternal() {
		if (!validateForm($formState)) {
			return;
		}

		$userStore.then((user) => {
			createSession({
				email: user.email,
				password: $formState.elements.currentPassword.getValue()
			})
				.then((response) => {
					updateCurrentUser({
						password: $formState.elements.newPassword.getValue()
					}).then(() => {
						$formState.reset();
						onSubmit();
					});
				})
				.catch((error) => {
					$formState.setServerError('currentPassword', form.currentPasswordInvalidError);
				});
		});
	}
</script>

<div class="box">
	<h2 class="box-title">{form.title}</h2>
	<form class="form" on:submit|preventDefault={onSubmitInternal}>
		<div class="form-main">
			<FormElement
				type="input"
				subType="password"
				size="large"
				name="currentPassword"
				label={form.currentPasswordLabel}
				invalidError={form.currentPasswordInvalidError}
				requiredError={form.currentPasswordRequiredError}
				formState={$formState}
				bind:this={$formState.elements.currentPassword}
			/>
			<FormElement
				type="input"
				subType="password"
				size="large"
				name="newPassword"
				label={form.newPasswordLabel}
				requiredError={form.newPasswordRequiredError}
				minLength={8}
				minLengthError={form.newPasswordMinLengthError}
				formState={$formState}
				bind:this={$formState.elements.newPassword}
			/>
			<FormElement
				type="input"
				subType="password"
				size="large"
				name="repeatNewPassword"
				label={form.repeatNewPasswordLabel}
				requiredError={form.repeatNewPasswordRequiredError}
				validator={validateRepeatNewPassword}
				formState={$formState}
				bind:this={$formState.elements.repeatNewPassword}
			/>
			<div class="form-submit">
				<button class="button" type="submit">{form.submitButtonLabel}</button>
			</div>
		</div>
	</form>
</div>
