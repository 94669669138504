<script lang="ts">
	import type { AccountOrdersPage } from '$models/Page/AccountOrdersPage';
	import { browser } from '$app/environment';
	import OrdersTable from '$components/Account/Orders/OrdersTable.svelte';
	import AccountOrderDetailPage from '$components/Page/AccountOrderDetailPage.svelte';
	import Loader from '$components/UI/Loader.svelte';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { listOrders } from '$lib/webparking/orders/orders';
	import { onMount } from 'svelte';

	// Component properties
	export let page: AccountOrdersPage;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();

	let ordersPromise = new Promise(() => {});
	let resourceIdPromise = new Promise(() => {});
	onMount(async () => {
		await $userStore;
		try {
			ordersPromise = listOrders();
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});

	$: {
		if (browser) {
			const url = new URL(window.location);
			page.detailPage.resourceId = url.searchParams.get('resourceId');
			resourceIdPromise = Promise.resolve(page.detailPage.resourceId);
		}
	}
</script>

{#await resourceIdPromise then resourceId}
	{#if resourceId === null}
		<div class="account-box">
			<div class="box">
				<h1 class="box-title">{page.title}</h1>
			</div>
		</div>

		{#await ordersPromise}
			<Loader />
		{:then orders}
			<div class="account-box">
				<div class="box">
					{#if orders.length}
						<OrdersTable {orders} />
					{:else}
						{page.layout.translations.errors.noOrdersFound}
					{/if}
				</div>
			</div>
		{:catch error}
			<div class="error">{page.layout.translations.errors.failedToLoadOrders}</div>
		{/await}
	{:else}
		<AccountOrderDetailPage page={page.detailPage} />
	{/if}
{/await}
