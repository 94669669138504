<script lang="ts">
	// Utility imports
	import { sanitizeHtml } from '$lib/utils';

	export let title: string;
	export let text: string;
	export let buttonUrl: string;
	export let buttonLabel: string;
</script>

<h2 class="box-title">
	{title}
</h2>

<div class="box-text rich-text">
	<p>{@html sanitizeHtml(text)}</p>
	<a href={buttonUrl} class="button">
		{buttonLabel}
	</a>
</div>
