<script lang="ts">
	import type { Page } from '$models/Page/Page';
	import Footer from '$components/Layout/Footer.svelte';
	import Header from '$components/Layout/Header.svelte';
	import HeaderSpacing from '$components/Layout/HeaderSpacing.svelte';
	import MobileCtaButton from '$components/UI/Buttons/MobileCtaButton.svelte';

	let { page, children }: { page: Page; children: any } = $props();
</script>

<Header {page} />

<HeaderSpacing />

{@render children?.()}

<MobileCtaButton {page} />

<Footer layout={page.layout} />
