<script lang="ts">
	import type { Order } from '$models/Order/Order';
	import type { OrderStatusPage } from '$models/Page/OrderStatusPage';
	import type { UserStoreData } from '$models/Store/UserStore';
	import { error } from '@sveltejs/kit';
	import { browser } from '$app/environment';
	import { page as pageStore } from '$app/stores';
	import TrustCard from '$components/Social/TrustCard.svelte';
	import Button from '$components/UI/Buttons/Button.svelte';
	import Loader from '$components/UI/Loader.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { trackCheckoutStepFour } from '$lib/datalayer/datalayer';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { formatDate, sanitizeHtml } from '$lib/utils';
	import { getOrder } from '$lib/webparking';
	import { onMount } from 'svelte';

	// Component properties
	export let page: OrderStatusPage;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();
	const site = getSiteContext();

	let orderStatusPromise = new Promise(() => {});
	let resourceIdPromise = new Promise(() => {});

	let order: Order | null = null;
	let user: UserStoreData | null = null;

	onMount(async () => {
		user = await $userStore;
		try {
			const resourceId = await resourceIdPromise;
			const isUrgentOrder = $pageStore.url.searchParams.has('urgent-order');

			if (!resourceId || typeof resourceId !== 'string') {
				error(404, {
					message: 'Invalid order ID provided'
				});
			}

			order = await getOrder(resourceId as string);

			if (order === undefined || order === null) {
				error(404, {
					message: `Order with ID ${resourceId} not found`
				});
			}

			orderStatusPromise = Promise.resolve({
				deliveryDate: order.deliveryDate,
				deliveryCompanyName: order.deliveryCompanyName
			});
			trackCheckoutStepFour(order, isUrgentOrder, user);
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});

	$: {
		if (browser) {
			const url = new URL(window.location);
			page.resourceId = url.searchParams.get('resourceId');
			resourceIdPromise = Promise.resolve(page.resourceId);
		}
	}
</script>

<div class="block block--flex variant-tint-1">
	<div class="block__container container">
		<div class="box box--width-medium box--padding-medium">
			{#await orderStatusPromise}
				<Loader />
			{:then orderStatus}
				<h2 class="box-title box-title--large">{page.confirmed.title}</h2>
				<div class="order-status">
					<div class="order-status-image">
						<img src={page.confirmed.image.url} alt={page.confirmed.image.alt} />
					</div>
					<div class="order-status-content">
						<div class="rich-text">
							{@html sanitizeHtml(
								page.confirmed.text
									.replaceAll(
										'%delivery_date%',
										formatDate(orderStatus.deliveryDate, site.locale.code)
									)
									.replaceAll('%delivery_company%', orderStatus.deliveryCompanyName)
							)}
						</div>
					</div>
				</div>

				{#await $userStore then user}
					{#if user && order?.paymentStatus === 'paid'}
						<TrustCard {order} {user} />
					{/if}
				{/await}
			{/await}
		</div>
	</div>
</div>

<style lang="postcss">
	.order-status {
		@media (--md) {
			display: flex;
		}

		&-image {
			margin-bottom: var(--spacing-5);
			@media (--md) {
				width: 332px;
				margin-bottom: 0;
			}

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		&-content {
			@media (--md) {
				flex: 1;
				padding-left: var(--spacing-8);
			}
		}
		&-button {
			margin-top: var(--spacing-5);
		}
	}
</style>
