<script>
	import { onMount } from 'svelte';

	let show = false;

	onMount(() => {
		// Only show loader after x ms of waiting
		setTimeout(() => {
			show = true;
		}, 500);
	});
</script>

{#if show}
	<div class="loader" />
{/if}

<style>
	@-webkit-keyframes spin {
		100% {
			transform: rotate(360deg);
			-webkit-transform: rotate(360deg);
			-moz-transform: rotate(360deg);
		}
	}

	@keyframes spin {
		100% {
			transform: rotate(360deg);
			-webkit-transform: rotate(360deg);
			-moz-transform: rotate(360deg);
		}
	}

	.loader {
		width: var(--loader-width);
		height: var(--loader-height);
		background-image: var(--loader-background-image);
		background-size: cover;
		background-repeat: no-repeat;
		animation: spin 1s ease-in-out infinite;

		&:not(:last-child) {
			margin-bottom: var(--spacing-5);
		}
	}
</style>
