<script lang="ts">
	import type { ListColumnsBlock } from '$models/Block/ListColumnsBlock';
	import BeanIcon from '$components/UI/Icons/BeanIcon.svelte';
	import InfoIcon from '$components/UI/Icons/InfoIcon.svelte';

	export let block: ListColumnsBlock;
</script>

<div class="list-columns">
	{#each block.columns as column}
		<div class="list-column">
			<header class="list-column-header">
				<figure class="list-column-icon">
					<BeanIcon />
				</figure>
				<h3 class="list-column-title">
					{#if column.link}
						<a href={column.link.url}>
							{column.title}
						</a>
					{:else}
						{column.title}
					{/if}
				</h3>
			</header>
			<div class="list-column-rows">
				{#each column.rows as row}
					<div class="list-column-row">
						{#if row.title}
							<div class="list-column-subheader">
								<strong class="list-column-subtitle">
									{row.title}
								</strong>
								{#if row.tooltip}
									<div class="list-column-tooltip">
										<div class="list-column-tooltip-trigger">
											<InfoIcon />
											<div class="list-column-tooltip-content">
												{row.tooltip}
											</div>
										</div>
									</div>
								{/if}
							</div>
						{/if}
						<div class="list-column-text">
							{row.text}
						</div>
					</div>
				{/each}
			</div>
		</div>
	{/each}
</div>

<style lang="postcss">
	.list-columns {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
		column-gap: var(--spacing-4);
		row-gap: var(--spacing-8);

		&:not(:last-child) {
			margin-bottom: var(--spacing-10);
		}
	}

	.list-column {
		flex: 1;

		&-header {
			display: flex;
			align-items: center;
			min-height: 54px;
			color: var(--color-primary);
			border-bottom: 2px solid var(--color-primary);
		}

		&-icon {
			max-width: 32px;
			margin-right: var(--spacing-2);
		}

		&-title {
			font-family: var(--list-columns-title-font-family);
			font-size: var(--list-columns-title-font-size);
			font-weight: var(--list-columns-title-font-weight);
			text-transform: var(--list-columns-title-text-transform);
			line-height: var(--list-columns-title-lineheight);
		}

		&-row {
			font-size: var(--font-size-1);
			padding: var(--spacing-4) var(--spacing-3);
			min-height: 100px;
			&:nth-child(2n) {
				background-color: var(--list-columns-row-background);
			}
			&:nth-child(2) {
				min-height: 120px;
			}
		}

		&-subtitle {
			font-family: var(--list-columns-subtitle-font-family);
			font-size: var(--list-columns-subtitle-font-size);
			font-weight: var(--list-columns-subtitle-font-weight);
			text-transform: var(--list-columns-subtitle-text-transform);
		}

		&-text {
			font-size: var(--list-columns-text-font-size);
			line-height: var(--list-columns-text-lineheight);
		}
		&-subheader {
			display: flex;
			align-items: center;
		}
		&-tooltip {
			display: block;
			margin-left: var(--spacing-1);
			&-trigger {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				color: var(--color-primary);

				&:hover {
					cursor: pointer;
					> * {
						opacity: 0.95;
					}
				}
			}
			&-content {
				position: absolute;
				bottom: calc(100% + 12px);
				left: 50%;
				transform: translateX(-50%);
				min-width: 200px;
				border-radius: var(--radius-2);
				padding: var(--spacing-3);
				color: white;
				font-size: 12px;
				line-height: 1.25;
				font-weight: var(--font-weight-5);
				background-color: var(--color-grey-2);
				text-align: center;
				opacity: 0;
				transition: opacity var(--transition);

				&:after {
					content: '';
					position: absolute;
					bottom: -8px;
					left: 50%;
					transform: translateX(-50%);
					border-left: 8px solid transparent;
					border-right: 8px solid transparent;
					border-top: 8px solid var(--color-grey-2);
				}
			}
		}
	}
</style>
