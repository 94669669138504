<script lang="ts">
	import type { OrderConfigurator } from '$models/OrderConfigurator/OrderConfigurator';
	import type { AllInOneStep as AllInOneStepType } from '$models/OrderConfigurator/Step/AllInOneStep';
	import type { FrequencySelectorStep as FrequencySelectorStepType } from '$models/OrderConfigurator/Step/FrequencySelectorStep';
	import type { ProductSelectorStep as ProductSelectorStepType } from '$models/OrderConfigurator/Step/ProductSelectorStep';
	import type { StepType } from '$models/OrderConfigurator/Step/Step';
	import AllInOneStep from '$components/OrderConfigurator/Step/AllInOneStep.svelte';
	import FrequencySelectorStep from '$components/OrderConfigurator/Step/FrequencySelectorStep.svelte';
	import ProductSelectorStep from '$components/OrderConfigurator/Step/ProductSelectorStep.svelte';

	// Component properties
	export let translations: any;
	export let configurator: OrderConfigurator;
	export let configuratorStore: any;
	export let step: StepType;
	export let index: number;

	// Explicitely downcast the step model
	let allInOneStep: AllInOneStepType;
	let frequencySelectorStep: FrequencySelectorStepType;
	let productSelectorStep: ProductSelectorStepType;
	let productAmount: number = 0;
	$: {
		if (step.type === 'allInOne') {
			allInOneStep = step as AllInOneStepType;
		} else if (step.type === 'frequencySelector') {
			frequencySelectorStep = step as FrequencySelectorStepType;
		} else if (step.type === 'productSelector') {
			productSelectorStep = step as ProductSelectorStepType;
		}
		productAmount = 0;
		configuratorStore.getEntities('product').forEach((product) => {
			productAmount += configuratorStore.getSelectedEntityAmount('product', product.id);
		});
		if (
			configuratorStore.steps[index].type === 'frequencySelector' &&
			productAmount === 0 &&
			!configuratorStore.steps[index].isHidden
		) {
			configuratorStore.hideStep(index);
		}
	}

	// @TODO: This should ideally be handled with refs and/or actions
	const entityEventHandlers = {
		onInputChange: (event, product) => {
			const productElement = event.target.closest('[data-product-external-id]');
			const input = productElement.querySelector('input');

			if (!/^\d+$/.test(input.value)) {
				input.value = 0;
			}

			configuratorStore.setEntityAmount(
				step.entityType,
				productElement.dataset.productExternalId,
				parseInt(input.value)
			);

			configuratorStore.setEntityErrorText(step.entityType);
		},
		onDecreaseButtonClick: (event, product) => {
			const productElement = event.target.closest('[data-product-external-id]');
			const input = productElement.querySelector('input');
			const quantityMultiplier = product.quantityMultiplier || 1;

			if (input.value !== '0') {
				input.value = parseInt(input.value) - quantityMultiplier;
			}

			configuratorStore.setEntityAmount(step.entityType, product.id, parseInt(input.value));

			configuratorStore.setEntityErrorText(step.entityType);
		},
		onIncreaseButtonClick: (event, product) => {
			const productElement = event.target.closest('[data-product-external-id]');
			const input = productElement.querySelector('input');
			const quantityMultiplier = product.quantityMultiplier || 1;

			input.value = parseInt(input.value) + quantityMultiplier;

			configuratorStore.setEntityAmount(step.entityType, product.id, parseInt(input.value));

			configuratorStore.setEntityErrorText(step.entityType);
		}
	};
</script>

{#if !configuratorStore.steps[index].isHidden}
	<div id={step.htmlId} class="step-section" data-step-index={index}>
		{#if step.title || step.subTitle}
			<div class="step-section-header">
				{#if step.title}
					<div class="step-section-title">
						{step.title}
					</div>
				{/if}
				{#if step.subTitle}
					<h3 class="step-section-subtitle">
						{step.subTitle}
					</h3>
				{/if}
			</div>
		{/if}
		{#if step.type == 'allInOne'}
			<AllInOneStep
				{translations}
				{configurator}
				{configuratorStore}
				step={allInOneStep}
				{index}
				{entityEventHandlers}
			/>
		{:else if step.type == 'frequencySelector'}
			<FrequencySelectorStep
				{translations}
				{configurator}
				{configuratorStore}
				step={frequencySelectorStep}
				{index}
				{entityEventHandlers}
			/>
		{:else if step.type == 'productSelector'}
			<ProductSelectorStep
				{translations}
				{configurator}
				{configuratorStore}
				step={productSelectorStep}
				{index}
				{entityEventHandlers}
			/>
		{/if}
	</div>
{/if}
