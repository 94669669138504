<script lang="ts">
	import type { Form } from '$models/Form/Form';
	import FormElement from '$components/Form/FormElement/FormElement.svelte';
	import { getCartStore, getCheckoutStore, getToastStore, getUserStore } from '$lib/stores';
	import { validateForm } from '$lib/utils';
	import {
		createSession,
		getCurrentUser,
		getOrderPrices,
		getOrderPricesDiscountData,
		listOrders
	} from '$lib/webparking';
	import { onMount } from 'svelte';
	import { writable } from 'svelte/store';

	// Component properties
	export let form: Form;
	export let notice: string;
	export let showForgotPasswordForm: any;
	export let showRegisterForm: any;
	export let initialEmail: string | null;
	export let extraForm: any;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();
	const toastStore = getToastStore();

	let formState = writable({
		isHot: false,
		elements: {
			email: null,
			password: null
		},
		errors: {
			email: null,
			password: null,
			submit: null
		},
		setIsHot: (isHot) => {
			$formState.isHot = isHot;
		},
		setError: (elementName, error) => {
			$formState.errors[elementName] = error;
		}
	});

	onMount(() => {
		if (initialEmail !== null) {
			$formState.elements.email.setValue(initialEmail);
		}
	});

	async function onSubmit() {
		let isExtraFormValid = true;
		let couponCodeValue = null;
		if (extraForm !== null && extraForm !== undefined) {
			if (extraForm.isEditing()) {
				[isExtraFormValid, couponCodeValue] = await extraForm.submit(null, false);
			}
		}
		if (!validateForm($formState) || !isExtraFormValid) {
			return;
		}

		try {
			const cart = await $cartStore;
			if (cart !== null && couponCodeValue !== null) {
				cart.couponCode = couponCodeValue;
				cartStore.setCart(cart);
			}

			await createSession({
				email: $formState.elements.email.getValue(),
				password: $formState.elements.password.getValue()
			});
			const user = await getCurrentUser();
			checkoutStore.clearCheckout();
			userStore.setUser(user);

			// After successful login, validate the coupon code  to see if is valid
			if (cart !== null && cart.couponCode) {
				try {
					const orderPrices = await getOrderPrices(cart);
					const discountData = await getOrderPricesDiscountData(cart, orderPrices);
					if (!discountData.isApplicable) {
						toastStore.trigger({
							message: discountData.noApplicableDiscountReason.message,
							type: 'error'
						});
						// Remove the invalid coupon code
						cart.couponCode = null;
						cartStore.setCart(cart);
					}
				} catch (error) {
					console.error('Error validating coupon code:', error);
				}
			}

			if (cart === null) {
				const orders = await listOrders({
					uncompletedOnly: true
				});
				const latestUncompletedOrder = orders.reduce((accumulator, currentValue) => {
					if (accumulator === null) {
						return currentValue;
					}
					if (currentValue.index > accumulator.index) {
						return currentValue;
					}
					return accumulator;
				}, null);
				if (latestUncompletedOrder !== null) {
					cartStore.setCart(latestUncompletedOrder);
				}
			}
		} catch (error) {
			$formState.errors.submit = form.submitFailedError;
		}
	}
</script>

<form class="form" on:submit|preventDefault={onSubmit}>
	<div class="form-main">
		{#if $formState.errors.submit}
			<div class="form-error">
				{$formState.errors.submit}
			</div>
		{/if}
		<FormElement
			type="input"
			subType="email"
			size="large"
			name="email"
			label={form.emailLabel}
			requiredError={form.emailRequiredError}
			invalidError={form.emailInvalidError}
			formState={$formState}
			bind:this={$formState.elements.email}
		/>
		<FormElement
			type="input"
			subType="password"
			size="large"
			name="password"
			label={form.passwordLabel}
			requiredError={form.passwordRequiredError}
			formState={$formState}
			bind:this={$formState.elements.password}
		/>
	</div>
	{#if showRegisterForm !== undefined && showRegisterForm !== null}
		<div class="form-sidebar">
			<div class="form-toggle">
				<div class="form-toggle-label">
					{form.registerLabel}
				</div>

				<span class="form-toggle-link" on:click={showRegisterForm}>{form.registerButtonLabel}</span>
			</div>
		</div>
	{/if}
	<div class="form-submit">
		<button class="button" type="submit">{form.submitButtonLabel}</button>
	</div>
	<div class="form-row">
		<button class="form-toggle-link" on:click={showForgotPasswordForm}>
			{form.forgotPasswordButtonLabel}
		</button>
	</div>
</form>
