<script lang="ts">
	import type { Order } from '$models/Order/Order';
	import { goto } from '$app/navigation';
	import { page as pageStore } from '$app/stores';
	import CheckoutCustomerInfoSummary from '$components/Checkout/CheckoutCustomerInfoSummary.svelte';
	import CheckoutDelivery from '$components/Checkout/CheckoutDelivery.svelte';
	import CheckoutPaymentMethod from '$components/Checkout/CheckoutPaymentMethod.svelte';
	import CheckoutSidebar from '$components/Checkout/CheckoutSidebar.svelte';
	import CheckoutSteps from '$components/Checkout/CheckoutSteps.svelte';
	import Form from '$components/Form/Form.svelte';
	import OrderConfigurator from '$components/OrderConfigurator/OrderConfigurator.svelte';
	import OrderConfiguratorV2 from '$components/OrderConfigurator/OrderConfiguratorV2.svelte';
	import InfoBlock from '$components/UI/InfoBlock.svelte';
	import { getSiteContext } from '$lib/context/site';
	import {
		trackCheckoutStepOne,
		trackCheckoutStepThree,
		trackCheckoutStepTwo
	} from '$lib/datalayer/datalayer';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { isOrderConfiguratorV2 } from '$lib/type-helpers';
	import { formatDate } from '$lib/utils';
	import { createOrder, getOrderPrices, listOrders, updateOrder } from '$lib/webparking';
	import { onMount } from 'svelte';
	import { derived } from 'svelte/store';

	// Component properties
	export let page: any;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();
	const trackingStore = derived([cartStore, checkoutStore, userStore], async (stores) => {
		const [cart, checkout, user] = await Promise.all(stores);

		if (user === null) {
			return {
				step: 1,
				isPaymentMethodSubmitted: checkout.isPaymentMethodSubmitted,
				cart
			};
		}

		return {
			step: checkout.currentStep,
			isPaymentMethodSubmitted: checkout.isPaymentMethodSubmitted,
			cart
		};
	});
	const site = getSiteContext();

	// Keep track of the checkout page and possible changes, and track it.
	onMount(async () => {
		let trackedSteps: number[] = [];

		trackingStore.subscribe(async (trackingDataPromise) => {
			const { step, cart, isPaymentMethodSubmitted } = await trackingDataPromise;
			if (!trackedSteps.includes(step) && !isRedirecting && !isPaymentMethodSubmitted) {
				trackedSteps = [...trackedSteps, step];

				switch (step) {
					case 1:
						trackCheckoutStepOne(cart);
						break;
					case 2:
						trackCheckoutStepTwo(cart);
						break;
					case 3:
						trackCheckoutStepThree(cart);
						break;
				}
			}
		});
	});

	function onCustomerInfoSubmit(formState, [user, billingAddress, shippingAddress]) {
		$checkoutStore.then((checkout) => {
			$cartStore.then((cart) => {
				if (cart.id === null) {
					createOrder({
						billingAddressId: billingAddress.id,
						shippingAddressId: shippingAddress.id,
						frequency: cart.frequency,
						products: cart.products,
						nonSubscribableProducts: cart.nonSubscribableProducts,
						deliveryNote: cart.deliveryNote,
						poNumber: cart.poNumber
					})
						.then((order) => {
							cartStore.setCart({
								...cart,
								id: order.id,
								billingAddressId: billingAddress.id,
								shippingAddressId: shippingAddress.id,
								deliveryDate: order.deliveryDate.toISOString(),
								deliveryCompanyName: order.deliveryCompanyName
							});
							checkoutStore.setCheckout({
								...checkout,
								currentStep: 3
							});
						})
						.catch((error) => {
							if (error.response.status === 401) {
								cartStore.anonymizeCart(cart);
								checkoutStore.clearCheckout();
								userStore.setUser(null);
							}
						});
				} else {
					updateOrder({
						id: cart.id,
						billingAddressId: billingAddress.id,
						shippingAddressId: shippingAddress.id,
						frequency: cart.frequency,
						products: cart.products,
						nonSubscribableProducts: cart.nonSubscribableProducts,
						deliveryNote: cart.deliveryNote,
						poNumber: cart.poNumber
					})
						.then((order) => {
							cartStore.setCart({
								...cart,
								id: order.id,
								billingAddressId: billingAddress.id,
								shippingAddressId: shippingAddress.id,
								deliveryDate: order.deliveryDate.toISOString(),
								deliveryCompanyName: order.deliveryCompanyName
							});
							checkoutStore.setCheckout({
								...checkout,
								currentStep: 3
							});
						})
						.catch((error) => {
							if (error.response.status === 401) {
								cartStore.anonymizeCart(cart);
								checkoutStore.clearCheckout();
								userStore.setUser(null);
							}
						});
				}
			});
		});
	}

	let deliveryDate = null;
	let deliveryCompanyName = null;
	let orderPricesPromise = new Promise(() => {});
	let isCheckoutLoading = true;
	let isOrderConfiguratorVisible = false;
	let currentStep = 2;
	let isOrderStatusChecked = false;
	let isRedirecting = false;
	let orderStatusPromise = new Promise(() => {});
	let checkoutSidebarElement = null;

	function getOrderConfigurator(cart) {
		const product = $pageStore.data.products.regular.find((p) => p.id === cart.products[0].id);

		return page.layout.configurators.cartEdit.find((c) => {
			if (isOrderConfiguratorV2(c)) {
				return c.configuration.categories.find(
					({ productCategory }) => productCategory.id === product.category.id
				);
			}

			// For V1 configurators
			return c.productCategory?.id === product.category.id;
		});
	}

	function hideOrderConfigurator(order: Order, cart?) {
		let newOrder = order;

		// Cart is passed in for V2 configurators
		if (cart) {
			// Make sure we keep values from cart, they could also come from external order
			// Only overwrite values returned from OrderConfiguratorV2, which should be
			// of the type OrderConfigurationOrder.
			// @TODO: Refactor to OrderConfigurationOrder after v2 is released
			newOrder = {
				...cart,
				...order
			};
		}
		cartStore.setCart(newOrder);
		syncOrder();
		isOrderConfiguratorVisible = false;
	}

	function showCartConfigurator() {
		isOrderConfiguratorVisible = true;
	}

	// This function was created from a reactive declaration that would trigger
	// way too frequently. The only real dependency that it has to watch out for
	// is the cartStore, so it is the only parameter to this function.
	// Everything else inside this function body is not reactive.
	async function parseCart(cartStorePromise) {
		const cart = await $cartStore;
		try {
			if (cart !== null) {
				orderPricesPromise = getOrderPrices(cart);
				if (cart.deliveryDate) {
					deliveryDate = formatDate(new Date(cart.deliveryDate), site.locale.code);
				}
				deliveryCompanyName = cart.deliveryCompanyName;

				if (cart.id !== null && !isOrderStatusChecked) {
					listOrders()
						.then((orders) => {
							const order = orders.find((order) => order.id === cart.id);
							const paymentMethod = $pageStore.url.searchParams.get('payment_method');

							if (order === undefined) {
								throw new Error('Page not found'); // TODO HANDLE 404 ERROR THROWN FROM COMPONENTS AUTOMATICALLY
							}

							if (
								order.paymentStatus === 'paid' ||
								(order.paymentStatus === 'in_progress' && paymentMethod === 'banktransfer')
							) {
								isRedirecting = true;
								cartStore.clearCart();
								checkoutStore.clearCheckout();
								goto(page.layout.pages.orderStatusPage + '/?resourceId=' + cart.id);
							} else {
								isOrderStatusChecked = true;
							}
						})
						.catch((error) => {
							if (error.response.status === 401) {
								cartStore.anonymizeCart(cart);
								checkoutStore.clearCheckout();
								userStore.setUser(null);
							}
						});
				} else {
					isOrderStatusChecked = true;
				}
			} else {
				isOrderStatusChecked = true;
			}

			$checkoutStore.then((checkout) => {
				isCheckoutLoading = false;
				currentStep = checkout.currentStep;
			});
		} catch (error) {
			if (error.response.status === 401) {
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	}

	async function setCheckoutStep(checkoutStore) {
		const checkout = await checkoutStore;

		if (checkout && checkout.currentStep) {
			currentStep = checkout.currentStep;
		}
	}

	// Syncs the local cart data to an external order. This is mostly useful
	// for keeping track of things like deliveryDate and shippingProvider as they
	// could differ based on the size and/or contents of the order.
	// A final order update is always done when submitting the checkout, so error
	// handling here is not critical.
	async function syncOrder() {
		const cart = await $cartStore;
		const orderData = {
			billingAddressId: cart.billingAddressId,
			shippingAddressId: cart.shippingAddressId,
			frequency: cart.frequency,
			products: cart.products,
			nonSubscribableProducts: cart.nonSubscribableProducts,
			deliveryNote: cart.deliveryNote,
			poNumber: cart.poNumber
		};
		let order;

		try {
			if (cart?.id) {
				order = await updateOrder({
					id: cart.id,
					...orderData
				});
			} else {
				order = await createOrder(orderData);
			}

			if (order && cart) {
				cartStore.setCart({
					...cart,
					id: order.id,
					deliveryDate: order.deliveryDate ? order.deliveryDate.toISOString() : null,
					deliveryCompanyName: order.deliveryCompanyName || null
				});
			}
		} catch (error) {
			// On a 401 (unauthorized) error, the user's session has expired, so we need to clear the cart and redirect to login.
			if (error.response && error.response.status === 401) {
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	}

	$: parseCart($cartStore);
	$: setCheckoutStep($checkoutStore);
</script>

<div class="block block--flex variant-tint-1">
	<div class="block__container container">
		{#await $cartStore then cart}
			{#if isOrderStatusChecked && !isRedirecting}
				{#if cart === null}
					<div class="box box--width-medium box--padding-medium">
						<InfoBlock
							title={page.cartEmptyTitle}
							text={page.cartEmptyText}
							buttonUrl={page.cartEmptyButtonUrl}
							buttonLabel={page.cartEmptyButtonLabel}
						/>
					</div>
				{:else}
					{#await $userStore then user}
						{#if !isCheckoutLoading}
							<CheckoutSteps {page} />
							{#if isOrderConfiguratorVisible}
								{@const cartConfigurator = getOrderConfigurator(cart)}
								<div class="box box--width-medium box--padding-medium">
									{#if cartConfigurator && isOrderConfiguratorV2(cartConfigurator)}
										<OrderConfiguratorV2
											configurator={cartConfigurator}
											onSubmit={(order) => hideOrderConfigurator(order, cart)}
											defaultOrder={cart}
										/>
									{:else}
										<OrderConfigurator
											translations={page.layout.translations}
											configurator={cartConfigurator}
											defaultOrder={cart}
											onSubmit={hideOrderConfigurator}
										/>
									{/if}
								</div>
							{:else}
								<div class="checkout-container">
									<div class="checkout-main">
										{#if user === null}
											<Form form={page.forms.auth} extraForm={checkoutSidebarElement} />
										{:else if currentStep == 2}
											<Form
												form={page.forms.customerInfo}
												onSubmit={onCustomerInfoSubmit}
												extraForm={checkoutSidebarElement}
											/>
										{:else if currentStep === 3}
											<div>
												<CheckoutCustomerInfoSummary {page} />
												<CheckoutDelivery {page} />
											</div>
										{/if}
									</div>
									<div class="checkout-sidebar">
										<CheckoutSidebar
											bind:this={checkoutSidebarElement}
											{page}
											{orderPricesPromise}
											{showCartConfigurator}
										/>
										{#if user != null && currentStep === 3}
											<CheckoutPaymentMethod
												{page}
												{orderPricesPromise}
												extraForm={checkoutSidebarElement}
											/>
										{/if}
									</div>
								</div>
							{/if}
						{/if}
					{/await}
				{/if}
			{/if}
		{/await}
	</div>
</div>

<style lang="postcss" global>
	.checkout {
		&-container {
			position: relative;

			@media (--lg) {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
			}
		}

		&-main {
			margin-bottom: var(--spacing-6);
			@media (--lg) {
				width: calc(60% - var(--spacing-3));
				margin-bottom: 0;
			}
		}

		&-sidebar {
			@media (--lg) {
				position: sticky;
				top: var(--spacing-6);
				bottom: 0;
				width: calc(40% - var(--spacing-3));
			}
		}
		&-thankyou {
			@media (--md) {
				display: flex;
			}

			&-image {
				margin-bottom: var(--spacing-5);
				@media (--md) {
					width: 332px;
					margin-bottom: 0;
				}

				img {
					display: block;
					width: 100%;
					height: auto;
				}
			}
			&-content {
				@media (--md) {
					flex: 1;
					padding-left: var(--spacing-8);
				}
			}
		}
	}
</style>
