<script lang="ts">
	import type { AccountPageItem } from '$models/Page/AccountBasePage';
	import { goto } from '$app/navigation';
	import { page } from '$app/stores';
	import { getCartStore, getCheckoutStore, getToastStore, getUserStore } from '$lib/stores';
	import { deleteCurrentSession } from '$lib/webparking';

	// Component properties
	export let links: AccountPageItem[];

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const toastStore = getToastStore();
	const userStore = getUserStore();

	function onLogoutButtonClick() {
		goto('/').then(() => {
			try {
				deleteCurrentSession();
			} catch (error) {}
			cartStore.clearCart();
			checkoutStore.clearCheckout();
			userStore.setUser(null);
			toastStore.trigger({
				message: 'Je bent succesvol uitgelogd.',
				type: 'success'
			});
		});
	}
</script>

{#await $userStore then user}
	<div class="box">
		<div class="account-nav">
			<div class="account-nav-label">
				{$page.data.page.welcomeText.replaceAll('%first_name%', user.firstName)}
			</div>
			<div class="account-nav-menu">
				{#each links as link, i}
					<!-- TODO: Maybe think of something better? -->
					<!-- TODO: This is the second time we encountered this abomination -->
					{#if link.order < 999}
						<a
							href={link.link}
							class="account-nav-link"
							class:is-active={$page.url.pathname === link.link}
						>
							{link.title}
						</a>
					{/if}
				{/each}
				<button class="account-nav-link" on:click={onLogoutButtonClick}>
					{$page.data.page.logoutButtonLabel}
				</button>
			</div>
		</div>
	</div>
{/await}

<style lang="postcss">
	.account-nav {
		& {
			font-size: var(--account-nav-font-size);
			font-family: var(--account-nav-font-family);
			font-weight: var(--account-nav-font-weight);
			line-height: var(--font-lineheight-0);

			@media (--lg) {
				display: flex;
				align-items: center;
			}
		}

		&-label {
			display: block;
			font-size: var(--account-nav-label-font-size);
			font-family: var(--account-nav-label-font-family);
			font-weight: var(--account-nav-label-font-weight);
			text-transform: var(--account-nav-label-text-transform);
			margin-bottom: var(--spacing-4);

			@media (--lg) {
				display: flex;
				align-items: center;
				margin-bottom: 0;
			}
		}

		&-menu {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			@media (--md) {
				flex-direction: row;
			}

			@media (--lg) {
				margin-left: auto;
			}
		}

		&-link {
			color: var(--account-nav-font-color);
			font-weight: inherit;
			text-transform: var(--account-nav-text-transform);
			border-bottom: 1px solid transparent;
			transition:
				color var(--transition),
				border-color var(--transition);

			&:not(:last-child) {
				margin-bottom: var(--spacing-3);

				@media (--md) {
					margin-bottom: 0;
					margin-right: var(--spacing-8);
				}
			}

			&:hover {
				border-color: var(--account-nav-font-color);
			}

			&.is-active {
				color: var(--color-primary);
				border-color: var(--color-primary);
			}
		}
	}
</style>
