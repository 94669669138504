import type { Order, OrderItem } from '$models/Order/Order';
import type { Product } from '$models/Product/Product';
import type { Subscription } from '$models/Subscription/Subscription';
import type { IOptions } from 'sanitize-html';
import { format } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { nl } from 'date-fns/locale/nl';
import sanitizeHtmlLib from 'sanitize-html';
import { getSiteContext } from './context/site';

const SANITIZE_HTML_OPTIONS: IOptions = {
	allowedTags: [
		'a',
		'br',
		'em',
		'h1',
		'h2',
		'h3',
		'h4',
		'img',
		'li',
		'p',
		'strong',
		'b',
		'ul',
		'ol',
		'details',
		'summary',
		'table',
		'tbody',
		'tr',
		'td'
	],
	allowedAttributes: {
		'*': ['class', 'style'],
		a: ['href', { name: 'target', values: ['_blank'] }],
		img: ['alt', 'width', 'height', 'src', 'class']
	}
};

export function sanitizeHtml(html: string): string {
	return sanitizeHtmlLib(html, SANITIZE_HTML_OPTIONS);
}

export function calculateTotalProductsQuantity(items: OrderItem[], products: Product[]): number {
	return items.reduce((total: number, item: OrderItem) => {
		const product = products.find((product) => product.id === item.id);
		const quantityMultiplier = product?.quantityMultiplier ?? 1;

		if (!product) {
			return total;
		}

		return total + item.quantity * quantityMultiplier;
	}, 0);
}

export function validateQuantity(
	allowedQuantities: number[],
	quantity: number
): {
	isValid: boolean;
	nextValid: number | false;
	previousValid: number | false;
} {
	const higherQuantities = allowedQuantities.filter((q) => q > quantity).sort((a, b) => a - b);
	const lowerQuantities = allowedQuantities.filter((q) => q < quantity).sort((a, b) => b - a);

	return {
		isValid: allowedQuantities.includes(quantity),
		nextValid: higherQuantities.length ? higherQuantities[0] : false,
		previousValid: lowerQuantities.length ? lowerQuantities[0] : false
	};
}

/*
 * TODO: This only contains the bare minimum order data for now
 */
export function subscriptionToOrder(subscription: Subscription): Order {
	return {
		frequency: subscription.frequency,
		products: subscription.items,
		nonSubscribableProducts: [], // Never available on subscriptions
		billingAddressId: subscription.billingAddressId,
		shippingAddressId: subscription.shippingAddressId,
		deliveryNote: subscription.remarks,
		poNumber: subscription.poNumber,
		couponCode: null // Not available on subscription, has to be set manually
	};
}

export function formatNumericString(
	single: string,
	multiple: string,
	value: number,
	token: string
): string {
	if (value === 1) {
		return single.replaceAll(token, value);
	}
	return multiple.replaceAll(token, value);
}

export function formatQuantitySelectorString(
	labels: any,
	quantities: number[],
	value: number,
	token: string,
	tokenAdd: string,
	tokenRemove: string
) {
	let nearestBelow = null;
	let nearestAbove = null;
	for (const quantity of quantities) {
		if (quantity < value) {
			nearestBelow = quantity;
		} else {
			nearestAbove = quantity;
			break;
		}
	}
	if (nearestBelow !== null) {
		if (nearestAbove !== null) {
			if (value - nearestBelow === 1) {
				if (nearestAbove - value === 1) {
					return labels.addSingleOrRemoveSingle;
				} else {
					return labels.addMultipleOrRemoveSingle.replaceAll(token, nearestAbove - value);
				}
			} else if (nearestAbove - value === 1) {
				return labels.addSingleOrRemoveMultiple.replaceAll(token, value - nearestBelow);
			} else {
				return labels.addMultipleOrRemoveMultiple
					.replaceAll(tokenAdd, nearestAbove - value)
					.replaceAll(tokenRemove, value - nearestBelow);
			}
		} else {
			return formatNumericString(
				labels.removeSingle,
				labels.removeMultiple,
				value - nearestBelow,
				token
			);
		}
	} else if (nearestAbove !== null) {
		return formatNumericString(labels.addSingle, labels.addMultiple, nearestAbove - value, token);
	}
}

export function formatQuantityAndFrequencyString(labels: any, quantity: number, frequency: number) {
	if (frequency === 0) {
		return formatNumericString(
			labels.quantitySingleFrequencyNone,
			labels.quantityMultipleFrequencyNone,
			quantity,
			'%quantity%'
		);
	} else if (frequency === 1) {
		return formatNumericString(
			labels.quantitySingleFrequencySingle,
			labels.quantityMultipleFrequencySingle,
			quantity,
			'%quantity%'
		);
	} else if (quantity === 1) {
		return labels.quantitySingleFrequencyMultiple.replaceAll('%frequency%', frequency);
	}
	return labels.quantityMultipleFrequencyMultiple
		.replaceAll('%quantity%', quantity)
		.replaceAll('%frequency%', frequency);
}

export function parseIntWithFallback(value, fallback) {
	if (/^\d+$/.test(value)) {
		return parseInt(value);
	}
	return fallback;
}

export function formatDateOld(date: string, monthNames): string {
	// TODO: Use date-fns package, maybe forget about month names?
	const dateParts = date.split('T')[0].split('-');
	return `${dateParts[2]} ${monthNames[parseInt(dateParts[1]) - 1]} ${dateParts[0]}`;
}

export function formatDate(date: Date, locale: string, formatString: string = 'd LLLL y'): string {
	switch (locale) {
		case 'de_DE':
			return format(date, formatString, { locale: de });
		case 'nl_NL':
		default:
			return format(date, formatString, { locale: nl });
	}
}

export function formatCurrency(price: number, locale: string | undefined = undefined): string {
	switch (locale) {
		case 'de_DE':
			locale = 'de-DE';
			break;
		case 'nl_NL':
		default:
			locale = 'nl-NL';
			break;
	}

	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: 'EUR'
	}).format(price);
}

export function formatPercentage(percentage: number): string {
	return percentage + '%';
}

export function getSlugFromPath(path: string): string {
	if (path.endsWith('/')) {
		path = path.slice(0, -1);
	}
	if (path.length === 0) {
		path = 'home';
	}
	const slug = path.split('/');
	return slug[slug.length - 1];
}

export function getParentSlugFromPath(path: string): string | null {
	// Remove trailing slash if present
	if (path.endsWith('/')) {
		path = path.slice(0, -1);
	}

	const slug = path.split('/');

	// If the path is just a single segment or empty string return null
	if (slug.length === 1 || slug[slug.length - 2] === '') {
		return null;
	}

	// Return the parent slug
	return slug[slug.length - 2];
}

const EMAIL_REGEX =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export function isValidEmail(value) {
	return EMAIL_REGEX.test(value);
}

const PHONE_REGEX = /^[\(\)\+\-\ 0-9]*$/;
export function isValidPhone(value) {
	return PHONE_REGEX.test(value);
}

export function isUUID(str: string): boolean {
	const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
	return uuidPattern.test(str);
}

export function validateForm(formState) {
	formState.setIsHot(true);
	let isValid = true;
	Object.values(formState.elements).forEach((element) => {
		if (element === null) {
			return;
		}
		if (!element.validate()) {
			isValid = false;
		}
	});
	return isValid;
}

/**
 * Runs a callback function based on throttle settings.
 *
 * If the throttle function is called for the first time for a given namespace
 * and name combination, the callback function is instantly ran.
 *
 * If the throttle function has already been called for the given namespace
 * and name combination, the callback will be scheduled to run after the given
 * delay and any previously scheduled callbacks for the same namespace and name
 * are cancelled.
 *
 * If the throttle function is called with a namespace that differs from the
 * previous call (if any), all previously scheduled callbacks will be cancelled
 * and the complete history of calls will be cleared, so all new callbacks will
 * once again be run instantly once.
 *
 * @async
 * @function
 * @param {namespace} string - The namespace of the throttle
 * @param {name} string - The name of the throttle
 * @param {delay} number - The amount of milliseconds to throttle
 * @param {callback} Function - The callback function to throttle
 * @returns {Promise<any>} - The result of the callback wrapped in a promise
 */
let throttleIds = {};
let throttleNamespace = null;
export async function throttle(
	namespace: string,
	name: string,
	delay: number,
	callback: Function
): Promise<any> {
	if (throttleNamespace !== namespace) {
		throttleNamespace = namespace;
		throttleIds = {};
	}
	if (!(name in throttleIds)) {
		throttleIds[name] = crypto.randomUUID();
		return callback();
	}
	throttleIds[name] = crypto.randomUUID();
	const throttleId = throttleIds[name];
	await new Promise((resolve) => setTimeout(resolve, delay));
	if (namespace === throttleNamespace && throttleId === throttleIds[name]) {
		return callback();
	}
}

export function getPathFromUrl(url: URL): string {
	let path = url.pathname;
	if (!path.startsWith('/')) {
		path = '/' + path;
	}
	if (!path.endsWith('/')) {
		path += '/';
	}
	if (path === '/404/') {
		path = '/page-not-found/';
	}
	return path;
}

export function scrollElementIntoView(element, offset) {
	if (offset === undefined) {
		offset = 0;
	}
	const headerHeight = document.querySelector('.header').clientHeight;
	if (isElementInViewport(element, headerHeight)) {
		return;
	}
	window.scrollTo({
		top: element.getBoundingClientRect().top + window.scrollY - headerHeight - offset,
		left: 0,
		behavior: 'smooth'
	});
}

function isElementInViewport(element, headerHeight) {
	const elementRectangle = element.getBoundingClientRect();
	return (
		elementRectangle.top >= headerHeight &&
		elementRectangle.left >= 0 &&
		elementRectangle.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
		elementRectangle.right <= (window.innerWidth || document.documentElement.clientWidth)
	);
}

export function scrollFirstInvalidElementIntoView(formState) {
	Object.values(formState.elements).forEach((component) => {
		if (component === null || component.isValid()) {
			return;
		}
		component.scrollIntoView();
		return false;
	});
}
