<script lang="ts">
	import type { OrderConfiguratorBlock } from '$models/Block/OrderConfiguratorBlock';
	import type { Order } from '$models/Order/Order';
	import type { Page } from '$models/Page/Page';
	import { goto } from '$app/navigation';
	import OrderConfigurator from '$components/OrderConfigurator/OrderConfigurator.svelte';
	import OrderConfiguratorV2 from '$components/OrderConfigurator/OrderConfiguratorV2.svelte';
	import { trackAddToCart, trackViewItem } from '$lib/datalayer/datalayer';
	import { getCartStore } from '$lib/stores';
	import { isOrderConfiguratorV2 } from '$lib/type-helpers';
	import { onMount } from 'svelte';

	// Component properties
	export let page: Page;
	export let block: OrderConfiguratorBlock;

	const cartStore = getCartStore();

	async function goToCartPage(order: Order) {
		const cart = await $cartStore;
		cartStore.setCart({
			// @TODO: These fields should be able to be omitted after OrderConfigurator v2 is released
			id: null,
			deliveryDate: null,
			deliveryCompanyName: null,
			billingAddressId: null,
			shippingAddressId: null,
			deliveryNote: null,
			poNumber: null,
			...cart,
			...order
		});
		trackAddToCart(order);
		goto(page.layout.pages.cartPage);
	}

	onMount(() => {
		if (!block.configurator) {
			return;
		}

		// @TODO: Implement for v2
		if (isOrderConfiguratorV2(block.configurator)) {
			return;
		}

		const { defaultItems, defaultFrequency, products } = block.configurator;

		const productsFiltered = Object.entries(defaultItems.product)
			.filter(([_, quantity]) => quantity > 0)
			.map(([id, quantity]) => {
				return {
					id,
					quantity: quantity / (products[0]?.quantityMultiplier || 1)
				};
			});

		const nonSubscribableProductsFiltered = Object.entries(defaultItems.non_subscribable)
			.filter(([_, quantity]) => quantity > 0)
			.map(([id, quantity]) => {
				return {
					id,
					quantity
				};
			});

		trackViewItem({
			frequency: defaultFrequency,
			products: productsFiltered,
			nonSubscribableProducts: nonSubscribableProductsFiltered
		});
	});
</script>

{#if isOrderConfiguratorV2(block.configurator)}
	<OrderConfiguratorV2
		configurator={block.configurator}
		onSubmit={goToCartPage}
		recurringCouponCode={block.couponCodeRecurring}
		onetimeCouponCode={block.couponCodeOnetime}
	/>
{:else}
	<OrderConfigurator
		translations={page.layout.translations}
		couponCodeRecurring={block.couponCodeRecurring}
		couponCodeOnetime={block.couponCodeOnetime}
		configurator={block.configurator}
		onSubmit={goToCartPage}
	/>
{/if}
