<script lang="ts">
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';

	// Component properties
	export let page: any;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();

	function onStep2Click(event) {
		if (!event.target.classList.contains('clickable')) {
			return;
		}
		$checkoutStore.then((checkout) => {
			checkoutStore.setCheckout({
				...checkout,
				currentStep: 2
			});
		});
	}

	function onStep3Click(event) {
		if (!event.target.classList.contains('clickable')) {
			return;
		}
		$checkoutStore.then((checkout) => {
			checkoutStore.setCheckout({
				...checkout,
				currentStep: 3
			});
		});
	}
</script>

{#await $userStore then user}
	{#await $checkoutStore then checkout}
		{#await $cartStore then cart}
			<div class="checkout-steps">
				<div class="checkout-step">
					<button
						class="checkout-step-dot"
						class:active={user === null}
						class:completed={user !== null}
					>
						<div class="checkout-step-label">
							{page.step1Label}
						</div>
					</button>
				</div>
				<div class="checkout-step">
					<button
						class="checkout-step-dot"
						class:active={user !== null}
						class:clickable={user !== null}
						class:completed={user !== null && checkout.currentStep === 3}
						on:click={onStep2Click}
					>
						<div class="checkout-step-label">
							{page.step2Label}
						</div>
					</button>
				</div>
				<div class="checkout-step">
					<button
						class="checkout-step-dot"
						class:active={user !== null && checkout.currentStep === 3}
						class:clickable={user !== null &&
							cart.billingAddressId !== null &&
							cart.shippingAddressId !== null}
						on:click={onStep3Click}
					>
						<div class="checkout-step-label">
							{page.step3Label}
						</div>
					</button>
				</div>
			</div>
		{/await}
	{/await}
{/await}

<style lang="postcss">
	.checkout-steps {
		& {
			display: flex;
			margin: 0 auto var(--spacing-12) auto;
			padding: 0;
			max-width: 960px;
		}
	}

	.checkout-step {
		flex: 1;
		display: flex;
		align-items: center;

		&:before,
		&:after {
			content: '';
			flex: 1;
			height: 2px;
			background-color: var(--color-primary);
		}

		&-dot {
			display: block;
			position: relative;
			width: 20px;
			height: 20px;
			border: 4px solid var(--color-primary);
			margin: 0 var(--spacing-1);
			border-radius: 50%;

			@media (--sm) {
				width: 24px;
				height: 24px;
				border-width: 5px;
				margin: 0 var(--spacing-2);
			}

			&.active {
				background-color: var(--color-primary);
			}

			&.completed {
				background-color: var(--color-primary);
				background-image: var(--form-input-bg-image);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: 12px 9px;
			}

			&:not(.clickable) {
				cursor: default;
			}
		}

		&-label {
			display: block;
			position: absolute;
			top: calc(100% + 12px);
			left: 50%;
			transform: translateX(-50%);
			white-space: nowrap;
			font-size: var(--font-size-0);
			font-weight: var(--font-weight-5);
		}
	}
</style>
