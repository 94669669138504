<script lang="ts">
	import type { ProductCategory } from '$models/Product/ProductCategory';
	import { getConfiguratorState } from '$lib/state/configurator.svelte';
	import OrderConfiguratorStepHeader from '../OrderConfiguratorStepHeader.svelte';

	interface Props {
		title: string | null;
		subtitle: string | null;
		text: string | null;
		onCategorySelect: (category: ProductCategory) => void;
	}

	let { title, subtitle, text, onCategorySelect }: Props = $props();

	const configuratorState = getConfiguratorState();
</script>

<div class="step-section step-section--small">
	<OrderConfiguratorStepHeader {title} {subtitle} />

	<div class="step-section-box">
		<div class="step-section-box-inner">
			{#if text}
				<div class="step-section-text">
					{@html text}
				</div>
			{/if}
			<div class="step-section-choices" class:no-selection={!configuratorState.selectedCategory()}>
				{#each configuratorState.categories() as category}
					{#if category.image}
						<button
							class="step-section-choice-image"
							class:is-active={configuratorState.selectedCategory()?.id === category.id}
							onclick={() => onCategorySelect(category)}
						>
							<div class="step-section-choice-image-holder">
								<img
									class="step-section-choice-image-src"
									src={category.image.sizes.large}
									alt={category.image.alt}
								/>
							</div>
							<span class="step-section-choice-image-button button button--no-arrow">
								{category.name}
							</span>
						</button>
					{:else}
						<button
							class="step-section-choice-button button button--no-arrow"
							class:is-active={configuratorState.selectedCategory()?.id === category.id}
							onclick={() => onCategorySelect(category)}
						>
							{category.name}
						</button>
					{/if}
				{/each}
			</div>
		</div>
	</div>
</div>
