<script lang="ts">
	import type { CancelReason } from '$models/CancelReason/CancelReason';
	import type { Product } from '$models/Product/Product';
	import type { Subscription } from '$models/Subscription/Subscription';
	import { page } from '$app/stores';
	import { getModalStore, getToastStore } from '$lib/stores';
	import { autoApplyDiscount, updateSubscription } from '$lib/webparking';

	export let subscription: Subscription;
	export let messages: {
		success: string;
		error: string;
		autoDiscountSuccess: string;
		autoDiscountError: string;
	};
	export let translations: {
		title: string;
		body: string;
		confirmButtonText: string;
		cancelButtonText: string;
		pauseButtonText: string;
	};
	export let onSuccess: (updatedSubscription: Subscription) => void | undefined;
	export let onPause: (subscription: Subscription) => void;
	export let onUrgentOrder: (subscription: Subscription) => void;
	export let cancelReasons: CancelReason[];

	const modalStore = getModalStore();
	const toastStore = getToastStore();

	let step = 1;
	let reasons = randomizeReasons();
	let isLoading = false;
	let remarks = '';
	let selectedProducts: Product[] = [];
	let selectedReason: CancelReason | null;

	function randomizeReasons() {
		const reasonOther = cancelReasons.find((reason) => reason.reasonId === 'other');
		let reasons = cancelReasons.filter((reason) => reason.reasonId !== 'other');
		reasons = reasons.sort(() => Math.random() - 0.5);
		if (reasonOther) {
			reasons = [...reasons, reasonOther];
		}

		return reasons;
	}

	function handleQuit() {
		modalStore.close();
	}

	function handleUrgentOrder() {
		if (onUrgentOrder) {
			onUrgentOrder(subscription);
		}
		handleQuit();
	}

	function handlePause() {
		if (onPause) {
			onPause(subscription);
		}
		handleQuit();
	}

	async function handleAutoDiscount(code) {
		isLoading = true;

		try {
			await autoApplyDiscount(code);

			toastStore.trigger({
				type: 'success',
				message: messages.autoDiscountSuccess
			});

			handleQuit();
		} catch (e) {
			toastStore.trigger({
				type: 'error',
				message: messages.autoDiscountError
			});
		} finally {
			isLoading = false;
		}
	}

	async function handleCancel() {
		isLoading = true;

		try {
			let newSubscriptionData = subscription;

			if (selectedReason && selectedReason.reasonId) {
				newSubscriptionData.cancellationReasonId = selectedReason.reasonId;

				if (selectedReason.reasonId === 'did_not_like_taste' && selectedProducts.length) {
					newSubscriptionData.cancellationRemarks = `[products: ${selectedProducts.join(',')}]`;
				}

				if (selectedReason.reasonId === 'other' && remarks) {
					newSubscriptionData.cancellationRemarks = remarks;
				}
			}

			const updatedSubscription = await updateSubscription({
				...newSubscriptionData,
				isCancelled: true
			});

			toastStore.trigger({ type: 'success', message: messages.success });

			if (onSuccess !== undefined) {
				onSuccess(updatedSubscription);
			}

			handleQuit();
		} catch (error) {
			toastStore.trigger({
				type: 'error',
				message: messages.error
			});
		} finally {
			isLoading = false;
		}
	}

	function getProducts(productCategories: ProductCategory[]) {
		const allRegularProducts = $page.data.products.regular;

		if (!productCategories.length) {
			return allRegularProducts;
		}

		return allRegularProducts.filter((p) => {
			return !!productCategories.find((pc) => pc.id === p.category.id);
		});
	}
</script>

{#if step === 1}
	<h3 class="modal-title">{translations.title}</h3>

	<div class="modal-body rich-text">
		<p>{translations.body}</p>
	</div>

	<footer class="modal-buttons modal-buttons--align-left">
		<button class="modal-button button button--secondary button--no-arrow" on:click={handlePause}>
			{translations.pauseButtonText}
		</button>
		<button
			class="modal-button button button--outline button--no-arrow"
			on:click={() => (step = 2)}
		>
			{translations.confirmButtonText}
		</button>
	</footer>
{:else if step === 2}
	<h3 class="modal-title">{translations.title}</h3>

	<div class="modal-body">
		<div class="cancel-reasons">
			{#each reasons as reason}
				<div class="cancel-reason">
					<div class="cancel-reason-input cancel-reason-radio">
						<input
							type="radio"
							name="reason_id"
							id={reason.reasonId}
							value={reason}
							bind:group={selectedReason}
						/>
						<label for={reason.reasonId}>
							{@html reason.label}
						</label>
					</div>
					{#if selectedReason && reason.reasonId === 'did_not_like_taste' && selectedReason.reasonId === 'did_not_like_taste'}
						<div class="cancel-reason-pane">
							{#if reason.sublabel}
								<p class="cancel-reason-pane-subtitle">{reason.sublabel}</p>
							{/if}
							<ul class="cancel-reason-pane-list">
								{#each getProducts(reason.productCategories) as product}
									<li class="cancel-reason-pane-item">
										<div class="cancel-reason-input cancel-reason-checkbox">
											<input
												type="checkbox"
												id={product.id}
												name="flavors"
												bind:group={selectedProducts}
												value={product.name}
											/>
											<label for={product.id}>
												{@html product.name}
											</label>
										</div>
									</li>
								{/each}
							</ul>
						</div>
					{:else if reason.reasonId === 'other' && selectedReason && selectedReason.reasonId === 'other'}
						<div class="cancel-reason-pane">
							{#if reason.sublabel}
								<p class="cancel-reason-pane-subtitle">{reason.sublabel}</p>
							{/if}
							<div class="form-rows">
								<div class="form-row">
									<span class="form-row-input-wrapper">
										<textarea bind:value={remarks} />
									</span>
								</div>
							</div>
						</div>
					{/if}
				</div>
			{/each}
		</div>
	</div>

	<div class="modal-buttons">
		<button
			type="button"
			class="modal-button modal-button-cancel button button--back button--outline"
			on:click={() => handleQuit()}
		>
			{translations.cancelButtonText}
		</button>

		{#if selectedReason && selectedReason.hasExtraStep}
			<button type="button" class="modal-button button" on:click={() => (step = 3)}>
				{$page.data.page.layout.translations.buttonLabels.nextStepLabel}
			</button>
		{:else}
			<button type="button" class="modal-button button" on:click={() => handleCancel()}>
				{translations.confirmButtonText}
			</button>
		{/if}
	</div>
{:else if step === 3 && selectedReason && selectedReason.hasExtraStep}
	{#if selectedReason.cancelTitle}
		<h3 class="modal-title">{selectedReason.cancelTitle}</h3>
	{/if}

	<div class={`cancel-blocks cancel-blocks--count-${selectedReason.cancelBlocks.length}`}>
		{#each selectedReason.cancelBlocks as block}
			<div class="cancel-block" class:is-loading={isLoading}>
				<h3 class="cancel-block-title">{block.title}</h3>
				<p class="cancel-block-text">
					{block.text}
				</p>
				{#if block.showButton}
					<div class="cancel-block-button">
						{#if block.buttonType === 'urgent_order'}
							<button type="button" class="button" on:click={handleUrgentOrder}>
								{block.buttonLabel}
							</button>
						{:else if block.buttonType === 'auto_discount'}
							<button
								type="button"
								class="button"
								disabled={isLoading}
								on:click={() => handleAutoDiscount(block.buttonCouponCode)}
							>
								{block.buttonLabel}
							</button>
						{:else if block.buttonType === 'pause_modal'}
							<button type="button" class="button" disabled={isLoading} on:click={handlePause}>
								{block.buttonLabel}
							</button>
						{:else if block.buttonLink}
							<a href={block.buttonLink.url} class="button">
								{block.buttonLink.title}
							</a>
						{/if}
					</div>
				{/if}
			</div>
		{/each}
	</div>

	<div class="modal-buttons">
		<button type="button" class="modal-button-link" on:click={() => handleCancel()}>
			{translations.confirmButtonText}
		</button>
	</div>
{/if}

<style lang="postcss">
	.cancel-reasons {
		margin-bottom: var(--spacing-6);
	}
	.cancel-reason {
		padding-bottom: var(--spacing-3);
		border-bottom: 1px solid var(--border-color);

		&:not(:first-child) {
			padding-top: var(--spacing-3);
		}

		&-input {
			label {
				display: block;
				position: relative;
				padding-left: 28px;

				&:before {
					content: '';
					display: block;
					position: absolute;
					top: 2px;
					left: 0;
					width: 18px;
					height: 18px;
					background-color: white;
					border: 1px solid var(--border-color);
					transition:
						background-color var(--transition),
						border-color var(--transition);
				}

				&:after {
					content: '';
					position: absolute;
					z-index: 3;
					top: 11px;
					left: 9px;
					transform: translate(-50%, -50%) scale(0.5);
					width: 12px;
					height: 9px;
					background-image: var(--form-input-bg-image);
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
					opacity: 0;
					transition: all var(--transition);
				}

				&:hover {
					cursor: pointer;

					&:before {
						border-color: var(--border-color-focus);
					}
				}
			}

			input {
				display: none;
				&:checked ~ label {
					&:before {
						background-color: var(--form-input-bg-color);
						border-color: var(--form-input-bg-color);
					}
					&:after {
						opacity: 1;
						transform: translate(-50%, -50%) scale(0.8);
					}
				}
			}
		}
		&-radio {
			label {
				&:before {
					border-radius: 50%;
				}
			}
		}

		&-pane {
			margin-top: var(--spacing-2);
			margin-bottom: var(--spacing-2);
			margin-left: 28px;
			padding: var(--spacing-5);
			background-color: var(--color-primary-light);

			&-subtitle {
				line-height: var(--font-lineheight-0);
				font-weight: var(--font-weight-4);
				&:not(:last-child) {
					margin-bottom: var(--spacing-3);
				}
			}

			&-list {
				display: grid;
				gap: var(--spacing-1);

				@media (--xs) {
					grid-template-columns: 1fr 1fr;
				}
			}
		}
	}

	.cancel-blocks {
		display: grid;
		gap: var(--spacing-5);
		margin-bottom: var(--spacing-6);

		&--count-2 {
			@media (--sm) {
				grid-template-columns: 1fr 1fr;
			}
		}
	}

	.cancel-block {
		display: flex;
		flex-direction: column;
		padding: var(--spacing-6);
		background-color: var(--color-primary-light);

		&-title {
			font-size: var(--font-size-2);
			margin-bottom: var(--spacing-3);
		}

		&-text {
			font-size: var(--font-size-1);

			&:not(:last-child) {
				margin-bottom: var(--spacing-4);
			}
		}

		&-button {
			margin-top: auto;
		}
	}
</style>
