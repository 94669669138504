import type { NonSubscribableProduct } from '$models/NonSubscribableProduct/NonSubscribableProduct';
import type { OrderPrices } from '$models/Order/OrderPrices';
import type {
	OrderConfigurationOrder,
	OrderConfiguratorConfiguration,
	OrderConfiguratorConfigurationCategory
} from '$models/OrderConfigurator/OrderConfiguratorV2';
import type { Product } from '$models/Product/Product';
import type { ProductCategory } from '$models/Product/ProductCategory';
import { isRegularProduct } from '$lib/type-helpers';
import {
	getOrderPrices,
	getOrderPricesDiscountData,
	getOrderPricesProductsPrice
} from '$lib/webparking';
import { getContext, setContext, untrack } from 'svelte';

const key = Symbol('orderConfiguratorState');
const pricesCache = new Map();

export function createConfiguratorState(configuration: OrderConfiguratorConfiguration) {
	let configurationCategory: OrderConfiguratorConfigurationCategory | undefined = $state();
	let products = $derived(configurationCategory?.products ?? []);
	let nonSubscribableProducts = $state(configuration.nonSubscribableProducts ?? []);
	let quantities = $derived(configurationCategory?.quantities ?? []);
	let minimumQuantity = $derived(quantities.reduce((a, b) => Math.min(a, b), 0));
	let maximumQuantity = $derived(quantities.reduce((a, b) => Math.max(a, b), 0));
	// Frequencies is set in the configuration and does not change between categories.
	let frequencies = configuration.frequencies;
	let minimumFrequency = $derived(
		frequencies.filter((f) => f > 0).reduce((a, b) => Math.min(a, b), 1)
	);
	let maximumFrequency = $derived(frequencies.reduce((a, b) => Math.max(a, b), 0));
	let quantityMultiplier: number = $derived(products?.[0]?.product?.quantityMultiplier ?? 1);
	let selectedCategory: ProductCategory | undefined = $derived(
		configurationCategory?.productCategory
	);
	let selectedFrequency: number = $state(configuration.defaultFrequency); // Can be any frequency
	let selectedRecurringFrequency: number = $state(
		configuration.defaultFrequency > 0 ? configuration.defaultFrequency : minimumFrequency
	); // Always > 0
	// Selected quantity is the total quantity of all products
	let selectedQuantity: number = $derived(products.reduce((acc, p) => acc + p.quantity, 0));
	let recurringCouponCode: string = $state('');
	let onetimeCouponCode: string = $state('');
	let isValidCategory: boolean = $derived(!!selectedCategory);
	let isValidQuantity: boolean = $derived(quantities.includes(selectedQuantity));
	let isValidFrequency: boolean = $derived(frequencies.includes(selectedFrequency));
	let isFetchingPrices: boolean = $state(false);
	let prices: OrderPrices = $state({
		productsLowestRecurringPrice: null,
		productsRecurringPrice: null,
		productsOneTimePrice: null,
		nonSubscribableProductPrices: {},
		totalNonSubscribableProductsPrice: 0,
		discountAmount: 0,
		discountAmountOnetime: 0,
		freeNonSubscribableProducts: []
	});

	let order: OrderConfigurationOrder = $derived.by(() => {
		let couponCode = selectedFrequency > 0 ? recurringCouponCode : onetimeCouponCode;
		return {
			frequency: selectedFrequency,
			products: products
				.filter((p) => p.quantity > 0)
				.map((p) => {
					return {
						id: p.product.id,
						quantity: p.quantity
					};
				}),
			nonSubscribableProducts: nonSubscribableProducts
				.filter((p) => p.quantity > 0)
				.map((p) => {
					return {
						id: p.product.id,
						quantity: p.quantity
					};
				}),
			couponCode: couponCode || null
		};
	});

	// If only one category is available, select it by default
	if (configuration.categories.length === 1) {
		configurationCategory = configuration.categories[0];
	}

	// If more categories exist and a default is defined, set it
	if (configuration.categories.length > 1 && configuration.defaultCategory) {
		configurationCategory = configuration.categories.find(({ productCategory }) => {
			return productCategory.id === configuration?.defaultCategory?.id;
		});
	}

	function findProductItem(product: Product | NonSubscribableProduct) {
		const productList = isRegularProduct(product) ? products : nonSubscribableProducts;
		return productList.find((p) => p.product.id === product.id);
	}

	async function updatePrices() {
		if (!selectedCategory) {
			return;
		}

		const cacheKey = `${selectedCategory.id}:${selectedRecurringFrequency}:${selectedQuantity}`;

		if (pricesCache.has(cacheKey)) {
			prices = pricesCache.get(cacheKey);
			return;
		}

		isFetchingPrices = true;

		// Artificial timeout for debuggin/styling
		// await new Promise((resolve, reject) => {
		// 	setTimeout(resolve, 1000);
		// });

		// This basically does the same as getOrderPrices but with more control.
		// @TODO: Deprecate getOrderPrices and maybe figure out a better way to do this
		prices = {
			productsLowestRecurringPrice: await getOrderPricesProductsPrice(
				order,
				selectedRecurringFrequency
			),
			productsRecurringPrice: await getOrderPricesProductsPrice(order, selectedRecurringFrequency),
			productsOneTimePrice: await getOrderPricesProductsPrice(order, 0),
			nonSubscribableProductPrices: 0,
			totalNonSubscribableProductsPrice: 0,
			discountAmount: 0,
			discountAmountOnetime: 0,
			freeNonSubscribableProducts: []
		};

		if (recurringCouponCode) {
			const recurringDiscountData = await getOrderPricesDiscountData(
				{
					...order,
					frequency: selectedRecurringFrequency,
					couponCode: recurringCouponCode
				},
				prices
			);
			prices.discountAmount = recurringDiscountData.amount;
			prices.freeNonSubscribableProducts = recurringDiscountData.freeNonSubscribableProducts;
		}

		if (onetimeCouponCode) {
			const onetimeDiscountData = await getOrderPricesDiscountData(
				{ ...order, frequency: 0, couponCode: onetimeCouponCode },
				prices
			);
			prices.discountAmountOnetime = onetimeDiscountData.amount;
		}

		pricesCache.set(cacheKey, prices);

		isFetchingPrices = false;
	}

	$effect(() => {
		if (!isValidQuantity) {
			return;
		}

		updatePrices();
	});

	return {
		isValid() {
			return isValidCategory && isValidQuantity && isValidFrequency;
		},
		isValidCategory() {
			return isValidCategory;
		},
		isValidQuantity() {
			return isValidQuantity;
		},
		isValidFrequency() {
			return isValidFrequency;
		},
		isFetchingPrices() {
			return isFetchingPrices;
		},
		isOneTimeOrderAllowed() {
			return frequencies.includes(0);
		},
		order() {
			return order;
		},
		categories() {
			return configuration.categories.map((category) => category.productCategory);
		},
		products() {
			return products;
		},
		nonSubscribableProducts() {
			return nonSubscribableProducts;
		},
		/**
		 * Set a product's quantity.
		 *
		 * @param product
		 * @param value Non-multiplied new quantity
		 * @returns void
		 */
		setProductQuantity(product: Product | NonSubscribableProduct, value: number) {
			const productItem = findProductItem(product);
			const multipliedValue = value * quantityMultiplier;
			const remainder = multipliedValue % quantityMultiplier;

			if (!productItem) {
				return;
			}

			// Make sure the new quantity is a valid value.
			if (remainder !== 0) {
				value = (multipliedValue - remainder) / quantityMultiplier;
			}

			productItem.quantity = value;
		},
		/**
		 * Increments product's quantity by 1, unless maximum quantity is reached.
		 *
		 * @param product
		 * @returns
		 */
		incrementProductQuantity(product: Product | NonSubscribableProduct) {
			const productItem = findProductItem(product);

			if (!productItem) {
				return;
			}

			productItem.quantity = Math.min(productItem.quantity + 1, maximumQuantity);
		},
		/**
		 * Decrements product's quantity by 1 but not lower than 0.
		 *
		 * @param product
		 * @returns
		 */
		decrementProductQuantity(product: Product | NonSubscribableProduct) {
			const productItem = findProductItem(product);

			if (!productItem) {
				return;
			}

			productItem.quantity = Math.max(productItem.quantity - 1, 0);
		},
		quantities() {
			return quantities;
		},
		frequencies() {
			return frequencies;
		},
		quantityMultiplier() {
			return quantityMultiplier;
		},
		prices() {
			return prices;
		},
		selectedCategory() {
			return selectedCategory;
		},
		selectedQuantity() {
			return selectedQuantity;
		},
		selectedFrequency() {
			return selectedFrequency;
		},
		selectedRecurringFrequency() {
			return selectedFrequency;
		},
		setCategory(category: ProductCategory) {
			// Set the selected configuration category
			configurationCategory = configuration.categories.find(
				(c: OrderConfiguratorConfigurationCategory) => c.productCategory.id === category.id
			);
		},
		/**
		 * Sets the selected frequency
		 *
		 * Can be any frequency, including 0.
		 * @param newFrequency
		 */
		setFrequency(newFrequency: number) {
			selectedFrequency = newFrequency;

			if (newFrequency > 0) {
				selectedRecurringFrequency = newFrequency;
			}
		},
		/**
		 * Sets the preferred recurring frequency
		 *
		 * Has to be greater than 0.
		 * @param newFrequency
		 */
		setRecurringFrequency(newFrequency: number) {
			selectedRecurringFrequency = newFrequency;
		},
		setRecurringCouponCode(newCode: string) {
			recurringCouponCode = newCode;
		},
		setOnetimeCouponCode(newCode: string) {
			onetimeCouponCode = newCode;
		}
	};
}

export function setConfiguratorState(
	configuratorState: ReturnType<typeof createConfiguratorState>
) {
	setContext(key, configuratorState);
}

export function getConfiguratorState(): ReturnType<typeof createConfiguratorState> {
	return getContext(key);
}
