<script lang="ts">
	import Pencil from '$components/UI/Icons/Pencil.svelte';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { listUserAddressesByType } from '$lib/webparking';
	import { onMount } from 'svelte';

	// Component properties
	export let page: any;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();

	async function getCustomerAddressById(customerAddressId) {
		// TODO GET FROM API
		return {
			id: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
			customer_address_type_id: 'shipping',
			attention: 'string',
			street: 'Aert van Nesstraat',
			house_number: '45',
			postal_code: '3012 CA',
			city: 'Rotterdam',
			country_code: 'nl'
		};
	}

	function goToStep2() {
		$checkoutStore.then((checkout) => {
			checkoutStore.setCheckout({
				...checkout,
				currentStep: 2
			});
		});
	}

	let billingAddressesPromise = new Promise(() => {});
	let shippingAddressesPromise = new Promise(() => {});
	onMount(async () => {
		await $userStore;
		try {
			const [billingAddresses, shippingAddresses] = await listUserAddressesByType();
			billingAddressesPromise = Promise.resolve(billingAddresses);
			shippingAddressesPromise = Promise.resolve(shippingAddresses);
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});
</script>

{#await $userStore then user}
	{#await $cartStore then cart}
		<div class="box">
			<div class="box-header">
				<h2 class="box-title">
					{page.summary.title}
				</h2>
				<div class="box-actions">
					<button class="box-edit" on:click={goToStep2}>
						<Pencil />
						{page.summary.editButtonLabel}
					</button>
				</div>
			</div>

			<div class="customer-info">
				<div class="customer-info-text">
					{user.firstName}
					{user.lastName}<br />
					{user.email}
				</div>
				<div class="customer-info-boxes">
					{#await billingAddressesPromise then billingAddresses}
						{#each billingAddresses as billingAddress}
							{#if billingAddress.id === cart.billingAddressId}
								<div class="customer-info-box">
									<h3 class="customer-info-title">{page.summary.billingAddress}</h3>
									<div>
										{billingAddress.street}
										{billingAddress.houseNumber}{billingAddress.houseNumberSuffix.length
											? '-'
											: ''}{billingAddress.houseNumberSuffix}
									</div>
									<div>{billingAddress.postalCode}, {billingAddress.city}</div>
									{#if page.layout.countries[billingAddress.country.toLowerCase()]}
										<div>{page.layout.countries[billingAddress.country.toLowerCase()]}</div>
									{:else}
										{billingAddress.country}
									{/if}
								</div>
							{/if}
						{/each}
					{/await}
					{#await shippingAddressesPromise then shippingAddresses}
						{#each shippingAddresses as shippingAddress}
							{#if shippingAddress.id === cart.shippingAddressId}
								<div class="customer-info-box">
									<h3 class="customer-info-title">{page.summary.shippingAddress}</h3>
									<div>
										{shippingAddress.street}
										{shippingAddress.houseNumber}{shippingAddress.houseNumberSuffix.length
											? '-'
											: ''}{shippingAddress.houseNumberSuffix}
									</div>
									<div>{shippingAddress.postalCode}, {shippingAddress.city}</div>
									{#if page.layout.countries[shippingAddress.country.toLowerCase()]}
										<div>{page.layout.countries[shippingAddress.country.toLowerCase()]}</div>
									{:else}
										{shippingAddress.country}
									{/if}
								</div>
							{/if}
						{/each}
					{/await}
				</div>
			</div>
		</div>
	{/await}
{/await}

<style lang="postcss">
	.customer-info {
		&-text {
			font-size: var(--box-text-font-size);
			line-height: var(--box-text-lineheight);
			margin-bottom: var(--spacing-4);
		}
		&-title {
			font-size: var(--font-size-1);
			font-weight: var(--font-weight-5);
			margin-bottom: var(--spacing-1);
		}
		&-boxes {
			@media (--sm) {
				display: flex;
				justify-content: space-between;
			}
		}
		&-box {
			border: 1px solid var(--border-color);
			padding: var(--spacing-4);
			font-size: var(--font-size-0);
			line-height: var(--font-lineheight-3);

			&:not(:last-child) {
				margin-bottom: var(--spacing-5);

				@media (--sm) {
					margin-bottom: 0;
				}
			}

			@media (--sm) {
				width: calc(50% - var(--spacing-2));
			}
		}
	}
</style>
