import { webparking } from '$lib/webparking';

function orderPaymentToWebparkingOrderPaymentData(orderPayment) {
	return {
		return_url: orderPayment.returnUrl,
		discount_code: orderPayment.discountCode !== null ? orderPayment.discountCode : undefined,
		emergency_order_for_subscription_id:
			orderPayment.emergencyOrderForSubscriptionId !== null
				? orderPayment.emergencyOrderForSubscriptionId
				: undefined,
		payment_method: orderPayment.paymentMethod,
		issuer:
			orderPayment.paymentMethodIssuer !== null ? orderPayment.paymentMethodIssuer : undefined,
		advocate_id: orderPayment.advocateId ?? undefined
	};
}

function webparkingOrderPaymentDataToOrderPayment(data) {
	return {
		completedAt: data.completed_at !== undefined ? data.completed_at : null,
		failedAt: data.failed_at !== undefined ? data.failed_at : null,
		createdAt: data.created_at !== undefined ? data.created_at : null,
		checkoutUrl: data.checkout_url
	};
}

export async function createOrderPayment(orderPayment) {
	const json = await webparking
		.post(`orders/${orderPayment.orderId}/payments`, {
			json: orderPaymentToWebparkingOrderPaymentData(orderPayment)
		})
		.json();
	return webparkingOrderPaymentDataToOrderPayment(json.data);
}

export async function listOrderPayments(order) {
	const json = await webparking.get(`orders/${order.id}/payments`).json();
	return json.data.map(webparkingOrderPaymentDataToOrderPayment);
}
