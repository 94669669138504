<script lang="ts">
	import type { AccountAddressDetailPage } from '$models/Page/AccountAddressDetailPage';
	import { goto } from '$app/navigation';
	import Form from '$components/Form/Form.svelte';
	import { getToastStore } from '$lib/stores';

	export let page: AccountAddressDetailPage;

	const toastStore = getToastStore();

	function onSubmit($formState) {
		goto(page.submitSuccessPage).then(() => {
			toastStore.trigger({
				message: page.submitSuccessText,
				type: 'success'
			});
		});
	}
</script>

<div class="box">
	<Form form={page.forms.address} {onSubmit} />
</div>
