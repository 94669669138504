interface Parameter {
	callback?: (isIntersecting: boolean) => void;
}

export function intersectionObserver(node: HTMLElement, options?: Parameter): void {
	$effect(() => {
		const observer = new IntersectionObserver((entries) => {
			if (options?.callback) {
				options.callback(entries[0].isIntersecting);
			}
		});

		observer.observe(node);

		return () => {
			observer.disconnect();
		};
	});
}
