<script lang="ts">
	import type { OrderConfigurator } from '$models/OrderConfigurator/OrderConfigurator';
	import { getSiteContext } from '$lib/context/site';
	import { formatCurrency } from '$lib/utils';

	// Component properties
	export let translations: any;
	export let configurator: OrderConfigurator;
	export let configuratorStore: any;
	export let step: Step;
	export let index: number;
	export let entityEventHandlers: any;

	const site = getSiteContext();

	let stepEntityAmount: number = 0;
	$: {
		stepEntityAmount = 0;
		configuratorStore.getEntities(step.entityType).forEach((product) => {
			stepEntityAmount += configuratorStore.getSelectedEntityAmount(step.entityType, product.id);
		});
	}
</script>

<div class="cards">
	<div class="cards-grid cards-grid--count-{configuratorStore.getEntities(step.entityType).length}">
		{#each configuratorStore.getEntities(step.entityType) as product}
			<div class="card" data-product-external-id={product.id}>
				<figure class="card-image-holder">
					<img class="card-image" src={product.image.url} alt={product.image.alt} />
				</figure>

				<h3 class="card-title">
					{product.name}
				</h3>
				<div class="card-quantity">
					<div class="card-quantity-inner">
						<button
							class="card-quantity-button card-quantity-button--prev"
							on:click={(e) => entityEventHandlers.onDecreaseButtonClick(e, product)}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"
								><path
									stroke="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="1.5"
									d="M13.688 9.188H4.312"
								/></svg
							>
						</button>
						<input
							class="card-quantity-input"
							on:change={(e) => entityEventHandlers.onInputChange(e, product)}
							type="text"
							value={configuratorStore.getSelectedEntityAmount(step.entityType, product.id)}
						/>
						<button
							class="card-quantity-button card-quantity-button--next"
							on:click={(e) => entityEventHandlers.onIncreaseButtonClick(e, product)}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"
								><path
									stroke="currentColor"
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="1.5"
									d="M9 4.313v9.375M13.688 9H4.312"
								/></svg
							>
						</button>
					</div>
				</div>
			</div>
		{/each}
	</div>
	{#if step.entityType === 'non_subscribable'}
		{#if stepEntityAmount > 0}
			{#await configuratorStore.prices then prices}
				<div class="cards-price">
					{formatCurrency(prices.totalNonSubscribableProductsPrice, site.locale.code)}
				</div>
			{/await}
		{/if}
		<div class="cards-next">
			<button class="button" on:click={configuratorStore.goToNextStepIfValid[step.entityType]}>
				{step.buttonText}
			</button>
		</div>
	{:else}
		{#if configuratorStore.entityErrorText[step.entityType] !== null}
			<div class="cards-error">
				{configuratorStore.entityErrorText[step.entityType]}
			</div>
		{:else if stepEntityAmount > 0}
			{#await configuratorStore.prices then prices}
				{#if prices.productsLowestRecurringPrice !== null}
					<div class="cards-price">
						{translations.fromPriceLabel}
						{formatCurrency(prices.productsLowestRecurringPrice, site.locale.code)}
					</div>
				{/if}
			{/await}
		{/if}
		<div class="cards-next">
			{#await configuratorStore.prices}
				<button
					class="button disabled"
					on:click={configuratorStore.goToNextStepIfValid[step.entityType]}
				>
					{step.buttonText}
				</button>
			{:then prices}
				<button
					class="button"
					disabled={prices.productsLowestRecurringPrice === null}
					on:click={configuratorStore.goToNextStepIfValid[step.entityType]}
				>
					{step.buttonText}
				</button>
			{/await}
		</div>
	{/if}
</div>

<style lang="postcss">
	.cards {
		max-width: 100%;
		overflow: hidden;
		--slide-spacing: var(--spacing-5);
		--slide-size: 100%;

		@media (--md) {
			--slide-size: 33.3333333333%;
		}

		&-grid {
			@media (--md) {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin-left: calc(var(--slide-spacing) * -1);
				--slide-size: 33.3333333333%;

				&--count-4 {
					--slide-size: 50%;
				}
			}

			@media (--lg) {
				&--count-4 {
					--slide-size: 25%;
				}
			}
		}

		&-price {
			font-size: var(--font-size-4);
			line-height: var(--font-lineheight-1);
			text-align: center;
			margin-bottom: var(--spacing-5);
		}

		&-next {
			display: flex;
			justify-content: center;
		}

		&-error {
			color: var(--color-red);
			text-align: center;
			font-size: var(--font-size-1);
			font-weight: var(--font-weight-5);
			margin-bottom: var(--spacing-6);
		}
	}

	.card {
		text-align: center;
		margin-bottom: var(--spacing-8);

		@media (--md) {
			flex: 0 0 var(--slide-size);
			min-width: 0;
			padding-left: var(--slide-spacing);
		}

		&-image-holder {
			max-width: 75%;
			margin: 0 auto var(--spacing-6) auto;

			@media (--md) {
				max-width: 100%;
			}
		}

		&-image {
			display: block;
			width: 100%;
			height: auto;
			margin-bottom: 0;
		}

		&-title {
			font-size: var(--font-size-2);
			text-transform: uppercase;
			font-weight: var(--font-weight-5);
			margin-bottom: var(--spacing-4);
		}

		&-quantity {
			display: flex;
			justify-content: center;

			&-inner {
				display: flex;
				border: 1px solid var(--border-color);
				overflow: hidden;
				color: var(--base-color);
				border-radius: var(--configurator-input-radius);
				background-color: white;
			}

			&-button {
				position: relative;
				width: 32px;
				display: flex;
				align-items: center;
				color: var(--color-primary);

				&--prev {
					justify-content: flex-end;
					padding-right: 2px;
				}

				&--next {
					justify-content: flex-start;
					padding-left: 3px;
				}
			}

			&-input {
				border: 0;
				padding-right: 0;
				padding-left: 0;
				max-width: 40px;
				min-height: 38px;
				text-align: center;
				background-color: transparent;
			}
		}
	}
</style>
