<script lang="ts">
	import type { Subscription } from '$models/Subscription/Subscription';
	import type { EmblaCarouselType } from 'embla-carousel';
	import { page as pageStore } from '$app/stores';
	import Summary from '$components/Summary/Summary.svelte';
	import Loader from '$components/UI/Loader.svelte';
	import Slider from '$components/UI/Slider/Slider.svelte';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { listSubscriptions } from '$lib/webparking';
	import { onMount } from 'svelte';

	// Component properties
	export let accountBlock: any;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();

	let subscriptions: Promise<Subscription[]> = new Promise(() => {});

	let subscriptionsSlider: EmblaCarouselType | undefined;

	let currentSubscriptionSlide: number = 1;

	$: translations = $pageStore.data.page.layout.translations;

	onMount(async () => {
		await $userStore;
		try {
			subscriptions = listSubscriptions({ orderBy: 'status' });
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});

	function initSlider() {
		if (subscriptionsSlider === undefined) {
			return;
		}

		subscriptionsSlider.on('slidesInView', (instance) => {
			const slidesInView = instance.slidesInView();

			if (slidesInView.length > 0) {
				currentSubscriptionSlide = slidesInView[0] + 1;
			}
		});
	}
</script>

<div class="account-box">
	<div class="box">
		<div class="box-header">
			<h2 class="box-title">
				{$pageStore.data.page.mySubscriptionsTitle}
				<span>
					{#await subscriptions then subscriptions}
						{#if subscriptions.length > 1}
							({currentSubscriptionSlide}/{subscriptions.length})
						{/if}
					{/await}
				</span>
			</h2>
			<div class="box-arrows">
				{#await subscriptions then subscriptions}
					{#if subscriptions.length > 1}
						{#if subscriptionsSlider}
							<button
								type="button"
								class="box-arrows-prev"
								on:click={() => subscriptionsSlider?.scrollPrev()}
							/>
							<button
								type="button"
								class="box-arrows-next"
								on:click={() => subscriptionsSlider?.scrollNext()}
							/>
						{/if}
					{/if}
				{/await}
			</div>
		</div>

		{#await subscriptions}
			<Loader />
		{:then subscriptions}
			{#if subscriptions.length}
				<Slider
					options={{ loop: true, inViewThreshold: 0.5 }}
					let:Slide
					bind:instance={subscriptionsSlider}
					on:init={initSlider}
				>
					{#each subscriptions as subscription}
						<Slide>
							<h3 class="account-slider-title">
								{subscription.number}
							</h3>
							<Summary {subscription} />
							<div class="account-slider-button">
								<a
									href="{$pageStore.data.page.layout.pages
										.accountSubscriptionsPage}/?resourceId={subscription.id}"
									class="button"
								>
									{translations.buttonLabels.viewSubscription}
								</a>
							</div>
						</Slide>
					{/each}
				</Slider>
			{:else}
				{translations.errors.noSubscriptionsFound}
			{/if}
		{:catch error}
			{translations.errors.failedToLoadSubscriptions}
		{/await}
	</div>
</div>
