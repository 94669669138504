<script lang="ts">
	import { page as pageStore } from '$app/state';
	import ButtonLoader from '$components/UI/ButtonLoader.svelte';
	import PaymentIconInvoice from '$components/UI/Icons/PaymentIconInvoice.svelte';
	import { intersectionObserver } from '$lib/actions/intersection-observer.svelte';
	import { getAdvocateId } from '$lib/affiliate';
	import { getCartStore, getCheckoutStore, getToastStore, getUserStore } from '$lib/stores';
	import { scrollElementIntoView } from '$lib/utils';
	import {
		createOrder,
		createOrderPayment,
		listOrderPayments,
		listPaymentMethods,
		updateOrder
	} from '$lib/webparking';
	import { onMount } from 'svelte';

	// Component properties
	export let page: any;
	export let orderPricesPromise: any;
	export let extraForm: any;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const toastStore = getToastStore();
	const userStore = getUserStore();

	let currentPaymentMethod = null;
	let paymentMethodsPromise = new Promise(() => {});

	let isProcessingOrder = false;
	let canPayByInvoice = false;
	let orderFrequency: number | undefined;
	let ctaInViewport: boolean = false;

	onMount(async () => {
		const user = await $userStore;
		canPayByInvoice = user?.canPayByInvoice ?? false;
		try {
			paymentMethodsPromise = listPaymentMethods(pageStore.data.page.paymentMethod.labels);

			const cart = await $cartStore;
			const checkout = await $checkoutStore;
			const orderPayments = await listOrderPayments(cart);

			orderFrequency = cart.frequency;

			// If the order has an uncompleted payment show an error toast, but
			// only if the uncompleted payment was created after the previous
			// uncompleted payment that was shown an error for.
			const errorOrderPaymentCreatedAt = orderPayments.reduce((accumulator, currentValue) => {
				if (currentValue.completedAt !== null) {
					return accumulator;
				}
				if (accumulator === null || currentValue.createdAt > accumulator) {
					return currentValue.createdAt;
				}
				return accumulator;
			}, checkout.errorOrderPaymentCreatedAt);
			if (errorOrderPaymentCreatedAt !== checkout.errorOrderPaymentCreatedAt) {
				toastStore.trigger({
					message: page.status.paymentFailedError,
					type: 'error'
				});

				// Create a new order so it can potentially be edited.
				const order = await createOrder({
					billingAddressId: cart.billingAddressId,
					shippingAddressId: cart.shippingAddressId,
					frequency: cart.frequency,
					products: cart.products,
					nonSubscribableProducts: cart.nonSubscribableProducts,
					deliveryNote: cart.deliveryNote,
					poNumber: cart.poNumber
				});

				// Remove the ID from the cart so a new order is created when updates are attempted.
				cartStore.setCart({
					...cart,
					id: order.id
				});
			}

			const paymentMethods = await paymentMethodsPromise;
			checkoutStore.setCheckout({
				...checkout,
				paymentMethod: paymentMethods[0].id,
				errorOrderPaymentCreatedAt
			});
		} catch (error) {
			if (error.response.status === 401) {
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});

	function updatePaymentMethod(methodId: string) {
		$checkoutStore.then((checkout) => {
			checkoutStore.setCheckout({
				...checkout,
				paymentMethod: methodId,
				paymentMethodIssuer: null
			});
		});
	}

	async function updatePaymentMethodIssuer(event) {
		const checkout = await $checkoutStore;
		let paymentMethodIssuerError = null;
		if (checkout.isPaymentMethodSubmitted && event.target.value.length === 0) {
			paymentMethodIssuerError = page.paymentMethod.bankRequiredError;
		}
		checkoutStore.setCheckout({
			...checkout,
			paymentMethodIssuer: event.target.value,
			paymentMethodIssuerError: paymentMethodIssuerError
		});
	}

	async function finalizeOrder() {
		if (isProcessingOrder) return;

		let paymentMethods = await paymentMethodsPromise;
		const checkout = await $checkoutStore;
		const cart = await $cartStore;

		let isExtraFormValid = true;
		let couponCodeValue = null;

		paymentMethods = [
			{
				id: 'bank_transfer',
				name: pageStore.data.page.paymentMethod.statee,
				issuers: []
			},
			...paymentMethods
		];
		if (extraForm !== null && extraForm !== undefined) {
			if (extraForm.isEditing()) {
				[isExtraFormValid, couponCodeValue] = await extraForm.submit(null, false);
				if (isExtraFormValid) {
					cart.couponCode = couponCodeValue;
					cartStore.setCart({ ...cart });
				}
			}
		}
		if (!isExtraFormValid) {
			extraForm.scrollCouponCodeIntoView();
			return;
		}

		// Validate the payment method
		let paymentMethodIssuerError = null;
		for (const paymentMethod of paymentMethods) {
			if (paymentMethod.id === checkout.paymentMethod) {
				if (
					paymentMethod.issuers.length > 0 &&
					(checkout.paymentMethodIssuer === null || checkout.paymentMethodIssuer.length === 0)
				) {
					paymentMethodIssuerError = page.paymentMethod.bankRequiredError;
				}
				break;
			}
		}

		if (paymentMethodIssuerError !== null) {
			scrollElementIntoView(document.querySelector('.payment-method-wrap'), 12);
			checkoutStore.setCheckout({
				...checkout,
				paymentMethodIssuerError: paymentMethodIssuerError,
				isPaymentMethodSubmitted: true
			});
			return;
		}
		checkoutStore.setCheckout({
			...checkout,
			paymentMethodIssuerError: paymentMethodIssuerError,
			isPaymentMethodSubmitted: true
		});

		try {
			isProcessingOrder = true;

			// Update the order only if it doesn't already have any order payments
			const orderPayments = await listOrderPayments(cart);
			if (orderPayments.length === 0) {
				// const orderPrices = await orderPricesPromise; // TODO: UNCOMMENT IF: FREE PRODUCTS SHOULD BE ADDED TO THE ORDER (FREE PRODUCTS OF COUPON "testproduct2" CAUSES WEBPARKING ERROR)
				await updateOrder({
					id: cart.id,
					shippingAddressId: cart.shippingAddressId,
					billingAddressId: cart.billingAddressId,
					frequency: cart.frequency,
					products: cart.products,
					nonSubscribableProducts: cart.nonSubscribableProducts, // TODO: COMMENT IF: FREE PRODUCTS SHOULD BE ADDED TO THE ORDER (FREE PRODUCTS OF COUPON "testproduct2" CAUSES WEBPARKING ERROR)
					// nonSubscribableProducts: [...cart.nonSubscribableProducts, ...orderPrices.freeNonSubscribableProducts], // TODO: UNCOMMENT IF: FREE PRODUCTS SHOULD BE ADDED TO THE ORDER (FREE PRODUCTS OF COUPON "testproduct2" CAUSES WEBPARKING ERROR)
					deliveryNote: cart.deliveryNote
				});
			}

			// Create a new order payment and redirect the user to the checkout url
			const orderPayment = await createOrderPayment({
				orderId: cart.id,
				returnUrl: window.location.origin + window.location.pathname,
				discountCode: cart.couponCode,
				paymentMethod: checkout.paymentMethod,
				paymentMethodIssuer: checkout.paymentMethodIssuer,
				advocateId: getAdvocateId()
			});

			isProcessingOrder = false;

			window.location.href = orderPayment.checkoutUrl;
		} catch (error) {
			if (error.response.status === 401) {
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	}
</script>

<div class="box">
	<h2 class="box-title">
		{page.paymentMethod.title}
	</h2>

	{#await $checkoutStore then checkout}
		{#await paymentMethodsPromise then paymentMethods}
			<div class="payment-methods">
				{#each paymentMethods as paymentMethod, index}
					<div class="payment-method">
						<label class="payment-method-label">
							<input
								type="radio"
								class="payment-method-input"
								name="paymentMethod"
								value={paymentMethod.id}
								on:change={() => updatePaymentMethod(paymentMethod.id)}
								checked={checkout.paymentMethod === paymentMethod.id ||
									(checkout.paymentMethod === null && index === 0)}
							/>
							<div class="payment-method-indicator" />
							<div class="payment-method-wrap">
								<img
									class="payment-method-logo"
									src={paymentMethod.logo.url}
									alt={paymentMethod.name}
									width="32"
									height="24"
								/>
								<div class="payment-method-title">
									{paymentMethod.name}
								</div>
								{#if paymentMethod.issuers.length > 0}
									{#if checkout.paymentMethod === paymentMethod.id || (checkout.paymentMethod === null && index === 0)}
										<div class="payment-method-pane">
											<select on:change={updatePaymentMethodIssuer} disabled={isProcessingOrder}>
												<option value="">{page.paymentMethod.chooseYourBank}</option>
												{#each paymentMethod.issuers as issuer}
													<option
														value={issuer.id}
														selected={checkout.paymentMethodIssuer === issuer.id}
														>{issuer.name}</option
													>
												{/each}
											</select>
											{#if checkout.paymentMethodIssuerError !== null}
												<div class="payment-method-error">
													{checkout.paymentMethodIssuerError}
												</div>
											{/if}
										</div>
									{/if}
								{/if}
							</div>
						</label>
					</div>
				{/each}
				{#if canPayByInvoice && (!orderFrequency || orderFrequency === 0)}
					<div class="payment-method">
						<label class="payment-method-label">
							<input
								type="radio"
								class="payment-method-input"
								name="paymentMethod"
								value="bank_transfer"
								on:change={() => updatePaymentMethod('bank_transfer')}
								checked={checkout.paymentMethod === 'bank_transfer'}
							/>
							<div class="payment-method-indicator" />
							<div class="payment-method-wrap">
								<div class="payment-method-logo">
									<PaymentIconInvoice />
								</div>
								<div class="payment-method-title">
									{pageStore.data.page.paymentMethod.statee}
								</div>
							</div>
						</label>
					</div>
				{/if}
			</div>

			<div
				use:intersectionObserver={{
					callback: (isIntersecting: boolean) => (ctaInViewport = isIntersecting)
				}}
			>
				<button
					class="button button--full button--secondary"
					on:click={finalizeOrder}
					disabled={isProcessingOrder}
					class:button--fixed={!ctaInViewport}
				>
					<ButtonLoader show={isProcessingOrder} />
					<span
						>{isProcessingOrder
							? page.confirm.submitButtonLoadingLabel
							: page.confirm.submitButtonLabel}</span
					>
				</button>
			</div>
		{/await}
	{/await}
</div>

<style lang="postcss">
	.payment-methods {
		&:not(:last-child) {
			margin-bottom: var(--spacing-6);
		}
	}

	.payment-method {
		&:not(:last-child) {
			margin-bottom: var(--spacing-4);

			@media (--sm) {
				margin-bottom: var(--spacing-3);
			}
		}

		&-label {
			position: relative;
			display: block;
			padding-left: 30px;

			&:after {
				content: '';
				position: absolute;
				z-index: 3;
				top: 0;
				left: 0;
				transform: scale(0.5);
				width: 12px;
				height: 9px;
				background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEyIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMTAuODI3OCAwLjEwOTg2NkMxMC42ODMgLTAuMDM2NjIxOSAxMC40NDMyIC0wLjAzNjYyMTkgMTAuMjk3NCAwLjEwOTg2Nkw0LjcwNTcxIDUuNjc3MzdDNC41NTk4NyA1LjgyNTg1IDQuMzIxMTMgNS44MjU4NSA0LjE3NTMgNS42NzczN0wxLjcyNTA1IDMuMTgxMTNDMS42NTMxMyAzLjEwODM4IDEuNTU3MjQgMy4wNzE0OSAxLjQ2MjM1IDMuMDcxNDlDMS4zNjU0NSAzLjA3MDUgMS4yNjc1NyAzLjEwNjM5IDEuMTkzNjUgMy4xODExM0wwLjExMTg3NCA0LjE1NTcxQzAuMDM4OTU1NyA0LjIyOTQ2IDAgNC4zMjIxMSAwIDQuNDE4NzdDMCA0LjUxNTQzIDAuMDM4OTU1NyA0LjYxODA4IDAuMTExODc0IDQuNjkwODNMNC4xNzUzIDguODkwMTNDNC4zMjExMyA5LjAzNjYyIDQuNTU5ODcgOS4wMzY2MiA0LjcwNTcxIDguODkwMTNMMTEuODkwNiAxLjcxNjI1QzEyLjAzNjUgMS41Njk3NiAxMi4wMzY1IDEuMzI3NTkgMTEuODkwNiAxLjE4MTFMMTAuODI3OCAwLjEwOTg2NloiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo=');
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				opacity: 0;
				transition: all var(--transition);
			}
		}

		&-input {
			display: none;

			&:checked {
				+ .payment-method-indicator {
					background-color: var(--color-primary);
					border-color: var(--color-primary);
					&:before {
						opacity: 1;
						transform: translate(-50%, -50%) scale(0.8);
					}
				}
			}
		}

		&-indicator {
			position: absolute;
			top: 4px;
			left: 0;
			width: 18px;
			height: 18px;
			border: 1px solid var(--border-color);
			border-radius: 50%;

			&:before {
				content: '';
				position: absolute;
				z-index: 3;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(0.5);
				width: 12px;
				height: 9px;
				background-image: var(--form-input-bg-image);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				opacity: 0;
				transition: all var(--transition);
			}
		}

		&-wrap {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

		&-logo {
			margin-right: var(--spacing-2);
			min-height: 24px;
		}

		&-title {
			font-size: var(--font-size-0);
			font-weight: var(--font-weight-5);
			margin-right: var(--spacing-3);
			min-height: 24px;
		}

		&-pane {
			margin-top: var(--spacing-2);
			margin-bottom: var(--spacing-2);
			width: 100%;

			select {
				width: 200px;
			}
		}

		&-error {
			display: block;
			margin-top: var(--spacing-1);
			color: var(--color-red);
			font-size: var(--font-size-0);
			font-weight: var(--font-weight-5);
		}
	}
</style>
