<script lang="ts">
	import type { ProductCategory } from '$models/Product/ProductCategory';
	import type { Subscription } from '$models/Subscription/Subscription';
	import { page } from '$app/stores';
	import OrderItems from '$components/Order/OrderItems.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import {
		calculateTotalProductsQuantity,
		formatCurrency,
		formatDate,
		formatNumericString
	} from '$lib/utils';
	import { calculateSubscriptionPrice } from '$lib/webparking/subscriptions';
	import { onMount } from 'svelte';

	export let subscription: Subscription;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();
	const site = getSiteContext();

	$: translations = $page.data.page.layout.translations;
	$: frequencyString = formatNumericString(
		translations.frequencySingle,
		translations.frequencyMultiple,
		subscription.frequency,
		'%quantity%'
	);

	let subscriptionPricePromise = new Promise(() => {});
	let productCategory: ProductCategory | null = $page.data.products.regular.find(
		(product) => subscription.items.length && product.id === subscription.items[0].id
	)?.category;

	onMount(async () => {
		await $userStore;
		try {
			subscriptionPricePromise = calculateSubscriptionPrice(subscription);
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});
</script>

<div class="summary">
	<div class="summary-row">
		<div class="summary-row-label">{translations.subscriptionStatusLabel}:</div>
		<div class="summary-row-value">{subscription.status.translation}</div>
	</div>
	<div class="summary-row">
		<div class="summary-row-label">{translations.productAmountLabel}:</div>
		<div class="summary-row-value">
			{formatNumericString(
				translations.productAmountTextSingle.replaceAll(
					'%product%',
					productCategory?.translations?.unitNameSingle ?? '%product%'
				),
				translations.productAmountTextMultiple.replaceAll(
					'%products%',
					productCategory?.translations?.unitNamePlural ?? '%products%'
				),
				calculateTotalProductsQuantity(subscription.items, $page.data.products.regular),
				'%quantity%'
			)} / {frequencyString}
		</div>
	</div>
	<div class="summary-row">
		<div class="summary-row-label">{translations.productsLabel}:</div>
		<div class="summary-row-value">
			<OrderItems items={subscription.items} />
		</div>
	</div>
	<div class="summary-row">
		<div class="summary-row-label">{translations.nextDeliveryDateLabel}:</div>
		<div class="summary-row-value">
			{formatDate(subscription.nextDeliveryDate, site.locale.code)}
		</div>
	</div>
	<div class="summary-row">
		<div class="summary-row-label">{translations.totalPriceFrequencyLabel}:</div>
		<!-- TODO: Calculate subscription price -->
		<div class="summary-row-value">
			{#await subscriptionPricePromise then subscriptionPrice}
				{#if subscriptionPrice !== null}
					{formatCurrency(subscriptionPrice, site.locale.code)}
				{/if}
			{/await}
			<small>/ {frequencyString}</small>
		</div>
	</div>
	<div class="summary-row">
		<div class="summary-row-label">{translations.paymentMethodLabel}</div>
		<div class="summary-row-value">{subscription.paymentAccountReference ?? '-'}</div>
	</div>
</div>
