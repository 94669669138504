<script lang="ts">
	import type { Navigation } from '$models/Navigation/Navigation';
	import Menu from './Menu.svelte';

	export let navigation: Navigation;
</script>

<nav class="header-menu">
	<Menu {navigation} />
</nav>

<style lang="postcss" global>
	.header-menu {
		display: none;

		@media (--xl) {
			display: flex;
			flex: 1;
			margin-right: var(--spacing-6);
		}

		ul {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;

			li {
				position: relative;
				margin: 0 var(--spacing-4);

				&.has-children {
					padding-right: 18px;

					&:after {
						content: '';
						position: absolute;
						top: calc(50% - 4px);
						right: 3px;
						z-index: 2;
						display: inline-block;
						border-right: 1.5px solid black;
						border-bottom: 1.5px solid black;
						width: 7px;
						height: 7px;
						transform: rotate(-315deg);

						.is-fixed & {
							border-color: var(--header-text-color-fixed);
						}
					}
				}

				a {
					display: block;
					color: inherit;
					text-decoration: none;
					font-size: var(--header-menu-font-size);
					font-family: var(--header-menu-font-family);
					text-transform: var(--header-menu-text-transform);
					font-weight: var(--header-menu-font-weight);
					border-bottom: 1.5px solid transparent;
					transition: border-color var(--transition);

					&:hover {
						border-color: var(--action-active-color);

						.is-fixed & {
							border-color: var(--header-text-color-fixed);
						}
					}

					&.header-menu-button {
						@media (--xl) {
							padding: 8px 24px;
							border: 0;
							font-family: var(--button-font-family);
							font-weight: var(--button-font-weight);
							color: var(--button-color);
							background-color: var(--button-background);
							border-radius: 22px;
							transition:
								background-color var(--transition),
								border-color var(--transition);

							&:hover {
								background-color: var(--button-background-hover);
							}
						}
					}
				}

				ul {
					position: absolute;
					top: calc(100% + 10px);
					left: 50%;
					transform: translateX(-50%);
					display: block;
					min-width: 148px;
					padding: 10px 14px;
					background-color: var(--header-submenu-bg-color);
					opacity: 0;
					visibility: hidden;
					transition:
						opacity var(--transition),
						visibility var(--transition);

					&:before {
						content: '';
						position: absolute;
						top: -6px;
						left: 50%;
						transform: translateX(-100%);
						border-left: 6px solid transparent;
						border-right: 6px solid transparent;
						border-bottom: 6px solid var(--header-submenu-bg-color);
					}

					li {
						margin: 0;
						padding: 0;

						&:not(:last-child) {
							margin-bottom: 4px;
						}

						a {
							font-size: var(--header-submenu-font-size);
							font-family: var(--header-submenu-font-family);
							text-transform: var(--header-submenu-text-transform);
							font-weight: var(--header-submenu-font-weight);
							color: var(--header-submenu-font-color);
							border-bottom: 0;

							&:hover {
								text-decoration: underline;
							}
						}
					}
				}

				&:hover {
					> ul {
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
</style>
