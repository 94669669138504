import type { Subscription } from '$models/Subscription/Subscription';
import type { ChangePayment as WebparkingChangePaymentMethodResponse } from '$models/Webparking/ChangePayment';
import type { PotentialShipmentDates as WebparkingPotentialShipmentDates } from '$models/Webparking/PotentialShipmentDates';
import type { Subscription as WebparkingSubscription } from '$models/Webparking/Subscriptions';
import type { WebparkingApiResponse } from '$models/Webparking/WebparkingApiResponse';
import { subscriptionToOrder } from '$lib/utils';
import { webparking } from '$lib/webparking';
import { addMonths, format, parse } from 'date-fns';
import { getOrderPricesProductsPrice } from './orders/prices';

enum SubscriptionPriority {
	Active = 1,
	OnHold = 2,
	Cancelled = 3,
	AwaitingPayment = 4
}

export async function calculateSubscriptionPrice(subscription: Subscription) {
	const order = subscriptionToOrder(subscription);
	return await getOrderPricesProductsPrice(order, subscription.frequency);
}

function webparkingSubscriptionDataToSubscription(data: WebparkingSubscription): Subscription {
	return {
		id: data.id,
		number: data.number,
		poNumber: data.po_number ?? null,
		shippingAddressId: data.shipping_address_id,
		billingAddressId: data.billing_address_id,
		frequency: data.order_frequency,
		isOnHold: data.on_hold,
		isCancelled: data.cancelled,
		isAwaitingPayment: data.awaiting_payment,
		createdAt: new Date(data.created_at),
		nextOrderDate: new Date(data.next_order_date),
		nextDeliveryDate: new Date(data.next_delivery_date),
		inTransitOrderDeliveryDate: data.in_transit_order_delivery_date
			? new Date(data.in_transit_order_delivery_date)
			: null,
		paymentMethod: data.payment_method,
		paymentAccountReference: data.payment_account_reference,
		remarks: data.remarks,
		items: data.subscription_items.map((item) => {
			return {
				id: item.product_id,
				quantity: item.quantity
			};
		}),
		status: data.status,
		cancellationReasonId: data.cancellation_reason_id ?? null,
		cancellationRemarks: data.cancellation_remarks ?? null
	};
}

function subscriptionToWebparkingSubscriptionData(
	subscription: Subscription
): WebparkingSubscription {
	return {
		id: subscription.id,
		number: subscription.number,
		po_number: subscription.poNumber ?? null,
		shipping_address_id: subscription.shippingAddressId,
		billing_address_id: subscription.billingAddressId,
		order_frequency: subscription.frequency,
		on_hold: subscription.isOnHold,
		cancelled: subscription.isCancelled,
		awaiting_payment: subscription.isAwaitingPayment,
		next_order_date: format(subscription.nextOrderDate, 'yyyy-MM-dd'),
		next_delivery_date: subscription.nextDeliveryDate
			? format(subscription.nextDeliveryDate, 'yyyy-MM-dd')
			: undefined,
		payment_method: subscription.paymentMethod,
		payment_account_reference: subscription.paymentAccountReference,
		remarks: subscription.remarks,
		subscription_items: subscription.items.map((item) => {
			return {
				product_id: item.id,
				quantity: item.quantity
			};
		}),
		status: subscription.status,
		cancellation_reason_id: subscription.cancellationReasonId ?? undefined,
		cancellation_remarks: subscription.cancellationRemarks ?? undefined
	};
}

interface ListSubscriptionsOptions {
	orderBy?: 'status' | undefined;
}

export async function listSubscriptions(
	options?: ListSubscriptionsOptions
): Promise<Subscription[]> {
	const json = (await webparking.get('subscriptions').json()) as WebparkingApiResponse<
		WebparkingSubscription[]
	>;
	let subscriptions = json.data.map(webparkingSubscriptionDataToSubscription);

	if (options && options?.orderBy === 'status') {
		subscriptions = subscriptions.sort(sortSubscriptions);
	}
	return subscriptions;
}

export async function listPotentialShipmentDates(subscription: Subscription): Promise<Date[]> {
	const today = new Date();
	const json: WebparkingApiResponse<WebparkingPotentialShipmentDates> = await webparking
		.post(`subscriptions/${subscription.id}/potential-shipment-dates`, {
			json: {
				// Ok to hardcode for now, won't change much
				from_date: format(today, 'yyyy-MM-dd'),
				until_date: format(addMonths(today, 3), 'yyyy-MM-dd')
			}
		})
		.json();
	return json.data.map((dateString: string) => parse(dateString, 'yyyy-MM-dd', today));
}

export async function updateSubscription(subscription: Subscription): Promise<Subscription> {
	const json: WebparkingApiResponse<WebparkingSubscription> = await webparking
		.patch(`subscriptions/${subscription.id}`, {
			json: subscriptionToWebparkingSubscriptionData(subscription)
		})
		.json();
	return webparkingSubscriptionDataToSubscription(json.data);
}

export async function changeSubscriptionPaymentMethod(
	subscription: Subscription,
	returnUrl: URL | string
) {
	// Convert strings to URL objects
	if (typeof returnUrl === 'string') {
		returnUrl = new URL(returnUrl);
	}

	const json: WebparkingApiResponse<WebparkingChangePaymentMethodResponse> = await webparking
		.post(`subscriptions/${subscription.id}/change-payment-method`, {
			json: {
				return_url: returnUrl.href
			}
		})
		.json();
	return {
		checkoutUrl: json.data.checkout_url
	};
}

function getSubscriptionPriority(subscription: Subscription): number {
	switch (true) {
		case subscription.isAwaitingPayment:
			return SubscriptionPriority.AwaitingPayment;
		case subscription.isCancelled:
			return SubscriptionPriority.Cancelled;
		case subscription.isOnHold:
			return SubscriptionPriority.OnHold;
		default:
			return SubscriptionPriority.Active; // If all above are false, assume active
	}
}

function sortSubscriptions(a: Subscription, b: Subscription): number {
	return getSubscriptionPriority(a) - getSubscriptionPriority(b);
}
