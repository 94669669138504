<script lang="ts">
	import type { Order } from '$models/Order/Order';
	import type { AccountSubscriptionDetailPage } from '$models/Page/AccountSubscriptionDetailPage';
	import type { ProductCategory } from '$models/Product/ProductCategory';
	import type { Subscription } from '$models/Subscription/Subscription';
	import type { UserAddress } from '$models/User/UserAddress';
	import { page as pageStore } from '$app/stores';
	import OrdersTable from '$components/Account/Orders/OrdersTable.svelte';
	import DeliveryInfo from '$components/Delivery/DeliveryInfo.svelte';
	import OrderItems from '$components/Order/OrderItems.svelte';
	import Loader from '$components/UI/Loader.svelte';
	import ActivateModal from '$components/UI/Modals/ActivateModal.svelte';
	import CancelModal from '$components/UI/Modals/CancelModal.svelte';
	import ChangeDeliveryDateModal from '$components/UI/Modals/ChangeDeliveryDateModal.svelte';
	import ChangePaymentMethodModal from '$components/UI/Modals/ChangePaymentMethodModal.svelte';
	import EditSubscriptionModal from '$components/UI/Modals/EditSubscriptionModal.svelte';
	import PauseModal from '$components/UI/Modals/PauseModal.svelte';
	import UrgentOrderModal from '$components/UI/Modals/UrgentOrderModal.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { trackEvent } from '$lib/datalayer/datalayer';
	import {
		getCartStore,
		getCheckoutStore,
		getModalStore,
		getToastStore,
		getUserStore
	} from '$lib/stores';
	import { isOrderConfiguratorV2 } from '$lib/type-helpers';
	import {
		calculateTotalProductsQuantity,
		formatCurrency,
		formatDate,
		formatNumericString
	} from '$lib/utils';
	import {
		calculateSubscriptionPrice,
		listSubscriptions,
		listUserAddresses,
		updateSubscription
	} from '$lib/webparking';
	import { listOrders } from '$lib/webparking/orders/orders';
	import { isDate, isToday } from 'date-fns';
	import { onMount } from 'svelte';

	// Component properties
	export let page: AccountSubscriptionDetailPage;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const modals = getModalStore();
	const toasts = getToastStore();
	const userStore = getUserStore();
	const site = getSiteContext();

	let subscription: Promise<Subscription> | Subscription;
	let frequencyString: string;

	$: if (subscription !== undefined && $pageStore.url.searchParams.has('action')) {
		triggerModal(subscription, $pageStore.url.searchParams.get('action'));
	}

	$: if ($pageStore.url.searchParams.has('changed-payment-method')) {
		toasts.trigger({
			type: 'success',
			message: page.modals.changePaymentMethod.messages.success
		});
	}

	$: translations = $pageStore.data.page.layout.translations;

	function getOrderConfigurator(type: string, subscription: Subscription) {
		const product = $pageStore.data.products.regular.find((p) => p.id === subscription.items[0].id);
		const configuratorType = page.layout.configurators[type] ?? page.layout.configurators.cartEdit;
		return configuratorType.find((c) => {
			if (isOrderConfiguratorV2(c)) {
				return c.configuration.categories.find(
					({ productCategory }) => productCategory.id === product.category.id
				);
			}

			// For V1 configurators
			return c.productCategory?.id === product.category.id;
		});
	}

	async function getSubscription(id: string) {
		try {
			const subscriptions = await listSubscriptions();
			const subscription = subscriptions.find((s) => s.id === id);
			if (!subscription) {
				throw new Error('Subscription not found');
			}
			return subscription;
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	}

	function setSubscription(newSubscription: Subscription) {
		// Re-fetch from Webparking to reflect actual data
		subscription = getSubscription(newSubscription.id);
	}

	async function triggerModal(subscriptionPromise: Promise<Subscription>, action: string) {
		const subscription = await subscriptionPromise;
		switch (action) {
			case 'activate':
				triggerActivateModal(subscription);
				break;
			case 'change_date':
				triggerChangeDeliveryDateModal(subscription);
				break;
			case 'urgent_order':
				triggerUrgentOrderModal(subscription, []);
				break;
		}
	}

	function triggerActivateModal(subscription: Subscription) {
		// REMINDER: This also needs the last "checkout" step like in urgent order
		const { title, body, messages, translations } = page.modals.activateSubscription;
		const configurator = getOrderConfigurator('subscriptionActivate', subscription);

		// Only show for subscriptions where it applies
		if (!subscription.isOnHold && !subscription.isCancelled) {
			return;
		}

		modals.trigger({
			title,
			body,
			type: 'component',
			component: {
				ref: ActivateModal,
				props: {
					onSuccess: (subscription: Subscription) => {
						trackEvent('activate_subscription');
						setSubscription(subscription);
					},
					subscription,
					messages,
					translations,
					configurator
				}
			}
		});
	}

	function triggerCancelModal(subscription: Subscription) {
		const { title, body, messages, translations } = page.modals.cancelSubscription;
		const { cancelReasons } = page;

		if (cancelReasons.length > 0) {
			// Cancel wizard with options for different reasons.
			modals.trigger({
				// title,
				// body,
				type: 'component',
				component: {
					ref: CancelModal,
					props: {
						onSuccess: (subscription: Subscription) => {
							trackEvent('cancel_subscription');
							setSubscription(subscription);
						},
						onPause: (subscription) => triggerPauseModal(subscription),
						onUrgentOrder: (subscription) => triggerUrgentOrderModal(subscription, []),
						subscription,
						messages,
						translations: {
							...translations,
							title,
							body
						},
						cancelReasons
					}
				}
			});
		} else {
			// Basic confirm modal which cancels immediately
			modals.trigger({
				title,
				body,
				type: 'confirm',
				confirm: {
					confirmText: translations.confirmButtonText,
					cancelText: translations.cancelButtonText
				},
				response: async (r) => {
					if (!r) {
						return;
					}

					try {
						subscription = await subscription;

						subscription.isCancelled = true;

						subscription = await updateSubscription(subscription);

						toasts.trigger({
							type: 'success',
							message: messages.success
						});

						modals.close();

						trackEvent('cancel_subscription');

						setSubscription(subscription);
					} catch (error) {
						if (error.response.status === 401) {
							const cart = await $cartStore;
							cartStore.anonymizeCart(cart);
							checkoutStore.clearCheckout();
							userStore.setUser(null);
						}
						toasts.trigger({
							type: 'error',
							message: messages.error
						});
					}
				}
			});
		}
	}

	function triggerChangeDeliveryDateModal(subscription: Subscription) {
		const { title, body, messages, translations } = page.modals.changeDeliveryDate;
		const configurator = getOrderConfigurator('subscriptionChangeDeliveryDate', subscription);
		modals.trigger({
			title,
			type: 'component',
			component: {
				ref: ChangeDeliveryDateModal,
				props: {
					onSuccess: (subscription: Subscription) => {
						// Changing delivery date is considered a temporary pause
						trackEvent('pause_subscription_temporary');
						setSubscription(subscription);
					},
					subscription,
					messages,
					translations: {
						...translations,
						introText: body
					},
					configurator
				}
			}
		});
	}

	function triggerChangePaymentMethodModal(subscription: Subscription) {
		const { title, body, messages, translations } = page.modals.changePaymentMethod;
		const returnUrl = new URL($pageStore.url);

		returnUrl.searchParams.append('changed-payment-method', 'true');

		modals.trigger({
			title,
			body,
			type: 'component',
			component: {
				ref: ChangePaymentMethodModal,
				props: {
					subscription,
					messages,
					translations,
					returnUrl
				}
			}
		});
	}

	function triggerEditSubscriptionModal(subscription: Subscription) {
		const { title, body, messages } = page.modals.editSubscription;
		const configurator = getOrderConfigurator('cartEdit', subscription);
		modals.trigger({
			title,
			body,
			type: 'component',
			component: {
				ref: EditSubscriptionModal,
				props: {
					onSuccess: setSubscription,
					subscription,
					messages,
					configurator
				}
			}
		});
	}

	function triggerPauseModal(subscription: Subscription) {
		const { title, body, messages, translations } = page.modals.pauseSubscription;
		modals.trigger({
			title,
			body,
			type: 'component',
			component: {
				ref: PauseModal,
				props: {
					onSuccess: (subscription: Subscription) => {
						trackEvent('pause_subscription_temporary');
						setSubscription(subscription);
					},
					subscription,
					messages,
					translations
				}
			}
		});
	}

	function triggerUrgentOrderModal(subscription: Subscription, urgentOrders: Order[]) {
		const urgentOrderCount = urgentOrders.length;
		const urgentOrderCountToday = urgentOrders.filter(
			(order) => isDate(order.completedAt) && isToday(order.completedAt)
		).length;
		const dailyUrgentOrderLimit = 2; // TODO: Maybe move the daily urgent order limit to an option field?
		const initialStatus = subscription.status;

		if (urgentOrderCountToday < dailyUrgentOrderLimit) {
			const { title, body, messages, translations } = page.modals.urgentOrder;
			const configurator = getOrderConfigurator('subscriptionUrgentOrder', subscription);

			modals.trigger({
				title,
				body,
				type: 'component',
				component: {
					ref: UrgentOrderModal,
					props: {
						onSuccess: (subscription: Subscription) => {
							if (initialStatus.id === 'cancelled') {
								trackEvent('activate_subscription');
								trackEvent('emergency_order_cancelled_customers');
							} else {
								trackEvent('emergency_order_active_customers');
							}
							setSubscription(subscription);
						},
						subscription,
						messages,
						translations,
						configurator,
						skipIntro: urgentOrderCount > 0
					}
				}
			});
		} else {
			const { title, body } = page.modals.urgentOrderLimit;
			modals.trigger({
				title,
				body,
				type: 'confirm'
			});
		}
	}

	function listUserSubscriptionAddresses(subscription: Subscription): Promise<UserAddress[]> {
		return new Promise(async (resolve) => {
			const addresses = await listUserAddresses();
			const billingAddressID = subscription.billingAddressId;
			const shippingAddressID = subscription.shippingAddressId;

			const subscriptionAddresses = addresses.filter(
				(address) => address.id === billingAddressID || address.id === shippingAddressID
			);

			resolve(subscriptionAddresses);
		});
	}

	let userAddressesPromise = new Promise(() => {});
	let urgentOrdersPromise = new Promise(() => {});
	let ordersPromise = new Promise(() => {});
	let subscriptionPricePromise = new Promise(() => {});
	let userSubscriptionAddressesPromise = new Promise(() => {});
	let productCategory: ProductCategory | null;

	onMount(async () => {
		await $userStore;
		try {
			subscription = getSubscription(page.resourceId);
			const subscriptionValue = await subscription;
			const product = $pageStore.data.products.regular.find(
				(product) => product.id === subscriptionValue.items[0].id
			);

			productCategory = product.category;

			userSubscriptionAddressesPromise = listUserSubscriptionAddresses(subscriptionValue);

			urgentOrdersPromise = listOrders({ emergencyOrderForSubscriptionId: subscriptionValue.id });
			ordersPromise = listOrders({ subscriptionId: subscriptionValue.id });
			subscriptionPricePromise = calculateSubscriptionPrice(subscriptionValue);
		} catch (error) {
			if (error.response && error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});
</script>

{#await subscription}
	<Loader />
{:then subscription}
	{#if subscription}
		{@const frequencyString = formatNumericString(
			translations.frequencySingle,
			translations.frequencyMultiple,
			subscription.frequency,
			'%quantity%'
		)}
		<div class="account-columns">
			<div class="account-column">
				<div class="account-box">
					<div class="box">
						<h2 class="box-title">
							{subscription.number}
						</h2>
						<div class="summary">
							<div class="summary-row">
								<div class="summary-row-label">{translations.subscriptionStatusLabel}:</div>
								<div class="summary-row-value">{subscription.status.translation}</div>
								{#if subscription.status.id === 'active'}
									<button
										type="button"
										class="summary-row-edit"
										on:click={() => triggerPauseModal(subscription)}
									>
										{translations.buttonLabels.pauseSubscription}
									</button>
								{/if}
							</div>
							<div class="summary-row">
								<div class="summary-row-label">{translations.productsLabel}:</div>
								<div class="summary-row-value">
									<strong>
										{formatNumericString(
											translations.productAmountTextSingle.replaceAll(
												'%product%',
												productCategory?.translations?.unitNameSingle ?? '%product%'
											),
											translations.productAmountTextMultiple.replaceAll(
												'%products%',
												productCategory?.translations?.unitNamePlural ?? '%products%'
											),
											calculateTotalProductsQuantity(
												subscription.items,
												$pageStore.data.products.regular
											),
											'%quantity%'
										)} / {frequencyString}
									</strong>
									<OrderItems items={subscription.items} />
								</div>
								{#if !subscription.isCancelled}
									<button
										type="button"
										class="summary-row-edit"
										on:click={() => triggerEditSubscriptionModal(subscription)}
									>
										{translations.buttonLabels.editSubscription}
									</button>
								{/if}
							</div>
							<div class="summary-row">
								<div class="summary-row-label">{translations.nextDeliveryDateLabel}:</div>
								<div class="summary-row-value">
									{formatDate(subscription.nextDeliveryDate, site.locale.code)}
								</div>
								{#if !subscription.isCancelled}
									<button
										type="button"
										class="summary-row-edit"
										on:click={() => triggerChangeDeliveryDateModal(subscription)}
									>
										{translations.buttonLabels.changeSubscriptionDate}
									</button>
								{/if}
							</div>
							<div class="summary-row">
								<div class="summary-row-label">{translations.totalPriceFrequencyLabel}:</div>
								<div class="summary-row-value">
									{#await subscriptionPricePromise then subScriptionPrice}
										{#if subScriptionPrice !== null}
											{formatCurrency(subScriptionPrice, site.locale.code)}
										{/if}
									{/await}
									<small>/ {frequencyString}</small>
								</div>
							</div>
							<div class="summary-row">
								<div class="summary-row-label">{translations.paymentMethodLabel}</div>
								<div class="summary-row-value">{subscription.paymentAccountReference ?? '-'}</div>
								<button
									type="button"
									class="summary-row-edit"
									on:click={() => triggerChangePaymentMethodModal(subscription)}
								>
									{translations.buttonLabels.changeSubscriptionPaymentMethod}
								</button>
							</div>
							{#if subscription.status.id !== 'cancelled'}
								<div class="summary-row">
									<button
										type="button"
										class="summary-row-cancel"
										on:click={() => triggerCancelModal(subscription)}
									>
										{translations.buttonLabels.cancelSubscription}
									</button>
								</div>
							{/if}
						</div>

						{#if subscription.inTransitOrderDeliveryDate !== null}
							<div class="message message--info">
								{@html page.layout.translations.inTransitOrderText.replace(
									'%delivery_date%',
									formatDate(subscription.inTransitOrderDeliveryDate, site.locale.code)
								)}
							</div>
						{/if}

						{#if subscription.status.id === 'cancelled' || subscription.status.id === 'on_hold'}
							<button
								type="button"
								class="button button--highlight"
								on:click={() => triggerActivateModal(subscription)}
							>
								{page.layout.translations.buttonLabels.subscriptionActivateButtonLabel}
							</button>
						{/if}
					</div>
				</div>
				<div class="account-box account-box--mobile">
					<div class="box">
						{#await urgentOrdersPromise}
							<Loader />
						{:then urgentOrders}
							<h2 class="box-title">
								{page.urgentOrderBox.title}
							</h2>
							<div class="rich-text">
								{@html page.urgentOrderBox.text}
								<DeliveryInfo />
								<button
									type="button"
									class="button button--secondary"
									on:click={() => triggerUrgentOrderModal(subscription, urgentOrders)}
								>
									{translations.buttonLabels.placeUrgentOrder}
								</button>
							</div>
						{/await}
					</div>
				</div>
				{#await userSubscriptionAddressesPromise then addresses}
					{#each addresses as address}
						<div class="account-box">
							<div class="box">
								<h2 class="box-title">
									{#if address.type === 'shipping'}
										{translations.entities.shippingAddress}
									{:else}
										{translations.entities.billingAddress}
									{/if}
								</h2>
								<address class="box-address">
									{address.street}
									{address.houseNumber}{address.houseNumberSuffix.length
										? '-'
										: ''}{address.houseNumberSuffix} <br />
									{address.postalCode}
									{address.city} <br />
									{address.country}
								</address>
								<a
									class="box-edit"
									href="{page.layout.pages.accountAddressesPage}/?resourceId={address.id}"
								>
									<span>
										{translations.buttonLabels.edit}
									</span>
								</a>
							</div>
						</div>
					{/each}
				{/await}
			</div>
			<div class="account-column">
				<div class="account-box account-box--desktop">
					<div class="box">
						{#await urgentOrdersPromise}
							<Loader />
						{:then urgentOrders}
							<h2 class="box-title">
								{page.urgentOrderBox.title}
							</h2>
							<div class="rich-text">
								{@html page.urgentOrderBox.text}
								<DeliveryInfo />
								<button
									type="button"
									class="button button--secondary"
									on:click={() => triggerUrgentOrderModal(subscription, urgentOrders)}
								>
									{translations.buttonLabels.placeUrgentOrder}
								</button>
							</div>
						{/await}
					</div>
				</div>
				<div class="account-box">
					<div class="box">
						{#await ordersPromise}
							<Loader />
						{:then orders}
							{@const totalOrders = orders.length}
							<h2 class="box-title">
								{translations.entities.orders}
							</h2>

							{#if !orders.length}
								{translations.errors.noOrdersFound}
							{/if}

							{#if totalOrders > 0}
								{#if totalOrders > 4}
									<OrdersTable compact orders={orders.slice(0, 4)} />
									<a href={$pageStore.data.page.layout.pages.accountOrdersPage} class="box-link">
										{$pageStore.data.page.layout.translations.buttonLabels.viewOrders}
									</a>
								{:else}
									<OrdersTable compact {orders} />
								{/if}
							{/if}
						{:catch error}
							{translations.errors.failedToLoadOrders}
						{/await}
					</div>
				</div>
			</div>
		</div>
	{/if}
{:catch error}
	{error.failedToLoadSubscription}
{/await}
