<script lang="ts">
	import type { FaqBlock } from '$models/Block/FaqBlock';

	export let block: FaqBlock;

	let slicedTopics = splitTopics(block.topics);

	function splitTopics(topics: any[]) {
		const half = Math.ceil(topics.length / 2);
		return [topics.slice(0, half), topics.slice(half)];
	}
</script>

<div class="block-main">
	{#if block.text}
		<div class="block-text">
			{@html block.text}
		</div>
	{/if}

	<div class="faqs">
		{#each slicedTopics as topics}
			<div class="faqs-column">
				{#each topics as topic}
					<div class="faqs-group first">
						{#if topic.title}
							<h2 class="faqs-title">{topic.title}</h2>
						{/if}
						{#each topic.questions as question}
							<details class="faqs-item">
								<summary class="faqs-toggle">{question.question}</summary>
								<div class="faqs-text rich-text">
									{@html question.answer}
								</div>
							</details>
						{/each}
					</div>
				{/each}
			</div>
		{/each}
	</div>
</div>

<style lang="postcss">
	.faqs {
		& {
			@media (--lg) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}
		}
		&-column {
			@media (--lg) {
				width: calc(50% - 20px);
			}
		}
		&-group {
			margin-bottom: var(--spacing-8);

			@media (--lg) {
				margin-bottom: 0;

				&:not(:last-child) {
					margin-bottom: var(--spacing-10);
				}
			}
		}
		&-title {
			font-size: var(--faqs-title-font-size);
			font-weight: var(--faqs-title-font-weight);
			text-transform: var(--faqs-title-text-transform);
			&:not(:last-child) {
				margin-bottom: var(--spacing-4);
			}
		}
		&-item {
			padding: var(--spacing-4) 0;
			border-top: 1px solid var(--border-color);
			transition: border-color var(--transition);

			&:last-child {
				border-bottom: 1px solid var(--border-color);
			}

			&:not(:last-child) {
				margin-bottom: 0;
			}

			&[open] {
				> summary {
					display: block;
					&:after {
						transform: translateY(2px) rotate(225deg);
					}
				}
			}
		}
		&-toggle {
			position: relative;
			font-size: var(--faqs-toggle-font-size);
			font-weight: var(--faqs-toggle-font-weight);
			line-height: var(--font-lineheight-2);
			color: var(--color-black);
			text-decoration: none;
			padding-right: var(--spacing-4);

			&:hover {
				cursor: pointer;
			}

			&:after {
				position: absolute;
				top: 3px;
				right: 0;
				align-self: flex-start;
				margin-left: auto;
				margin-right: 4px;
			}
		}
		&-text {
			padding-top: var(--spacing-2);
			font-size: var(--faqs-text-font-size);
			font-weight: var(--faqs-text-font-weight);
			line-height: var(--faqs-text-lineheight);
		}
	}
</style>
