<script lang="ts">
	import type { OrderConfigurator } from '$models/OrderConfigurator/OrderConfigurator';
	import { page } from '$app/stores';
	import RangeSlider from '$components/UI/FormElements/RangeSlider.svelte';
	import PortionSuggestions from '$components/UI/Tooltips/PortionSuggestions.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { formatCurrency, formatNumericString, sanitizeHtml } from '$lib/utils';

	// Component properties
	export let translations: any;
	export let configurator: OrderConfigurator;
	export let configuratorStore: any;
	export let step: Step;
	export let index: number;
	export let entityEventHandlers: any;

	const site = getSiteContext();

	$: translations = $page.data.page.layout.translations;

	function setTarget(event) {
		const targetContainerElement = event.target.closest('[data-target-container]');
		const targetFrequency = parseInt(
			targetContainerElement.querySelector('[name="targetFrequency"]').value
		);
		const targetQuantity = parseInt(
			targetContainerElement.querySelector('[name="targetQuantity"]').value
		);

		// Make sure the range sliders don't exceed the new maximum
		Object.keys(configuratorStore.items[step.entityType]).forEach((productExternalId) => {
			if (configuratorStore.items[step.entityType][productExternalId] > targetQuantity) {
				configuratorStore.setEntityAmount(step.entityType, productExternalId, targetQuantity);
			}
		});

		configuratorStore.setTarget(targetFrequency, targetQuantity);
		configuratorStore.setIsRecurringOrder(targetFrequency > 0);

		// Make sure the error message is updated according to the new maximum

		configuratorStore.setEntityErrorText(step.entityType);
	}

	// Make sure an order configurator with an all in one step is initialized to allow only the selected quantity
	configuratorStore.setTarget(configuratorStore.targetFrequency, configuratorStore.targetQuantity);
	configuratorStore.setIsRecurringOrder(configuratorStore.targetFrequency > 0);
</script>

<div class="aio-step">
	{#if step.sidebarTitle && step.sidebarTitle.length && step.sidebarText && step.sidebarText.length}
		<div class="aio-step-info">
			<h2 class="aio-step-info-title">
				{step.sidebarTitle}
			</h2>
			<div class="aio-step-info-text rich-text">
				{@html sanitizeHtml(step.sidebarText)}
			</div>
		</div>
	{/if}

	<div class="aio-step-main">
		<h3 class="aio-step-main-title">
			{#if configuratorStore.targetFrequency === 0}
				{step.selectFieldsZeroLabel}
			{:else}
				{step.selectFieldsLabel}
			{/if}
		</h3>
		<div class="aio-step-header">
			<div class="aio-step-header-selects" data-target-container>
				<select
					class="aio-step-header-select"
					name="targetFrequency"
					on:change={setTarget}
					disabled={configurator.frequencies.length === 1}
				>
					{#each configurator.frequencies as frequency}
						<option value={frequency} selected={configuratorStore.targetFrequency == frequency}>
							{#if frequency == 0}
								{translations.frequencyOnetime}
							{:else}
								{formatNumericString(
									translations.frequencySingle,
									translations.frequencyMultiple,
									frequency,
									'%quantity%'
								)}
							{/if}
						</option>
					{/each}
				</select>
				<select
					class="aio-step-header-select"
					name="targetQuantity"
					on:change={setTarget}
					disabled={configurator.quantities.length === 1}
				>
					{#each configurator.quantities as quantity}
						<option value={quantity} selected={configuratorStore.targetQuantity == quantity}>
							{formatNumericString(
								translations.productAmountTextSingle.replaceAll(
									'%product%',
									configurator?.productCategory?.translations?.unitNameSingle ?? '%product%'
								),
								translations.productAmountTextMultiple.replaceAll(
									'%products%',
									configurator?.productCategory?.translations?.unitNamePlural ?? '%products%'
								),
								quantity * configuratorStore.quantityMultiplier,
								'%quantity%'
							)}
						</option>
					{/each}
				</select>
			</div>
			{#if configuratorStore.targetQuantity > 0}
				<div class="aio-step-header-price">
					{#await configuratorStore.targetPrices then targetPrices}
						{#if configuratorStore.targetFrequency > 0 && targetPrices.productsRecurringPrice !== null}
							{#if targetPrices.discountAmount > 0}
								<div class="aio-step-header-price-value aio-step-header-price-value--discount">
									<small>{translations.discountPriceLabel}</small>
									{formatCurrency(
										targetPrices.productsRecurringPrice - targetPrices.discountAmount,
										site.locale.code
									)}
									<small>
										{translations.discountRegularPriceLabel.replace(
											'%price%',
											formatCurrency(targetPrices.productsRecurringPrice, site.locale.code)
										)}
									</small>
								</div>
							{:else}
								<div class="aio-step-header-price-value">
									{formatCurrency(targetPrices.productsRecurringPrice, site.locale.code)}
								</div>
							{/if}
						{:else if configuratorStore.targetFrequency === 0 && targetPrices.productsOneTimePrice !== null}
							{#if targetPrices.discountAmountOnetime > 0}
								<div class="aio-step-header-price-value aio-step-header-price-value--discount">
									<small>{translations.discountPriceLabel}</small>
									{formatCurrency(
										targetPrices.productsOneTimePrice - targetPrices.discountAmountOnetime,
										site.locale.code
									)}
									<small>
										{translations.discountRegularPriceLabel.replace(
											'%price%',
											formatCurrency(targetPrices.productsOneTimePrice, site.locale.code)
										)}
									</small>
								</div>
							{:else}
								<div class="aio-step-header-price-value">
									{formatCurrency(targetPrices.productsOneTimePrice, site.locale.code)}
								</div>
							{/if}
						{/if}

						{#if targetPrices.discountAmount <= 0}
							<div class="aio-step-header-price-frequency">
								{#if configuratorStore.targetFrequency > 0}
									/
									{formatNumericString(
										translations.frequencySingle,
										translations.frequencyMultiple,
										configuratorStore.targetFrequency,
										'%quantity%'
									)}
								{/if}
							</div>
						{/if}

						{#if site.showUnitPrices}
							<div class="aio-step-header-price-per-unit">
								{formatCurrency(
									(targetPrices.productsRecurringPrice - targetPrices.discountAmount) /
										(configuratorStore.targetQuantity * configuratorStore.quantityMultiplier),
									site.locale.code
								)}
								{translations.perUnitText.replaceAll(
									'%product%',
									configurator.productCategory?.translations.unitNameSingle ?? '%product%'
								)}
							</div>
						{/if}
					{/await}
				</div>
			{/if}
		</div>
		{#if configuratorStore.targetQuantity > 0}
			{#if step.showPortionsTooltip && configuratorStore.targetFrequency > 0}
				<PortionSuggestions
					portions={configurator.products[0].portions * configuratorStore.targetQuantity}
					frequency={configuratorStore.targetFrequency}
					days={step.amountOfPortionsPeriodDays}
					translations={{
						totalSingle: step.amountOfPortionsTotalSingle,
						totalPlural: step.amountOfPortionsTotalPlural,
						perPeriodSingle: step.amountOfPortionsPerPeriodSingle,
						perPeriodPlural: step.amountOfPortionsPerPeriodPlural
					}}
				/>
			{/if}
			<div class="aio-step-content">
				<h3 class="aio-step-main-title">
					{step.productSelectionTitle}
				</h3>
				<div class="aio-step-sliders">
					{#each configuratorStore.getEntities(step.entityType) as product}
						<div class="aio-step-slider" data-product-external-id={product.id}>
							<div class="aio-step-slider-label">
								{product.name}
							</div>
							<div class="aio-step-slider-range">
								<RangeSlider
									min={0}
									max={configuratorStore.targetQuantity * (product.quantityMultiplier || 1)}
									step={product.quantityMultiplier || 1}
									value={configuratorStore.getSelectedEntityAmount(step.entityType, product.id)}
									onChange={(e) => entityEventHandlers.onInputChange(e, product)}
								/>
							</div>
							<div class="aio-step-slider-value">
								{configuratorStore.getSelectedEntityAmount(step.entityType, product.id)}
							</div>
						</div>
					{/each}
				</div>
				<div class="aio-step-totals">
					{#if configuratorStore.entityErrorText[step.entityType] !== null}
						<div class="aio-step-totals-error">
							{configuratorStore.entityErrorText[step.entityType]}
						</div>
					{/if}
					<div class="aio-step-totals-count">
						<span class="aio-step-totals-count-value">
							{configuratorStore.getTotalAmount(step.entityType)}
						</span>
						/
						<span class="aio-step-totals-count-value">
							{#if step.entityType === 'product'}
								{configuratorStore.targetQuantity * (configuratorStore.quantityMultiplier || 1)}
							{:else}
								{configuratorStore.targetQuantity}
							{/if}
						</span>
					</div>
				</div>
			</div>
		{/if}
		{#await configuratorStore.prices}
			<div class="aio-step-button">
				<button
					class="button button--secondary disabled"
					on:click={configuratorStore.goToNextStepIfValid[step.entityType]}
				>
					{step.buttonText}
				</button>
			</div>
		{:then prices}
			<div class="aio-step-button">
				<button
					class="button button--secondary"
					class:disabled={prices.productsLowestRecurringPrice === null}
					on:click={configuratorStore.goToNextStepIfValid[step.entityType]}
				>
					{step.buttonText}
				</button>
			</div>
			{#if site.showWeightPrices}
				<div class="aio-step-volume-price">
					<small>
						{#if configuratorStore.targetFrequency > 0}
							{formatCurrency(
								(prices.productsRecurringPrice - prices.discountAmount) /
									(configuratorStore.targetQuantity * configuratorStore.quantityMultiplier) /
									0.0053,
								site.locale.code
							)} / 1kg
						{:else if site.showWeightPrices}
							{formatCurrency(
								(prices.productsOneTimePrice - prices.discountAmountOnetime) /
									(configuratorStore.targetQuantity * configuratorStore.quantityMultiplier) /
									0.0053,
								site.locale.code
							)} / 1kg
						{/if}
					</small>
				</div>
			{/if}
		{/await}
	</div>
</div>

<style lang="postcss">
	.aio-step {
		& {
			@media (--lg) {
				display: flex;
			}
		}

		&-info {
			margin-bottom: var(--spacing-7);
			@media (--lg) {
				width: 356px;
				padding-right: var(--spacing-10);
			}

			&-title {
				margin-bottom: var(--spacing-4);
			}
		}

		&-main {
			flex: 1;

			&-title {
				margin-bottom: var(--spacing-4);
			}
		}

		&-header {
			& {
				width: 100%;
			}

			@media (--sm) {
				display: flex;
				align-items: center;
			}

			&-selects {
				width: 100%;
				margin-bottom: var(--spacing-6);
				@media (--sm) {
					display: flex;
					align-self: flex-start;
					justify-content: space-between;
					max-width: 60%;
					margin-bottom: 0;
				}
			}

			&-select {
				@media (--sm) {
					width: calc(50% - 7px);
				}

				&:not(:last-child) {
					margin-bottom: var(--spacing-3);

					@media (--sm) {
						margin-bottom: 0;
					}
				}
			}

			&-price {
				flex: 1;
				display: flex;
				flex-wrap: wrap;
				align-items: flex-end;
				line-height: var(--font-lineheight-0);

				@media (--sm) {
					justify-content: flex-end;
				}

				&-value {
					margin-left: auto;
					font-size: var(--font-size-4);

					small {
						display: block;
						font-size: var(--font-size-0);
						color: var(--color-grey-3);
					}

					&--discount {
						text-align: right;
						line-height: var(--font-lineheight-1);
					}
				}

				&-frequency {
					margin-left: 4px;
					font-size: var(--font-size-0);
					color: var(--color-grey-3);
				}

				&-per-unit {
					display: block;
					width: 100%;
					font-size: 13px;
					text-align: right;
					color: var(--color-grey-2);
					margin-top: var(--spacing-1);
				}
			}
		}

		&-content {
			padding-top: var(--spacing-6);
		}

		&-sliders {
			&:not(:last-child) {
				margin-bottom: var(--spacing-6);
			}
		}

		&-slider {
			position: relative;
			font-size: var(--font-size-0);

			@media (--sm) {
				display: flex;
				align-items: center;
				font-size: var(--font-size-1);
			}

			&:not(:last-child) {
				margin-bottom: var(--spacing-5);

				@media (--sm) {
					margin-bottom: var(--spacing-6);
				}
			}

			&-label {
				font-family: var(--step-slider-label-font-family);
				font-size: var(--step-slider-label-font-size);
				font-weight: var(--step-slider-label-font-weight);
				color: var(--step-slider-label-color);
				text-transform: var(--step-slider-label-text-transform);
				margin-bottom: 4px;

				@media (--sm) {
					width: 200px;
					margin-bottom: 0;
				}
			}

			&-range {
				@media (--sm) {
					flex: 1;
				}
			}

			&-value {
				position: absolute;
				z-index: 2;
				top: 0;
				right: 0;
				text-align: right;

				@media (--sm) {
					position: relative;
					top: auto;
					right: auto;
					margin-left: var(--spacing-4);
				}
			}
		}

		&-totals {
			position: relative;
			display: flex;
			justify-content: flex-end;
			text-align: right;
			padding-top: var(--spacing-4);
			margin-bottom: var(--spacing-6);

			&:before {
				content: '';
				width: 60px;
				height: 2px;
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				background-color: var(--color-primary);
			}

			&-error {
				position: relative;
				margin-right: var(--spacing-3);
				font-size: 13px;
				text-align: right;
				line-height: var(--font-lineheight-1);
				padding: 10px 12px 10px 35px;
				color: white;
				white-space: nowrap;
				background-color: var(--color-red);
				background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNCI+PHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJtOCAwIDggMTRIMEw4IDBaTTcgNC45NzZjMC0uNTU3LjQ0NC0xLjAxIDEtMS4wMS41NTIgMCAxIC40NDggMSAxLjAxdjMuOTMxYzAgLjU1OC0uNDQ0IDEuMDEtMSAxLjAxLS41NTIgMC0xLS40NDgtMS0xLjAxVjQuOTc2Wk03IDExLjlhLjk5My45OTMgMCAwIDEgMS0uOTkyYy41NTIgMCAxIC40NCAxIC45OTJhLjk5My45OTMgMCAwIDEtMSAuOTkyYy0uNTUyIDAtMS0uNDQtMS0uOTkyWiIvPjwvc3ZnPg==');
				background-size: 16px 14px;
				background-position: 11px calc(50% - 1px);
				background-repeat: no-repeat;
				border-radius: var(--radius-2);

				&:after {
					content: '';
					position: absolute;
					top: calc(50% - 8px);
					right: -6px;
					width: 0;
					height: 0;
					border-top: 8px solid transparent;
					border-bottom: 8px solid transparent;
					border-left: 8px solid var(--color-red);
				}
			}

			&-count {
				font-size: 1.25rem;
				text-transform: uppercase;
				position: relative;
				display: inline-block;

				&-value {
					color: var(--color-grey-2);
				}
			}
		}

		&-button {
			display: flex;
			justify-content: flex-end;
		}

		&-volume-price {
			display: flex;
			justify-content: flex-end;
			margin-top: var(--spacing-4);
		}
	}
</style>
