<script lang="ts">
	import type { OrderPrices } from '$models/Order/OrderPrices';
	import type { OrderConfiguratorDualStep } from '$models/OrderConfigurator/OrderConfiguratorDualStep';
	import type { OrderConfigurationOrder } from '$models/OrderConfigurator/OrderConfiguratorV2';
	import type { ProductCategory } from '$models/Product/ProductCategory';
	import type { EmblaCarouselType } from 'embla-carousel';
	import { page } from '$app/state';
	import PriceScaleMessage from '$components/PriceScale/PriceScaleMessage.svelte';
	import QuantityValidationMessage from '$components/Quantity/QuantityValidationMessage.svelte';
	import Slider from '$components/UI/Slider/Slider.svelte';
	import { getSiteContext } from '$lib/context/site';
	import { getConfiguratorState } from '$lib/state/configurator.svelte';
	import { formatCurrency, formatNumericString } from '$lib/utils';
	import { tick } from 'svelte';
	import OrderConfiguratorCategories from './Components/OrderConfiguratorCategories.svelte';
	import OrderConfiguratorStepHeader from './OrderConfiguratorStepHeader.svelte';

	interface Props {
		configurator: OrderConfiguratorDualStep;
		onSubmit: (order: OrderConfigurationOrder) => void;
	}

	let { configurator, onSubmit }: Props = $props();

	const configuratorState = getConfiguratorState();
	const site = getSiteContext();
	const translations = page.data.page.layout.translations;

	// Assign some local variables for ergonomics and readability
	let quantityMultiplier = $derived(configuratorState.quantityMultiplier());
	let prices = $derived(configuratorState.prices());
	let selectedCategory = $derived(configuratorState.selectedCategory());
	let selectedQuantity = $derived(configuratorState.selectedQuantity());
	let selectedDisplayQuantity = $derived(selectedQuantity * quantityMultiplier);

	// Local state
	let requiresCategorySelection = $derived(configuratorState.categories().length > 1);
	let userSelectedFrequency: number = $state(configurator.configuration.defaultFrequency ?? 2);

	let currentStep: number = $state(0);
	let stepElements: {
		productSelection: HTMLElement | null;
		frequencySelection: HTMLElement | null;
		nonSubscribableProductsSelection: HTMLElement | null;
	} = {
		productSelection: null,
		frequencySelection: null,
		nonSubscribableProductsSelection: null
	};

	let nonSubscribableProductsSlider: EmblaCarouselType | undefined = $state();
	let nonSubscribableProductsScrollSnapList: any[] = $state([]);
	let nonSubscribableProductsSelectedScrollSnap: number = $state(0);

	function initSlider(e) {
		nonSubscribableProductsSlider = e.detail as EmblaCarouselType;
		nonSubscribableProductsScrollSnapList = nonSubscribableProductsSlider.scrollSnapList();
		// Keep track of snap when it changes
		nonSubscribableProductsSlider.on('select', (instance) => {
			nonSubscribableProductsSelectedScrollSnap = instance.selectedScrollSnap();
		});
		// Update snap on resize
		nonSubscribableProductsSlider.on('resize', (instance) => {
			nonSubscribableProductsScrollSnapList = instance.scrollSnapList();
			nonSubscribableProductsSelectedScrollSnap = instance.selectedScrollSnap();
		});
	}

	// If a default category is selected, open up the product selection right away
	if (configurator.configuration.defaultCategory) {
		configuratorState.setCategory(configurator.configuration.defaultCategory);
		currentStep = 1;
	}

	async function handleCategorySelect(category: ProductCategory) {
		configuratorState.setCategory(category);
		currentStep = 1;
		await tick();
		if (stepElements.productSelection) {
			stepElements.productSelection.scrollIntoView();
		}
	}

	async function handleProductSelectionSubmit() {
		currentStep = 2;
		await tick();
		if (stepElements.frequencySelection) {
			stepElements.frequencySelection.scrollIntoView();
		}
	}

	async function handleFrequencySubmit() {
		if (configuratorState.nonSubscribableProducts().length > 0) {
			currentStep = 3;
			await tick();
			if (stepElements.nonSubscribableProductsSelection) {
				stepElements.nonSubscribableProductsSelection.scrollIntoView();
			}
		} else {
			handleSubmit();
		}
	}

	function calculateSavings(prices: OrderPrices) {
		if (!prices.productsOneTimePrice || !prices.productsRecurringPrice) {
			return 0;
		}

		return (
			prices.productsOneTimePrice -
			prices.discountAmountOnetime -
			(prices.productsRecurringPrice - prices.discountAmount)
		);
	}

	function handleRecurringSubmit() {
		configuratorState.setFrequency(userSelectedFrequency);

		handleFrequencySubmit();
	}

	function handleOnetimeSubmit() {
		// Make sure frequency is set to 0
		configuratorState.setFrequency(0);

		handleFrequencySubmit();
	}

	function handleSubmit() {
		onSubmit(configuratorState.order());
	}
</script>

<!-- Category selection step -->
{#if configuratorState.categories().length > 1}
	<OrderConfiguratorCategories
		title={configurator.categorySelectionIntroTitle}
		subtitle={configurator.categorySelectionIntroSubtitle}
		text={configurator.categorySelectionText}
		onCategorySelect={handleCategorySelect}
	/>
{/if}

<div
	class="step-section step-section--v2"
	class:hidden={currentStep < 1 && requiresCategorySelection}
	bind:this={stepElements.productSelection}
>
	<OrderConfiguratorStepHeader
		title={configurator.productSelectionIntroTitle}
		subtitle={configurator.productSelectionIntroSubtitle}
	/>

	<!-- Product selection -->
	<div class="step product-selection">
		<div class="step-inner">
			<div class="step-main">
				<div class="step-header">
					<h3 class="step-title">
						{configurator.productSelectionTitle}
					</h3>
					{#if configurator.productSelectionSubtitle}
						<div class="step-subtitle">
							{configurator.productSelectionSubtitle}
						</div>
					{/if}
				</div>
				<div class="step-products">
					{#each configuratorState.products() as { product, quantity }, i}
						{@const displayQuantity = quantity * (product.quantityMultiplier ?? 1)}
						<div class="step-product">
							<div class="step-product-title">
								{product.name}
							</div>
							<div class="step-product-input">
								<button onclick={() => configuratorState.decrementProductQuantity(product)}
									>-</button
								>
								<input
									type="text"
									value={displayQuantity}
									onchange={(e) =>
										configuratorState.setProductQuantity(
											product,
											parseInt(e.target.value) / product.quantityMultiplier
										)}
								/>
								<button onclick={() => configuratorState.incrementProductQuantity(product)}
									>+</button
								>
							</div>
						</div>
					{/each}
				</div>
				<div class="step-totals">
					<div class="step-totals-inner">
						{#if !configuratorState.isValidQuantity()}
							<div class="step-totals-error">
								<QuantityValidationMessage
									quantities={configuratorState.quantities()}
									quantity={configuratorState.selectedQuantity()}
									multiplier={configuratorState.quantityMultiplier()}
									{...selectedCategory?.translations}
								/>
							</div>
						{:else}
							<PriceScaleMessage
								quantity={selectedDisplayQuantity}
								priceScales={selectedCategory?.priceScales}
								{...selectedCategory?.translations}
							/>
						{/if}
						<div class="step-totals-count">
							{selectedDisplayQuantity}
							{#if selectedDisplayQuantity === 1}
								{selectedCategory?.translations.unitNameSingle}
							{:else}
								{selectedCategory?.translations.unitNamePlural}
							{/if}
						</div>
					</div>
				</div>
				<div class="step-footer">
					{#if configuratorState.isValid() && prices}
						<!-- TODO: @Mellie move to own component? -->
						{#if prices.productsLowestRecurringPrice !== null}
							{#if prices.discountAmount > 0}
								{@const discountedPrice =
									prices.productsLowestRecurringPrice - prices.discountAmount}

								<div class="step-discount-price">
									{#if site.showUnitPrices}
										<div class="step-discount-price-per-unit">
											{translations.fromPriceLabel}
											{formatCurrency(discountedPrice / selectedDisplayQuantity, site.locale.code)}
											{translations.perUnitText.replaceAll(
												'%product%',
												selectedCategory?.translations.unitNameSingle ?? '%product%'
											)}
										</div>
									{:else}
										<div class="step-discount-price-value">
											{translations.fromPriceLabel}
											{formatCurrency(discountedPrice, site.locale.code)}
										</div>
									{/if}
								</div>
							{:else}
								<div class="step-price">
									{#if !site.showUnitPrices}
										<div class="step-price-label">{translations.fromPriceLabel}</div>
										<div class="step-price-count">
											{formatCurrency(prices.productsLowestRecurringPrice, site.locale.code)}
										</div>
									{/if}
									{#if site.showUnitPrices}
										<div class="step-price-label">
											{translations.fromPriceLabel}
											{formatCurrency(
												prices.productsLowestRecurringPrice / selectedDisplayQuantity,
												site.locale.code
											)}
											{translations.perUnitText.replaceAll(
												'%product%',
												selectedCategory?.translations.unitNameSingle ?? '%product%'
											)}
										</div>
									{/if}
								</div>
							{/if}
						{/if}
					{/if}
					<div class="step-button">
						<button
							class="button button--secondary"
							disabled={!configuratorState.isValidQuantity()}
							onclick={handleProductSelectionSubmit}
						>
							{configurator.productSelectionButtonText}
						</button>
					</div>
				</div>
			</div>
			{#if configurator.productSelectionImage}
				<figure class="step-image">
					<img
						src={configurator.productSelectionImage.url}
						alt={configurator.productSelectionImage.alt}
					/>
				</figure>
			{/if}
		</div>
	</div>
</div>

<div
	class="step-section step-section--v2"
	class:hidden={currentStep < 2}
	bind:this={stepElements.frequencySelection}
>
	<!-- Frequency selection -->
	<OrderConfiguratorStepHeader
		title={configurator.frequencySelectionIntroTitle}
		subtitle={configurator.frequencySelectionIntroSubtitle}
	/>

	<div class="frequency-step">
		<div class="frequency-step-inner">
			<div
				class="frequency-step-box"
				class:frequency-step-box--centered={!configuratorState.isOneTimeOrderAllowed()}
			>
				<h3 class="frequency-step-title">
					{configurator.recurringOrderTitle}
				</h3>
				<div class="frequency-step-select">
					{#if configurator.recurringOrderSelectLabelSingle && configurator.recurringOrderSelectLabelPlural}
						<span class="frequency-step-select-label">
							{formatNumericString(
								configurator.recurringOrderSelectLabelSingle,
								configurator.recurringOrderSelectLabelPlural,
								selectedDisplayQuantity,
								'%quantity%'
							)
								.replaceAll('%product%', selectedCategory?.translations.unitNameSingle ?? '')
								.replaceAll('%products%', selectedCategory?.translations.unitNamePlural ?? '')}
						</span>
					{/if}

					<select
						class="frequency-step-select-input"
						bind:value={userSelectedFrequency}
						onchange={(e) => configuratorState.setFrequency(parseInt(e.target.value))}
					>
						{#each configuratorState.frequencies() as frequency}
							{#if frequency > 0}
								<option
									value={frequency}
									selected={configuratorState.selectedFrequency() == frequency}
								>
									{formatNumericString(
										translations.frequencySingle,
										translations.frequencyMultiple,
										frequency,
										'%quantity%'
									)}
								</option>
							{/if}
						{/each}
					</select>
				</div>
				<div class="frequency-step-text">
					{@html configurator.recurringOrderText}
				</div>
				{#if configuratorState.isValid() && prices}
					{#if prices.productsRecurringPrice !== null && prices.discountAmount !== null}
						<div class="frequency-step-footer">
							<div class="frequency-step-price">
								{#if prices.discountAmount > 0}
									{@const discountedPrice =
										prices.productsRecurringPrice +
										prices.totalNonSubscribableProductsPrice -
										prices.discountAmount}
									<div class="frequency-step-price-label">
										{translations.discountPriceLabel}
									</div>
									<div class="frequency-step-price-value">
										{formatCurrency(discountedPrice, site.locale.code)}
									</div>
									{#if formatCurrency(discountedPrice, site.locale.code) !== formatCurrency(prices.productsRecurringPrice, site.locale.code)}
										<div class="frequency-step-price-after">
											{translations.discountRegularPriceLabel.replaceAll(
												'%price%',
												formatCurrency(prices.productsRecurringPrice, site.locale.code)
											)}
										</div>
									{/if}
								{:else}
									<div class="frequency-step-price-value">
										{formatCurrency(prices.productsRecurringPrice, site.locale.code)}
									</div>
								{/if}
								{#if site.showUnitPrices}
									<div class="frequency-step-price-per-unit">
										{formatCurrency(
											(prices.productsRecurringPrice - prices.discountAmount) /
												selectedDisplayQuantity,
											site.locale.code
										)}
										{translations.perUnitText.replaceAll(
											'%product%',
											selectedCategory?.translations.unitNameSingle ?? '%product%'
										)}
										{#if site.showWeightPrices}
											<div class="frequency-step-tooltip">
												<!-- @TODO: This should be refactored into a proper feature. Hardcoded for cups for now. -->
												<div class="frequency-step-tooltip-label">
													{formatCurrency(
														(prices.productsRecurringPrice - prices.discountAmount) /
															selectedDisplayQuantity /
															0.0053,
														site.locale.code
													)} / 1kg
												</div>
												<div class="frequency-step-tooltip-trigger">i</div>
											</div>
										{/if}
									</div>
								{/if}
							</div>

							<div class="frequency-step-button">
								<button
									class="button button--secondary"
									onclick={handleRecurringSubmit}
									disabled={!configuratorState.isValid()}
								>
									{configurator.recurringOrderButtonText}
								</button>
								{#if calculateSavings(prices) > 0 && configuratorState.isValid()}
									<div class="frequency-step-savings">
										{translations.discountPromotionLabel.replaceAll(
											'%percentage%',
											formatCurrency(calculateSavings(prices), site.locale.code)
										)}
									</div>
								{/if}
							</div>
						</div>
					{/if}
				{/if}
			</div>

			{#if configuratorState.isOneTimeOrderAllowed()}
				<div class="frequency-step-spacer">
					<span>{translations.configurator.andLabel}</span>
				</div>

				<div class="frequency-step-box">
					<h3 class="frequency-step-title">
						{configurator.onetimeOrderTitle}
					</h3>
					<div class="frequency-step-text">
						{@html configurator.onetimeOrderText}
					</div>
					{#if configuratorState.isValid() && prices && prices.productsOneTimePrice !== null}
						<div class="frequency-step-footer">
							<div class="frequency-step-price">
								{#if prices.discountAmountOnetime > 0}
									{@const discountedPrice =
										prices.productsOneTimePrice +
										prices.totalNonSubscribableProductsPrice -
										prices.discountAmountOnetime}
									<div class="frequency-step-price-label">
										{translations.discountPriceLabel}
									</div>
									<div class="frequency-step-price-value">
										{formatCurrency(discountedPrice, site.locale.code)}
									</div>
								{:else}
									<div class="frequency-step-price-value">
										{formatCurrency(prices.productsOneTimePrice, site.locale.code)}
									</div>
								{/if}
								{#if site.showUnitPrices}
									<div class="frequency-step-price-per-unit">
										{formatCurrency(
											(prices.productsOneTimePrice - prices.discountAmountOnetime) /
												selectedDisplayQuantity,
											site.locale.code
										)}
										{translations.perUnitText.replaceAll(
											'%product%',
											selectedCategory?.translations.unitNameSingle ?? '%product%'
										)}

										{#if site.showWeightPrices}
											<div class="frequency-step-tooltip">
												<!-- @TODO: This should be refactored into a proper feature. Hardcoded for cups for now. -->
												<div class="frequency-step-tooltip-label">
													{formatCurrency(
														(prices.productsOneTimePrice - prices.discountAmountOnetime) /
															selectedDisplayQuantity /
															0.0053,
														site.locale.code
													)} / 1kg
												</div>
												<div class="frequency-step-tooltip-trigger">i</div>
											</div>
										{/if}
									</div>
								{/if}
							</div>

							<div class="frequency-step-button">
								<button
									class="button button--outline"
									onclick={handleOnetimeSubmit}
									disabled={!configuratorState.isValid()}
								>
									{configurator.onetimeOrderButtonText}
								</button>
							</div>
						</div>
					{/if}
				</div>
			{/if}
		</div>
	</div>
</div>

<div
	class="step-section step-section--v2"
	class:hidden={currentStep < 3}
	bind:this={stepElements.nonSubscribableProductsSelection}
>
	<!-- Non subscribable product selection -->
	{#if configuratorState.nonSubscribableProducts().length > 0}
		<OrderConfiguratorStepHeader
			title={configurator.nonSubscribableProductSelectionIntroTitle}
			subtitle={configurator.nonSubscribableProductSelectionIntroSubtitle}
		/>

		<div class="step-extras">
			<div
				class="step-extras-slider step-extras-slider--count-{configuratorState.nonSubscribableProducts()
					.length}"
			>
				<Slider
					options={{ loop: false, align: 'start', inViewThreshold: 1 }}
					let:Slide
					on:init={initSlider}
				>
					{#each configuratorState.nonSubscribableProducts() as { product, quantity }}
						<Slide>
							<div class="step-extra">
								{#if product.image}
									<figure class="step-extra-image">
										<img src={product.image.url} alt={product.image.alt} />
									</figure>
								{/if}

								<strong class="step-extra-name">{@html product.name}</strong>

								<span class="step-extra-price">
									{formatCurrency(product.price, site.locale.code)}
								</span>

								<div class="step-extra-quantity">
									<button
										class="step-extra-quantity-button"
										onclick={() => configuratorState.decrementProductQuantity(product)}>-</button
									>
									<input
										class="step-extra-quantity-input"
										type="text"
										value={quantity}
										onchange={(e) =>
											configuratorState.setProductQuantity(product, parseInt(e.target.value))}
									/>
									<button
										class="step-extra-quantity-button"
										onclick={() => configuratorState.incrementProductQuantity(product)}>+</button
									>
								</div>
							</div>
						</Slide>
					{/each}
				</Slider>
				{#if nonSubscribableProductsSlider && nonSubscribableProductsScrollSnapList && nonSubscribableProductsScrollSnapList.length > 1}
					<div class="slider-pagination">
						{#each nonSubscribableProductsScrollSnapList as snapPosition, index}
							<button
								class="slider-bullet"
								class:is-active={index === nonSubscribableProductsSelectedScrollSnap}
								onclick={() => nonSubscribableProductsSlider?.scrollTo(index)}
							/>
						{/each}
					</div>
				{/if}
			</div>
			<div class="step-extras-footer">
				{#if prices?.totalNonSubscribableProductsPrice !== undefined}
					<div class="step-extras-subtotal">
						{translations.totalPriceLabel}: {formatCurrency(
							prices.totalNonSubscribableProductsPrice,
							site.locale.code
						)}
					</div>
				{/if}
				<div class="step-extras-submit">
					<button
						class="button button--secondary"
						disabled={!configuratorState.isValid()}
						onclick={handleSubmit}
					>
						{configurator.nonSubscribableProductsSelectionOrderButtonText}
					</button>
				</div>
			</div>
		</div>
	{/if}
</div>
