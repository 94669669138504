<script lang="ts">
	import type { AddressForm as AddressFormType } from '$models/Form/AddressForm';
	import type { AuthForm as AuthFormType } from '$models/Form/AuthForm';
	import type { ChangePasswordForm as ChangePasswordFormType } from '$models/Form/ChangePasswordForm';
	import type { CustomerInfoForm as CustomerInfoFormType } from '$models/Form/CustomerInfoForm';
	import type { EmailPreferencesForm as EmailPreferencesFormType } from '$models/Form/EmailPreferencesForm';
	import type { Form } from '$models/Form/Form';
	import AddressForm from '$components/Form/AddressForm.svelte';
	import AuthForm from '$components/Form/AuthForm.svelte';
	import ChangePasswordForm from '$components/Form/ChangePasswordForm.svelte';
	import CustomerInfoForm from '$components/Form/CustomerInfoForm.svelte';
	import EmailPreferencesForm from '$components/Form/EmailPreferencesForm.svelte';

	// Component properties
	export let form: Form;
	export let onSubmit: any;
	export let extraForm: any;

	// Explicitely downcast the form model
	let addressForm: AddressFormType;
	let authForm: AuthFormType;
	let changePasswordForm: ChangePasswordFormType;
	let customerInfoForm: CustomerInfoFormType;
	let emailPreferencesForm: EmailPreferencesFormType;
	$: {
		if (form.type === 'address') {
			addressForm = form as AddressFormType;
		} else if (form.type === 'auth') {
			authForm = form as AuthFormType;
		} else if (form.type === 'changePassword') {
			changePasswordForm = form as ChangePasswordFormType;
		} else if (form.type === 'customerInfo') {
			customerInfoForm = form as CustomerInfoFormType;
		} else if (form.type === 'emailPreferences') {
			emailPreferencesForm = form as EmailPreferencesFormType;
		}
	}
</script>

{#if form.type == 'address'}
	<AddressForm form={addressForm} {onSubmit} />
{:else if form.type == 'auth'}
	<AuthForm form={authForm} {onSubmit} {extraForm} />
{:else if form.type == 'changePassword'}
	<ChangePasswordForm form={changePasswordForm} {onSubmit} />
{:else if form.type == 'customerInfo'}
	<CustomerInfoForm form={customerInfoForm} {onSubmit} {extraForm} />
{:else if form.type == 'emailPreferences'}
	<EmailPreferencesForm form={emailPreferencesForm} {onSubmit} />
{/if}
