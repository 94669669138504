import type { CocSuggestion, CocSuggestionsParameters } from '$models/Webparking/CocSuggestions';
import { webparking } from '$lib/webparking';

export async function listCocSuggestions(data: CocSuggestionsParameters): Promise<CocSuggestion[]> {
	const json = await webparking
		.post('coc-suggestions', {
			json: {
				country_code: data.countryCode,
				company_name: data.companyName
			}
		})
		.json();

	return json.data.map((suggestion) => {
		return {
			cocNumber: suggestion.coc_number,
			companyName: suggestion.company_name
		};
	});
}
