<script lang="ts">
	import type { FormBlock } from '$models/Block/FormBlock';

	// Component properties
	export let block: FormBlock;

	const allowedInputFields = ['text', 'email', 'phone', 'url', 'number'];
	const allowedFields = ['textarea', 'select', 'radio', 'checkbox', ...allowedInputFields];
	let errorMessage: string | undefined;
	let errors: Record<string, string> | undefined;
	let submitting = false;
	let success: boolean = false;

	async function handleSubmit(e) {
		submitting = true;
		errorMessage = undefined;
		errors = undefined;
		try {
			const response = await fetch('/api/forms', {
				method: 'POST',
				body: new FormData(e.target)
			});
			const result = await response.json();

			if (!response.ok) {
				// Validation errors
				if (response.status === 409) {
					errors = result;
				} else {
					errorMessage = block.errorMessage;
				}
			} else {
				success = true;
			}
		} catch (error) {
			errorMessage = block.errorMessage;
		} finally {
			submitting = false;
		}
	}
</script>

<div class="block-main">
	{#if success}
		<!-- TODO: Add redirect and page cases? -->
		{#if block.form.successAction.type === 'message'}
			<div class="message message--success">
				{block.form.successAction.message}
			</div>
		{/if}
	{:else}
		<div class="form-wrapper">
			<div class="form-text rich-text">
				{@html block.text}
			</div>
			<div class="form-main">
				<form on:submit|preventDefault={handleSubmit} class="form" class:is-loading={submitting}>
					<!-- Generic error message when nothing better is available -->
					{#if errorMessage !== undefined}
						<div class="form-row">
							<div class="message message--error">
								{errorMessage}
							</div>
						</div>
					{/if}

					<!-- Render fields -->
					{#each block.form.fields as field}
						{#if allowedFields.includes(field.type)}
							{@const validationError = errors ? (errors[field.id] ?? '') : ''}

							<div class="form-row{field.classes ? ' ' + field.classes : ''}">
								<label class="form-label" for={field.id}>
									{field.name}
									{#if field.required}
										<span>*</span>
									{/if}
								</label>

								{#if allowedInputFields.includes(field.type)}
									<!-- Generic input types -->
									<input type={field.type} name={field.id} required={field.required} />
								{:else if field.type === 'textarea'}
									<!-- Textarea -->
									<textarea name={field.id} cols="30" rows="10" required={field.required} />
								{:else if field.type === 'select'}
									<!-- Select -->
									<select name={field.id} id={field.id} required={field.required}>
										{#each field.options as option}
											<option value={option.value}>
												{option.label}
											</option>
										{/each}
									</select>
								{:else if field.type === 'radio'}
									<!-- Radio -->
									<div class="form-radio-group">
										{#each field.options as option, index}
											<div class="form-radio">
												<label for={`${field.id}-${index + 1}`}>
													<input
														type="radio"
														name={field.id}
														value={option.value}
														required={field.required}
														id={`${field.id}-${index + 1}`}
													/>
													<span><!-- input --></span>
													{option.label}
												</label>
											</div>
										{/each}
									</div>
								{:else if field.type === 'checkbox'}
									<!-- Checkbox -->
									<div class="form-checkbox-group">
										<!-- @TODO: Fix multiple selections submission -->
										{#each field.options as option, index}
											<div class="form-checkbox">
												<label for={`${field.id}-${index + 1}`}>
													<input
														type="checkbox"
														name={field.id}
														value={option.value}
														required={field.required}
														id={`${field.id}-${index + 1}`}
													/>
													<span><!-- input --></span>
													{option.label}
												</label>
											</div>
										{/each}
									</div>
								{/if}

								{#if validationError.length > 0}
									<div class="form-error">
										{validationError}
									</div>
								{/if}
							</div>
						{/if}
					{/each}

					<input type="hidden" name="form_id" value={block.form.id} />

					<div class="form-submit">
						<button type="submit" class="button" disabled={submitting}>
							{block.form.submitLabel}
						</button>
					</div>
				</form>
			</div>
			{#if block.image}
				<div class="form-image">
					<img src={block.image.url} alt={block.image.alt} />
				</div>
			{/if}
		</div>
	{/if}
</div>

<style lang="postcss">
	.form {
		&-wrapper {
			max-width: var(--container-extra-small);
			margin: 0 auto;

			@media (--md) {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
			}
		}

		&-text {
			width: 100%;
		}

		&-main {
			flex: 1;

			&:not(:last-child) {
				margin-bottom: var(--spacing-8);

				@media (--md) {
					margin-bottom: 0;
				}
			}

			.form {
				width: 100%;
			}
		}

		&-text {
			margin-bottom: var(--spacing-8);
		}

		&-image {
			max-width: 300px;

			@media (--md) {
				margin-left: var(--spacing-12);
			}
		}
	}
</style>
