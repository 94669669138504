<script lang="ts">
	import { page as pageStore } from '$app/stores';

	// Component properties
	export let page: CartPage;
	export let orderPricesPromise: any;

	function getNonSubscribableProduct(id) {
		return $pageStore.data.products.nonSubscribable.find(
			(nonSubscribableProduct) => nonSubscribableProduct.id === id
		);
	}
</script>

{#await orderPricesPromise then orderPrices}
	{#if orderPrices.freeNonSubscribableProducts.length > 0}
		<div class="summary-row">
			<div class="summary-row-label">
				{page.forms.cart.freeProductsLabel}
			</div>
			<div class="summary-row-value">
				<div class="summary-row-container">
					<ul>
						{#each orderPrices.freeNonSubscribableProducts as freeNonSubscribableProduct}
							{@const product = getNonSubscribableProduct(freeNonSubscribableProduct.id)}
							{#if freeNonSubscribableProduct.quantity > 0 && product}
								<li>
									{freeNonSubscribableProduct.quantity}x {@html product.name}
								</li>
							{/if}
						{/each}
					</ul>
				</div>
			</div>
		</div>
	{/if}
{/await}
