<script lang="ts">
	import type { Form } from '$models/Form/Form';
	import FormElement from '$components/Form/FormElement/FormElement.svelte';
	import { getCartStore, getToastStore } from '$lib/stores';
	import { validateForm } from '$lib/utils';
	import { updatePasswordReset } from '$lib/webparking';
	import { writable } from 'svelte/store';

	// Component properties
	export let form: Form;
	export let token: string;
	export let showLoginForm: any;
	export let extraForm: any;

	const cartStore = getCartStore();
	const toastStore = getToastStore();

	let formState = writable({
		isHot: false,
		elements: {
			email: null,
			newPassword: null,
			repeatNewPassword: null
		},
		errors: {
			email: null,
			newPassword: null,
			repeatNewPassword: null,
			submit: null
		},
		setIsHot: (isHot) => {
			$formState.isHot = isHot;
		},
		setError: (elementName, error) => {
			$formState.errors[elementName] = error;
		}
	});

	function validateRepeatNewPassword(value) {
		if (
			$formState.elements.repeatNewPassword.getValue() !==
			$formState.elements.newPassword.getValue()
		) {
			return form.repeatNewPasswordInvalidError;
		}
		return null;
	}

	async function onSubmit() {
		let isExtraFormValid = true;
		let couponCodeValue = null;
		if (extraForm !== null && extraForm !== undefined) {
			if (extraForm.isEditing()) {
				[isExtraFormValid, couponCodeValue] = await extraForm.submit(null, false);
			}
		}
		if (!validateForm($formState) || !isExtraFormValid) {
			return;
		}

		try {
			const cart = await $cartStore;
			if (cart !== null && couponCodeValue !== null) {
				cart.couponCode = couponCodeValue;
				cartStore.setCart(cart);
			}

			await updatePasswordReset({
				email: $formState.elements.email.getValue(),
				password: $formState.elements.newPassword.getValue(),
				token: token
			});
			$formState.setError('submit', null);
			showLoginForm();
			toastStore.trigger({
				message: form.submitSuccess,
				type: 'success'
			});
		} catch (error) {
			$formState.setError('submit', form.submitError);
		}
	}
</script>

<h2 class="box-title">
	{form.title}
</h2>

<form class="form" on:submit|preventDefault={onSubmit}>
	<div class="form-main">
		{#if $formState.errors.submit}
			<div class="message message--error">{$formState.errors.submit}</div>
		{/if}
		<FormElement
			type="input"
			subType="email"
			size="large"
			name="email"
			label={form.emailLabel}
			requiredError={form.emailRequiredError}
			invalidError={form.emailInvalidError}
			formState={$formState}
			bind:this={$formState.elements.email}
		/>
		<FormElement
			type="input"
			subType="password"
			size="large"
			name="newPassword"
			label={form.newPasswordLabel}
			requiredError={form.newPasswordRequiredError}
			minLength={8}
			minLengthError={form.newPasswordMinLengthError}
			formState={$formState}
			bind:this={$formState.elements.newPassword}
		/>
		<FormElement
			type="input"
			subType="password"
			size="large"
			name="repeatNewPassword"
			label={form.repeatNewPasswordLabel}
			requiredError={form.repeatNewPasswordRequiredError}
			validator={validateRepeatNewPassword}
			formState={$formState}
			bind:this={$formState.elements.repeatNewPassword}
		/>
	</div>
	<div class="form-submit">
		<button class="button" type="submit">{form.submitButtonLabel}</button>
	</div>
	<div class="form-row">
		<button class="form-toggle-link" on:click={showLoginForm}>{form.loginButtonLabel}</button>
	</div>
</form>
