<script lang="ts">
	import { page as pageStore } from '$app/stores';
	import ArrowRightIcon from '$components/UI/Icons/ArrowRightIcon.svelte';
	import { getCartStore } from '$lib/stores';
	import { scrollElementIntoView } from '$lib/utils';
	import { getOrderPricesDiscountData } from '$lib/webparking';
	import { tick } from 'svelte';

	// Component properties
	export let page: CartPage;
	export let orderPricesPromise: any;

	const cartStore = getCartStore();

	let isAddCouponCodeFormVisible = false;
	let addCouponCodeError = null;
	let addCouponCodeInput = null;
	async function startAddCouponCodeForm() {
		addCouponCodeError = null;
		isAddCouponCodeFormVisible = true;
		await tick();
		addCouponCodeInput.focus();
	}
	function cancelAddCouponCodeForm() {
		isAddCouponCodeFormVisible = false;
	}

	export function scrollIntoView() {
		scrollElementIntoView(addCouponCodeInput.closest('.summary-row'));
	}

	export function isEditing() {
		return isAddCouponCodeFormVisible;
	}
	async function removeCouponCode() {
		const cart = await $cartStore;
		cartStore.setCart({
			...cart,
			couponCode: null
		});
	}
	export async function submit(event, updateCart) {
		event?.preventDefault();
		const cart = await $cartStore;
		const newOrder = {
			...cart,
			couponCode: addCouponCodeInput.value
		};
		const orderPrices = await orderPricesPromise;
		try {
			const discountData = await getOrderPricesDiscountData(newOrder, orderPrices);
			if (discountData.isApplicable) {
				if (updateCart !== false) {
					cartStore.setCart(newOrder);
				}
				isAddCouponCodeFormVisible = false;
				if (updateCart === false) {
					return [true, addCouponCodeInput.value];
				}
				return true;
			} else {
				addCouponCodeError = discountData.noApplicableDiscountReason.message;
			}
		} catch (error) {
			addCouponCodeError = $pageStore.data.page.layout.translations.errors.discountCodeInvalidError;
		}
		if (updateCart === false) {
			return [false, null];
		}
		return false;
	}
</script>

{#await $cartStore then cart}
	<div class="summary-row">
		<div class="summary-row-label">
			{page.forms.cart.discountCodeLabel}
		</div>
		<div class="summary-row-value">
			<div class="coupon">
				{#if cart.couponCode === null}
					{#if isAddCouponCodeFormVisible}
						<form class="coupon-form" on:submit={submit}>
							<input class="coupon-field" type="text" bind:this={addCouponCodeInput} />
							<button class="coupon-button" type="submit">
								<ArrowRightIcon />
								<span class="hidden">
									{page.forms.cart.discountCodeSaveLabel}
								</span>
							</button>
							<button class="coupon-cancel" on:click={cancelAddCouponCodeForm}>
								{page.forms.cart.discountCodeCancelLabel}
							</button>
							{#if addCouponCodeError !== null}
								<div class="coupon-validation">
									<div class="message message--error">
										{addCouponCodeError}
									</div>
								</div>
							{/if}
						</form>
					{:else}
						<button class="coupon-toggle" on:click={startAddCouponCodeForm}>
							{page.forms.cart.discountCodeAddLabel}
						</button>
					{/if}
				{:else}
					<div class="coupon-container">
						<span class="coupon-label">{cart.couponCode}</span>
						<span class="coupon-remove" on:click={removeCouponCode}>
							{page.forms.cart.discountCodeRemoveLabel}
						</span>
					</div>
				{/if}
			</div>
		</div>
	</div>
{/await}

<style lang="postcss">
	.coupon {
		&-toggle {
			color: var(--coupon-toggle-color);
			text-decoration: underline;
		}
		&-form {
			display: flex;
			flex-wrap: wrap;
			padding-top: var(--spacing-1);
			padding-bottom: var(--spacing-2);
		}
		&-field {
			flex: 1;
			margin-right: 10px;
		}
		&-button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			color: white;
			background-color: var(--color-primary);
		}
		&-cancel {
			display: block;
			margin-top: var(--spacing-2);
			width: 100%;
			font-size: var(--font-size-0);
			text-align: left;
			text-decoration: underline;
			color: var(--color-grey-3);
		}
		&-validation {
			margin-top: var(--spacing-3);
		}
		&-container {
			display: flex;
		}
		&-remove {
			font-size: var(--font-size-0);
			font-weight: var(--font-weight-4);
			color: var(--color-primary);
			text-decoration: underline;
			margin-left: auto;

			&:hover {
				color: var(--color-primary);
				cursor: pointer;
			}
		}
	}
</style>
