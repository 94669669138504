<script lang="ts">
	// Component properties
	export let subType: string;
	export let name: string;
	export let options: any;
	export let formState: any;
	export let onChange: any;
	export let onFocus: any | undefined;
	export let onBlur: any | undefined;
	export let defaultValue: any;
	let value = defaultValue;
	if (value === undefined) {
		value = [];
	}

	export function getValue() {
		return value;
	}

	export function setValue(newValue) {
		value = newValue;
	}

	export function validate() {
		return true;
	}

	export function isValid() {
		return true;
	}

	export function scrollIntoView() {
		return;
	}
</script>

{#if subType === 'checkbox'}
	<div class="form-checkbox-group">
		{#each options as option}
			<div class="form-checkbox">
				<label for={name + '_option_' + option[0]}>
					<input
						type="checkbox"
						id={name + '_option_' + option[0]}
						{name}
						value={option[0]}
						checked={value === option[0]}
						bind:group={value}
						on:change={onChange}
					/>
					<span />
					{option[1]}
				</label>
			</div>
		{/each}
	</div>
{:else if subType === 'radio'}
	<div class="form-radio-group">
		{#each options as option}
			<div class="form-radio">
				<label for={name + '_option_' + option[0]}>
					<input
						type="radio"
						id={name + '_option_' + option[0]}
						{name}
						value={option[0]}
						checked={value === option[0]}
						bind:group={value}
						on:change={onChange}
					/>
					<span />
					{option[1]}
				</label>
			</div>
		{/each}
	</div>
{:else if subType === 'select'}
	<div class="form-select">
		<select {name} bind:value on:change={onChange}>
			{#each options as option}
				<option value={option[0]} checked={value === option[0]}>{option[1]}</option>
			{/each}
		</select>
	</div>
{/if}
