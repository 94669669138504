import type { UserAddress } from '$models/User/UserAddress';
import type { CustomerAddress as WebparkingUserAddressData } from '$models/webparking/CustomerAddresses'; // @TODO: TS error?
import { webparking } from '$lib/webparking';

function userAddressToWebparkingUserAddressData(
	userAddress: UserAddress
): WebparkingUserAddressData {
	let houseNumber = userAddress.houseNumber;
	if (userAddress.houseNumberSuffix !== null) {
		houseNumber += ' ' + userAddress.houseNumberSuffix;
	}
	return {
		id: userAddress.id !== null ? userAddress.id : undefined,
		customer_address_type_id: userAddress.type,
		country_code: userAddress.country.toUpperCase(),
		postal_code: userAddress.postalCode,
		house_number: houseNumber,
		street: userAddress.street,
		city: userAddress.city
	};
}

function webparkingUserAddressDataToUserAddress(data: WebparkingUserAddressData): UserAddress {
	const houseNumberParts = data.house_number.split(' ');
	return {
		id: data.id,
		type: data.customer_address_type_id,
		country: data.country_code,
		postalCode: data.postal_code,
		houseNumber: houseNumberParts[0],
		houseNumberSuffix: houseNumberParts.slice(1),
		street: data.street,
		city: data.city
	};
}

export async function createUserAddress(userAddress: UserAddress): Promise<UserAddress> {
	const json = await webparking
		.post('customer-addresses', {
			json: userAddressToWebparkingUserAddressData(userAddress)
		})
		.json();
	return webparkingUserAddressDataToUserAddress(json.data);
}

export async function updateUserAddress(userAddress: UserAddress): Promise<UserAddress> {
	const json = await webparking
		.patch(`customer-addresses/${userAddress.id}`, {
			json: userAddressToWebparkingUserAddressData(userAddress)
		})
		.json();
	return webparkingUserAddressDataToUserAddress(json.data);
}

export async function listUserAddresses(): Promise<UserAddress[]> {
	const json = await webparking.get('customer-addresses/').json();
	return json.data.map(webparkingUserAddressDataToUserAddress);
}

export async function listUserAddressesByType(): Promise<[UserAddress[], UserAddress[]]> {
	const userAddresses = await listUserAddresses();
	return [
		userAddresses.filter((userAddress) => userAddress.type === 'billing'),
		userAddresses.filter((userAddress) => userAddress.type === 'shipping')
	];
}

export async function deleteUserAddress(userAddress: UserAddress): Promise<void> {
	return webparking.delete(`customer-addresses/${userAddress.id}`);
}
