<script lang="ts">
	import { page as pageStore } from '$app/stores';
	import OrdersTable from '$components/Account/Orders/OrdersTable.svelte';
	import Loader from '$components/UI/Loader.svelte';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { listOrders } from '$lib/webparking';
	import { onMount } from 'svelte';

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();

	// Component properties
	export let accountBlock: any;

	let ordersPromise = new Promise(() => {});
	onMount(async () => {
		await $userStore;
		try {
			ordersPromise = listOrders();
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});
</script>

<div class="account-box">
	<div class="box">
		<h2 class="box-title">
			{$pageStore.data.page.orderHistoryTitle}
		</h2>
		<div class="rich-text">
			{#await ordersPromise}
				<Loader />
			{:then orders}
				{@const totalOrders = orders.length}
				{#if totalOrders === 0}
					{$pageStore.data.page.layout.translations.errors.noOrdersFound}
				{/if}
				{#if totalOrders > 0}
					{#if totalOrders > 4}
						<OrdersTable compact orders={orders.slice(0, 4)} />
						<a href={$pageStore.data.page.layout.pages.accountOrdersPage} class="box-link">
							{$pageStore.data.page.layout.translations.buttonLabels.viewOrders}
						</a>
					{:else}
						<OrdersTable compact {orders} />
					{/if}
				{/if}
			{:catch error}
				{$pageStore.data.page.layout.translations.errors.failedToLoadOrders}
			{/await}
		</div>
	</div>
</div>
