<script lang="ts">
	import type { Order } from '$models/Order/Order';
	import { page } from '$app/stores';
	import DocumentIcon from '$components/UI/Icons/DocumentIcon.svelte';
	import PaymentStatus from '$components/UI/Status/PaymentStatus.svelte';
	import ShippingStatus from '$components/UI/Status/ShippingStatus.svelte';
	import { PUBLIC_WEBPARKING_URL } from '$env/static/public';
	import { getSiteContext } from '$lib/context/site';
	import { formatCurrency, formatDate } from '$lib/utils';

	export let orders: Order[];
	export let compact = false;

	const site = getSiteContext();

	$: translations = $page.data.page.layout.translations;
</script>

<table>
	<thead>
		<tr>
			<th class="order-number">{translations.orderNumberLabel}</th>
			<th class="order-total">{translations.totalPriceLabel}</th>
			{#if !compact}
				<th class="order-payment-status">{translations.paymentStatusLabel}</th>
			{/if}
			<th class="order-delivery-status">{translations.shippingStatusLabel}</th>
			{#if !compact}
				<th class="order-delivery-date">{translations.deliveryDateLabel}</th>
			{/if}
			<th class="order-invoice">{translations.invoiceLabel}</th>
		</tr>
	</thead>
	<tbody>
		{#each orders as order}
			<tr class="order">
				<td class="order-number">
					<a href="{$page.data.page.layout.pages.accountOrdersPage}/?resourceId={order.id}">
						#{order.number}
					</a>
				</td>
				<td class="order-total">
					{order.total ? formatCurrency(order.total, site.locale.code) : '-'}
				</td>
				{#if !compact}
					<td class="order-payment-status">
						<PaymentStatus status={order.paymentStatus} />
					</td>
				{/if}
				<td class="order-delivery-status">
					<ShippingStatus status={order.shippingStatus} />
				</td>
				{#if !compact}
					<td class="order-delivery-date">
						{formatDate(order.deliveryDate, site.locale.code)}
					</td>
				{/if}
				<td class="order-invoice">
					{#if order.paymentStatus === 'paid'}
						<a href={`${PUBLIC_WEBPARKING_URL}v1/orders/${order.id}/invoice`}>
							<DocumentIcon />
						</a>
					{/if}
				</td>
			</tr>
		{/each}
	</tbody>
</table>
