<script lang="ts">
	import type { AccountPage } from '$models/Page/AccountPage';
	import AccountBlock from '$components/Account/AccountBlock/AccountBlock.svelte';
	import AccountColumn from '$components/Account/AccountColumn.svelte';
	import AccountColumns from '$components/Account/AccountColumns.svelte';

	// Component properties
	export let page: AccountPage;
</script>

<AccountColumns>
	{#each page.columns as column}
		<AccountColumn>
			{#each column.blocks as block}
				<AccountBlock accountBlock={block} {page} />
			{/each}
		</AccountColumn>
	{/each}
</AccountColumns>

<style lang="postcss" global>
	.account {
		&-columns {
			@media (--lg) {
				display: flex;
				gap: var(--spacing-6);
				grid-template-columns: repeat(2, 1fr);
			}
		}
		&-column {
			@media (--lg) {
				width: 50%;
			}
		}
		&-box {
			margin-bottom: var(--spacing-6);

			&--desktop {
				display: none;

				@media (--lg) {
					display: block;
				}
			}

			&--mobile {
				@media (--lg) {
					display: none;
				}
			}
		}
		&-slider {
			position: relative;

			&:not(:last-child) {
				margin-bottom: var(--spacing-10);
			}

			&-viewport {
				max-width: 100%;
				overflow: hidden;
			}

			&-container {
				backface-visibility: hidden;
				display: flex;
				touch-action: pan-y;
			}

			&-item {
				display: flex;
				flex-direction: column;
				flex: 0 0 100%;
				min-width: 0;
				position: relative;
			}

			&-title {
				text-transform: uppercase;
				font-size: var(--font-size-3);
				color: var(--color-primary);
			}
		}
	}
	.delivery-info {
		&:not(:last-child) {
			margin-bottom: var(--spacing-5);
		}
		&-item {
			display: flex;
			align-items: flex-start;
			line-height: var(--font-lineheight-3);

			&:not(:last-child) {
				margin-bottom: var(--spacing-1);
			}
		}

		&-flag {
			margin-right: var(--spacing-2);
		}

		&-text {
			font-size: var(--font-size-0);
			flex: 1;
		}
	}
</style>
