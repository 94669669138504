<script lang="ts">
	import type { AccountAddressesPage } from '$models/Page/AccountAddressesPage';
	import { browser } from '$app/environment';
	import AccountAddressDetailPage from '$components/Page/AccountAddressDetailPage.svelte';
	import Loader from '$components/UI/Loader.svelte';
	import { getCartStore, getCheckoutStore, getUserStore } from '$lib/stores';
	import { listUserAddresses } from '$lib/webparking';
	import { onMount } from 'svelte';

	export let page: AccountAddressesPage;

	const cartStore = getCartStore();
	const checkoutStore = getCheckoutStore();
	const userStore = getUserStore();

	let userAddressesPromise = new Promise(() => {});
	let resourceIdPromise = new Promise(() => {});
	onMount(async () => {
		await $userStore;
		try {
			userAddressesPromise = listUserAddresses();
		} catch (error) {
			if (error.response.status === 401) {
				const cart = await $cartStore;
				cartStore.anonymizeCart(cart);
				checkoutStore.clearCheckout();
				userStore.setUser(null);
			}
		}
	});

	$: {
		if (browser) {
			const url = new URL(window.location);
			page.detailPage.resourceId = url.searchParams.get('resourceId');
			resourceIdPromise = Promise.resolve(page.detailPage.resourceId);
		}
	}
</script>

{#await resourceIdPromise then resourceId}
	{#if resourceId === null}
		<h1>{page.layout.translations.entities.addresses}</h1>
		{#await userAddressesPromise}
			<Loader />
		{:then addresses}
			{#if addresses.length}
				<ul>
					{#each addresses as address}
						<li>
							<a href="{page.layout.pages.accountAddressesPage}/?resourceId={address.id}">
								{address.id}
							</a>
						</li>
					{/each}
				</ul>
			{:else}
				{page.layout.translations.errors.noAddressesFound}
			{/if}
		{:catch error}
			{page.layout.translations.errors.failedToLoadAddresses}
		{/await}
	{:else}
		<AccountAddressDetailPage page={page.detailPage} />
	{/if}
{/await}
