<script lang="ts">
	import type { LandingPage } from '$models/Page/LandingPage';
	import Block from '$components/Block/Block.svelte';

	/**
	 * The page model to render.
	 */
	export let page: LandingPage;
</script>

{#each page.blocks as block}
	<Block {block} {page} />
{/each}
