<script lang="ts">
	import type { FeaturedBlock } from '$models/Block/FeaturedBlock';
	import Button from '$components/UI/Buttons/Button.svelte';
	import { sanitizeHtml } from '$lib/utils';

	export let block: FeaturedBlock;
</script>

<div class="featured">
	<div
		class="featured-container featured-container--align-{block.imagePosition} featured-container--size-{block.imageWidth}"
	>
		<figure class="featured-visual {block.showMobileImage ? '' : 'featured-visual--hide-mobile'}">
			<img
				class="featured-image"
				class:featured-image--desktop={block.imageMobile}
				width={block.image.width}
				height={block.image.height}
				alt={block.image.alt}
				src={block.image.url}
			/>

			{#if block.imageMobile && block.showMobileImage}
				<img
					class="featured-image featured-image--mobile"
					width={block.imageMobile.width}
					height={block.imageMobile.height}
					src={block.imageMobile.url}
					alt={block.imageMobile.alt}
				/>
			{/if}
		</figure>

		<div class="featured-main">
			{#if block.title || block.subtitle}
				<div class="featured-header">
					{#if block.subtitle}
						<div class="featured-subtitle">{block.subtitle}</div>
					{/if}
					{#if block.title}
						<h2 class="featured-title">{block.title}</h2>
					{/if}
				</div>
			{/if}
			<div class="featured-content rich-text">
				{@html sanitizeHtml(block.text)}

				{#if block.button}
					<div class="featured-button">
						<Button type="anchor" href={block.button.url}>
							{block.button.title}
						</Button>
					</div>
				{/if}
			</div>
		</div>
	</div>
</div>

<style lang="postcss">
	.featured {
		&-container {
			display: flex;
			flex-direction: column;

			@media (--sm) {
				flex-direction: row;
			}

			&--align-right {
				@media (--sm) {
					flex-direction: row-reverse;
				}
			}
		}

		&-main {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: var(--spacing-8);

			@media (--sm) {
				flex: 1;
				padding: 5%;
				min-height: 400px;
			}

			.featured-container--size-two-third & {
				@media (--xl) {
					padding: 5%;
				}
			}
		}

		&-content {
			font-size: var(--featured-text-font-size);
			line-height: var(--featured-text-lineheight);
		}

		&-header {
			&:not(:last-child) {
				margin-bottom: var(--spacing-6);
			}
		}

		&-subtitle {
			font-family: var(--featured-subtitle-font-family);
			font-weight: var(--featured-subtitle-font-weight);
			font-size: var(--featured-subtitle-font-size-sm);
			color: var(--featured-subtitle-color);
			text-transform: var(--featured-subtitle-text-transform);

			@media (--sm) {
				font-size: var(--featured-subtitle-font-size);
			}
		}

		&-title {
			font-family: var(--featured-title-font-family);
			font-weight: var(--featured-title-font-weight);
			font-size: var(--featured-title-font-size-sm);
			color: var(--featured-title-color);
			text-transform: var(--featured-title-text-transform);

			@media (--sm) {
				font-size: var(--featured-title-font-size);
			}
		}

		&-visual {
			position: relative;
			padding-bottom: 100%;

			&--hide-mobile {
				display: none;

				@media (--sm) {
					display: block;
				}
			}

			@media (--sm) {
				width: 50%;
				padding-bottom: 0;
			}

			.featured-container--size-third & {
				@media (--sm) {
					width: 33.3333333333%;
				}
			}

			.featured-container--size-two-third & {
				@media (--sm) {
					width: 50%;
				}
				@media (--xl) {
					width: 66.6666666667%;
				}
			}
		}

		&-image {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center top;

			&--desktop {
				display: none;

				@media (--sm) {
					display: block;
				}
			}

			&--mobile {
				@media (--sm) {
					display: none;
				}
			}
		}
	}
</style>
